import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { RxCross2 } from "react-icons/rx";
import Inputfield from "../../../components/form/InputField";
import PhoneWithCode from "../../../components/form/PhoneWithCode";
import SelectFromArray from "../../../components/form/SelectFromArray";
import FormSubmitButton from "../../../components/buttons";

const AddReservation = ({ close }) => {
  const { t } = useTranslation();
  const [reservationId, setReservationId] = useState("");
  const [guestName, setGuestName] = useState("");
  const [reservationEmail, setReservationEmail] = useState("");
  const [contact, setContact] = useState("");
  const [checkInDate, setCheckInDate] = useState("");
  const [checkOutDate, setCheckOutDate] = useState("");
  const [arrivingTime, setArrivingTime] = useState("");
  const [numberOfGuests, setNumberOfGuests] = useState("");
  const [numberOfRooms, setNumberOfRooms] = useState("");
  //   const [hotelRooms, setHotelRooms] = useState([]);
  const [streetAddress, setStreetAddress] = useState("");
  const [designation, setDesignation] = useState("");
  const [department, setDepartment] = useState("");
  return (
    <div className="relative w-full h-auto border border-[#E5E9EB] rounded-lg bg-[#FFFFFF] mt-6 p-6">
      <div className="font-bold text-[26px] leading-[32.76px] text-[#07090D] tracking-[-1.5%]">
        {t("Add Reservation")}
      </div>
      <button
        className="absolute rounded-full overflow-hidden font-semibold right-5 top-7"
        onClick={close}
      >
        <RxCross2
          size={26}
          className="hover:rotate-90 duration-500 hover:text-[#F04438]"
        />
      </button>
      <div className="grid md:grid-cols-2 lg:grid-cols-3 md:gap-4 lg:gap-6 my-4">
        <Inputfield
          label={t("Reservation ID")}
          placeholder={t("Enter Reservation ID")}
          value={reservationId}
          onChange={(e) => setReservationId(e.target.value)}
          minLength={3}
          maxLength={30}
        />
        <Inputfield
          label={`${t("Guest Name")} *`}
          placeholder={t("Enter Guest Name")}
          value={guestName}
          onChange={(e) => setGuestName(e.target.value)}
          required={true}
          minLength={3}
          maxLength={30}
        />
        <Inputfield
          label={t("Reservation Email")}
          type="email"
          placeholder={t("Enter Reservation Email")}
          value={reservationEmail}
          onChange={(e) => setReservationEmail(e.target.value)}
          minLength={3}
          maxLength={30}
        />

        <PhoneWithCode
          label={`${t("Contact")} *`}
          placeholder={t("Enter Number")}
          phone={contact}
          setPhone={setContact}
        />
        <Inputfield
          label={t("Check In Date")}
          type="date"
          placeholder={t("503")}
          value={checkInDate}
          onChange={(e) => setCheckInDate(e.target.value)}
          required={true}
        />
        <Inputfield
          label={t("Check Out Date")}
          type="date"
          placeholder={t("503")}
          value={checkOutDate}
          onChange={(e) => setCheckOutDate(e.target.value)}
          required={true}
          min={checkInDate}
        />
        {/* arriving Time */}
        <div className="">
          <label>{`${t("Arriving Time")} *`}</label>
          <input
            type="time"
            className="h-12 w-full "
            value={arrivingTime}
            onChange={(e) => setArrivingTime(e?.target?.value)}
            required
          />
        </div>
        <Inputfield
          label={`${t("Number Of Guests")} *`}
          placeholder={t("509")}
          value={numberOfGuests}
          onChange={(e) => setNumberOfGuests(+e.target.value)}
          type="number"
        />
        <Inputfield
          label={`${t("Number Of Rooms")} *`}
          placeholder={t("509")}
          value={numberOfRooms}
          onChange={(e) => setNumberOfRooms(+e.target.value)}
          type="number"
        />
        {/* <RoomTypeRoomNumbers
          hotelRooms={hotelRooms}
          setHotelRooms={setHotelRooms}
          value={numberOfRooms}
          setValue={setNumberOfRooms}
        />
        {[...Array(+numberOfRooms)]?.map((ro, index) => (
          <RoomTypeRoomNumber
            hotelRooms={hotelRooms}
            setHotelRooms={setHotelRooms}
            index={index}
          />
        ))} */}
        <Inputfield
          label={`${t("Street Address")} *`}
          placeholder={t("Enter Street Address")}
          value={streetAddress}
          onChange={(e) => setStreetAddress(e.target.value)}
          minLength={10}
          maxLength={100}
          required={true}
        />
        <SelectFromArray
          label={t("658")}
          options={[""]}
          value={designation}
          onChange={(e) => setDesignation(e?.target?.value)}
        />
        <SelectFromArray
          label={t("659")}
          options={[""]}
          value={department}
          onChange={(e) => setDepartment(e?.target?.value)}
        />
      </div>
      <div className="flex items-center justify-end mt-8">
        <FormSubmitButton text="Check In" />
      </div>
    </div>
  );
};

export default AddReservation;
