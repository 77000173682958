import React from "react";
import ToolTip from "../common/ToolTip";
import { useTranslation } from "react-i18next";
import { State } from "country-state-city";
import { useEffect } from "react";

const StateNameSelector = ({
  state,
  setState,
  required = false,
  countryCode,
  message = null,
  stateIsoCode,
  setStateIsoCode,
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    if (countryCode) {
      const stateName = State.getStatesOfCountry(countryCode)[0]?.name;
      const iso = State.getStatesOfCountry(countryCode)[0]?.isoCode;
      setState(stateName);
      setStateIsoCode(iso);
    }
  }, [countryCode]);

  return (
    <div>
      <div className="flex justify-between">
        <label>
          {t(`state`)}
        </label>
        <ToolTip Message={message} />
      </div>
      <div className="relative flex items-center">
        <select
          className="border border-[#D0D5DD] w-full h-12 text-sm py-2 px-3 rounded-md focus:border-base-primary"
          onChange={(e) => {
            setState(e?.target?.innerText);
            setStateIsoCode(e?.target?.value);
          }}
          value={stateIsoCode}
          required={required}
        >
          {State.getStatesOfCountry(countryCode)?.map((value) => (
            <option value={value?.isoCode} key={value?.isoCode}>
              {value?.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default StateNameSelector;
