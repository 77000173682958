import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { registerEvent } from "../../../logs";
import {  ONBOARDING_STAGES } from "../../../constants"; 
import SelectFromObject from "../../../components/form/SelectFromObject";
import Inputfield from "../../../components/form/InputField";
import { Link } from "react-router-dom";
import AddSubmitButton from "../../../components/buttons/AddSubmitButton"; 
import ToolTip from "../../../components/common/ToolTip";
import { FiCopy } from "react-icons/fi";
import SelectFromArray from "../../../components/form/SelectFromArray";

const BookingSourceAdd = (props) => {
  const { t } = useTranslation();
  const [source, setSource] = useState("booking.com");
  const [email, setEmail] = useState("");
  const [open, setOpen] = useState(false);
  const [oTACommisssion,setOTACommisssion] = useState("")
  const [bankAccount,setBankAccount] = useState([])
  const [generatedEmail, setGeneratedEmail] = useState("");
  const [accept, setAccept] = useState(false);

  // open modal for confimation of booking source add or not
  const handleClickOpen = (e) => {
    e.preventDefault();
    const data = { source, email };
    setOpen(true);
    registerEvent("BOOKING SOURCE ADD", "booking source submit button", {
      data,
    });
  };

  // sumbit form confirmation
  const handleSubmit = async () => {
    let data = { stage: ONBOARDING_STAGES.DASHBOARD };
    if (
      props?.hotel &&
      props?.hotel?.bookingSource &&
      props?.hotel?.bookingSource?.length
    ) {
      data = {
        hotelId: props?.hotel?._id,
        bookingSource: [...props?.hotel?.bookingSource, { source, email }],
      };
    } else {
      data = {
        hotelId: props?.hotel?._id,
        bookingSource: [{ source, email }],
      };
    }
    if (!source || !email) {
      // notifyError(t("756"));
      setOpen(false)
      return
    } else {
      setOpen(false);
      await props.addBookingSource(data);
    }
  };

  //close booking source add modal / not adding booking source/ confirmation cancel
  const closeModal = () => {
    props.setOpen(false);
    registerEvent("CLOSE MODAL", "booking source add component");
  };

  useEffect(() => {
    if (props?.data && props?.data?.isupdated) {
      // notifySuccess(`${t("757")}, ${email}`);
      props?.reset();
    }
  }, [props?.data]);

  return (
    <>
      {/* <SaveOrNot
        formSubmit={handleSubmit}
        isopen={open}
        close={() => setOpen(false)}
      /> */}
      <div className="w-full mx-auto bg-white border border-[#D0D5DD] rounded-[10px] my-4 pt-6 pb-8 px-6">
        <div className="flex items-center gap-3">
          <div className="font-bold text-[30px] text-center leading-[37.8px] text-black tracking-[-1.5%]">
            {t("413")}
          </div>
          <ToolTip Message={"Add booking source"} size={22}/>
          {/* <CloseButton onClick={closeModal}/>   */}
        </div>
        <form
          className="w-full text-[14px] leading-6 font-normal mt-8"
          onSubmit={handleClickOpen}
        >
          <div className="relative grid grid-cols-3 items-start gap-4">
            <SelectFromArray
              label={t("160")}
              message={t("415")}
              options={[]}
              value={source}
              onChange={(e)=>setSource(e.target.value)}
            />
           
           <Inputfield
              label={t("Email Address")}
              optionalText={` (provided to booking source OTA)`}
              message={t("417")}
              placeholder={t("123@gmail.com")}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required={true}
              icon={<FiCopy size={24}/>}
            />
             <Inputfield
              label={t("OTA Commission Settlement")}
              message={t("417")}
              placeholder={t("16% Per Booking")}
              value={oTACommisssion}
              onChange={(e) => setOTACommisssion(e.target.value)}
            />
            <SelectFromObject
              label={t("Bank Account")}
              optionalText= {` (provided to booking source)`}
              message={t("415")}
              options={{accountNoCorporate:"Account No 1 (Corporate)"}}
              value={bankAccount}
              setValue={setBankAccount}
            />
              <Inputfield
                label={t("758")}
                message={t("759")}
                placeholder={t("760")}
                value={generatedEmail}
                onChange={(e)=>setGeneratedEmail(e.target.value)}
                readOnly={true}
                icon={<FiCopy size={24}/>}
              />
        
            <div className="flex flex-col text-[#000000] underline">
              <Link to={"#"} className="hover:text-base-primary">
                {t("421")}
              </Link>
              <Link to={"#"} className="hover:text-base-primary">
                {t("422")}
              </Link>
              <Link to={"#"} className="hover:text-base-primary">
                {t("423")}
              </Link>
              <Link to={"#"} className="hover:text-base-primary">
                {t("Top email providers")}
              </Link>
              <Link to={"#"} className="hover:text-base-primary">
                {t("Other emails")}
              </Link>
            </div>
          </div>
          <div className="relative w-[66%]">
              <div className="flex gap-2 absolute -top-11">
              <input
                type="checkbox"
                className="h-4 w-4 border border-[#F9F9F9] rounded-[4px]  accent-[#00A35E] mt-1"
                onChange={() => setAccept(!accept)}
                value={accept}
                required={true}
              />
              <span className="w-full">
                {t(
                  `I have enabled forwarding and all my booking emails of booking.com will be forwarded to generated mail`
                )}
              </span>
              </div>
          </div>
          <div className="flex items-center gap-6 mt-6">
            <button
              className="h-10 w-[204px] text-white font-semibold text-sm bg-base-primary hover:bg-base-primary_hover rounded-md"
              type="button"
            >
              {t("425")}
            </button>
            <button
              className="text-base-primary hover:text-base-primary_hover underline"
              type="button"
            >
              {t("426")}
            </button>
          </div>
          <div className="mt-4">
            <AddSubmitButton
              h="h-10"
              width={204}
              text={t("413")}
              disabled={props?.data && props?.data?.loading}
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default BookingSourceAdd;
