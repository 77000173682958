import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

// get assets report for all time By Date action
export const assetsReportByDateAction = createAsyncThunk(
  "reports/assetsReportByDateAction",
  async (credentials, thunkAPI) => {
    try {
      const { hotelId, formData, toDate } = credentials || {};
      if (!hotelId || formData || !toDate) return;
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/reports/assets/by-date`;
      const response = await axios.post(url, credentials);
      // localStorage.setItem(TOKEN, response.data.token);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

//get assets report for all time slice
export const assetsReportByDateSlice = createSlice({
  name: "reports/assetsReportByDateSlice",
  initialState: {
    loading: false,
    assetsreport: [],
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(assetsReportByDateAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(assetsReportByDateAction.fulfilled, (state, action) => {
        state.loading = false;
        state.assetsreport = action.payload.assetsreport;
      })
      .addCase(assetsReportByDateAction.rejected, (state, action) => {
        state.loading = false;
        state.assetsreport = null;
        state.error = action.payload || "Failed to assets Report By Date";
      });
  },
});
