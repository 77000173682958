import React, { useState, useEffect } from "react";
import ReservationInfo from "./ReservationInfo";
// import CancelReservation from "./CancelReservation";

const ReserveRoom = ({ date, bookings, left = false, top = false }) => {
  const [open, setOpen] = useState(false);
  const [booking, setBooking] = useState(null);
  const [cancel, setCancel] = useState(false);

  const handleOpen = () => setCancel(true);

  const handleClose = () => setCancel(false);

  //check for bg colors
  const [isStart, setIsStart] = useState(false);
  const [isMid, setIsMid] = useState(false);
  const [isEnd, setIsEnd] = useState(false);

  // booking status
  const [reserved, setReserved] = useState(false);
  const [occupied, setOccupied] = useState(false);
  const [arrived, setArrived] = useState(false);

  useEffect(() => {
    const today = new Date();
    const newToday = today.toJSON()?.substring(0, 10);
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + 1);
    const realDate = newDate?.toJSON()?.substring(0, 10);
    setBooking(null); 
    if (realDate && bookings?.length && Array.isArray(bookings)) { 
      bookings?.map((bk, index) => {
        const checkInDate = bk?.checkInDate?.substring(0, 10);
        const checkOutDate = bk?.checkOutDate?.substring(0, 10); 
        if (
          (checkInDate <= realDate && checkOutDate >= realDate) ||
          checkInDate === realDate ||
          checkOutDate === realDate
        ) {
          setBooking(bk); 
          if (checkInDate === realDate) setIsStart(true);
          if (checkInDate < realDate && checkOutDate > realDate) setIsMid(true);
          if (checkOutDate === realDate) setIsEnd(true);
          if (checkInDate > newToday) setReserved(true);

          if (checkInDate <= newToday && checkOutDate >= newToday)
            setOccupied(true);
          if (checkOutDate < newToday) setArrived(true);
        }
      });
    }
  }, [date, bookings]);

  useEffect(()=>{
    console.log(booking, reserved, occupied, arrived, isStart, isEnd, isMid);
  }, [booking, reserved, occupied, arrived])

  const mouseOver = () => {
    console.log("ok")
    if (booking && booking?._id) {
      setOpen(true);
    }
  };

  const mouseLeave = () => {
    setOpen(false);
  };

  return (
    <>
    
      <div
        className={`bg-white w-[60px] min-w-[60px] h-[60px] border-r border-b flex justify-center items-center relative`}
        onMouseLeave={mouseLeave}
      >
        <div
          className={`${
            booking && reserved
              ? `reservation-bg-green`
              : booking && occupied
              ? "reservation-bg-blue"
              : booking && arrived
              ? "reservation-bg-red"
              : "hidden"
          }  ${isStart ? "right-0 rounded-l-[4px] w-[31px] border-r-0" : ""} ${
            isMid ? "w-[61px] -left-[1px] border-r-0 border-l-0" : ""
          } ${
            isEnd ? "left-0 rounded-r-[4px] w-[30px] border-l-0" : ""
          } absolute top-[5px] h-[50px]`}
          onClick={mouseOver}
        ></div>
        {booking && open && !cancel ? (
          <ReservationInfo
            booking={booking}
            handleCancel={handleOpen}
            top={top}
            left={left}
          />
        ) : null}
      </div>

    <div className="">
    {/* <CancelReservation
        open={cancel}
        handleClose={handleClose}
        booking={booking}
      /> */}
    </div>
    </>
  );
};

export default ReserveRoom;
