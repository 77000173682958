import React, { useState } from "react";
import ToolTip from "../../../components/common/ToolTip";
import Inputfield from "../../../components/form/InputField";
import SelectFromObject from "../../../components/form/SelectFromObject"; 
import { TbSquareRoundedPlus } from "react-icons/tb";
import CustomeTable from "../../../components/widget/CustomeTable";

const AddCorporateAccountDetails = () => {
  const [corporateName, setCorporateName] = useState("indigo");
  const [contactPerson, setContactPerson] = useState("Rahul (Manager)");
  const [bankAccount, setBankAccount] = useState("");
  const [addPackage, setAddPackage] = useState([]);
  const [priceType, setPriceType] = useState([]);
  const [billingCycle, setBillingCycle] = useState([]);
  const [corporateDiscount, setCorporateDiscount] = useState([]);
  const [termAndConditions, setTermAndConditions] = useState("loreum ipase");

  const packageRowData = [
    {
      packageId: "01",
      packageName: "Special Corporate Package",
      roomTypeAdded: "Deluxe Room",
      mealPlaneAdded: "Breakfast",
      serviceAdded: "Airport Transfer",
      availabilityFor: "Corporate",
    },
  ];
  const columnDefsPackage = [
    {
      headerName: "Package Id",
      field: "packageId",
    },
    {
      headerName: "Package Name",
      field: "packageName",
      filter: true,
    },
    {
      headerName: "Room Type Added",
      field: "roomTypeAdded",
      filter: true,
    },
    {
      headerName: "Meal Plane Added",
      field: "mealPlaneAdded",
      filter: true,
    },
    {
      headerName: "Service Added",
      field: "serviceAdded",
      filter: true,
    },
    {
      headerName: "availability For",
      field: "availabilityFor",
      filter: true,
    },
  ];
  const ServicesRowData = [
    {
      serviceId: "001",
      serviceName: "Airport Transfer",
      serviceType: "Transportation",
      availableStatus: "Active Service",
      servicesTimings: "7:00AM- 8:00PM",
    },
  ];
  const columnDefsServices = [
    {
      headerName: "Service Id",
      field: "serviceId",
    },
    {
      headerName: "Service Name",
      field: "serviceName",
      filter: true,
    },
    {
      headerName: "Service Type",
      field: "serviceType",
      filter: true,
    },
    {
      headerName: "Available Status",
      field: "availableStatus",
      filter: true,
    },
    {
      headerName: "Service Timmings",
      field: "servicesTimings",
      filter: true,
    },
  ];
  return (
    <div className="w-full border border-[#D0D5DD] rounded-[10px] bg-white">
      <div className="border-b border-[#D0D5DD] px-6 py-8">
        <div className="flex items-center gap-3 ">
          <div className="text-[20px] leading-[25.2px] font-bold text-[#07090D] tracking-[-1.5%]">
            Add Corporate Account Details
          </div>
          <ToolTip size={22} Message={"Add Corporate Account Details"} />
        </div>
        <div className="grid grid-cols-3 items-center gap-4 mt-6">
          <Inputfield
            label={"Corporate Name"}
            message={"enter Corporate Name"}
            placeholder={"Enter Corporate Name"}
            value={corporateName}
            onChange={(e) => setCorporateName(e.target.value)}
          />
           <Inputfield
            label={"Contact Person"}
            message={"417"}
            placeholder={"Enter Contact person name"}
            value={contactPerson}
            onChange={(e) => setContactPerson(e.target.value)}
          />
          <SelectFromObject
            label={"Bank Account"}
            optionalText={` (provided to booking source)`}
            message={"415"}
            options={{ accountNoCorporate: "Account No 1 (Corporate)" }}
            value={bankAccount}
            setValue={setBankAccount}
          />
        </div>
      </div>
      <div className="border-b border-[#D0D5DD] px-6 py-8">
        <div className="text-[20px] leading-[25.2px] font-bold text-[#07090D] tracking-[-1.5%]">
          Add Financial Details
        </div>
        <div className="grid grid-cols-4 items-center gap-4 mt-6">
          <SelectFromObject
            label={"Add Package"}
            message={"415"}
            options={{ specialPackageCorparate: "Special Package Corparate" }}
            value={addPackage}
            setValue={setAddPackage}
          />
          <SelectFromObject
            label={"Price Type"}
            message={"415"}
            options={{}}
            value={priceType}
            setValue={setPriceType}
          />
          <SelectFromObject
            label={"Billing Cycle"}
            message={"415"}
            options={{}}
            value={billingCycle}
            setValue={setBillingCycle}
          />
          <SelectFromObject
            label={"Corporate Discount"}
            message={"415"}
            options={{ten:"10%"}}
            value={corporateDiscount}
            setValue={setCorporateDiscount}
          />
        </div>
      </div>
      <div className="border-b border-[#D0D5DD] px-6 py-8">
        <div className="text-[20px] leading-[25.2px] font-bold text-[#07090D] tracking-[-1.5%]">
          Special Terms & Conditions
        </div>
        <div className="flex items-end gap-4 mt-6 mb-4">
          <button
            type="button"
            className="size-fit rounded-full text-base-primary hover:text-base-primary_hover duration-300 ease-[0.3s]"
          >
            <TbSquareRoundedPlus size={48} />
          </button>
          <Inputfield
            label={"Terms & Conditions"}
            value={termAndConditions}
            onChange={(e) => setTermAndConditions(e.target.value)}
            message={"enter Terms & Conditions"}
            placeholder={"Enter Term & Conditions"}
            className={"w-full"}
          />
        </div>
      </div>
      <div className="border-b border-[#D0D5DD] px-6 py-8">
        <div className="text-[20px] leading-[25.2px] font-bold text-[#07090D] tracking-[-1.5%]">
          Add Packages
        </div>
        <div className="mt-4">
          <label htmlFor="table">Selected Package</label>
          <CustomeTable
            id={"table"}
            size={"h-[20vh]"}
            columnDefs={columnDefsPackage}
            rowData={packageRowData}
            pagination={false}
            showButtons={false}
          />
        </div>
      </div>
      <div className="px-6 py-8">
        <div className="text-[20px] leading-[25.2px] font-bold text-[#07090D] tracking-[-1.5%]">
          Add Services
        </div>
        <div className="mt-4">
          <label htmlFor="table">Selected Services</label>
          <CustomeTable
            id={"table"}
            size={"h-[20vh]"}
            columnDefs={columnDefsServices}
            rowData={ServicesRowData}
            pagination={false}
            showButtons={false}
          />
        </div>
      </div>
    </div>
  );
};

export default AddCorporateAccountDetails;
