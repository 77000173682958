/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { registerEvent } from "../../logs/";
import DeleteOrNot from "../../components/modals/DeleteOrNot";
import BackGroundLoadSpinner from "../../components/common/loader/BackGroundLoadSpinner";
import { ROUTES } from "../../constants";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CustomeTable from "../../components/widget/CustomeTable";
import DeleteButton from "../../components/buttons/DeleteButton";
import OutlineButton from "../../components/buttons/OutlineButton";
import EditButton from "../../components/buttons/EditButton"; 
import SubmitButton from "../../components/buttons/SubmitButton";
import { deletekitchenMenuItemAction } from "../../lib/slices/kitchen/menuSlice";

const AllMenuItem = ({ setMenu, kitchen }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { menuItems } = useSelector((state) => state.getAllKitchenMenuItem);
  console.log("menuItems", menuItems);

  const { loading: delLoading } = useSelector(
    (state) => state.deletekitchenMenuItem
  );
  console.log("delLoading", delLoading);

  const [index, setIndex] = useState([1]);

  const [datas, setDatas] = useState([]);

  const [selectedRows, setSelectedRows] = useState([]);
  const [deleteId, setDeleteId] = useState("");
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  //set kitchen menu as datas
  useEffect(() => {
    if (menuItems && Array.isArray(menuItems)) {
      let newData = [];
      menuItems?.map((item) => {
        newData.push({
          itemId: item?.itemId,
          foodAndBeveragesName: item?.foodAndBeveragesName,
          foodAndBeverageType: item?.foodAndBeverageType,
          price: item?.price,
          dietaryPreference: item?.dietaryPreference,
          portionSize: item?.portionSize,
          available: item?.available,
          availableIn: item?.availableIn,
          avgOrders: item?.avgOrders,
          invAvailable: item?.invAvailable,
          _id: item?._id,
        });
      });
      if (newData?.length) {
        setDatas(newData);
      }
    }
  }, [menuItems]);

  //set menu item for delete
  // const setMenuItem = (id) => {
  //   menuItems?.map((item) => {
  //     if (item.id === id) {
  //       setMenu(item);
  //     }
  //   });
  // };

  //delete menu items modal open for confirmation
  // const delMenuItem = (id) => {
  //   if (id) {
  //     setOpen(true);
  //     setDeleteId(id);
  //   }
  // };

  // confirm to delete menu item
  const deleteConfirmed = () => {
    registerEvent("delete confirm", "menu item", deleteId);
    dispatch(deletekitchenMenuItemAction(kitchen._id, deleteId));
  };

  // delete not confirmed to delete menu item
  const closeModal = () => {
    setOpen(false);
    setDeleteId("");
  };
  //view AllMenuItem Actions buttons
  const ViewAllMenuItemActions = () => {
    const setAllMenuItemRedirect = (link) => {
      if (link) {
        navigate(link);
      }
    };
    return (
      <div className="h-full flex items-center gap-2">
        <SubmitButton
          type="button"
          text={t("1085")}
          onClick={() =>
            setAllMenuItemRedirect(ROUTES.KITCHEN_MENU_INGREDIENTS)
          }
        />
        <EditButton />
        <DeleteButton />
      </div>
    );
  };

  const tableButtonName = [
    {
      id: 1,
      name: "Food Table",
    },
    {
      id: 2,
      name: "Baverages Table",
    },
  ];

  const handleTableButton = (id) => {
    setIndex(id);
  };
  const rowData = [
    {
      itemId: "IT01",
      foodAndBeveragesName: "Chicken Wings",
      foodAndBeverageType: "Appetizer",
      price: "200",
      dietaryPreference: "Yes",
      portionSize: "Individual",
      available: "Regular",
      availableIn: "Room,Restaurant",
      avgOrders: "10",
      invAvailable: "12",
    },
    {
      itemId: "IT02",
      foodAndBeveragesName: "Chicken Wings",
      foodAndBeverageType: "Appetizer",
      price: "500",
      dietaryPreference: "Yes",
      portionSize: "Sharing",
      available: "Regular",
      availableIn: "Bar & Cafe,Buffet",
      avgOrders: "12",
      invAvailable: "4",
    },
    {
      itemId: "IT03",
      foodAndBeveragesName: "Caesar Salad",
      foodAndBeverageType: "Appetizer",
      price: "200",
      dietaryPreference: "No",
      portionSize: "Sharing",
      available: "Weekdays",
      availableIn: "Restaurant",
      avgOrders: "20",
      invAvailable: "5",
    },
    {
      itemId: "IT04",
      foodAndBeveragesName: "Steak",
      foodAndBeverageType: "Main Dish",
      price: "600",
      dietaryPreference: "Yes",
      portionSize: "Sharing",
      available: "Weekend",
      availableIn: "Room,Restaurant",
      avgOrders: "10",
      invAvailable: "6",
    },
    {
      itemId: "IT05",
      foodAndBeveragesName: "Beef Platter",
      foodAndBeverageType: "Main Dish",
      price: "100",
      dietaryPreference: "No",
      portionSize: "Platter",
      available: "Regular",
      availableIn: "Restaurant",
      avgOrders: "4",
      invAvailable: "3",
    },
  ];

  const columnDef = [
    {
      headerName: "Item ID",
      field: "itemId",
      minWidth: 140,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Item Name",
      field: "foodAndBeveragesName",
      filter: true,
    },
    {
      headerName: "Item Type",
      field: "foodAndBeverageType",
      filter: true,
    },
    {
      headerName: "Price",
      field: "price",
      filter: true,
    },
    {
      headerName: "Dietary Preference",
      field: "dietaryPreference",
      filter: true,
    },
    {
      headerName: "Portion Size",
      field: "portionSize",
      filter: true,
    },
    {
      headerName: "Available",
      field: "available",
      filter: true,
    },
    {
      headerName: "Available In",
      field: "availableIn",
      filter: true,
    },
    {
      headerName: "Avg Orders",
      field: "avgOrders",
      filter: true,
    },
    {
      headerName: "Avg Orders",
      field: "avgOrders",
      filter: true,
    },
    {
      headerName: "Inv Available",
      field: "invAvailable",
      filter: true,
    },
    {
      headerName: "Actions",
      minWidth: 180,
      cellRenderer: ViewAllMenuItemActions,
    },
  ];
  return (
    <>
      <div className="mt-6">
        <div className="text-[30px] leading-6 font-bold text-[#000000]">
          Food & Beverages Table
        </div>
        <div className="flex items-center gap-4 mt-6 mb-4">
          {tableButtonName?.map((tb) => {
            const { id, name } = tb;
            return (
              <OutlineButton
                key={id}
                text={name}
                active={+index === id}
                onClick={() => handleTableButton(id)}
              />
            );
          })}
        </div>
        {/* {datas.length ? ( */}
        {+index === 1 ? (
          <CustomeTable
            tableName={"Food List Table"}
            rowData={rowData}
            columnDefs={columnDef}
            setSelectedRows={setSelectedRows}
          />
        ) : null}
        {+index === 2 ? (
          <CustomeTable
            tableName={"Beverages List Table"}
            rowData={rowData}
            columnDefs={columnDef}
            setSelectedRows={setSelectedRows}
          />
        ) : null}
        {/* ) : null} */}
      </div>
      <DeleteOrNot
        open={open}
        close={closeModal}
        formSubmit={deleteConfirmed}
      />
      {delLoading ? <BackGroundLoadSpinner /> : null}
    </>
  );
};

export default AllMenuItem;
