import React, { useState } from "react";
// import Seperator from "../../components/layouts/Seperator";
import AllFoodOrder from "./AllFoodOrder";
import FoodOrder from "./FoodOrder";
import OutlineButton from "../../components/buttons/OutlineButton";
import SubmitButton from "../../components/buttons/SubmitButton";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MdOutlineShoppingCart } from "react-icons/md";

const FoodOrderPage = (props) => {
  const { t } = useTranslation();
  const [openFoodOrder, setOpenFoodOrder] = useState(false);
  const [openFoodHistory, setOpenFoodHistory] = useState(true);
  const track = {
    heading: "All Food Orders",
    seperator: [
      {
        text: "Front Desk",
      },
      {
        text: "Food Order",
      },
    ],
  };
  const handleFoodHistory = () => {
    setOpenFoodHistory(true);
    setOpenFoodOrder(false);
  };
  const handleFoodOrder = () => {
    setOpenFoodOrder(true);
    setOpenFoodHistory(false);
  };
  return (
    <div className="page-container">
      <div className="flex items-end justify-between">
        {/* <Seperator data={track} /> */}
        <div className="flex items-center justify-end gap-4">
          <OutlineButton
            text={"Order Food History"}
            onClick={handleFoodHistory}
          />
          <SubmitButton text={"Order Food"} onClick={handleFoodOrder} />
          <div className="flex gap-2">
            <Link
              to={"#"}
              className="py-2 px-3 w-fit h-10 flex items-center bg-[#00a35e] rounded-md gap-2 relative text-white capitalize font-semibold tracking-[-0.6%]"
            >
              <div className="flex flex-col">
                <div className="text-[10px] leading-[10px] text-center">2</div>
                <MdOutlineShoppingCart size={20} />
              </div>
              <div className="text-[14px] leading-[14px] ">Cart</div>
            </Link>
          </div>
        </div>
      </div>
      <div className="h-[calc(100vh] overflow-hidden">
        <div className="my-6">{openFoodOrder ? <FoodOrder /> : null}</div>
        <div>{openFoodHistory ? <AllFoodOrder /> : null}</div>
      </div>
    </div>
  );
};

export default FoodOrderPage;
