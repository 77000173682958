import React, { useState } from "react"; 
import { REPORTS } from "../../constants";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import EmployeeSalaryReports from "./EmployeeSalaryReports";
import InventoryReports from "./InventoryReports";
import PurchasOrderReports from "./PurchasOrderReports";
import ThirdPartyServiceReports from "./ThirdPartyServiceReports";
import RepairMaintenanceReports from "./RepairMaintenanceReports";
import SecurityReports from "./SecurityReports";
import EmployeeAttandnce from "./EmployeeAttandnce"; 
import RoomBookingSalesReport from "./RoomBookingSalesReport";
import OccupancyReport from "./OccupancyReport";
import AssetsReport from "./AssetsReport";
import ExpenseReport from "./ExpenseReport";

const Reports = () => {
  const { t } = useTranslation();
  const { hotel } = useSelector((state) => state.Hotel);
  const [reportOf, setReportOf] = useState(REPORTS[0]);
  //filters
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const headingData = {
    heading: t("278"),
    seperator: [
      { text: t("241") },
      {
        text: t("4"),
      },
    ],
  };
 

  return (
    <>
      {hotel && hotel?._id ? (
        <div className="page-container">
          <div className="mx-auto">
            <div className="flex justify-between items-center mb-4">
              {/* <Seperator data={headingData} /> */}
            </div>
            {/* <FilterReport
              reportOf={reportOf}
              setReportOf={setReportOf}
              fromDate={fromDate}
              setFromDate={setFromDate}
              toDate={toDate}
              setToDate={setToDate}
            /> */}
            {reportOf === REPORTS[0] ? (
              <RoomBookingSalesReport
                hotel={hotel}
                fromDate={fromDate}
                toDate={toDate}
              />
            ) : null}

            {reportOf === REPORTS[1] ? (
              <OccupancyReport
                hotel={hotel}
                fromDate={fromDate}
                toDate={toDate}
              />
            ) : null}
            {reportOf === REPORTS[2] ? (
              <AssetsReport hotel={hotel} fromDate={fromDate} toDate={toDate} />
            ) : null}

            {/* Expense Reports Total blogs */}
            {reportOf === REPORTS[3] ? <ExpenseReport /> : null}
            {/* Employee Salary Reports Total blogs */}
            {reportOf === REPORTS[4] ? <EmployeeSalaryReports /> : null}
            {/* Inventory Reports Total blogs */}
            {reportOf === REPORTS[5] ? <InventoryReports /> : null}

            {/* Room Booking Sales Total blogs*/}
            {reportOf === REPORTS[6] ? <PurchasOrderReports /> : null}

            {/* Third Party Services Total blogs*/}
            {reportOf === REPORTS[7] ? <ThirdPartyServiceReports /> : null}

            {/* Repairs and maintanance  Total blogs*/}
            {reportOf === REPORTS[8] ? <RepairMaintenanceReports /> : null}
            {reportOf === REPORTS[9] ? <SecurityReports /> : null}
            {/* Employee Attendence Total blogs*/}
            {reportOf === REPORTS[10] ? <EmployeeAttandnce /> : null}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Reports;
