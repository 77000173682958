import React, { useState } from "react";
import CustomeTable from "../../../components/widget/CustomeTable";

const AllServices = () => {
  const [selectedRows, setSelectedRows] = useState([]);

  const rowData = [
    {
      serviceName: "abcdefghijklmn",
      serviceType: "abcdefghijklmn",
      servicePrice: "$12.00",
      availabilityStatus: "active",
      discount: "10%",
      serviceTimings: "7:00Am to 8:00Pm",
      description: "abcdefghijklmn",
    },
    {
      serviceName: "abcdefghijklmn",
      serviceType: "abcdefghijklmn",
      servicePrice: "$12.00",
      availabilityStatus: "active",
      discount: "10%",
      serviceTimings: "7:00Am to 8:00Pm",
      description: "abcdefghijklmn",
    },
    {
      serviceName: "abcdefghijklmn",
      serviceType: "abcdefghijklmn",
      servicePrice: "$12.00",
      availabilityStatus: "active",
      discount: "10%",
      serviceTimings: "7:00Am to 8:00Pm",
      description: "abcdefghijklmn",
    },
    {
      serviceName: "abcdefghijklmn",
      serviceType: "abcdefghijklmn",
      servicePrice: "$12.00",
      availabilityStatus: "active",
      discount: "10%",
      serviceTimings: "7:00Am to 8:00Pm",
      description: "abcdefghijklmn",
    },
    {
      serviceName: "abcdefghijklmn",
      serviceType: "abcdefghijklmn",
      servicePrice: "$12.00",
      availabilityStatus: "active",
      discount: "10%",
      serviceTimings: "7:00Am to 8:00Pm",
      description: "abcdefghijklmn",
    },
  ];
  const serviceAction = () => {
    return (
      <div className="h-full flex items-center gap-4">
        {/* <EditButton />
        <DeleteButton /> */}
      </div>
    );
  };
  const columnDefs = [
    {
      headerName: "Service Name",
      field: "serviceName",
      minWidth: 200,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Service Type",
      field: "serviceType",
      filter: true,
    },
    {
      headerName: "Service Price",
      field: "servicePrice",
      filter: true,
    },
    {
      headerName: "Availability Status",
      field: "availabilityStatus",
      filter: true,
    },
    {
      headerName: "Service Timings",
      field: "serviceTimings",
      filter: true,
    },
    {
      headerName: "Description",
      field: "description",
      filter: true,
    },
    {
      headerName: "Actions",
      field: "id",
      minWidth: 200,
      cellRenderer: serviceAction,
    },
  ];

  return (
    <CustomeTable
      tableName={"All Services List Table"}
      rowData={rowData}
      setSelectedRows={setSelectedRows}
      columnDefs={columnDefs}
    />
  );
};

export default AllServices;
