import React, { useState } from "react";
import SelectBox from "../../../components/form/SelectBox";
import ShiftButton from "../../../components/buttons/ShiftButton";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import SubmitButton from "../../../components/buttons/SubmitButton";

const AvailbilityFor = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const rowData = [
    {
      names: "Corporate 1",
    },
    {
      names: "Corporate 2",
    },
    {
      names: "Travel Agent 1",
    },
    {
      names: "Travel Agent 2",
    },
  ];

  const columnDefs = [
    {
      headerName: "Names",
      field: "names",
      checkboxSelection: true,
    },
  ];
  return (
    <div className="text-[#07090D] px-6">
      <div className="text-[30px] leading-[37.8px] font-bold tracking-[-1.5%]">
        Availbility For
      </div>
      <p className="text-[20px] leading-5">
        Lorem ipsum amit epic alit thu Lorem ipsum amit epic alit thu{" "}
      </p>
      <div className="grid grid-cols-10 w-full mt-14">
        {/* Left side */}
        <div className="col-span-4">
          <SelectBox
           tableSize={"h-[58vh]"}
            label={"Select Available For"}
            columnDefs={columnDefs}
            rowData={rowData}
            setSelectedRows={setSelectedRows}
            showBtn={false}
          />
        </div>

        {/* Buttons for add and remove */}
        <div className="col-span-2 flex flex-col items-center justify-center gap-6 px-5">
          <ShiftButton
            className="h-10 w-full"
            name={"Add"}
            icon={<AiOutlineDoubleRight size={18} />}
          />
          <ShiftButton
            className="h-10 w-full"
            name={"Remove"}
            icon={<AiOutlineDoubleLeft size={18} />}
          />
        </div>

        {/* Right side */}
        <div className="col-span-4 w-full">
          <SelectBox
           tableSize={"h-[58vh]"}
            label={"Selected Available For"}
            showBtn={false}
            columnDefs={columnDefs}
            rowData={rowData}
          />
        </div>
      </div>
      <div className="flex items-center justify-end gap-6 mt-8">
        <button
          type="button"
          className="h-12 w-[148px] border border-[#6E7C87] rounded-md text-[20px]  leading-5 font-semibold hover:bg-[#6E7C87] hover:text-white duration-300 ease-[0.3s]"
        >
          Skip
        </button>
        <SubmitButton
          type="button"
          className={"h-12 w-[160px] text-[20px] leading-5"}
          text={"Next"}
        />
      </div>
    </div>
  );
};

export default AvailbilityFor;
