import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ONBOARDING_PROPERTY, PROPERTY_ID, ROUTES } from "../../constants";
import { registerEvent } from "../../logs";
import { useTranslation } from "react-i18next"; 
import { useNavigate } from "react-router-dom";
import AddButton from "../../components/buttons/AddButton";
import CustomeTable from "../../components/widget/CustomeTable";
import { clearHotelAction, getAllHotelsAction , getHotelByIdAction} from "../../lib/slices/hotel/hotelSlice";
import Breadcrumbs from "../../components/common/Breadcrumbs";  

const AllHotel = () => {
  const [datas, setDatas] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch()

  // get all hotels
  const { hotels } = useSelector((state) => state.allHotels);  

  //get all hotels 
  useEffect(() => {
    dispatch(getAllHotelsAction());
  }, []);

  //set datas
  useEffect(() => {
    if (hotels && Array.isArray(hotels)) {
      const data = hotels?.map((hotelData) => {
        return {
          propertyName: hotelData?.propertyName,
          propertyType: hotelData?.propertyType,
          pl: 10,
          expense: 32,
          revenue: 65,
          monthly_ex_and_re: "$4000",
          occupancy: 54,
          _id: hotelData?._id,
        };
      });
      if (data?.length) {
        setDatas(data?.reverse());
      }
    }
  }, [hotels]);

  //select hotel
  const selectDataForTrack = (id) => { 
    if (id) {
      registerEvent("select hotel", "select hotel button", { id });
      if (hotels) {
        const hotel = hotels?.filter((x) => x?._id === id);
        if (hotel[0] && hotel[0]?._id) {
          localStorage.setItem(PROPERTY_ID, hotel[0]?._id);
          dispatch(getHotelByIdAction(hotel[0]?._id)) 
        }
      }
    }
  };
  //add new hotel
  const gotoAddNewHotel = () => { 
    localStorage.removeItem(ONBOARDING_PROPERTY);
    dispatch(clearHotelAction())
    navigate(ROUTES.ONBOARDING);
  };

  const customeButton = (e) => {
    return (
      <button
        className="h-[32px] w-[145px] mt-[1px] capitalize bg-base-primary hover:bg-base-primary_hover rounded text-[14px] text-white leading-6 tracking-[-0.6%] transition-all duration-300 ease-[0s]"
        onClick={() => selectDataForTrack(e?.data?._id)}
      >
        {t("51")}
      </button>
    );
  };

  const columnDefs = [
    {
      headerName: "Hotel ID",
      field: "_id",
      minWidth: 125,
      filter: true,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Property Name",
      field: "propertyName",
      minWidth: 130,
      filter: true,
    },
    {
      headerName: "Property Type",
      field: "propertyType",
      minWidth: 130,
      filter: true,
    },
    {
      headerName: "Pl",
      field: "pl",
      minWidth: 80,
      filter: true,
    },
    {
      headerName: "Expense",
      field: "expense",
      minWidth: 100,
      filter: true,
    },
    {
      headerName: "Revenue",
      field: "revenue",
      minWidth: 100,
      filter: true,
    },
    {
      headerName: "Monthly_ex_and_re",
      field: "monthly_ex_and_re",
      minWidth: 130,
      filter: true,
    },
    {
      headerName: "Occupancy",
      field: "occupancy",
      minWidth: 130,
      filter: true,
    },
    {
      headerName: "Actions",
      field: "_id",
      cellRenderer: customeButton,
      minWidth: 200,
    },
  ];

  const track = {
    heading: t("My Hotel"),
    seperator: [
      {
        text: t("492"),
      },
      { text: t("491") },
    ],
  };

  return (
    <div className="page-container">
      <div className="w-full flex items-end justify-between mx-auto mb-6"> 
        <Breadcrumbs data={track}/>
        <AddButton onClick={gotoAddNewHotel} text={t("58")} />
      </div>
      {/* <div className="flex items-center justify-end gap-4 text-[14px] leading-[17.64px] tracking-[-0.8px] font-normal text-[#18181B] mb-4">
        <div className="h-10 w-[308px] flex items-center justify-center border border-[#0000001a] rounded-md bg-white hover:bg-[#f5f7f2] overflow-hidden">
          <div className="relative w-[308px] h-10 flex flex-row justify-center items-center gap-3 border  rounded-md box-border bg-white text-[#18181B] p-3">
            <input
              value={props.date}
              onChange={(e) => props.setDate(e.target.value)}
              type={"date"}
              className="flex w-1/2 my-[10px] bg-white items-center border-none shadow-none rounded h-5 font-normal cursor-pointer select-none text-[14px] leading-[18px] tracking-[-0.8px] focus:border-none"
            />
            <input
              value={props.date}
              onChange={(e) => props.setDate(e.target.value)}
              type={"date"}
              className="flex w-1/2 my-[10px] bg-white items-center border-none shadow-none rounded h-5 font-normal cursor-pointer select-none text-[14px] leading-[18px] tracking-[-0.8px] focus:border-none"
            />
          </div>
        </div>
      </div> */}
      <CustomeTable
        columnDefs={columnDefs}
        rowData={datas}
        setSelectedRows={setSelectedRows}
      />
    </div>
  );
};
export default AllHotel;
