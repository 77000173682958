import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux"; 

const ExpenseTopCategory = (props) => {
  const { t } = useTranslation();
  const [datas, setDatas] = useState([]);
  const { sales } = useSelector((state) => state.SalesReport);

  useEffect(() => {
    props.getAssetsFromToDateAll(
      props?.hotel?._id,
      props?.fromDate,
      props?.toDate
    );
  }, [props?.fromDate, props?.toDate]);

  useEffect(() => {
    if (sales && Array.isArray(sales) && sales?.length) {
      let expense = sales?.map((d) => {
        return {
          categoryName: d?.categoryName,
          amount: d?.amount,
        };
      });
      setDatas(expense);
    }
  }, [sales]);
  const tableData = [
    {
      categoryName: "Food",
      amount: "$105.55",
    },
    {
      categoryName: "Equipment",
      amount: "$351.02",
    },
    {
      categoryName: "Salary",
      amount: "$106.58",
    },
    {
      categoryName: "Transportation",
      amount: "$779.58",
    },
    {
      categoryName: "Program",
      amount: "$328.85",
    },
    {
      categoryName: "Marketing",
      amount: "$589.99",
    },
    {
      categoryName: "Food",
      amount: "$105.55",
    },
    {
      categoryName: "Equipment",
      amount: "$351.02",
    },
    {
      categoryName: "Salary",
      amount: "$106.58",
    },
    {
      categoryName: "Transportation",
      amount: "$779.58",
    },
    {
      categoryName: "Program",
      amount: "$328.85",
    },
    {
      categoryName: "Marketing",
      amount: "$589.99",
    },
    {
      categoryName: "Food",
      amount: "$105.55",
    },
    {
      categoryName: "Equipment",
      amount: "$351.02",
    },
    {
      categoryName: "Salary",
      amount: "$106.58",
    },
    {
      categoryName: "Transportation",
      amount: "$779.58",
    },
    {
      categoryName: "Program",
      amount: "$328.85",
    },
    {
      categoryName: "Marketing",
      amount: "$589.99",
    },
    {
      categoryName: "Food",
      amount: "$105.55",
    },
    {
      categoryName: "Equipment",
      amount: "$351.02",
    },
    {
      categoryName: "Salary",
      amount: "$106.58",
    },
    {
      categoryName: "Transportation",
      amount: "$779.58",
    },
    {
      categoryName: "Program",
      amount: "$328.85",
    },
    {
      categoryName: "Marketing",
      amount: "$589.99",
    },
  ];

  return (
    <div>
      <div className="border-b border-[#E5E9EB] h-[80px]">
        <div className="text-[18px] leading-[22.68px] tracking-[-0.8px] text-[#000000] font-medium mx-5 mt-4">
          Top Category
        </div>
        <div className="flex items-center justify-between px-5 text-[14px] leading-6 font-normal text-[#6E7C87] tracking-[-0.6%] mt-5">
          <div className="">Category Name</div>
          <div className="">Amount</div>
        </div>
      </div>
      <div className="overflow-y-auto h-[348px] w-full scroll-bar3">
        <ul className="h-[360px] w-full px-2">
          {tableData.map((data) => {
            return (
              <li
                className="flex items-center justify-between border-t  py-4 p-3"
                key={data.bookingNo}
              >
                {data.categoryName}
                <div className="">{data.amount}</div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: state.AssetsReport,
});

const mapDispatchToProps = (dispatch) => ({ 
});

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseTopCategory);
