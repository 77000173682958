/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import ToolTip from "../common/ToolTip";
import { Country } from "country-state-city";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const CountryNameSelector = ({
  setCountry,
  required = false,
  countryIsoCode,
  setCountryIsoCode,
  meessage = null,
  label="Country" 
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    setCountry(Country.getAllCountries()[0]?.name);
    setCountryIsoCode(Country.getAllCountries()[0]?.isoCode);
  }, []);

  return (
    <>
      <div>
        <div className="flex justify-between">
          <label>
            {label}
          </label>
          <ToolTip Message={meessage} />
        </div>
        <div className="relative flex items-center">
          <select
            className={`border border-[#D0D5DD] w-full h-12 text-sm py-2 px-3 rounded-md focus:border-base-primary`}
            onChange={(e) => {
              setCountry(e.target.innerText);
              setCountryIsoCode(e?.target?.value);
            }}
            value={countryIsoCode}
            required={required}
          >
            {Country.getAllCountries()?.map((value) => (
              <option value={value?.isoCode} key={value?.isoCode}>
                {value?.name}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
};

export default CountryNameSelector;
