import React, { useState } from "react";
import CloseButton from "../../../components/buttons/CloseButton";
import SelectFromObject from "../../../components/form/SelectFromObject";
import SubmitButton from "../../../components/buttons/SubmitButton";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai"; 
import ShiftButton from "../../../components/buttons/ShiftButton";
import SelectBox from "../../../components/form/SelectBox";

const AddAmenitiesToRoom = (props) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [amenitiesListName, setAmenitiesListName] = useState([]);
  const rowData = [
    {
      roomNo: "A-101",
      roomType: "Deluxe Room",
    },
    {
      roomNo: "A-102",
      roomType: "Standard Room",
    },
    {
      roomNo: "A-103",
      roomType: "Suite",
    },
    {
      roomNo: "B-201",
      roomType: "Single Room",
    },
    {
      roomNo: "A-101",
      roomType: "Deluxe Room",
    },
    {
      roomNo: "A-102",
      roomType: "Standard Room",
    },
    {
      roomNo: "A-103",
      roomType: "Suite",
    },
    {
      roomNo: "B-201",
      roomType: "Single Room",
    },
    {
      roomNo: "A-101",
      roomType: "Deluxe Room",
    },
    {
      roomNo: "A-102",
      roomType: "Standard Room",
    },
    {
      roomNo: "A-103",
      roomType: "Suite",
    },
    {
      roomNo: "B-201",
      roomType: "Single Room",
    },
  ];
  const columnDefs = [
    {
      headerName: "Room No",
      field: "roomNo",
      checkboxSelection: true,
      minWidth: 200,
      // headerCheckboxSelection: true,
    },
    {
      headerName: "Room Type",
      field: "roomType",
      filter: true,
    },
  ];
  return (
    <div className="w-full border border-[#D0D5DD] bg-white rounded-[10px] p-6 mt-4">
      <div className="flex items-center justify-between">
        <div className="text-[26px] leading-[32.76px] font-bold">
          Add Amenities To Room
        </div>
        <CloseButton onClick={props.close} />
      </div>
      <div className="w-2/5 mt-6">
        <SelectFromObject
          label={"Room Amenity List Name"}
          options={{
            roomAmenityList1: "Room Amenity List 1",
            roomAmenityList2: "Room Amenity List 2",
            roomAmenityList3: "Room Amenity List 3",
          }}
          value={amenitiesListName}
          setValue={setAmenitiesListName}
        />
      </div>

      <div className="grid grid-cols-10 w-full mt-6">
        {/* Left side */}
        <div className="col-span-4 w-full">
        <SelectBox 
         label={"Available Room"}
         heading={"Select Rooms"}
         btnName={"Add Room Type"}
         handleTopBtn={""}
         rowData={rowData}
         columnDefs={columnDefs}
         setSelectedRows={setSelectedRows}
        />
         
        </div>

        {/* Buttons for add and remove */}
        <div className="col-span-2 flex flex-col items-center justify-center gap-6">
          <ShiftButton name={"Add"} icon={<AiOutlineDoubleRight size={18} />} />
          <ShiftButton
            name={"Remove"}
            icon={<AiOutlineDoubleLeft size={18} />}
          />
        </div>

        {/* Right side */}
        <div className="col-span-4 w-full">
          <SelectBox  label={"Room amenity List 1"}
            heading={"Selected Room"}
            showBtn={false}
            rowData={rowData}
            columnDefs={columnDefs}
            setSelectedRows={setSelectedRows}/>
         
        </div>
      </div>
      <div className="flex items-center justify-end mt-6">
        <SubmitButton text={"Configure Minibar"} />
      </div>
    </div>
  );
};

export default AddAmenitiesToRoom;
