import React from "react";
// import Seperator from "../../components/layouts/Seperator";
import { useTranslation } from "react-i18next";
import { ID_NUMBER, ROUTES } from "../../constants";
import { BiArrowBack } from "react-icons/bi";
// import DetailsHeading from "../../components/layouts/DetailsHeading";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useState } from "react";
import RoomInfoDetails from "./RoomInfoDetails";
import EditIconBooking from "../../images/icons/EidIconBooking.svg";
import { IoIosArrowUp } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const BookingDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [booking, setBooking] = useState(null);
  const { bookings } = useSelector((state) => state?.AllBooking);
  const [isCencelling, setIsCencelling] = useState(false);

  useEffect(() => {
    const bookingId = sessionStorage.getItem(ID_NUMBER);
    if (bookingId) {
      const data = bookings?.filter((x) => x?._id === bookingId);
      setBooking(data[0]);
    }
  }, [bookings]);

  const headingData = {
    heading: t("133"),
    seperator: [
      {
        text: t("Front Desk"),
      },
      {
        link: ROUTES.BOOKINGS,
        text: t("35"),
      },
      {
        text: t("237"),
      },
    ],
  };
  const backBookingDetails = () => {
    navigate(ROUTES.BOOKINGS);
  };

  return (
    <div className="page-container">
      <div className="flex justify-between items-end mb-6">
        {/* <Seperator data={headingData} /> */}
        <button
          className="h-10 w-[89px] border rounded-md bg-white hover:bg-gray-50 flex items-center relative"
          onClick={() => backBookingDetails()}
        >
          <BiArrowBack size={14} className="text-[#252C32] absolute left-4" />
          <div className="text-[14px] leading-6 font-normal  pl-10">
            {t("236")}
          </div>
        </button>
      </div>
      {/* cencelling information */}
      {/* <div className="text-[24px] leading-[30.24px] font-bold text-[#F04438] tracking-[-0.8px] mb-4">
          Canceling Information
        </div>
        <div className="w-full p-8 h-auto border border-[#E5E9EB] rounded-[10px] bg-[#FFFFFF]">
          <div className="flex items-center gap-10">
            <DetailsHeading
              heading={t("Canceled Room")}
              subHeading={"101; 204"}
            />
            <DetailsHeading
              heading={t("Cancel Reason")}
              subHeading={"Health Issues"}
            />
            <DetailsHeading heading={t("Canceled By")} subHeading={"Rajeev"} />
            <DetailsHeading heading={t("Cancelation Fee")} subHeading={"$75"} />
          </div>
          <div className="mt-4">
            <button
              className="flex items-center gap-2"
              onClick={() => setIsCencelling(!isCencelling)}
            >
              {isCencelling ? <IoIosArrowUp /> : <IoIosArrowDown />}
              <div className="text-[16px] leading-4 font-semibold">
                Cencellation Note
              </div>
            </button>
            {isCencelling ? (
              <div className="w-full pl-6">
                <p className="text-[14px] leading-[26px] font-normal text-[#6E7C87] break-all pr-1">
                  Lorem ipsum dolor sit amet consectetur. Risus imperdiet
                  vivamus eget velit quis mi ultricies mattis. Molestie massa
                  neque nec enim quis. Lorem nec porta neque lacus elementum.
                  Enim faucibus vestibulum sit ornare nisl neque enim. Nulla
                  pellentesque in nibh accumsan elit. Dignissim scelerisque
                  aliquam platea enim quis id. Vitae vulputate lacus sed
                  dignissim nullam at. Sem libero aenean integer id id enim.
                  Elementum venenatis arcu ullamcorper faucibus. In nibh porta
                  at dignissim orci in maecenas. Massa ridiculus amet fringilla
                  augue tellus facilisis porta augue auctor. Felis pellentesque
                  massa facilisi elementum auctor. Lectus cursus magna quis quis
                  nulla pretium. Diam in elementum id augue. Augue augue cras
                  sit a. Aliquam aliquet cursus id vitae tempor. Morbi euismod
                  odio porta at id. Ullamcorper a nulla tristique sed a amet. At
                  nunc feugiat adipiscing ac mi semper risus dolor felis.
                </p>
              </div>
            ) : null}
          </div>
        </div> */}

      {/* Refund Information*/}
      {/* <div className="text-[24px] leading-[30.24px] font-bold text-[#F04438] tracking-[-0.8px] my-4">
          Refund Information
        </div>
        <div className="w-full h-[102px] border border-[#E5E9EB] rounded-[10px] p-8 bg-[#FFFFFF] flex items-center gap-16">
          <DetailsHeading heading={t("Refundable")} subHeading={"Yes"} />
          <DetailsHeading
            heading={t("Refund Method")}
            subHeading={"Credit Card"}
          />
          <DetailsHeading heading={t("Refund Amount")} subHeading={"$525"} />
          <DetailsHeading heading={t("Refund To")} subHeading={"Guest"} />
          <DetailsHeading heading={t("Tax Exempt")} subHeading={"No"} />
        </div> */}
      {/* Booking Information */}
      <div className="flex items-center gap-4 mt-4">
        <div className="text-[24px] leading-[30px] tracking-[-0.8px] text-[#000000]">
          {t("135")}
        </div>
        <button type="button" className="w-9 h-9 bg-white border  rounded-full flex items-center justify-center hover:text-white hover:bg-base-rgba_primary_1">
          <img src={EditIconBooking} alt="" />
        </button>
      </div>
      <div className="bg-white rounded-[10px] h-[158px] mt-4 border px-8 py-6">
        <div className="grid grid-cols-6 items-center justify-center gap-8">
          {/* <DetailsHeading
            heading={t("175")}
            subHeading={booking?.bookingName}
          />
          <DetailsHeading
            heading={t("510")}
            subHeading={booking?.bookingEmail}
          />
          <DetailsHeading
            heading={t("496")}
            subHeading={booking?.bookingType}
          />
          <DetailsHeading
            heading={t("160")}
            subHeading={booking?.bookingSource}
          />
          <DetailsHeading
            heading={t("137")}
            subHeading={booking?.bookingSourceId}
          />
          <DetailsHeading
            heading={t("138")}
            subHeading={booking?.bookingDate?.slice(0, 10)}
          />

          <DetailsHeading
            heading={t("570")}
            subHeading={booking?.checkInDate?.slice(0, 10)}
          />
          <DetailsHeading
            heading={t("571")}
            subHeading={booking?.checkOutDate?.slice(0, 10)}
          />
          <DetailsHeading heading={t("507")} subHeading="2" />
          <DetailsHeading
            heading={t("508")}
            subHeading={booking?.roomDetails?.length}
          />
          <DetailsHeading heading={t("572")} subHeading="No" /> */}
        </div>
        {/* <div className="flex items-center justify-between pt-8">
         
        </div> */}
      </div>
      {/* Room Information */}
      <div className="flex items-center gap-4 py-4">
        <div className="text-[24px] leading-[30px] tracking-[-0.8px] text-[#000000]">
          {t("515")}
        </div>
        <button className="w-9 h-9 bg-white border  rounded-full flex items-center justify-center hover:text-white hover:bg-base-rgba_primary_1">
          <img src={EditIconBooking} alt="" />
        </button>
      </div>
      <div className="bg-white border rounded-[10px] p-2">
        <RoomInfoDetails
          RoomNumber={t("143")}
          guestAdult={t("575")}
          guestChild={t("Guest 1 (child)")}
        />
        <div className="pt-4">
          <RoomInfoDetails
            RoomNumber={t("1098")}
            guestAdult={t("Guest 2 (Adult)")}
            guestChild={t("Guest 2 (child)")}
          />
        </div>
      </div>

      {/* Other information */}

      <div className="text-[24px] leading-[30px] tracking-[-0.8px] text-[#000000] my-4 ">
        {t("Other Information")}
      </div>
      <div className="rounded-[10px] h-auto p-8 border bg-white">
        {/* <DetailsHeading heading={t("Email Booking Voucher")} subHeading="Yes" /> */}
        <button
          className="flex items-center gap-2 mt-4"
          onClick={() => setIsCencelling(!isCencelling)}
        >
          {isCencelling ? (
            <IoIosArrowUp className={`${isCencelling ? "duration-500" : ""}`} />
          ) : (
            <IoIosArrowUp
              className={`${!isCencelling ? "rotate-180 duration-500" : ""}`}
            />
          )}

          <div className="text-[16px] leading-4 font-semibold">
            Internal Note
          </div>
        </button>
        {isCencelling ? (
          <div className="w-full pl-6 h-fit">
            <p className="text-[14px] leading-[26px] font-medium text-[#6E7C87] break-all pr-1">
              Lorem ipsum dolor sit amet consectetur. Risus imperdiet vivamus
              eget velit quis mi ultricies mattis. Molestie massa neque nec enim
              quis. Lorem nec porta neque lacus elementum. Enim faucibus
              vestibulum sit ornare nisl neque enim. Nulla pellentesque in nibh
              accumsan elit. Dignissim scelerisque aliquam platea enim quis id.
              Vitae vulputate lacus sed dignissim nullam at. Sem libero aenean
              integer id id enim. Elementum venenatis arcu ullamcorper faucibus.
              In nibh porta at dignissim orci in maecenas. Massa ridiculus amet
              fringilla augue tellus facilisis porta augue auctor. Felis
              pellentesque massa facilisi elementum auctor. Lectus cursus magna
              quis quis nulla pretium. Diam in elementum id augue. Augue augue
              cras sit a. Aliquam aliquet cursus id vitae tempor. Morbi euismod
              odio porta at id. Ullamcorper a nulla tristique sed a amet. At
              nunc feugiat adipiscing ac mi semper risus dolor felis.
            </p>
          </div>
        ) : null}
      </div>
      {/* Billing Information */}
      <div className="text-[24px] leading-[30px] tracking-[-0.8px] text-[#000000] py-3 ">
        {t("569")}
      </div>
      <div className="rounded-[10px] h-[171px] p-8 border bg-white">
        <div className="grid grid-cols-7 items-center gap-8">
          {/* <DetailsHeading heading={t("552")} subHeading="Fully Paid" />
          <DetailsHeading heading={t("554")} subHeading="Credit Card" />
          <DetailsHeading heading={t("150")} subHeading="$1000" />
          <DetailsHeading heading={t("580")} subHeading="$600" />
          <DetailsHeading heading={t("581")} subHeading="$400" />
          <DetailsHeading heading={t("582")} subHeading="Yes" />
          <DetailsHeading heading={t("560")} subHeading="11-05-2023" />
          <DetailsHeading heading={t("563")} subHeading="Guest" />
          <DetailsHeading heading={t("564")} subHeading="No" /> */}
        </div>
      </div>
    </div>
  );
};

export default BookingDetails;
