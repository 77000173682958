import React from "react"; 
import { useTranslation } from "react-i18next";

const NotFound404 = () => {
  const { t } = useTranslation();
  return (
    <> 
      <div className="flex min-h-screen w-full items-center justify-center bg-[#FFFFFF] text-gray-700 text-2xl sm:text-3xl md:text-4xl lg:text-5xl">
        {t("Page not found 404!")}
      </div>
    </>
  );
};

export default NotFound404;
