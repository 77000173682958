import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { TOKEN } from "../../../constants";

// get assets report by month action
export const assetsReportByMonthAction = createAsyncThunk(
  "reports/assetsReportByMonthAction",
  async (credentials, thunkAPI) => {
    try {
      const { hotelId, fromMonth, toMonth } = credentials || {};
      if (!hotelId || !fromMonth || !toMonth) return;
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/reports/assets/by-month`;
      const response = await axios.post(url, credentials);
      // localStorage.setItem(TOKEN, response.data.token);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

//get assets report by month slice
export const assetsReportByMonthSlice = createSlice({
  name: "reports/assetsReportByMonthSlice",
  initialState: {
    loading: false,
    assetsreport: [],
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(assetsReportByMonthAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(assetsReportByMonthAction.fulfilled, (state, action) => {
        state.loading = false;
        state.assetsreport = action.payload.assetsreport;
      })
      .addCase(assetsReportByMonthAction.rejected, (state, action) => {
        state.loading = false;
        state.assetsreport = null;
        state.error =
          action.payload || "Failed to assets Report All Time By Date";
      });
  },
});

// get assets report by month for one asset action
export const assetsReportByMonthForOneAction = createAsyncThunk(
  "reports/assetsReportByMonthForOneAction",
  async (credentials, thunkAPI) => {
    try {
      const { hotelId, fromMonth, toMonth, assetId } = credentials || {};
      if (!hotelId || !fromMonth || !toMonth || !assetId) return;
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/reports/assets/by-month`;
      const response = await axios.post(url, credentials);
      localStorage.setItem(TOKEN, response.data.token);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

//get assets report by month slice
export const assetsReportByMonthForOneSlice = createSlice({
  name: "reports/assetsReportByMonthForOneSlice",
  initialState: {
    loading: true,
    assetsreport: [],
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(assetsReportByMonthForOneAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(assetsReportByMonthForOneAction.fulfilled, (state, action) => {
        state.loading = false;
        state.assetsreport = action.payload.assetsreport;
      })
      .addCase(assetsReportByMonthForOneAction.rejected, (state, action) => {
        state.loading = false;
        state.assetsreport = null;
        state.error =
          action.payload?.message || "Failed to assets Report By Month For One";
      });
  },
});
