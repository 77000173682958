import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import authAxios from "../../services/authAxios";

// add room action
export const addRoomAction = createAsyncThunk(
  "ADD_ROOM_ACTION",
  async (credentials, thunkAPI) => {
    try {
      const url = `/api/hotel/owner/rooms/add`;
      const response = await axios.post(url, credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// update room action
export const updateRoomAction = createAsyncThunk(
  "UPDATE_ROOM_ACTION",
  async (credentials, thunkAPI) => {
    try {
      const url = `/api/hotel/owner/rooms/update`;
      const response = await axios.put(url, credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

//  get all room Action
export const getAllRoomAction = createAsyncThunk(
  "GET_ALL_ROOMS_ACTION",
  async (hotelId, thunkAPI) => {
    try {
      const response = await authAxios.get(`/api/hotel/owner/rooms/all?hotelId=${hotelId}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// delete Room action
export const deleteRoomAction = createAsyncThunk(
  "DELETE_ROOM_ACTION",
  async (credentials, thunkAPI) => {
    try {
      let url = `api/hotel/owner/rooms`;
      const response = await axios.delete(url, credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// Helper function for handling pending state
const handlePending = (state) => {
  state.loading = true;
  state.error = null;
  state.isupdated = false;
  state.isadded = false;
  state.isdeleted = false;
};

// Helper function for handling rejected state
const handleRejected = (state, action) => {
  state.loading = false;
  state.error = action.payload;
  state.isupdated = false;
  state.isadded = false;
  state.isdeleted = false;
};

// all rooms slice
export const addRoomSlice = createSlice({
  name: "HOTEL_ROOMS_SLICE",
  initialState: {
    loading: false,
    isadded: false,
    isupdated: false,
    isdeleted: false,
    rooms: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addRoomAction.pending, handlePending)
      .addCase(addRoomAction.fulfilled, (state, action) => {
        state.loading = false;
        state.isadded = true;
        state.rooms = action.payload;
      })
      .addCase(addRoomAction.rejected, handleRejected)
      .addCase(updateRoomAction.pending, handlePending)
      .addCase(updateRoomAction.fulfilled, (state, action) => {
        state.loading = false;
        state.isupdated = true;
        state.rooms = action.payload;
      })
      .addCase(updateRoomAction.rejected, handleRejected)
      .addCase(getAllRoomAction.pending, handlePending)
      .addCase(getAllRoomAction.fulfilled, (state, action) => {
        state.loading = false;
        state.rooms = action.payload;
      })
      .addCase(getAllRoomAction.rejected, handleRejected)
      .addCase(deleteRoomAction.pending, handlePending)
      .addCase(deleteRoomAction.fulfilled, (state, action) => {
        state.loading = false;
        state.isdeleted = true;
        state.rooms = action.payload;
      })
      .addCase(deleteRoomAction.rejected, handleRejected)
  },
});