import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { isObjEmpty } from "../../utils";
import ProfilePhone from "./ProfilePhone";
import { AiOutlineMail } from "react-icons/ai";
import { SlLocationPin } from "react-icons/sl";
import { updateOwnerProfileAction } from "../../lib/slices/auth/profileSlice";
import { FaSignOutAlt } from "react-icons/fa";
import { logoutAction } from "../../lib/slices/auth/authSlice";

const OwnerProfile = () => {
  const dispatch = useDispatch()
  const { profile } = useSelector((state) => state.ownerProfile);
  const { user } = useSelector((state) => state.user);

  const [edit, setEdit] = useState(false);
  const [name, setName] = useState("")
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");

  const toggleEdit = () => setEdit(true);

  const updateProfile = () => {
    dispatch(updateOwnerProfileAction({
      phone,
      name,
      email,
      address,
      gender: "MALE"
    }))
    setEdit(false);
  };

  useEffect(() => {
    if (!isObjEmpty(profile)) {
      setAddress(profile?.address);
      setPhone(profile?.phone);
    }

    if (!isObjEmpty(user)) {
      setName(user?.name);
      setEmail(user?.email);
    }
  }, [profile, user]);

  return (
    <div className="p-6 w-full border border-[#E5E9EB] rounded-[10px] reletive bg-white overflow-hidden">
      <div className="flex justify-between items-center">
        {edit ? (
          <div className="flex items-center gap-4">
            <input
              value={name}
              onChange={(e) => setName(e?.target?.value)}
              type="text"
              className={`capitalize w-fit text-[22px] text-[#07090D] leading-[22.27px] font-semibold tracking-[-0.8px] max-w-fit ${edit ? "" : "!border-none !shadow-none none-focus"
                }`}
            />
          </div>
        ) : (
          <p className="capitalize text-[22px] h-12 text-[#07090D] leading-[22.27px] font-semibold tracking-[-0.8px]">
            {name}
          </p>
        )}
        <button
          onClick={edit ? updateProfile : toggleEdit}
          className="h-10 flex justify-between items-center px-4 gap-2 bg-base-primary hover:bg-base-primary_hover rounded-md"
        >
          <div className="text-[20px] leading-[25.2px] font-medium text-[#F6F8F9]">
            {edit ? "Done" : "Edit Profile"}
          </div>
        </button>
      </div>
      {/* profile text */}
      <div className="gap-4 mt-1">
        <div className="grid grid-cols-2 gap-4 items-center">
          <ProfilePhone phone={phone} setPhone={setPhone} readOnly={!edit} />
          <div className="flex items-center gap-1">
            <AiOutlineMail size={20} />
            <input
              value={email}
              onChange={(e) => setEmail(e?.target?.value)}
              type="email"
              required
              className={`text-[20px] text-[#6E7C87] leading-[25.2px] font-normal tracking-[-0.8px] ${edit ? "" : "!border-none !shadow-none none-focus"
                }`}
              readOnly={!edit}
            />
          </div>
        </div>

        <div className="flex items-center gap-1 mt-1">
          <SlLocationPin size={20} />
          <input
            value={address}
            onChange={(e) => setAddress(e?.target?.value)}
            required
            className={`text-[20px] text-[#6E7C87] leading-[25.2px] font-normal tracking-[-0.8px] ${edit ? "" : "!border-none !shadow-none none-focus"
              }`}
            readOnly={!edit}
          />
        </div>
      </div>
      <button
        onClick={()=>dispatch(logoutAction())}
        className="flex items-center justify-center px-4 py-2 mt-3 text-sm font-medium text-white bg-red-500 border border-transparent rounded-md shadow-sm hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-400"
      >
        <FaSignOutAlt className="w-5 h-5 mr-2" />
        Logout
      </button>
    </div >
  );
};

export default OwnerProfile;
