import React, { useState } from "react";
// import Seperator from "../../components/layouts/Seperator";
import { useTranslation } from "react-i18next";
import AddButton from "../../components/buttons/AddButton";
import { registerEvent } from "../../logs";
import CreateRoaster from "./CreateRoaster";
import AllCreateRoaster from "./AllCreateRoaster"; 
import assignShift from "../../images/icons/assignShift.svg";
import AddAssignShift from "./AddAssignShift";

const RoasterManagement = () => {
  const { t } = useTranslation();
  const [addRoaster, setAddRoaster] = useState(false);
  const [assignShifts, setAssignShifts] = useState(false);

  const openAddRoasterModal = () => {
    setAddRoaster(true);
    registerEvent("add roaster button open", "add item roaster");
  };

  const closeAddRoastereModal = () => {
    setAddRoaster(false);
    registerEvent("add roaster button close", "add item roaster");
  };

  const openAssignShiftModal = () => {
    setAssignShifts(true);
    registerEvent("add roaster button open", "add item roaster");
  };

  const closeAssignShiftModal = () => {
    setAssignShifts(false);
    registerEvent("add roaster button close", "add item roaster");
  };

  const headingData = {
    heading: t("Create Roaster"),
    // seperator: [
    //   {
    //     text: t("134"),
    //   },
    //   {
    //     text: t("198"),
    //   },
    // ],
  };
  return (
    <div className="page-container">
      <div className="w-full flex justify-between items-end mx-auto">
        {/* <Seperator data={headingData} /> */}
        <div className="flex gap-2">
          <button
            type="button"
            onClick={openAssignShiftModal}
            className="flex items-center justify-center gap-2 h-10 px-4 bg-base-primary hover:bg-base-primary_hover duration-300 ease-[0s] text-[14px] leading-[14px] font-semibold tracking-[-0.6%] text-[#F6F8F9] rounded-md"
          >
            <img src={assignShift} alt="" />
            <div className="">Assign Shift</div>
          </button>
          <AddButton text={t("Add Roaster")} onClick={openAddRoasterModal} />
        </div>
      </div>
      <div>
        {addRoaster ? <CreateRoaster close={closeAddRoastereModal} /> : null}
        {assignShifts ? <AddAssignShift close={closeAssignShiftModal} /> : null}
      </div>

      {/* All Create Roaster */}
      <div className="mt-4">
        <AllCreateRoaster />
      </div>
    </div>
  );
};
export default RoasterManagement;
