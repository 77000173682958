import { useState } from "react";
import { isObjEmpty } from "../../utils";
import { registerEvent } from "../../logs";
import AddEmployee from "./AddEmployee";
import AllEmployee from "./AllEmployee";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
// import Seperator from "../../components/layouts/Seperator";
import AddButton from "../../components/buttons/AddButton";
import { useEffect } from "react";
import OutlineButton from "../../components/buttons/OutlineButton";
import { getAllHotelEmployeeAction } from "../../lib/slices/employee/employeeSlice";

const EmployeePage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [updateData, setUpdateData] = useState(null);
  const { hotel } = useSelector((state) => state.Hotel);

  const addEmployeeModal = () => {
    setOpen(true);
    registerEvent("click add employee button", "all employee page");
  };

  //close add employee modal
  const close = () => {
    setOpen(false);
    setUpdateData(null);
    registerEvent("close add employee component", "add employee page");
  };

  useEffect(() => {
    if (hotel?._id) dispatch(getAllHotelEmployeeAction(hotel?._id));
  }, []);

  const headingData = {
    heading: t("198"),
    seperator: [
      {
        text: t("Employee Management"),
      },
      {
        text: t("198"),
      },
    ],
  };

  return (
    <div className="flex flex-1 gap-6">
      <div className="w-full flex justify-between items-end mx-auto">
        {/* <Seperator data={headingData} /> */}
        <div className="flex items-center gap-4">
          <OutlineButton text={"Data Privacy"} />
          <AddButton text={t("199")} onClick={addEmployeeModal} />
        </div>
      </div>

      {(updateData && !isObjEmpty(updateData)) || open ? (
        <AddEmployee close={close} updateData={updateData} />
      ) : null}

      <AllEmployee setUpdateData={setUpdateData} />
    </div>
  );
};

export default EmployeePage;
