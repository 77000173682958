import React from "react";
import { AiOutlineMail } from "react-icons/ai";
// import InputError from "../../components/alert/InputError";
import { validateEmail } from "../../utils/validations";
import { useTranslation } from "react-i18next";

const AuthEmailInput = ({ email, setEmail, emailError, setEmailError, readOnly = false }) => {
  const { t } = useTranslation();
  //onchange handler
  const enterValideEmail = (val) => {
    setEmail(val);
    setEmailError(validateEmail(val));
  };

  return (
    <>
      <div className="w-full mb-5 relative">
        <span className="absolute top-4 left-6">
          <AiOutlineMail />
        </span>
        <input
          type="text"
          onChange={(e) => enterValideEmail(e.target.value)}
          value={email}
          placeholder={t("167")}
          readOnly={readOnly}
          className="w-full outline-none border border-[#D0D5DD] p-3 pl-14 focus:border-base-primary rounded-3xl"
        />
        <div className="px-4 absolute -bottom-5 left-0">
          {/* <InputError error={emailError} /> */}
        </div>
      </div>
    </>
  );
};

export default AuthEmailInput;
