import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { TOKEN } from "../../../constants";

// employe satrt shift action
export const employeeStartShiftAction = createAsyncThunk(
  "employeeShift/employeeStartShiftAction",
  async (credentials, thunkAPI, formData) => {
    try {
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/employee/shift/start`;
      const response = await axios.post(url, formData, credentials);
      localStorage.setItem(TOKEN, response.data.token);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

//employee satrt shift slice
export const employeeStartShiftSlice = createSlice({
  name: "employeeShift/employeeStartShiftSlice",
  initialState: {
    loading: true,
    isstarted: false,
    employee: {},
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(employeeStartShiftAction.pending, (state) => {
        state.loading = true;
        state.isstarted = false;
        state.error = null;
      })
      .addCase(employeeStartShiftAction.fulfilled, (state, action) => {
        state.loading = false;
        state.isstarted = true;
        state.employee = action.payload.employee;
      })
      .addCase(employeeStartShiftAction.rejected, (state, action) => {
        state.loading = false;
        state.isstarted = false;
        state.error =
          action.payload?.message || "Failed to employee  start shift";
      });
  },
});

// employe End shift action
export const employeeEndShiftAction = createAsyncThunk(
  "employeeShift/employeeEndShiftAction",
  async (credentials, thunkAPI) => {
    try {
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/employee/shift/end`;
      const response = await axios.put(url, credentials);
      localStorage.setItem(TOKEN, response.data.token);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

//employee End shift slice
export const employeeEndShiftSlice = createSlice({
  name: "employeeShift/employeeEndShiftSlice",
  initialState: {
    loading: true,
    isended: false,
    employee: {},
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(employeeStartShiftAction.pending, (state) => {
        state.loading = true;
        state.isended = false;
        state.error = null;
      })
      .addCase(employeeStartShiftAction.fulfilled, (state, action) => {
        state.loading = false;
        state.isended = true;
        state.employee = action.payload.employee;
      })
      .addCase(employeeStartShiftAction.rejected, (state, action) => {
        state.loading = false;
        state.isended = false;
        state.error = action.payload?.message || "Failed to employee End shift";
      });
  },
});

// get Employee All Shift Action action
export const getEmployeeAllShiftAction = createAsyncThunk(
  "employeeShift/getEmployeeAllShiftAction",
  async (credentials, thunkAPI) => {
    try {
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/employee/shift`;
      const response = await axios.get(url, credentials);
      localStorage.setItem(TOKEN, response.data.token);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

//employee End shift slice
export const getEmployeeAllShiftSlice = createSlice({
  name: "employeeShift/getEmployeeAllShiftSlice",
  initialState: {
    loading: true,
    shifts: {},
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getEmployeeAllShiftAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getEmployeeAllShiftAction.fulfilled, (state, action) => {
        state.loading = false;
        state.shifts = action.payload.shifts;
      })
      .addCase(getEmployeeAllShiftAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message || "Failed to employee End shift";
      });
  },
});
