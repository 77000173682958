import React, { useState } from "react";
import CustomeTable from "../../components/widget/CustomeTable";

const BasicInformation = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const tableData = [
    {
      employeeId: "3123456",
      workLocation: "Main Brach",
      workSchedule: "12 hr",
      joiningDate: "19 April 2024",
      salary: "$1000",
      employee_Status: "Full Time",
    },
  ];
  const columnDefs = [
    {
      headerName: "Employee ID",
      field: "employeeId",
      minWidth: 200,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Work Location",
      field: "workLocation",
      filter: true,
    },
    {
      headerName: "Work Schedule",
      field: "workSchedule",
      filter: true,
    },
    {
      headerName: "Joining Date",
      field: "joiningDate",
      filter: true,
    },
    {
      headerName: "Salary",
      field: "salary",
      filter: true,
    },
    {
      headerName: "Employee Status",
      field: "employeeStatus",
      filter: true,
    },
  ];
  return (
    <CustomeTable
      size="h-[50vh]"
      tableName={"Basic information List table"}
      columnDefs={columnDefs}
      rowData={tableData}
      setSelectedRows={setSelectedRows}
    />
  );
};

export default BasicInformation;
