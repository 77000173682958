import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

// food order action
export const foodOrderAction = createAsyncThunk(
  "foodOrder/addItemToKitchenMenuAction",
  async (credentials, thunkAPI) => {
    try {
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/employee/kitchen/order`;
      const response = await axios.post(url, credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// food order slice
export const foodOrderSlice = createSlice({
  name: "foodOrder/foodOrderAction",
  initialState: {
    loading: false,
    isordered: false,
    order: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(foodOrderAction.pending, (state) => {
        state.loading = true;
        state.isordered = false;
        state.error = null;
      })
      .addCase(foodOrderAction.fulfilled, (state, action) => {
        state.loading = false;
        state.isordered = true;
        state.order = action.payload.order;
      })
      .addCase(foodOrderAction.rejected, (state, action) => {
        state.loading = false;
        state.isordered = false;
        state.error = action.payload || "Failed to order food";
      });
  },
});

// get food order Action
export const getFoodOrderAction = createAsyncThunk(
  "foodOrder/getFoodOrderAction",
  async (credentials, thunkAPI) => {
    try {
      const { hotelId, orderId } = credentials || {};
      if (!hotelId || !orderId) return;
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/employee/kitchen/order?orderId=${orderId}&hotelId=${hotelId}`;
      const response = await axios.get(url, credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// get food order slice
export const getFoodOrderSlice = createSlice({
  name: "foodOrder/getFoodOrderSlice",
  initialState: {
    loading: false,
    order: [],
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFoodOrderAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getFoodOrderAction.fulfilled, (state, action) => {
        state.loading = false;
        state.order = action.payload.order;
      })
      .addCase(getFoodOrderAction.rejected, (state, action) => {
        state.loading = false;
        state.order = null;
        state.error = action.payload || "Failed to get food order";
      });
  },
});

// get All food order Action
export const getAllFoodOrderAction = createAsyncThunk(
  "foodOrder/getAllFoodOrderAction",
  async (credentials, thunkAPI) => {
    try {
      const { hotelId, orderId } = credentials || {};
      if (!hotelId || !orderId) return;
      let url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/employee/kitchen/orders/all`;
      if (hotelId) {
        url += `?hotelId=${hotelId}`;
      }
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// get All food order slice
export const getAllFoodOrderSlice = createSlice({
  name: "foodOrder/getAllFoodOrderSlice",
  initialState: {
    loading: false,
    order: [],
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFoodOrderAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getFoodOrderAction.fulfilled, (state, action) => {
        state.loading = false;
        state.order = action.payload.order;
      })
      .addCase(getFoodOrderAction.rejected, (state, action) => {
        state.loading = false;
        state.order = null;
        state.error = action.payload || "Failed to get All food order";
      });
  },
});

// delete food order action
export const deleteFoodOrderAction = createAsyncThunk(
  "foodOrder/deleteFoodOrderAction",
  async (credentials, thunkAPI) => {
    try {
      const { orderId, hotelId } = credentials || {};
      if (!orderId || !hotelId) return;
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/employee/kitchen/order/cancel?hotelId=${hotelId}`;
      const response = await axios.delete(url, credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);
//  delete food order slice
export const deleteFoodOrderSlice = createSlice({
  name: "foodOrder/deleteFoodOrderSlice",
  initialState: {
    loading: false,
    order: {},
    isdeleted: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteFoodOrderAction.pending, (state) => {
        state.loading = true;
        state.isdeleted = false;
        state.error = null;
      })
      .addCase(deleteFoodOrderAction.fulfilled, (state, action) => {
        state.loading = false;
        state.isdeleted = true;
        state.order = action.payload.order;
      })
      .addCase(deleteFoodOrderAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to delete food order";
      });
  },
});
