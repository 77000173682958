import React, { useState } from "react";
import CloseButton   from "../../../components/buttons/CloseButton";
import RoomType from "./RoomType";
import Details from "./Details";
import AvailbilityFor from "./AvailbilityFor";
import Services from "./Services";
import MealPlan from "./MealPlan";
import Publish from "./Publish";

const AddPackage = (props) => {
  const [index, setIndex] = useState(1);
  const sideMenu = [
    { id: 1, name: "Package Details" },
    { id: 2, name: "Room Type" },
    { id: 3, name: "Meal Plan" },
    { id: 4, name: "Services" },
    { id: 5, name: "Availbility for" },
    { id: 6, name: "Publish" },
  ];

  const handleSideMenu = (id) => {
    setIndex(id);
  };
  return (
    <div className="w-full flex gap-0 border border-[#D0D5DD] rounded-[10px] bg-white overflow-hidden mt-6">
      {/* side menu */}
      <div className="w-1/4 border-r border-[#D0D5DD]">
        {sideMenu.map((menu) => {
          const { name, id } = menu;
          return (
            <button
              type="button"
              key={id}
              className={`h-[115px] w-full border-b border-[#D0D5DD] flex items-center  last:border-b-0 text-[16px] leading-[20.16px] font-medium text-[#000000] hover:bg-base-primary hover:text-white duration-300 ease-[0.3s] group px-4 ${
                index === id ? "bg-base-primary text-white " : ""
              }`}
              onClick={() => handleSideMenu(id)}
            >
              <div className="text-left">
                <div
                  className={` group-hover:text-white w-full ${
                    index === id
                      ? "bg-base-primary text-white "
                      : "text-base-primary"
                  }`}
                >
                  Step {id}
                </div>
                {name}
              </div>
            </button>
          );
        })}
      </div>
      <div className="w-full">
        <div className="flex justify-end pr-4 pt-4">
          <CloseButton onClick={props.close} />
        </div>
        {+index === 1 ? <Details /> : null}
        {+index === 2 ? <RoomType /> : null}
        {+index === 3 ? <MealPlan /> : null}
        {+index === 4 ? <Services /> : null}
        {+index === 5 ? <AvailbilityFor /> : null}
        {+index === 6 ? <Publish /> : null}
      </div>
    </div>
  );
};

export default AddPackage;
