import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { TOKEN } from "../../../constants";

// get wallet Action
export const getWalletAction = createAsyncThunk(
  "transection/getWalletAction",
  async ({ credentials }, thunkAPI) => {
    try {
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/wallet`;
      const response = await axios.get(url, { headers: credentials });
      localStorage.setItem(TOKEN, response.data.token);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// get wallet slice
export const getWalletSlice = createSlice({
  name: "transection/getWalletSlice",
  initialState: {
    loading: false,
    wallet: {},
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getWalletAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getWalletAction.fulfilled, (state, action) => {
        state.loading = false;
        state.wallet = action.payload.wallet;
      })
      .addCase(getWalletAction.rejected, (state, action) => {
        state.loading = false;
        state.wallet = null;
        state.error = action.payload || "Failed to get Wallet";
      });
  },
});

// update wallet Action
export const updateWalletAction = createAsyncThunk(
  "transection/updateWalletAction",
  async (credentials, thunkAPI) => {
    try {
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/wallet`;
      const response = await axios.put(url, credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// get wallet slice
export const updateWalletSlice = createSlice({
  name: "transection/updateWalletAction",
  initialState: {
    loading: false,
    isupdated: false,
    wallet: {},
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateWalletAction.pending, (state) => {
        state.loading = true;
        state.isupdated = false;
        state.error = null;
      })
      .addCase(updateWalletAction.fulfilled, (state, action) => {
        state.loading = false;
        state.isupdated = true;
        state.wallet = action.payload.wallet;
      })
      .addCase(updateWalletAction.rejected, (state, action) => {
        state.loading = false;
        state.isupdated = false;
        // state.wallet = null;
        state.error = action.payload || "Failed to update Wallet";
      });
  },
});

// get transaction history Action
export const getTransactionsHistoryAction = createAsyncThunk(
  "transection/getTransectionsHistoryAction",
  async (credentials, thunkAPI) => {
    try {
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/wallet/transactions`;
      const response = await axios.get(url, credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// get transaction history slice
export const getTransactionsHistorySlice = createSlice({
  name: "transection/getTransectionsHistorySlice",
  initialState: {
    loading: true,
    history: {},
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTransactionsHistoryAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTransactionsHistoryAction.fulfilled, (state, action) => {
        state.loading = false;
        state.history = action.payload.history;
      })
      .addCase(getTransactionsHistoryAction.rejected, (state, action) => {
        state.loading = false;
        // state.history = [];
        state.error = action.payload || "Failed to get Transections History";
      });
  },
});
