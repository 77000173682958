import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ISADMIN } from "../../../constants";
import axios from "axios";

// create Inventory action
export const addOwnerKitchenInventoryAction = createAsyncThunk(
  "inventoryOwner/addOwnerKitchenInventoryAction",
  async (credentials, thunkAPI, formData) => {
    try {
      const isadmin = localStorage.getItem(ISADMIN);
      if (!isadmin) return;
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/kitchen/inventory/add-item`;
      const response = await axios.post(url, formData, credentials);
      // localStorage.setItem(TOKEN, response.data.token);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

//create Inventory slice
export const addOwnerKitchenInventorySlice = createSlice({
  name: "inventoryOwner/addOwnerKitchenInventorySlice",
  initialState: {
    loading: false,
    isadded: false,
    inventory: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addOwnerKitchenInventoryAction.pending, (state) => {
        state.loading = true;
        state.isadded = false;
        state.error = null;
      })
      .addCase(addOwnerKitchenInventoryAction.fulfilled, (state, action) => {
        state.loading = false;
        state.isadded = true;
        state.inventory = action.payload.inventory;
      })
      .addCase(addOwnerKitchenInventoryAction.rejected, (state, action) => {
        state.loading = false;
        state.isadded = false;
        state.error = action.payload || "Failed to create Inventory";
      });
  },
});

// get owner kitchen inventory action
export const getOwnerKitchenInventoryAction = createAsyncThunk(
  "inventoryOwner/getOwnerKitchenInventoryAction",
  async ({ credentials, kitchenId }, thunkAPI) => {
    try {
      const isadmin = localStorage.getItem(ISADMIN);
      if (!isadmin || !kitchenId) return;
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/kitchen/inventory?kitchenId=${kitchenId}`;
      const response = await axios.get(url, { headers: credentials });
      // localStorage.setItem(TOKEN, response.data.token);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// get owner kitchen inventory slice
export const getOwnerKitchenInventorySlice = createSlice({
  name: "inventoryOwner/getOwnerKitchenInventorySlice",
  initialState: {
    loading: false,
    inventories: [],
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOwnerKitchenInventoryAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getOwnerKitchenInventoryAction.fulfilled, (state, action) => {
        state.loading = false;
        state.inventories = action.payload.inventories;
      })
      .addCase(getOwnerKitchenInventoryAction.rejected, (state, action) => {
        state.loading = false;
        state.inventories = [];
        state.error = action.payload || "Failed to get owner kitchen inventory";
      });
  },
});

// update kicthen inventory action
export const updateOwnerKitchenInventoryAction = createAsyncThunk(
  "inventoryOwner/updateOwnerKitchenInventoryAction",
  async (credentials, thunkAPI) => {
    try {
      const isadmin = localStorage.getItem(ISADMIN);
      if (!isadmin) return;
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/kitchen/inventory/update-item`;
      const response = await axios.put(url, credentials);
      // localStorage.setItem(TOKEN, response.data.token);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

//update kitchen inventory slice
export const updateOwnerKitchenInventorySlice = createSlice({
  name: "inventoryOwner/updateOwnerKitchenInventorySlice",
  initialState: {
    loading: false,
    isupdated: false,
    inventory: {},
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateOwnerKitchenInventoryAction.pending, (state) => {
        state.loading = true;
        state.isupdated = false;
        state.error = null;
      })
      .addCase(updateOwnerKitchenInventoryAction.fulfilled, (state, action) => {
        state.loading = false;
        state.isupdated = true;
        state.inventory = action.payload.inventory;
      })
      .addCase(updateOwnerKitchenInventoryAction.rejected, (state, action) => {
        state.loading = false;
        state.isupdated = false;
        state.error =
          action.payload || "Failed to update Owner kitchen inventory";
      });
  },
});
