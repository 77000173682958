import React, { useState, } from "react";
import { useTranslation } from "react-i18next";
import OwnerProfile from "./OwnerProfile";
import ProfileHeader from "./ProfileHeader";
import EmployeeInformation from "./EmployeeInformation";
import LeaveManagement from "./LeaveManagement";
import ShiftManagement from "./ShiftManagement";
import SalaryManagement from "./SalaryManagement";
import EmployeeProfile from "./EmployeeProfile";
import { useSelector } from "react-redux";

const Profile = () => {
  const [index, setIndex] = useState(1);
  const { user } = useSelector((state) => state.user);

  const { t } = useTranslation();

  return (
    <>
      <div className="page-container">
        <div className="mb-2 text-[30px] leading-[38px] font-bold tracking-[-0.8px]">
          {t("968")}
        </div>
        {
          user?.role === 'OWNER' ?
            <OwnerProfile /> :
            <>
              <EmployeeProfile />
              <div className=" border border-[#E5E9EB] rounded-[10px] bg-white mt-4 p-6">
                <ProfileHeader index={index} setIndex={setIndex} />
                {index === 1 ? <EmployeeInformation /> : null}
                {index === 2 ? <LeaveManagement /> : null}
                {index === 3 ? <ShiftManagement /> : null}
                {index === 4 ? <SalaryManagement /> : null}
              </div>
            </>
        }
      </div>
    </>
  );
};

export default Profile 
