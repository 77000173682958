import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { TOKEN } from "../../../constants";

// add hotel amenity Action
export const addHotelAmenityAction = createAsyncThunk(
  "hotelAmenity/AddHotelAmenityAction",
  async (credentials, thunkAPI, formData) => {
    try {
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/hotels/hotel/amenity`;
      const response = await axios.post(url, formData, credentials);
      localStorage.setItem(TOKEN, response.data.token);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// add hotel amenity slice
export const addHotelAmenitySlice = createSlice({
  name: "hotelAmenity/AddHotelAmenitySlice",
  initialState: {
    loading: true,
    isadded: false,
    amenities: [],
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addHotelAmenityAction.pending, (state) => {
        state.loading = true;
        state.isadded = false;
        state.error = null;
      })
      .addCase(addHotelAmenityAction.fulfilled, (state, action) => {
        state.loading = false;
        state.isadded = true;
        state.amenities = action.payload.amenities;
      })
      .addCase(addHotelAmenityAction.rejected, (state, action) => {
        state.loading = false;
        state.isadded = false;
        state.error = action.payload?.message || "Failed to add hotel amenity";
      });
  },
});

// delete hotel amenity action
export const deleteHotelAmenityAction = createAsyncThunk(
  "hotelAmenity/deleteHotelAmenityAction",
  async ({ token, hotelId, amenityId }, thunkAPI) => {
    try {
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/hotels/${hotelId}/amenity/${amenityId}`;
      const response = await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      localStorage.setItem(TOKEN, response.data.token);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

//delete hotel amenity slice
export const deleteHotelAmenitySlice = createSlice({
  name: "hotelAmenity/deleteHotelAmenitySlice",
  initialState: {
    loading: true,
    isdeleted: false,
    amenities: [],
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteHotelAmenityAction.pending, (state) => {
        state.loading = true;
        state.isdeleted = false;
        state.error = null;
      })
      .addCase(deleteHotelAmenityAction.fulfilled, (state, action) => {
        state.loading = false;
        state.isdeleted = true;
        state.amenities = action.payload.amenities;
      })
      .addCase(deleteHotelAmenityAction.rejected, (state, action) => {
        state.loading = false;
        state.isdeleted = false;
        state.error =
          action.payload?.message || "Failed to delete hotel amenity";
      });
  },
});

// update hotel amenity contact Action
export const updateHotelAmenityContactsAction = createAsyncThunk(
  "hotelAmenity/updateHotelAmenityContactAction",
  async (credentials, thunkAPI, formData) => {
    try {
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/hotels/contact`;
      const response = await axios.post(url, formData, credentials);
      localStorage.setItem(TOKEN, response.data.token);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// update hotel amenity contact slice
export const updateHotelAmenityContactsSlice = createSlice({
  name: "hotelAmenity/updateHotelAmenityContactSlice",
  initialState: {
    loading: true,
    isupdated: false,
    contacts: [],
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateHotelAmenityContactsAction.pending, (state) => {
        state.loading = true;
        state.isupdated = false;
        state.error = null;
      })
      .addCase(updateHotelAmenityContactsAction.fulfilled, (state, action) => {
        state.loading = false;
        state.isupdated = true;
        state.contacts = action.payload.contacts;
      })
      .addCase(updateHotelAmenityContactsAction.rejected, (state, action) => {
        state.loading = false;
        state.isupdated = false;
        state.error =
          action.payload?.message || "Failed to update hotel amenity contact";
      });
  },
});
