import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Header from "../components/layout/Header";
import { useDispatch, useSelector } from "react-redux";
import { ROUTES, ONBOARDING_PROPERTY, ROLE } from "../constants";
import { ownerAuthAction } from "../lib/slices/auth/authSlice";
import { getHotelByIdAction } from "../lib/slices/hotel/hotelSlice";

const OnBoardingRoutes = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { isAuthenticated } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.user);

  const isHotelExist = () => {
    const hotelId = localStorage.getItem(ONBOARDING_PROPERTY);
    if (hotelId) {
      dispatch(getHotelByIdAction(hotelId));
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      navigate(ROUTES.LOGIN);
    } else {
      dispatch(ownerAuthAction());
      isHotelExist()
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (user && user?.role === ROLE.OWNER && !user?.isEmailVerified) {
      navigate(ROUTES.VERIFY_EMAIL_OTP);
    }
  }, [user])

  return (
    <>
      <Header />
      <Outlet />
    </>
  );
};

export default OnBoardingRoutes;
