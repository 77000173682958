import React from "react";
import { MdPlayArrow } from "react-icons/md";
import { Link } from "react-router-dom";

const SingleMenu = ({ options, activeOption, setActiveOption }) => {
  return (
    <ul className="mt-4">
      {options.map((option) => (
        <li
          key={option.name}
          className={`pl-4 py-3 whitespace-nowrap text-[16px] leading-[21px]  capitalize hover:text-base-primary duration-300 ease-[0.3s]  ${
            activeOption === option.name
              ? "text-base-primary"
              : "text-[#738391]"
          }`}
        >
          <Link
            to={option.link}
            onClick={() => setActiveOption(option.name)}
            className="flex items-end gap-2"
          >
            {option.name}
            {activeOption === option.name ? <MdPlayArrow /> : null}
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default SingleMenu;
