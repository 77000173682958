import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux"; 
import ReportsCard from "./ReportsCard";
import calendartick from "../../images/icons/calendartick.svg";
import profitIcon from "../../images/icons/profitIcon.svg";
import { Link } from "react-router-dom";
import RoomTopBookingSource from "./RoomTopBookingSource";
import CustomeTable from "../../components/widget/CustomeTable";

const RoomBookingSalesReport = (props) => {
  // const { t } = useTranslation();
  const [datas, setDatas] = useState([]);
  const { sales } = useSelector((state) => state.SalesReport);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    //get booking sales by date
    if (props?.fromDate && props?.toDate) {
      props?.byDateSales(props?.hotel?._id, props?.fromDate, props?.toDate);
    }
    //get booking sales by month
    else if (props?.fromMonth && props.toMonth) {
      props?.byMonthSales(props?.hotel?._id, props?.fromMonth, props?.toMonth);
    }

    //get booking sales by year
    else if (props?.fromYear && props?.toYear) {
      props?.byYearSales(props?.hotel?._id, props?.fromYear, props?.toYear);
    }

    //get all booking sales
    else if (!props?.fromDate && !props?.fromMonth && !props?.fromYear) {
      props?.getAllSales(props?.hotel?._id);
    } else return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.fromDate, props?.toDate]);

  useEffect(() => {
    if (sales && Array.isArray(sales) && sales?.length) {
      let expense = sales?.map((d) => {
        return {
          bookingDate: d?.bookingDate,
          roomNumber: d?.roomNumber,
          roomType: d?.roomType,
          bookingId: d?.bookingId,
          checkInDate: d?.checkInDate,
          checkOutDate: d?.checkOutDate,
          refundable: d?.refundable,
          refundRequestURL: d?.refundRequestURL,
          roomTariff: d?.roomTariff,
          paymentMethod: d?.paymentMethod,
          status: d?.status,
        };
      });
      setDatas(expense);
    }
  }, [sales]);

  const tableData = [
    {
      id: "00R1",
      bookingDate: "2024-01-11",
      roomNumber: "101",
      roomType: "Suite",
      bookingId: "GKLMDC",
      checkInDate: "2024-01-11",
      checkOutDate: "2024-01-11",
      refundable: "Yes",
      refundRequestURL: "http:/www.reewq3r.com",
      roomTariff: "$1200",
      paymentMethod: "Card",
      status: "Paid",
    },
    {
      id: "00R2",
      bookingDate: "2024-01-11",
      roomNumber: "101",
      roomType: "Royal Suite",
      bookingId: "GKLMDC",
      checkInDate: "2024-01-11",
      checkOutDate: "2024-01-11",
      refundable: "No",
      refundRequestURL: "http:/www.reewq3r.com",
      roomTariff: "$1200",
      paymentMethod: "Card",
      status: "Unpaid",
    },
    {
      id: "00R3",
      bookingDate: "2024-01-11",
      roomNumber: "101",
      roomType: "Deluxe",
      bookingId: "GKLMDC",
      checkInDate: "2024-01-11",
      checkOutDate: "2024-01-11",
      refundable: "Yes",
      refundRequestURL: "http:/www.reewq3r.com",
      roomTariff: "$1200",
      paymentMethod: "Cash",
      status: "Paid",
    },
    {
      id: "00R4",
      bookingDate: "2024-01-11",
      roomNumber: "101",
      roomType: "Luxury",
      bookingId: "GKLMDC",
      checkInDate: "2024-01-11",
      checkOutDate: "2024-01-11",
      refundable: "No",
      refundRequestURL: "http:/www.reewq3r.com",
      roomTariff: "$1200",
      paymentMethod: "Card",
      status: "Unpaid",
    },
    {
      id: "00R5",
      bookingDate: "2024-01-13",
      roomNumber: "101",
      roomType: "Deluxe",
      bookingId: "GKLMDC",
      checkInDate: "2024-01-11",
      checkOutDate: "2024-01-11",
      refundable: "No",
      refundRequestURL: "http:/www.reewq3r.com",
      roomTariff: "$1200",
      paymentMethod: "Card",
      status: "Paid",
    },
    {
      id: "00R6",
      bookingDate: "2024-01-18",
      roomNumber: "101",
      roomType: "Deluxe",
      bookingId: "GKLMDC",
      checkInDate: "2024-01-11",
      checkOutDate: "2024-01-11",
      refundable: "Yes",
      refundRequestURL: "http:/www.reewq3r.com",
      roomTariff: "$1200",
      paymentMethod: "Card",
      status: "Paid",
    },
    {
      id: "00R7",
      bookingDate: "2024-01-17",
      roomNumber: "101",
      roomType: "Deluxe",
      bookingId: "GKLMDC",
      checkInDate: "2024-01-11",
      checkOutDate: "2024-01-11",
      refundable: "No",
      refundRequestURL: "http:/www.reewq3r.com",
      roomTariff: "$1200",
      paymentMethod: "Card",
      status: "Unpaid",
    },
    {
      id: "00R8",
      bookingDate: "2024-01-12",
      roomNumber: "101",
      roomType: "Deluxe",
      bookingId: "GKLMDC",
      checkInDate: "2024-01-11",
      checkOutDate: "2024-01-11",
      refundable: "Yes",
      refundRequestURL: "http:/www.reewq3r.com",
      roomTariff: "$1200",
      paymentMethod: "Card",
      status: "Unpaid",
    },
  ];
  const checkIsRefundable = ({ value }) => {
    return (
      <>
        <div className="text-[14px] leading-6 font-normal tracking-[-0.6%] mt-2">
          {value === "Yes" && <div className="text-base-primary">{value}</div>}
          {value === "No" && <div className="text-[#F04438]">{value}</div>}
        </div>
      </>
    );
  };

  const refundRequestURL = ({ value }) => {
    return (
      <>
        <div className="text-[14px] leading-6 font-normal tracking-[-0.6%] mt-2">
          <Link to={"#"} className="text-[#0059DF] hover:underline">
            {value}
          </Link>
        </div>
      </>
    );
  };

  const checkPaymentStatus = ({ value }) => {
    return (
      <>
        <div className="text-[14px] leading-6 font-normal tracking-[-0.6%] mt-2">
          {value === "Paid" && <div className="text-base-primary">{value}</div>}
          {value === "Unpaid" && <div className="text-[#F04438]">{value}</div>}
        </div>
      </>
    );
  };

  const columnDefs = [
    {
      headerName: "Room Booking ID",
      field: "id",
      minWidth: 210,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Booking Date",
      field: "bookingDate",
      filter: true,
    },
    {
      headerName: "Room Number",
      field: "roomNumber",
      filter: true,
    },
    {
      headerName: "Room Type",
      field: "roomType",
      filter: true,
    },
    {
      headerName: "Booking ID",
      field: "bookingId",
      filter: true,
    },
    {
      headerName: "Check-In-Date",
      field: "checkInDate",
      filter: true,
    },
    {
      headerName: "Check-Out-Date",
      field: "checkOutDate",
      filter: true,
    },
    {
      headerName: "Refundable",
      field: "refundable",
      filter: true,
      cellRenderer: checkIsRefundable,
    },
    {
      headerName: "Refund Request URL",
      field: "refundRequestURL",
      filter: true,
      cellRenderer: refundRequestURL,
    },
    {
      headerName: "Room Tariff",
      field: "roomTariff",
      filter: true,
    },
    {
      headerName: "Payment Method",
      field: "paymentMethod",
      filter: true,
    },
    {
      headerName: "Status",
      field: "status",
      filter: true,
      cellRenderer: checkPaymentStatus,
    },
  ];
  return (
    <div className="page-container">
      <div className="grid grid-cols-3 items-center gap-4 my-4">
        <ReportsCard
          icon={calendartick}
          iconBgColor={"bg-[#FFF6ED]"}
          heading={"Total Booking"}
          value={"1258"}
        />
        <ReportsCard
          icon={profitIcon}
          iconBgColor={"bg-[#F9F5FF]"}
          heading={"Total Sales"}
          value={"$1258"}
        />
        <ReportsCard
          icon={profitIcon}
          iconBgColor={"bg-[#F9F5FF]"}
          heading={"Payment in Cash"}
          value={"$1258"}
        />
      </div>
      {/* chart and top booking source */}
      <div className="w-full flex items-center gap-4 mb-4">
        {/* chart */}
        <div className="h-[467px] w-[65%] border border-[#E5E9EB] bg-white rounded-[15px] text-5xl flex items-center justify-center">
          insert chart here
        </div>
        {/* top booking source */}
        <div className="w-[35%] h-fit border border-[#E5E9EB] bg-white rounded-[10px] overflow-hidden pb-2">
          <RoomTopBookingSource />
        </div>
      </div>
      {/* {datas?.datas?.length ?( */}
      <CustomeTable
        tableName={"Room Booking Sales Report tabel"}
        columnDefs={columnDefs}
        rowData={tableData}
        setSelectedRows={setSelectedRows}
      />
      {/* ):null} */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  sales: state.SalesReport,
});

const mapDispatchToProps = (dispatch) => ({ 
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RoomBookingSalesReport);
