import React from "react";
import ToolTip from "../common/ToolTip";

const SelectTab = ({ label, value, setValue, options, message }) => {
  const handleValue = (e, index) => {
    setValue(e.target.value);
  };
  return (
    <div className="w-full">
      <div className="flex items-center justify-between">
        <label htmlFor="forThis">{label}</label>
        <ToolTip Message={"buffet purpose"} />
      </div>
      <div className="grid grid-cols-2 items-center gap-4">
        {Object.keys(options)?.map((tab, index) => (
          <button
            name="forThis"
            type="button"
            className={`h-12 w-full flex items-center justify-center capitalize border border-[#D0D5DD] rounded-lg text-base text-[#667085] ${
              value === options[tab]
                ? "border-base-primary text-base-primary"
                : ""
            }`}
            value={options[tab]}
            onClick={(e) => handleValue(e, index)}
            key={index}
          >
            {tab.replace("_", " ").toLocaleLowerCase()}
          </button>
        ))}
      </div>
    </div>
  );
};

export default SelectTab;
