import React from "react";
import { connect } from "react-redux";
import { useState } from "react"; 
import SaveOrNot from "../../components/modals/SaveOrNot";
import { isObjEmpty } from "../../utils"; 
import { useEffect } from "react";
import PhoneWithCode from "../../components/form/PhoneWithCode";
import { useTranslation } from "react-i18next";
import Inputfield from "../../components/form/InputField";
import CountryNameSelector from "../../components/form/CountryNameSelector";
import StateNameSelector from "../../components/form/StateNameSelector";
import CityNameSeletector from "../../components/form/CityNameSeletector";
import { GoCopy } from "react-icons/go";  
import FormSubmitButton from "../../components/buttons";
import SelectFromObject from "../../components/form/SelectFromObject"; 
import CloseButton from "../../components/buttons/CloseButton"; 

const AddVendor = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  //ADD New state
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [companyPhoneNumber, setCompanyPhoneNumber] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [productType, setProductType] = useState([]);
  const [productName, setProductName] = useState([]);
  // const [taxIdentificationNumber, setTaxIdentificationNumber] = useState("");
  const [country, setCountry] = useState("");
  const [countryIsoCode, setCountryIsoCode] = useState("");
  const [state, setState] = useState("");
  const [stateIsoCode, setStateIsoCode] = useState("");
  const [city, setCity] = useState("");
  // const [address, setAddress] = useState("");

  //vendor details
  const [vendorName, setVendorName] = useState("");
  const [vendorEmail, setVendorEmail] = useState([""]);
  const [vendorContact, setVendorContact] = useState("");
  // const [services, setServices] = useState([0]);

  const [password, setPassword] = useState("");
  const [userName, setUserName] = useState("");

  const [statusOfVendor, setStatusOfVendor] = useState([]);
  //click open
  const handleClickOpen = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  //close modal
  const closeModal = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    const data = {
      hotelId: props?.hotel?._id,
      name,
      phone: vendorContact,
      email: vendorEmail,
      productType,
      productName,
      company: {
        name,
        email,
        companyPhoneNumber,
        country,
        state,
        city,
        pinCode,
        streetAddress,
      },
    };
    console.log(data);
    if (isObjEmpty(props?.vendor)) {
      // props?.addVendor(data);
    } else {
      // props.updateVendor({
      //   vendorId: props?.vendor?.vendorId,
      //   ...data,
      // });
    }
  };

  //set data for update
  useEffect(() => {
    if (props?.vendor && !isObjEmpty(props?.vendor)) {
      setName(props?.vendor?.name);
      setEmail(props?.vendor?.email);
      setCompanyPhoneNumber(props?.vendor?.company?.contact);
      setCountry(props?.vendor?.company?.country);
      setState(props?.vendor?.company?.state);
      setCity(props?.vendor?.company?.city);
      setVendorName(props?.vendor?.name);
      setVendorContact(props?.vendor?.phone);
      setVendorEmail(props?.vendor?.email);
      setPinCode(props?.vendor?.pinCode);
      setPinCode(props?.vendor?.streetAddress);
      setProductType(props?.vendor?.productType);
      setProductName(props?.vendor?.productName);
    }
  }, [props?.vendor]);

  useEffect(() => {
    if (props?.add?.isadded || props?.update?.isupdated) {
      setName("");
      setEmail("");
      setCompanyPhoneNumber("");
      setCountry("");
      setState("");
      setCity("");
      setVendorName("");
      setVendorContact("");
      setVendorEmail("");
      setPinCode("");
      setStreetAddress("");
      setProductType("");
      setProductName("");
      props?.close();
    }
    if (props?.add?.isadded) {
      props?.getVendors(props?.hotel?._id); 
      props?.addReset();
    }
    if (props?.update?.isupdated) {
      props?.getVendors(props?.hotel?._id); 
      props?.updateReset();
    }

    if (props?.add?.error || props?.update?.error) { 
      props?.clear();
    }
  }, [props?.add, props?.update]);

  return (
    <>
      <div className="p-8 pt-6 mt-4 mx-auto flex flex-col rounded-lg bg-white border relative">
        <div className="flex items-center justify-between w-full mb-6">
          <div className="font-bold text-xl sm:text-2xl md:text-[30px] leading-9 text-black tracking-tighter text-center">
            {!isObjEmpty(props?.vendor) ? t("202") : t("201")}
          </div>
          <CloseButton onClick={props?.close} />
        </div>

        <form onSubmit={handleClickOpen}>
          <div className="text-[20px] leading-[100%] font-bold mb-4">
            {t("1108")}
          </div>
          <div className="w-full grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-6 grid-w-full items-center">
            <Inputfield
              label={t("766")}
              placeholder={t("767")}
              value={name}
              onChange={(e) => setName(e.target.value)}
              required={true}
              minLength={5}
              maxLength={100}
            />
            <Inputfield
              label={t("167")}
              placeholder={t("511")}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              required={true}
            />

            <PhoneWithCode
              phone={companyPhoneNumber}
              setPhone={setCompanyPhoneNumber}
              label={t("1106")}
              placeholder={t("769")}
            />
            {/* <Inputfield
              label={t("371")}
              placeholder={t("771")}
              value={taxIdentificationNumber}
              onChange={(e) => setTaxIdentificationNumber(e.target.value)}
            /> */}

            <CountryNameSelector
              imp="*"
              country={country}
              setCountry={setCountry}
              setCountryIsoCode={setCountryIsoCode}
            />
            <StateNameSelector
              imp="*"
              state={state}
              setState={setState}
              setStateIsoCode={setStateIsoCode}
              countryCode={countryIsoCode}
            />
            <CityNameSeletector
              city={city}
              setCity={setCity}
              countryCode={countryIsoCode}
              stateCode={stateIsoCode}
            />

            {/* <Inputfield
              label={t("52")}
              placeholder={t("767")}
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              minLength={5}
              maxLength={50}
            /> */}
          </div>
          <div className="grid grid-cols-3 gap-6 mt-6">
            <Inputfield
              label={t("1109")}
              placeholder={t("Code")}
              value={pinCode}
              onChange={(e) => setPinCode(e.target.value)}
              minLength={5}
              maxLength={50}
            />

            <Inputfield
              label={t("1107")}
              placeholder={t("1107")}
              value={streetAddress}
              onChange={(e) => setStreetAddress(e.target.value)}
              minLength={5}
              maxLength={50}
            />
            <SelectFromObject
              label={"Status Of Vendor"}
              value={statusOfVendor}
              setValue={setStatusOfVendor}
              options={{ active: "Active" }}
              className={"text-base-primary"}
            />
          </div>
          {/* Point of Contact Inforamation */}
          <div className="text-[20px] leading-[100%] font-bold mt-6 mb-4">
            {t("1110")}
          </div>
          <div className="w-full grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-6 grid-w-full">
            <Inputfield
              label={t("772")}
              placeholder={t("773")}
              value={vendorName}
              onChange={(e) => setVendorName(e.target.value)}
              minLength={3}
              maxLength={30}
              required={true}
            />
            <Inputfield
              label={t("167")}
              placeholder={t("511")}
              value={vendorEmail}
              onChange={(e) => setVendorEmail(e.target.value)}
              type="email"
              required={true}
            />

            <PhoneWithCode
              phone={vendorContact}
              setPhone={setVendorContact}
              label={t("527")}
              placeholder={t("528")}
            />

            {/* <MultipleSelect
              label={t("991")}
              values={services}
              setValues={setServices}
              options={VENDOR_SERVICES}
            /> */}
            {/* <SelectFromObject
              label={t("1111")}
              values={productType}
              setValue={setProductType}
              options={PRODUCT_TYPE}
            /> */}
            {/* <SelectFromObject
              label={t("1112")}
              values={productName}
              setValue={setProductName}
              options={PRODUCT_NAME}
            /> */}
          </div>
          <div className="text-[20px] leading-[100%] font-bold mt-6 mb-4">
            {t("Vendor Login Credentials")}
          </div>
          <div className="w-full grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-6 grid-w-full">
            <Inputfield
              label={"UserName"}
              placeholder={"Enter UserName"}
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              icon={<GoCopy size={22} />}
            />
            <Inputfield
              type="password"
              label={"Password"}
              placeholder={"Enter Password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              icon={<GoCopy size={22} />}
            />
            <div className="w-full flex items-end pr-16">
              <button
                type="button"
                className="h-12 w-full bg-white hover:bg-base-primary hover:text-white duration-300 ease-[0.3s] border border-base-primary rounded-md flex items-center justify-center text-[14px] leading-5 font-medium text-[#07090D]"
              >
                Generate Login Credentials
              </button>
            </div>
          </div>
          <div className="relative flex justify-end mt-8">
            <FormSubmitButton
              loading={props?.add?.loading || props?.update?.loading}
              text={!isObjEmpty(props?.vendor) ? t("202") : t("201")}
            />
          </div>
        </form>
      </div>
      <SaveOrNot isopen={open} close={closeModal} formSubmit={handleSubmit} />
    </>
  );
};

const mapStateToProps = (state) => ({
  add: state.AddVendor,
  update: state.UpdateVendorProfile,
});

const mapDispatchToProps = (dispatch) => ({ 
});

export default connect(mapStateToProps, mapDispatchToProps)(AddVendor);
