import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { ID_NUMBER, ROUTES } from "../../constants";
import { useNavigate } from "react-router-dom";
import CustomeTable from "../../components/widget/CustomeTable";
import LinksButton from "../../components/buttons/LinksButton";
import EditButton from "../../components/buttons/EditButton";
import DeleteButton from "../../components/buttons/DeleteButton";

const AllExpense = (props) => {
  const [datas, setDatas] = useState([]);

  const { expenses, loading, error } = useSelector(
    (state) => state.getAllExpense
  );
  console.log("get all expense slice :", expenses, loading, error);
  const [selectedRows, setSelectedRows] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (expenses && Array.isArray(expenses) && expenses?.length) {
      let expense = expenses?.map((d) => {
        return {
          expenseID: d?.expenseID,
          name: d?.name,
          type: d?.type,
          date: d?.date,
          frequency: d?.frequency,
          amount: d?.amount,
          paymentDate: d?.paymentDate,
          invoiceNumber: d?.invoiceNumber,
          _id: d?._id,
          // note:d?.note,
        };
      });
      setDatas(expense);
    }
  }, [expenses]);

  const viewExpenseActions = () => {
    const viewExpenseDetails = (id) => {
      if (id) {
        sessionStorage.setItem(ID_NUMBER, id);
        navigate(ROUTES.EXPENSE);
      }
    };
    return (
      <div className="h-full flex items-center gap-4">
        <LinksButton
          name={"View Details"}
          onClick={(id) => viewExpenseDetails(id)}
        />
        <EditButton />
        <DeleteButton />
      </div>
    );
  };

  const columnDefs = [
    {
      headerName: "Expense ID",
      field: "expenseID",
      minWidth: 160,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Name",
      field: "name",
      minWidth: 100,
      filter: true,
    },
    {
      headerName: "Type",
      field: "type",
      filter: true,
    },
    {
      headerName: "Date",
      field: "date",
      filter: true,
    },
    {
      headerName: "Frequency",
      field: "frequency",
      filter: true,
    },
    {
      headerName: "Amount",
      field: "amount",
      filter: true,
    },
    {
      headerName: "Payment Date",
      field: "paymentDate",
      filter: true,
    },
    {
      headerName: "Invoice Number",
      field: "invoiceNumber",
      filter: true,
    },
    {
      headerName: "Actions",
      field: "_id",
      cellRenderer: viewExpenseActions,
    },
  ];

  return (
    <>
      <div className="mt-4">
        {/* {datas?.datas?.length ? ( */}
        <CustomeTable
          tableName={"Expense Table"}
          columnDefs={columnDefs}
          rowData={datas}
          setSelectedRows={setSelectedRows}
        />
        {/* ) : null} */}
      </div>
      {/* <DeleteOrNot
        open={open}
        close={notDeleteExpense}
        formSubmit={deleteExpenseConfirm}
      /> */}
    </>
  );
};

export default AllExpense;
