import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"; 
import LoadSpinner from "../../components/common/loader/LoadSpinner";
import { ROUTES, RESET_PASSWORD_TOKEN } from "../../constants";
import AuthPasswordInput from "./AuthPasswordInput";
import AuthConfirmPasswordInput from "./AuthConfirmPasswordInput";
import { useTranslation } from "react-i18next";
import { resetPasswordAction } from "../../lib/slices/auth/authSlice";

const ResetPassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  // validation
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const { isAuthenticated, error, loading } = useSelector((state) => state.auth); 

  //reset password
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !passwordError &&
      !confirmPasswordError &&
      password &&
      confirmPassword
    ) {
      const formData = {
        password,
        confirmPassword,
      };

      dispatch(resetPasswordAction(formData));
    }
  };

  useEffect(() => {
    if (isAuthenticated) { 
      navigate(ROUTES.DASHBOARD);
    }
  }, [isAuthenticated]);

  //clearing error
  useEffect(() => {
    if (error) {
      // notifyError(error);
    }
  }, [error]);

  //on change password check password match or not
  useEffect(() => {
    if (confirmPassword) {
      if (password !== confirmPassword)
        setConfirmPasswordError("Password does not match!");
    }
  }, [password]);
  
  useEffect(() => {
    const token= localStorage.getItem(RESET_PASSWORD_TOKEN)
    if(!token){
      navigate(ROUTES.PASSWORD_FORGOT)
    }
  }, []);

  return (
    <> 
      <div className="w-full bg-[#fcf5ec] min-h-screen p-4 relative">
        <div className="w-[450px] mx-auto max-w-full rounded flex flex-col shadow-md my-8 bg-[#FFFFFF]">
          <div className="w-full pt-6">
            <h1 className="text-3xl font-medium text-center text-base-primary capitalize">
              {t("988")}
            </h1>
            <form
              className="w-full p-8 grid grid-cols-1 gap-4"
              onSubmit={handleSubmit}
            >
              <AuthPasswordInput
                password={password}
                setPassword={setPassword}
                passwordError={passwordError}
                setPasswordError={setPasswordError}
              />
              <AuthConfirmPasswordInput
                password={password}
                confirmPassword={confirmPassword}
                setConfirmPassword={setConfirmPassword}
                confirmPasswordError={confirmPasswordError}
                setConfirmPasswordError={setConfirmPasswordError}
              />
              <button
                className="inline-block h-12 w-full bg-base-primary text-white rounded-3xl font-semibold disabled:bg-base-rgba_primary_7"
                disabled={
                  loading ||
                  passwordError ||
                  confirmPasswordError ||
                  !password ||
                  !confirmPassword
                }
              >
                {loading ? <LoadSpinner /> : <>{t("988")}</>}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
