import React, { useState } from "react";
import Inputfield from "../../../components/form/InputField";
import ConditionToBookPackage from "./ConditionToBookPackage";
import SubmitButton from "../../../components/buttons/SubmitButton";

const Details = () => {
  const [packageName, setPackageName] = useState("");
  const [availableFor, setAvailableFor] = useState({});
  const [minNoOfRoomBookedTogether, setMinNoOfRoomBookedTogether] = useState(
    []
  );
  const [minNoOfNight, setMinNoOfNight] = useState([]);

  const packageAvailableFor = [
    {
      id: "1",
      name: "Group Booking",
    },
    { id: "2", name: "Corporates" },
    { id: "3", name: "Travel agents" },
    { id: "4", name: "Individuals" },
  ];

  const handleAvailabilityChange = (id, value) => {
    setAvailableFor((prev) => ({ ...prev, [id]: value }));
  };

  return (
    <div className="text-[#07090D] px-6">
      <div className="text-[30px] leading-[37.8px] font-bold tracking-[-1.5%]">
        Package Details
      </div>
      <p className="text-[20px] leading-6">
        Lorem ipsum amit epic alit thu Lorem ipsum amit epic alit thu{" "}
      </p>
      <div className="w-1/2 mt-10">
        <Inputfield
          label={"Package Name"}
          value={packageName}
          onChange={(e) => setPackageName(e.target.value)}
          placeholder={"Enter Package Name"}
          message={"enter package name"}
        />
      </div>
      <div className="text-[16px] leading-5 font-medium text-[#344054] mt-10 mb-6">
        <div>Package Available For :</div>
        <div className="flex flex-col gap-2 mt-4">
          {packageAvailableFor.map((paf) => (
            <div
              key={paf.id}
              className="w-1/2 flex items-center justify-between"
            >
              <div>{paf.name}</div>
              <div className="flex items-center gap-4">
                <div className="text-sm font-medium">
                  <input
                    type="radio"
                    id={`yes-${paf.id}`}
                    value="yes"
                    checked={availableFor[paf.id] === "yes"}
                    onChange={() => handleAvailabilityChange(paf.id, "yes")}
                    className="size-3 accent-current"
                  />
                  <label
                    htmlFor={`yes-${paf.id}`}
                    className={`ml-2 ${
                      availableFor[paf.id] === "yes"
                        ? "text-base-primary"
                        : null
                    }`}
                  >
                    Yes
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id={`no-${paf.id}`}
                    value="no"
                    checked={availableFor[paf.id] === "no"}
                    onChange={() => handleAvailabilityChange(paf.id, "no")}
                    className={`size-3 ${
                      availableFor[paf.id] === "no" ? "accent-[#F04438]" : null
                    }`}
                  />
                  <label
                    htmlFor={`no-${paf.id}`}
                    className={`ml-2 ${
                      availableFor[paf.id] === "no" ? "text-[#F04438]" : null
                    }`}
                  >
                    No
                  </label>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="text-[16px] leading-5 font-medium text-[#344054]">
        <div className="">Condition to Booke Package :</div>
        <div className="w-9/12 flex flex-col gap-6 mt-6">
          <ConditionToBookPackage
            h={"Minimum Number of Rooms Booked Together?"}
            p={"Lorem ipsum amit epic alit thu  Lorem ipsum amit "}
            message={"abcd"}
            value={minNoOfRoomBookedTogether}
            onChange={(e) => setMinNoOfRoomBookedTogether(e.target.value)}
            options={["1", "2", "3", "4", "5", "6"]}
          />
          <ConditionToBookPackage
            h={"Minimum Number of Nights?"}
            p={"Lorem ipsum amit epic alit thu  Lorem ipsum amit "}
            message={"abcd"}
            value={minNoOfNight}
            onChange={(e) => setMinNoOfNight(e.target.value)}
            options={["1", "2", "3", "4", "5", "6"]}
          />
        </div>
      </div>
      <div className="flex items-center justify-end mt-8">
        <SubmitButton
          type="button"
          className={"h-12 w-[160px] text-[20px] leading-5"}
          text={"Next"}
        />
      </div>
    </div>
  );
};

export default Details;
