import React, { useEffect, useState } from "react";
import Inputfield from "../../components/form/InputField";
import { GENDERS } from "../../constants";
import PhoneWithCode from "../../components/form/PhoneWithCode";
import CountryNameSelector from "../../components/form/CountryNameSelector";
import CollapseExpend from "../booking/CollapseExpend";
import { useTranslation } from "react-i18next";
import StateNameSelector from "../../components/form/StateNameSelector";
import CityNameSeletector from "../../components/form/CityNameSeletector";
import BookingCaptureCheckBox from "../booking/BookingCaptureCheckBox";
import SelectFromArray from "../../components/form/SelectFromArray";
import FormSubmitButton from "../../components/buttons";
import CloseButton from "../../components/buttons/CloseButton";

const AddGuest = (props) => {
  const { t } = useTranslation();
  const [guestName, setGuestName] = useState("");
  const [gender, setGender] = useState(GENDERS.MALE);
  const [nationality, setNationality] = useState("");
  const [nationalityIsoCode, setNationalityIsoCode] = useState("");
  const [contact, setContact] = useState("");
  const [idNumber, setIdNumber] = useState("");
  const [idType, setIdType] = useState("");
  const [issuingCountry, setIssuingCountry] = useState("");
  const [issuingCountryIsoCode, setIssuingCountryIsoCode] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [country, setCountry] = useState("");
  const [countryIsoCode, setCountryIsoCode] = useState("");
  const [state, setState] = useState("");
  const [stateIsoCode, setStateIsoCode] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [captureGuestInformation, setCaptureGuestInformation] = useState(true);
  const [captureGuestIdentity, setCaptureGuestIdentity] = useState(true);
  const [expendGuest, setExpendeGuest] = useState(true);
  const [expendPersonalInfo, setExpendPersonalInfo] = useState(true);
  const [expendIdentity, setExpendIdentity] = useState(true);
  const [expendAddress, setExpendAddress] = useState(true);

  useEffect(() => {
    const timeDelay = setTimeout(() => {
      if (guestName || contact || idNumber || streetAddress || zipCode !== "") {
        console.log(guestName, contact, idNumber, streetAddress, zipCode);
      }
    }, 1000);
    return () => {
      clearTimeout(timeDelay);
    };
  }, [
    captureGuestIdentity,
    captureGuestInformation,
    city,
    contact,
    country,
    expiryDate,
    gender,
    guestName,
    idNumber,
    idType,
    issuingCountry,
    nationality,
    state,
    streetAddress,
    zipCode,
  ]);

  return (
    <div className="w-full border border-[#E5E9EB] rounded-[10px] bg-white py-4 px-6 mb-4">
      <div className="flex items-center justify-between">
        <div className="text-[24px] leading-[30.24px] font-bold text-[#07090D] tracking-[-1.5%]">
          Add Guest
        </div>
        <CloseButton onClick={props?.close} />
      </div>
      <div className="md:flex md:justify-end items-center mt-4">
        <div className="flex flex-col sm:flex-row gap-4">
          <BookingCaptureCheckBox
            label={t("522")}
            text={captureGuestInformation ? t("113") : t("514")}
            value={captureGuestInformation}
            setValue={setCaptureGuestInformation}
          />
          <BookingCaptureCheckBox
            label={t("523")}
            text={captureGuestIdentity ? t("113") : t("514")}
            value={captureGuestIdentity}
            setValue={setCaptureGuestIdentity}
          />
        </div>
      </div>
      <div className={captureGuestInformation ? "relative" : "hidden"}>
        <div className={`${expendGuest ? "relative" : "hidden"}`}>
          {/* personal information */}
          <CollapseExpend
            value={expendPersonalInfo}
            setValue={setExpendPersonalInfo}
            text={t("524")}
          />
          <div
            className={`${
              expendPersonalInfo ? "relative" : "hidden"
            } w-full grid sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-4 grid-w-full`}
          >
            <Inputfield
              placeholder={t("387")}
              value={guestName}
              onChange={(e) => setGuestName(e.target.value)}
              label={`${t("525")} ${t("166")}`}
              minLength={3}
              maxLength={50}
              required={captureGuestInformation}
            />
            <SelectFromArray
              label="Gender"
              value={gender}
              onChange={(e) => setGender(+e?.target?.value)}
              options={[""]}
              required={true}
            />
            <CountryNameSelector
              country={nationality}
              setCountry={setNationality}
              label={t("526")}
              setCountryIsoCode={setNationalityIsoCode}
              countryIsoCode={nationalityIsoCode}
            />

            <PhoneWithCode
              label={t("527")}
              placeholder={t("528")}
              phone={contact}
              setPhone={setContact}
            />
          </div>

          {/* Identity information */}
          <div className={captureGuestIdentity ? "relative" : "hidden"}>
            <CollapseExpend
              value={expendIdentity}
              setValue={setExpendIdentity}
              text={t("529")}
            />
            <div
              className={`${
                expendIdentity ? "relative" : "hidden"
              } w-full grid sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-4 grid-w-full`}
            >
              <Inputfield
                label={t("530")}
                placeholder={t("531")}
                value={idNumber}
                onChange={(e) => setIdNumber(e.target.value)}
                minLength={4}
                maxLength={20}
                required={captureGuestIdentity && captureGuestInformation}
              />
              <SelectFromArray
                label={t("532")}
                options={[""]}
                value={idType}
                onChange={(e) => setIdType(e?.target?.value)}
              />

              <CountryNameSelector
                country={issuingCountry}
                setCountry={setIssuingCountry}
                setCountryIsoCode={setIssuingCountryIsoCode}
                label={t(t("533"))}
                countryIsoCode={issuingCountryIsoCode}
              />
              <Inputfield
                label={t("534")}
                placeholder={t("535")}
                type="date"
                value={expiryDate}
                onChange={(e) => setExpiryDate(e.target.value)}
                required={captureGuestIdentity && captureGuestInformation}
              />
            </div>
          </div>
          {/* Address information */}
          <div className="py-2 md:flex justify-between items-center">
            <CollapseExpend
              value={expendAddress}
              setValue={setExpendAddress}
              text={t("536")}
            />
            <div className="flex flex-col gap-4 mb-4 sm:flex-row justify-between md:mb-0">
              <div className="flex gap-2">
                <input
                  type="checkbox"
                  className="h-4 w-4 mt-1 border border-[#667085] rounded accent-current"
                />
                <label className="text-[14px] leading-6 font-normal text-[#07090D]">
                  {t("537")}
                </label>
              </div>
              <div className="flex gap-1">
                <label
                  htmlFor="label"
                  className="text-[14px] leading-6 font-normal text-[#07090D]"
                >
                  {t("538")}
                </label>
                <select className="w-[103px] h-[27px] border-none">
                  <option>{t("51")}</option>
                </select>
              </div>
            </div>
          </div>
          <div
            className={`${
              expendAddress ? "relative" : "hidden"
            } w-full grid grid-cols-4 gap-4`}
          >
            <div className="col-span-3">
              <Inputfield
                label={t("540")}
                placeholder={t("541")}
                value={streetAddress}
                onChange={(e) => setStreetAddress(e.target.value)}
                required={true}
                minLength={5}
                maxLength={100}
              />
            </div>
          </div>
          <div
            className={`${
              expendAddress ? "relative" : "hidden"
            } w-full grid sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-4 grid-w-full mt-4`}
          >
            <CountryNameSelector
              country={country}
              setCountry={setCountry}
              setCountryIsoCode={setCountryIsoCode}
              countryIsoCode={countryIsoCode}
              label={t("429")}
            />
            <StateNameSelector
              state={state}
              setState={setState}
              setStateIsoCode={setStateIsoCode}
              countryCode={countryIsoCode}
            />
            <CityNameSeletector
              city={city}
              setCity={setCity}
              countryCode={countryIsoCode}
              stateCode={stateIsoCode}
            />

            <Inputfield
              label={t("542")}
              placeholder={t("543")}
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
              minLength={4}
              maxLength={6}
            />
          </div>
        </div>
      </div>
      <div className="flex items-center justify-end mt-8">
        <FormSubmitButton text={t("Add Guest")} />
      </div>
    </div>
  );
};

export default AddGuest;
