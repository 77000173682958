import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import RoomsHeader from "./RoomsHeader"; 
import FoodBuffetPrice from "./FoodBuffetPrice";
import AllQRCode from "../qrcode";
import PackagesPage from "./packages";
import RoomAmenities from "./room-amenities";
import RoomMinibarList from "./room-minibar/RoomMinibarList";
import RoomMinibarItems from "./room-minibar/RoomMinibarItems";
import AllRooms from "./AllRooms";
import RoomRate from "./RoomRate";
import ServicesPage from "./services";
import Breadcrumbs from "../../components/common/Breadcrumbs";

const RoomConfiguration = () => {
  const { t } = useTranslation();
  const [index, setIndex] = useState(1); 

  const headingData = {
    heading: t("Room Configuration"),
    seperator: [
      {
        text: t("Hotel Configuration"),
      },
      {
        text: t("Room Configuration"),
      },
    ],
  };

  return (
    <div className="page-container">
      <Breadcrumbs data={headingData} />
      <div className="my-6">
        <RoomsHeader params={index} setIndex={setIndex} />
      </div>
      {index === 1 ? <RoomAmenities /> : null}
      {index === 2 ? <RoomMinibarItems /> : null}
      {index === 3 ? <RoomMinibarList /> : null}
      {index === 4 ? <AllRooms /> : null}
      {index === 5 ? <FoodBuffetPrice /> : null}
      {index === 6 ? <RoomRate /> : null}
      {index === 7 ? <AllQRCode /> : null}
      {index === 8 ? <ServicesPage /> : null}
      {index === 9 ? <PackagesPage /> : null}
    </div>
  );
};

export default RoomConfiguration;
