import React, { useState } from "react";
import { useTranslation } from "react-i18next"; 
import { ROUTES } from "../../constants"; 
import { useNavigate } from "react-router-dom"; 
import BackButton from "../../components/buttons/BackButton";
import ChangePassword from "../../components/modals/ChangePassword";
import OutlineButton from "../../components/buttons/OutlineButton";
import Inputfield from "../../components/form/InputField";
import { GoCopy } from "react-icons/go";
import SubmitButton from "../../components/buttons/SubmitButton";

function VendorDetails() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [isChangePassword, setIsChangePassword] = useState(false);
  const data = {
    heading: t("Details"),
    seperator: [
      {
        text: t("134"),
      },
      {
        link: ROUTES.VENDORS,
        text: t("233"),
      },
      { text: t("Details") },
    ],
  };
  const backToVendors = () => {
    navigate(ROUTES.VENDORS);
  };
  return (
    <>
      <div className="page-container">
        <div className="w-full max-w-7xl mx-auto">
          <div className="flex justify-between items-end">
            {/* <Seperator data={data} /> */}
            <BackButton onClick={backToVendors} />
          </div>
        </div>
        {/* Company Information */}
        <div className="my-4 text-[24px] leading-[30.24px] font-bold tracking-[-0.8px]">
          {t("Company Information")}
        </div>
        <div className="border border-[#D0D5DD] rounded-[10px] bg-white grid grid-cols-4 p-6 gap-4">
          {/* <DetailsHeading heading={"Name"} subHeading={"Amul"} />
          <DetailsHeading heading={"Email"} subHeading={"amul@gmail.com"} />
          <DetailsHeading
            heading={"Contact Number"}
            subHeading={"+91-214214535"}
          />
          <DetailsHeading
            heading={"Tax Identification Number"}
            subHeading={"NNN-NN-NNNN"}
          />
          <DetailsHeading heading={"Country"} subHeading={"India"} />
          <DetailsHeading heading={"State"} subHeading={"Maharashtra"} />
          <DetailsHeading heading={"City"} subHeading={"Mumbai"} />
          <DetailsHeading
            heading={"Address"}
            subHeading={"93, Aurbindo Apartment, Adchini"}
          />
          <DetailsHeading
            heading={"Vendor to Hotel Distance"}
            subHeading={"12 km"}
          /> */}
        </div>
        <div className="my-4 text-[24px] leading-[30.24px] font-bold tracking-[-0.8px]">
          {t("Vendor Information")}
        </div>
        <div className="border border-[#D0D5DD] rounded-[10px] bg-white grid grid-cols-3 p-6 gap-4">
          {/* <DetailsHeading heading={"Name"} subHeading={"Rajveer"} />
          <DetailsHeading heading={"Email"} subHeading={"raj@gmail.com"} />
          <DetailsHeading
            heading={"Contact Number"}
            subHeading={"+91-214214535"}
          />
          <DetailsHeading
            heading={"Product Type"}
            subHeading={"Grocery, Vegetable"}
          />
          <DetailsHeading
            heading={"Product Name"}
            subHeading={"Potato, Tomato"}
          />
          <DetailsHeading
            heading={"Average Delivery Time"}
            subHeading={"1 Hour"}
          /> */}
        </div>
        <div className="my-4 text-[24px] leading-[30.24px] font-bold tracking-[-0.8px]">
          {t("Vendor Login Id & Password")}
        </div>
        <div className="h-[146px]  border border-[#D0D5DD] bg-white rounded-[10px] p-6">
          <div className="grid grid-cols-3 items-end gap-6">
          <Inputfield
            label={"UserName"}
            placeholder={"Enter UserName"}
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            icon={<GoCopy size={22} />}
          />
          <Inputfield
            type="password"
            label={"Password"}
            placeholder={"Enter Password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            icon={<GoCopy size={22} />}
          />
          <OutlineButton
            sizing="h-12 w-[214px]"
            text={"CHange Password"}
            onClick={() => setIsChangePassword(true)}
          />
          </div>
          {isChangePassword ? (
            <ChangePassword close={() => setIsChangePassword(false)} />
          ) : null}
        </div>
        <div className="flex items-center justify-end mt-6">
            <SubmitButton className="w-[214px] h-12" text={"Save"} />
          </div>
      </div>
    </>
  );
}

export default VendorDetails;
