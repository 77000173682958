import React from "react"; 
import { useTranslation } from "react-i18next";
import { BiArrowBack } from "react-icons/bi";
import { ROUTES } from "../../constants"; 
import { useNavigate } from "react-router-dom";

const ExpenseDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const track = {
    heading: t("Details"),
    seperator: [
      {
        text: t("241"),
      },
      {
        text: t("Expenses"),
        link: ROUTES.EXPENSES,
      },
      {
        text: t("Details"),
      },
    ],
  };
  const backExpenseDetails = () => {
    navigate(ROUTES.EXPENSES);
  };
  const expenseInformation = [
    {
      id: 1,
      name: "Expense ID",
      value: "EX001",
    },
    { id: 2, name: "Expense Name", value: "Expense-1" },
    { id: 3, name: "Type", value: "Maintenance" },
    { id: 4, name: "Date", value: "17-04-2024" },
    { id: 5, name: "Frequency", value: "One Time" },
    { id: 6, name: "Amount", value: "$10,000" },
    { id: 7, name: "Invoice Number", value: "123" },
  ];
  const paymentApproval = [
    {
      id: 1,
      name: "Paid Amount",
      value: "$10,000",
    },
    { id: 2, name: "Payment Date", value: "17-04-2024" },
    { id: 3, name: "Payment Approvel By", value: "CEO" },
    { id: 4, name: "Status", value: "Approved" },
    { id: 5, name: "Payment Method", value: "Bank Transfer" },
    { id: 6, name: "Transaction Id", value: "863484vd634278" },
    { id: 7, name: "Tax", value: "0%" },
  ];
  return (
    <div className="page-container max-w-7xl">
      <div className=" flex justify-between items-end">
        {/* <Seperator data={track} /> */}
        <button
          className="h-10 w-[89px] border rounded-md bg-white hover:bg-gray-50 flex items-center relative"
          onClick={() => backExpenseDetails()}
        >
          <BiArrowBack size={14} className="text-[#252C32] absolute left-4" />
          <div className="text-[14px] leading-6 font-normal pl-10">
            {t("236")}
          </div>
        </button>
      </div>
      <div className="my-4 text-[24px] leading-[30.24px] font-bold tracking-[-0.8px]">
        {t("Expense Information")}
      </div>
      <div className="border border-[#D0D5DD] rounded-[10px] bg-white grid grid-cols-4 p-6 gap-4">
        {/* {expenseInformation?.map((ei) => {
          const { id, name, value } = ei;
          return <DetailsHeading key={id} heading={name} subHeading={value} />;
        })} */}
      </div>
      <div className="my-4 text-[24px] leading-[30.24px] font-bold tracking-[-0.8px]">
        {t("Payment Approval")}
      </div>
      <div className="border border-[#D0D5DD] rounded-[10px] bg-white grid grid-cols-4 p-6 gap-4">
        {/* {paymentApproval?.map((ei) => {
          const { id, name, value } = ei;
          return <DetailsHeading key={id} heading={name} subHeading={value} />;
        })} */}
      </div>
    </div>
  );
};

export default ExpenseDetails;
