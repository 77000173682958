import React from "react";  
import Inputfield from "../../components/form/InputField";
import { useTranslation } from "react-i18next";
import AddBookingCheck from "./AddBookingCheck";
import SelectFromObject from "../../components/form/SelectFromObject";
import { BILLING_TO, PAYMENT_STATUS } from "../../constants";

const BillingInformation = ({
  paymentStatus,
  setPaymentStatus,
  paymentMethod,
  setPaymentMethod,
  amount,
  setAmount,
  refundable,
  setRefundable,
  refundableTill,
  setRefundableTill,
  billTo,
  setBillTo,
  taxExemption,
  setTaxExemption,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="w-full bg-[#FFFFFF] border border-[#DDE2E4] rounded-lg mx-auto mt-4 p-6">
        <div className="font-bold text-[20px] leading-[100%]">{t("569")}</div>
        <div className="w-full grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 grid-w-full mt-4">
          <SelectFromObject
            label={t("552")}
            toolTipMessage={t("553")}
            options={PAYMENT_STATUS}
            value={paymentStatus}
            onChange={(e) => setPaymentStatus(e?.target?.value)}
          />
          <SelectFromObject
            label={t("554")}
            toolTipMessage={t("555")}
            options={PAYMENT_STATUS}
            value={paymentMethod}
            setValue={setPaymentMethod}
          />
          <Inputfield
            label={t("556")}
            placeholder={t("557")}
            value={amount}
            type="number"
            onChange={(e) => setAmount(e?.target?.value)}
            required={true}
          />
          <AddBookingCheck
            label={t("558")}
            message={t("559")}
            text={refundable ? t("113") : t("514")}
            value={refundable}
            setValue={setRefundable}
          />
          <Inputfield
            label={t("560")}
            message={t("561")}
            placeholder={t("562")}
            type="date"
            value={refundableTill}
            onChange={(e) => setRefundableTill(e.target.value)}
            required={refundable}
          />
          <SelectFromObject
            label={t("563")}
            value={billTo}
            options={BILLING_TO}
            onChange={(e) => setBillTo(e.target.value)}
          />
          <AddBookingCheck
            label={t("564")}
            message={t("565")}
            text={t("514")}
            value={taxExemption}
            setValue={setTaxExemption}
          />
        </div>
      </div>
    </>
  );
};

export default BillingInformation;
