import { useTranslation } from "react-i18next";
import ToolTip from "../../../components/common/ToolTip";
import { COUNTRY_CURRENCY } from "../../../constants";

const PropertyCurrencySelector = ({
  label,
  value,
  setValue, 
  required = false,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div>
        <div className="flex items-center justify-between">
          <label className="text-sm font-semibold text-[#344054]">
            {label}
          </label>
          <ToolTip Message={t("794")} />
        </div>
        <div className="relative flex items-center">
          <select
            onChange={(e) => {
              setValue(e?.target?.value);
            }}
            value={value}
            required={required}
          >
            {COUNTRY_CURRENCY?.map((data, index) => (
              <option value={data?.currency_code} key={index}>
                {data?.country + "  "}
                {data?.currency_code}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
};

export default PropertyCurrencySelector;
