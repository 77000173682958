import React, { useEffect, useState } from "react";
import { COUNTRY_CODES } from "../../constants";
import ToolTip from "../common/ToolTip";

const PhoneWithCode = ({
    phone = "",
    setPhone,
    placeholder = "",
    label = "Phone Number",
    toolTipMessage,
    required = false,
    readOnly = false,
}) => {
    const [selectedCountry, setSelectedCountry] = useState(COUNTRY_CODES[0].dial_code);
    const [phoneNumber, setPhoneNumber] = useState("");

    // Initialize phone number and selected country based on the `phone` prop
    useEffect(() => {
        if (phone) {
            const country = COUNTRY_CODES.find((c) => phone.startsWith(c.dial_code));
            if (country) {
                setSelectedCountry(country.dial_code);
                setPhoneNumber(phone.replace(country.dial_code + "-", ""));
            }
        }
    }, [phone]);

    const handleCountryChange = (e) => {
        const selectedCode = e.target.value;
        setSelectedCountry(selectedCode);
        formatAndSetPhoneNumber(phoneNumber, selectedCode);
    };

    const handlePhoneChange = (e) => {
        const rawPhoneNumber = e.target.value.replace(/[^\d]/g, ""); // Remove non-numeric characters
        console.log(rawPhoneNumber)
        formatAndSetPhoneNumber(rawPhoneNumber, selectedCountry);
    };

    const formatAndSetPhoneNumber = (rawNumber, dialCode) => {
        if (!rawNumber) {
            setPhoneNumber("");
            setPhone("");
            return;
        }
        const formattedNumber = `${dialCode}-${rawNumber}`;
        setPhoneNumber(rawNumber);
        setPhone(formattedNumber);
    };

    return (
        <div className="w-full">
            <div className="flex items-center justify-between">
                {label && (
                    <label className="text-sm font-medium text-gray-700">
                        {label}
                        {required && <span className="text-red-500">*</span>}

                    </label>
                )}
                {toolTipMessage && <ToolTip Message={toolTipMessage}/>}
            </div>
            <div className="flex items-center rounded-md relative">
                <select
                    className="absolute left-0.5 !border-none focus:!border-none !border-r !w-fit !pr-0 z-10 !h-11 !shadow-none"
                    value={selectedCountry}
                    onChange={handleCountryChange}
                    disabled={readOnly}
                >
                    {COUNTRY_CODES.map((country) => (
                        <option key={country.code} value={country.dial_code}>
                            {country.emoji} {country.dial_code}
                        </option>
                    ))}
                </select>
                <input
                    type="text"
                    className="w-full h-12 relative bg-transparent focus:outline-none px-2 !pl-28"
                    placeholder={placeholder}
                    value={phoneNumber}
                    onChange={handlePhoneChange}
                    readOnly={readOnly}
                    required={required}
                />
            </div>
        </div>
    );
};

export default PhoneWithCode;
