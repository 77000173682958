//check is object empty
export const isObjEmpty = (value) => {
  if (typeof value !== "object" || value === null) return true;
  return Object.keys(value).length === 0;
};

//check type of value is string and there is a string
export const isString = (value) => {
  if (typeof value !== "string") return false;
  return value.length > 0;
};

//check is hotel fully configured or not
export const propertyConfiguration = (hotel, profile, rooms) => {
  const hotelProfileConfigured = (hotel) => {
    if (
      hotel &&
      hotel?.propertyName &&
      hotel?.propertyType &&
      hotel?.contacts &&
      hotel?.contacts?.length &&
      hotel?.checkInTime &&
      hotel?.checkOutTime &&
      hotel?.propertyContact?.email &&
      hotel?.propertyContact?.phone &&
      hotel?.propertyAddress?.city &&
      hotel?.propertyAddress?.streetAddress &&
      hotel?.propertyAddress?.state &&
      hotel?.propertyAddress?.country &&
      hotel?.propertyAddress?.pinCode
    ) {
      return true;
    } else return false;
  };

  const roomsConfigured = (rooms) => {
    if (rooms && rooms?.length) return true;
    else return false;
  };

  if (isObjEmpty(profile)) {
    return -1;
  }
  if (isObjEmpty(hotel)) {
    return 10;
  } else if (isObjEmpty(rooms)) {
    return 30;
  } else if (hotelProfileConfigured) {
    return 30;
  } else if (roomsConfigured) {
    return 50;
  }
  return 0;
}