import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import OperationsHeader from "./OperationsHeader";
import LeftSide from "./LeftSide";
import GridHeader from "../reservation/GridHeader";
import BuffetStatus from "./BuffetStatus";
import AllKitchenOperations from "./AllKitchenOperations";

const KitchenOperations = () => {
  const { t } = useTranslation();
  const [month, setMonth] = useState(new Date().getMonth());
  const [dates, setDates] = useState([]);
  //get number of days in a month
  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  //create an array of dates.
  const getMonthDates = (year, month) => {
    const days = getDaysInMonth(year, month);
    const monthDates = Array(days)
      .fill(0)
      .map((date, index) => {
        const currentDate = new Date(year, month, 1);
        currentDate.setDate(currentDate.getDate() + index);
        return new Date(currentDate);
      });
    setDates(monthDates);
  };

  //set times
  useEffect(() => {
    const date = new Date();
    let year = date.getFullYear();
    const thisMonth = date.getMonth();

    if (month === thisMonth) {
      // console.log("same month", month, year);
    } else if (month < thisMonth) {
      if (thisMonth - month >= 6) {
        //  goto next year starting months
        year = year + 1;
      }
      // console.log("Previous month", month, year);
    } else if (month > thisMonth) {
      if (month - thisMonth > 6) {
        //  goto previous year ending months
        year = year - 1;
      }
      // console.log("Future month", month, year);
    }
    getMonthDates(year, month);
  }, [month]);

  return (
    <div className="page-container max-w-7xl">
      <div className="flex flex-col mx-auto  max-w-full">
        <OperationsHeader month={month} setMonth={setMonth} />
        <div className="flex border border-t-0 border-b-0 rounded-t-lg overflow-hidden bg-[#FFFFFF]">
          <LeftSide />
          <div className="flex scroll-horizontal overflow-x-auto overflow-y-hidden">
            <div className="">
              <GridHeader dates={dates} />
            </div>
          </div>
        </div>
        <div className="bg-[#FFFFFF] rounded-b-lg border">
          <BuffetStatus />
        </div>
      </div>
      <div className="mt-4">
        <AllKitchenOperations />
      </div>
    </div>
  );
};

export default KitchenOperations;
