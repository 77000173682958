import React from "react";
import { BiUser } from "react-icons/bi";
import { IoIosArrowDown } from "react-icons/io";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ROUTES } from "../../constants";

const ProfileButton = () => {
  const { user } = useSelector((state) => state.user);
  return (
    <>
      <Link
        to={ROUTES.MY_PROFILE}
        className="select-none  flex items-center justify-center"
      >
        <div className="text-2xl text-[#FFFFFF] rounded-full h-8 w-8 min-w-[32px] bg-[#152238] flex items-center justify-center">
          <BiUser />
        </div>
        <span className="hidden md:flex">
          <span className="font-semibold text-[20px] whitespace-nowrap mx-2 capitalize">
            {user?.name}
          </span>
          <span className="mt-2">
            <IoIosArrowDown />
          </span>
        </span>
      </Link>
    </>
  );
};


export default ProfileButton
