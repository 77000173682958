import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CustomeTable from "../../components/widget/CustomeTable";

function RoomSummary() {
  const { t } = useTranslation();
  const [selectedRows, setSelectedRows] = useState([]);
  const rowData = [
    {
      sn: "01",
      roomType: "Deluxe",
      rateType: "Americon Plane(AP)",
      noOfRooms: "1",
      roomNo: "101",
      discount: "12%",
      roomTarrif: "$1000",
      totaleRoomTarrif: "$1000",
    },
    {
      sn: "01",
      roomType: "Suit",
      rateType: "European Plane(EP)",
      noOfRooms: "1",
      roomNo: "101",
      discount: "12%",
      roomTarrif: "$1000",
      totaleRoomTarrif: "$1000",
    },
  ];

  const columnDefs = [
    {
      headerName: "",
      field: "_id",
      maxWidth: 30,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Room Type",
      field: "roomType",
      filter: true,
    },
    {
      headerName: "Rate Type",
      field: "rateType",
      filter: true,
    },
    {
      headerName: "No. Of Rooms",
      field: "noOfRooms",
      filter: true,
    },
    {
      headerName: "Room No",
      field: "roomNo",
      filter: true,
    },
    {
      headerName: "Discount",
      field: "discount",
      filter: true,
    },
    {
      headerName: "Room Tarrif",
      field: "roomTarrif",
      filter: true,
    },
    {
      headerName: "Totale Room Tarrif",
      field: "totaleRoomTarrif",
      filter: true,
    },
  ];
  return (
    <div className="mt-4 px-6 mb-6">
      <CustomeTable
        tableName={t("586")}
        columnDefs={columnDefs}
        rowData={rowData}
        setSelectedRows={setSelectedRows}
      />
    </div>

  );
}

export default RoomSummary;
