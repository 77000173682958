import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom"; 
import DeleteOrNot from "../../components/modals/DeleteOrNot";
import { ROUTES } from "../../constants";
import BackGroundLoadSpinner from "../../components/common/loader/BackGroundLoadSpinner";
import { isObjEmpty } from "../../utils";
import { useTranslation } from "react-i18next";
import CustomeTable from "../../components/widget/CustomeTable";
import SubmitButton from "../../components/buttons/SubmitButton";
import { deletekitchenAction } from "../../lib/slices/kitchen/kitchenSlice";

const AllUpcommingEventBuffet = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [kitchenId, setKitchenId] = useState("");
  const [datas, setDatas] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    if (props?.kitchen && !isObjEmpty(props?.kitchen?.kitchen)) {
      setDatas([
        {
          Kitchen_id: "1214",
          buffetName: props?.kitchen?.kitchen?.buffetName,
          buffetType: props?.kitchen?.kitchen?.buffetType,
          buffetPurpose: props?.kitchen?.kitchen?.buffetPurpose,
          buffetPricePerson: props?.kitchen?.kitchen?.buffetPricePerson,
          _id: props?.kitchen?.kitchen?._id,
        },
      ]);
    }
  }, [props?.kitchen]);

  //delete the kitchen
  const deleteKitchen = () => {
    if (kitchenId) {
      setKitchenId(kitchenId);
      dispatch(deletekitchenAction(kitchenId));
      setOpen(false);
    }
  };

  //kitchen added
  useEffect(() => {
    if (props?.add && props?.add?.isadded) {
      // notifySuccess(t("1060"));
    }
  }, [props?.add]);

  //view Buffet Actions buttons
  const ViewBuffetActions = () => {
    const navigate = useNavigate();
    const setKitchenAndRedirect = (link) => {
      if (link) {
        navigate(link);
      }
    };
    return (
      <div className="h-full flex items-center gap-2">
        <SubmitButton
          className="h-[30px] rounded-md"
          type="button"
          text={t("1061")}
          onClick={() => setKitchenAndRedirect(ROUTES.KITCHEN_BUFFETS)}
        />
        <SubmitButton
          className="h-[30px] rounded-md"
          type="button"
          text={t("Details")}
          onClick={() => setKitchenAndRedirect(ROUTES.KITCHEN_BUFFET)}
        /> 
      </div>
    );
  };

  const rowData = [
    {
      Kitchen_id: "BF01",
      buffetName: "Royal Buffet",
      buffetDay: "Monday",
      buffetType: "Breakfast Buffet",
      buffetPurpose: "Hotel Guest Buffet",
      dateOfEvent: "---",
      buffetPricePerson: "$12",
    },
    {
      Kitchen_id: "BF02",
      buffetName: "Grand Buffet",
      buffetDay: "Monday",
      buffetType: "Lunch Buffet",
      buffetPurpose: "Wedding Buffet",
      dateOfEvent: "---",
      buffetPricePerson: "$16",
    },
    {
      Kitchen_id: "BF03",
      buffetName: "Grand Buffet",
      buffetDay: "Monday",
      buffetType: "Lunch Buffet",
      buffetPurpose: "Wedding Buffet",
      dateOfEvent: "---",
      buffetPricePerson: "$15",
    },
    {
      Kitchen_id: "BF04",
      buffetName: "Grand Buffet",
      buffetDay: "Monday",
      buffetType: "Lunch Buffet",
      buffetPurpose: "Wedding Buffet",
      dateOfEvent: "---",
      buffetPricePerson: "$22",
    },
    {
      Kitchen_id: "BF05",
      buffetName: "Grand Buffet",
      buffetDay: "Monday",
      buffetType: "Lunch Buffet",
      buffetPurpose: "Wedding Buffet",
      dateOfEvent: "---",
      buffetPricePerson: "$18",
    },
  ];

  const columnDefs = [
    {
      headerName: "Buffet ID",
      field: "Kitchen_id",
      minWidth: 120,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Buffet Name",
      field: "buffetName",
      filter: true,
    },
    {
      headerName: "Buffet Day",
      field: "buffetDay",
      filter: true,
    },
    {
      headerName: "Buffet Type",
      field: "buffetType",
      filter: true,
    },
    {
      headerName: "Buffet Purpose",
      field: "buffetPurpose",
      filter: true,
    },
    {
      headerName: "Date Of Event",
      field: "dateOfEvent",
      filter: true,
    },
    {
      headerName: "Actions",
      field: "_id",
      minWidth: 470,
      cellRenderer: ViewBuffetActions,
    },
  ];

  return (
    <>
      {datas.length ? (
        <CustomeTable
          tableName={"Upcomming Event Buffet Table"}
          columnDefs={columnDefs}
          rowData={rowData}
          setSelectedRows={setSelectedRows}
        />
      ) : null}
      {(props?.add && props?.add?.loading) ||
        (props?.delete && props?.delete?.loading && <BackGroundLoadSpinner />)}

      <DeleteOrNot
        formSubmit={deleteKitchen}
        open={open}
        close={() => setOpen(false)}
      />
    </>
  );
};

export default AllUpcommingEventBuffet;
