import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import RoomTariff from "./RoomTariff";
import AllRoomRate from "./AllRoomRate";
import SubmitButton from "../../components/buttons/SubmitButton";
import { getAllRoomAction } from "../../lib/slices/room/roomSlice";

const RoomRate = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [openRoomTariff, setOpenRoomTariff] = useState(false);
  const dispatch = useDispatch();
  const { hotel } = useSelector((state) => state.Hotel);

  const handleRoomTariff = () => {
    setOpenRoomTariff(true);
    setOpen(false);
  };

  useEffect(() => {
    if (hotel?._id) dispatch(getAllRoomAction(hotel?._id));
  }, []);

  return (
    <div>
      <div className="flex items-center justify-end">
        <div className="flex gap-4">
          <SubmitButton
            type="button"
            text={"Room Tariff Automation"}
            onClick={handleRoomTariff}
          />
          <SubmitButton text={t("Calculate Room Price")} onClick={""} />
        </div>
      </div>

      {openRoomTariff ? (
        <RoomTariff close={() => setOpenRoomTariff(false)} />
      ) : null}

      <div className="mt-4">
        <AllRoomRate />
      </div>
    </div>
  );
};

export default RoomRate;
