import React from "react";
import ToolTip from "../../../components/common/ToolTip";
import SelectFromArray from "../../../components/form/SelectFromArray";

const ConditionToBookPackage = ({
  h,
  p,
  message,
  value,
  options,
  onChange = () => {},
}) => {
  return (
    <div className="flex items-center justify-between gap-4 text-[16px] leading-5 font-medium text-[#344054]">
      <div className="">
        <div className="flex items-center gap-2">
          {h}
          <ToolTip size={18} Message={"abcd"} />
        </div>
        <p className="text-[12px] font-normal text-base-primary">{p}</p>
      </div>
      <SelectFromArray
        className={"h-[42px] w-[92px]"}
        value={value}
        onChange={onChange}
        options={options}
      />
    </div>
  );
};

export default ConditionToBookPackage;
