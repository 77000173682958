import authAxios from "../../services/authAxios";

// send-invite link onboarding Action
export const sendInviteAction = async (credentials) => {
  try {
    const url = `/api/hotel/onboarding/send-invite`;
    const response = await authAxios.post(url, credentials);
    return response.data;
  } catch (error) {
    return error;
  }
};


