import React, { useEffect } from "react";
import { useState } from "react";
import { registerEvent } from "../../logs";
import AllAssets from "./AllAssets";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import AddButton from "../../components/buttons/AddButton";
import AddAssets from "./AddAssets";
import UpdateAssets from "./UpdateAssets";
import { getAllHotelAssetAction } from "../../lib/slices/asset/hotelAssetSlice";
import DeleteButton from '../../components/buttons/DeleteButton';
import EditButton from "../../components/buttons/EditButton";
import Breadcrumbs from "../../components/common/Breadcrumbs";

const AssetsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { hotel } = useSelector((state) => state.hotel);

  const [asset, setAsset] = useState(null);

  const toggleAddAssetsModal = () => {
    if (asset) setAsset(null);
    setOpen(!open);
    registerEvent("add asset button open", "add asset", { open });
  };

  useEffect(() => {
    if (hotel?._id) {
      dispatch(getAllHotelAssetAction(hotel?._id))
    }
  }, [hotel?._id]);

  const headingData = {
    heading: t("325"),
    seperator: [
      {
        text: t("Inventory Management"),
      },
      {
        text: t("325"),
      },
    ],
  };

  return (
    <div>
      <div className="flex justify-between items-end">
        <Breadcrumbs data={headingData} />
        <div className="flex items-center gap-2">
          <EditButton onClick={toggleAddAssetsModal} disabled={!asset} />
          <DeleteButton />
          <AddButton
            text={t("326")}
            onClick={toggleAddAssetsModal}
            disabled={open}
          />
        </div>
      </div>
      {open ? <AddAssets hotel={hotel} close={toggleAddAssetsModal} /> : null}
      {asset ? <UpdateAssets close={toggleAddAssetsModal} hotel={hotel} /> : null}
      <AllAssets hotel={hotel} setAsset={setAsset} />
    </div>
  );
};

export default AssetsPage;
