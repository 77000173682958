import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ID_NUMBER, ROUTES } from "../../constants"; 
import { useNavigate } from "react-router-dom";
import CustomeTable from "../../components/widget/CustomeTable";
import EditButton from "../../components/buttons/EditButton";
import DeleteButton from "../../components/buttons/DeleteButton";

const AllEmployee = ({ setUpdateData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [datas, setDatas] = useState([]);
  const { employees } = useSelector((state) => state.hotelEmployees);
  const [selectedRows, setSelectedRows] = useState([]);

  const [deleteId, setDeleteId] = useState("");
  const [confirmDelete, setConfirmDelete] = useState(false);

  //get employees array from redux and set employees as datas
  useEffect(() => {
    if (employees && Array.isArray(employees) && employees?.length) {
      let arr = [];

      //modify employee datas (filter )
      employees.map((employee) => {
        return arr.push({
          employeeId: employee?.employeeId,
          name: employee?.name,
          designation: employee?.designation,
          department: employee?.department,
          reportingTo: employee?.reportingTo,
          selectDutyTimeStart: employee?.selectDutyTimeStart,
          selectDutyTimeEnd: employee?.selectDutyTimeEnd,
          totalHours: employee?.selectDutyTimeStart?.selectDutyTimeEnd,
          _id: employee?.employeeId,
        });
      });
      setDatas(arr);
    }
  }, [employees]);

  const deleteTheEmployee = (id) => {
    if (id) {
      setDeleteId(id);
      setConfirmDelete(true);
    }
  };

  const cancelTheDelete = () => {
    setDeleteId(null);
    setConfirmDelete(false);
  };

  const deleteEmployee = () => {
    // if (deleteId) dispatch(deleteHotelEmployeeProfile(hotel?._id, deleteId));
  };

  const gotoViewDetails = (id) => {
    if (id) {
      sessionStorage.setItem(ID_NUMBER, id);
      navigate(ROUTES.EMPLOYEE_DETAILS);
    }
  };

  const updateTheEmployee = (id) => {
    if (id) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      const employee = employees?.filter((x) => x?.employeeId === id);
      setUpdateData(employee[0]);
    }
  };

  const ViewAllEmployeeActions = () => {
    return (
      <div className="h-full flex items-center gap-4 ">
        <button
          type="button"
          className="text-[14px] leading-6 font-normal tracking-[-0.6%] text-base-primary hover:text-base-primary_hover"
          onClick={gotoViewDetails}
        >
          View Details
        </button>
        <EditButton onClick={updateTheEmployee} />
        <DeleteButton onClick={deleteTheEmployee} />
      </div>
    );
  };

  const columnDefs = [
    {
      headerName: "Employee ID",
      field: "employeeId",
      minWidth: 170,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Name",
      field: "name",
      filter: true,
    },
    {
      headerName: "Designation",
      field: "designation",
      filter: true,
    },
    {
      headerName: "Department",
      field: "department",
      filter: true,
    },
    {
      headerName: "Reporting To",
      field: "reportingTo",
      filter: true,
    },
    {
      headerName: "Start Time",
      field: "selectDutyTimeStart",
      filter: true,
    },
    {
      headerName: "End Time",
      field: "selectDutyTimeEnd",
      filter: true,
    },
    {
      headerName: "Totale Hours",
      field: "totalHours",
      filter: true,
    },
    {
      headerName: "Actions",
      field: "_id",
      minWidth: 300,
      cellRenderer: ViewAllEmployeeActions,
    },
  ];

  return (
    <>
      <CustomeTable
        tableName={"Employees List Table"}
        columnDefs={columnDefs}
        rowData={datas}
        setSelectedRows={setSelectedRows}
      />
    </>
  );
};

export default AllEmployee;
