import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"; 
import authAxios from "../../services/authAxios";

// get owner profile
export const getOwnerProfileAction = createAsyncThunk(
  "profile/getOwnerProfileAction",
  async (credentials, thunkAPI) => {
    try {
      const response = await authAxios.post(
        "/api/hotel/owner/profile",
        credentials
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// update owner profile
export const updateOwnerProfileAction = createAsyncThunk(
  "profile/updateOwnerProfileAction",
  async (credentials, thunkAPI) => {
    try {
      const response = await authAxios.post(
        "/api/hotel/owner/profile",
        credentials
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

//   get owner profile slice
export const getOwnerProfileSlice = createSlice({
  name: "profile/getOwnerProfileSlice",
  initialState: {
    loading: false,
    profile: null,
    isupdated: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOwnerProfileAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getOwnerProfileAction.fulfilled, (state, action) => {
        state.loading = false;
        state.profile = action.payload.profile;
      })
      .addCase(getOwnerProfileAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateOwnerProfileAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateOwnerProfileAction.fulfilled, (state, action) => {
        state.loading = false;
        state.profile = action.payload;
        state.isupdated = true;
      })
      .addCase(updateOwnerProfileAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

 