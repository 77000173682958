import React from "react"; 
import { registerEvent } from "../../logs";
import { useTranslation } from "react-i18next";
import CustomModal from "./CustomModal";
import CancelButton from "../buttons/CancelButton";

export default function DeleteOrNot({ formSubmit, open, close }) {
  const { t } = useTranslation();
  //agree to delete
  const agree = () => {
    formSubmit();
    close();
    registerEvent("agree to delete", "delete or not delete modal");
  };

  //disagree to delete
  const disagree = () => {
    close();
    registerEvent("disagree to delete", "delete or not delete modal");
  };

  return (
    <CustomModal isCloseButton={false}>
    <div className="w-[336px] m-[-12px]">
      <h2 className="text-[18px] leading-[26px] font-normal tracking-[-1.5%] text-[#07090D]">
        Are you sure you want to delete this item?
      </h2>
      <div className="flex items-center gap-3 mt-5">
        <button
          type="button"
          className="h-[37px] w-[118px] rounded-[5px] bg-[#F04438] hover:bg-[#d1281c] text-[14px] leading-[22px] font-normal text-white transition-all duration-[0.3s] ease-[0s]"
        >
          Yes,Delete it
        </button>
        <CancelButton onClick={""} />
      </div>
    </div>
  </CustomModal>
  );
}
