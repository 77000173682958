import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import SubmitButton from "../../components/buttons/SubmitButton";
import { ROUTES } from "../../constants";
import { useTranslation } from "react-i18next";
import  CustomeTable  from "../../components/widget/CustomeTable";

const SpecialDateHotelGuestBuffetTable = () => {
  const { t } = useTranslation();
  const [selectedRows, setSelectedRows] = useState([]);
  //view Buffet Actions buttons
  const ViewBuffetActions = () => {
    const navigate = useNavigate();
    const setKitchenAndRedirect = (link) => {
      if (link) {
        navigate(link);
      }
    };
    return (
      <div className="h-full flex items-center gap-2">
        <SubmitButton
          className="h-[30px] rounded-md"
          type="button"
          text={t("1061")}
          onClick={() => setKitchenAndRedirect(ROUTES.KITCHEN_BUFFETS)}
        />
        <SubmitButton
          className="h-[30px] rounded-md"
          type="button"
          text={t("Details")}
          onClick={() => setKitchenAndRedirect(ROUTES.KITCHEN_BUFFET)}
        />
        {/* <EditButton />
        <DeleteButton /> */}
      </div>
    );
  };

  const rowData = [
    {
      Kitchen_id: "BF01",
      buffetName: "Royal Buffet",
      buffetDay: "Monday",
      buffetType: "Breakfast Buffet",
      buffetPurpose: "Hotel Guest Buffet",
      dateOfEvent: "---",
      buffetPricePerson: "$12",
    },
    {
      Kitchen_id: "BF02",
      buffetName: "Grand Buffet",
      buffetDay: "Monday",
      buffetType: "Lunch Buffet",
      buffetPurpose: "Wedding Buffet",
      dateOfEvent: "---",
      buffetPricePerson: "$16",
    },
    {
      Kitchen_id: "BF03",
      buffetName: "Grand Buffet",
      buffetDay: "Monday",
      buffetType: "Lunch Buffet",
      buffetPurpose: "Wedding Buffet",
      dateOfEvent: "---",
      buffetPricePerson: "$15",
    },
    {
      Kitchen_id: "BF04",
      buffetName: "Grand Buffet",
      buffetDay: "Monday",
      buffetType: "Lunch Buffet",
      buffetPurpose: "Wedding Buffet",
      dateOfEvent: "---",
      buffetPricePerson: "$22",
    },
    {
      Kitchen_id: "BF05",
      buffetName: "Grand Buffet",
      buffetDay: "Monday",
      buffetType: "Lunch Buffet",
      buffetPurpose: "Wedding Buffet",
      dateOfEvent: "---",
      buffetPricePerson: "$18",
    },
  ];

  const columnDefs = [
    {
      headerName: "Buffet ID",
      field: "Kitchen_id",
      minWidth: 120,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Buffet Name",
      field: "buffetName",
      filter: true,
    },
    {
      headerName: "Buffet Day",
      field: "buffetDay",
      filter: true,
    },
    {
      headerName: "Buffet Type",
      field: "buffetType",
      filter: true,
    },
    {
      headerName: "Buffet Purpose",
      field: "buffetPurpose",
      filter: true,
    },
    {
      headerName: "Date Of Event",
      field: "dateOfEvent",
      filter: true,
    },
    {
      headerName: "Actions",
      field: "_id",
      minWidth: 470,
      cellRenderer: ViewBuffetActions,
    },
  ];

  return (
    <>
      {/* {datas.length ? ( */}
      <CustomeTable
        tableName={"Special Date Hotel Guest Buffet List Table"}
        columnDefs={columnDefs}
        rowData={rowData}
        setSelectedRows={setSelectedRows}
      />
      {/* ) : null} */}
    </>
  );
};

export default SpecialDateHotelGuestBuffetTable;
