import React from "react";
// import AllKitchen from "./AllKitchen";
import { useSelector } from "react-redux";
import AddKitchen from "./AddKitchen";

const HotelOwnKitchen = (props) => {
  // const [kitchen, setKitchen] = useState(null);
  const { hotel } = useSelector((state) => state.hotel);
  console.log("hotel", hotel);
  return (
    <div>
      <AddKitchen
        close={props.closeHotelOwnKitchenModal}
        hotel={props?.hotel?.hotel}
      />
      {/* <div className="mt-4">
        <AllKitchen setKitchen={setKitchen} hotel={props?.hotel?.hotel} />
      </div> */}
    </div>
  );
};

export default HotelOwnKitchen;
