import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { registerEvent } from "../../logs";
import { THRESHOLD_CONSUME_IN } from "../../constants";
import { useTranslation } from "react-i18next";
import Inputfield from "../../components/form/InputField";
import SelectFromObject from "../../components/form/SelectFromObject";
import FormSubmitButton from "../../components/buttons";
import CloseButton from "../../components/buttons/CloseButton";
import InputTextArea from "../../components/form/InputTextArea";
import {
  addHotelAssetsAction,
} from "../../lib/slices/asset/hotelAssetSlice";

const AddAssets = ({ close }) => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [brand, setBrand] = useState("");
  const [description, setDescription] = useState("");
  const [pricePerItem, setPricePerItem] = useState("");
  const [availableQuantity, setAvailableQuantity] = useState("");
  const [thresholdValue, setThresholdValue] = useState("");
  const [consumeIn, setConsumeIn] = useState();
  const [vendorId, setVendorId] = useState("");
  const [vendorID, setVendorID] = useState("");

  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.hotelAssets);
  const { hotel } = useSelector((state) => state.hotel);

  const handleSubmit = () => {
    const data = {
      hotelId: hotel?._id,
      name,
      assetType: "abcd",
      brand,
      description,
      pricePerItem,
      availableQuantity,
      thresholdValue,
      purchaseDate: "2024-08-21",
      consumeIn,
      vendorId,
    };
    dispatch(addHotelAssetsAction(data));
    registerEvent("click add asset", "asset page", data);
  };


  return (
    <>
      <div className="relative w-full mx-auto bg-white rounded-lg border border-[#DDE2E4] mt-6 p-6">
        <div className="flex items-center justify-between">
          <div className="font-bold text-[30px] leading-[37.8px] text-black tracking-[-1.5%]">
            {t("326")}
          </div>
          <CloseButton onClick={close} />
        </div>

        <form className="relative mt-6" >
          <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-x-6 lg:gap-x-8 gap-6 mb-6">
            <Inputfield
              label={t("166")}
              placeholder={t("677")}
              value={name}
              onChange={(e) => setName(e.target.value)}
              minLength={3}
              maxLength={30}
              required={true}
            />
            <Inputfield
              label={t("1016")}
              placeholder={t("678")}
              value={brand}
              onChange={(e) => setBrand(e.target.value)}
              minLength={3}
              maxLength={30}
            />

            <Inputfield
              label={t("1017")}
              placeholder={t("489")}
              value={pricePerItem}
              onChange={(e) => setPricePerItem(e.target.value)}
              type="number"
              min={0}
              max={30000}
              required={true}
            />

            <Inputfield
              label={t("1018")}
              placeholder={t("679")}
              value={availableQuantity}
              onChange={(e) => setAvailableQuantity(e.target.value)}
              type="number"
              min={0}
              max={30000}
              required={true}
            />
            <Inputfield
              label={t("342")}
              placeholder={t("680")}
              value={thresholdValue}
              onChange={(e) => setThresholdValue(e.target.value)}
              type="number"
              min={0}
              max={30000}
              required={true}
            />
            <SelectFromObject
              label={t("334")}
              options={THRESHOLD_CONSUME_IN}
              value={consumeIn}
              setValue={setConsumeIn}
            />
          </div>
          <InputTextArea
            rows={4}
            label={t("1020")}
            placeholder={t("681")}
            value={description}
            onChange={(e) => setDescription(e?.target?.value)}
            minLength={20}
            maxLength={500}
          />
          <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-x-6 lg:gap-x-8 gap-6 my-6">
            <Inputfield
              label={t("485")}
              placeholder={t("682")}
              value={vendorID}
              onChange={(e) => setVendorID(e.target.value)}
            />
          </div>
          <div className="flex justify-end mt-6">
            <FormSubmitButton
              onSubmit={handleSubmit}
              buttonText={"Add Asset"}
              loading={loading}
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default AddAssets;
