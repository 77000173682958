import React, { useState } from "react";
import { FiArrowRight } from "react-icons/fi";
import { useSelector } from "react-redux";
import LoadSpinner from "../../components/common/loader/LoadSpinner";

const NextButton = ({ handleClick = () => { } }) => {
    const { loading } = useSelector((state) => state.hotel);
    const [clicked, setClicked] = useState(false);

    const goToPreviousPage = () => {
        handleClick()
        setClicked(true)
    }

    return (
        <>
            <button
                className="w-[120px] h-12 flex justify-center items-center gap-2 bg-base-primary hover:bg-base-primary_hover rounded-md  text-[#FFFFFF] capitalize"
                type="submit"
                disabled={loading}
                onClick={goToPreviousPage}
            >
                {loading && clicked ? (
                    <LoadSpinner />
                ) : (
                    <>
                        Next
                        <FiArrowRight size={18} />
                    </>
                )}
            </button>
        </>
    );
};
export default NextButton;
