import authAxios from "../../services/authAxios";

// send Otp action
export const sendPhoneOtpAction = async (credentials) => {
  try {
    const url = `/api/hotel/common/otp/send-otp`;
    const response = await authAxios.post(url, credentials);
    return response.data;
  } catch (error) {
    return error;
  }
};

// resend Otp action
export const resendPhoneOtpAction = async (credentials) => {
  try {
    const url = `/api/hotel/common/otp/resend-otp`;
    const response = await authAxios.post(url, credentials);
    return response.data;
  } catch (error) {
    return error;
  }
};

// verify Otp action
export const verifyPhoneOtpAction = async (credentials) => {
  try {
    const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/common/otp/verify-otp`;
    const response = await authAxios.post(url, credentials);
    return response.data;
  } catch (error) {
    return error;
  }
};
