import React from "react";
import { TbSquareRoundedPlus } from "react-icons/tb";
import CustomModal from "./CustomModal";

const AddAndQuickKitchen = ({
  close = () => {},
  handleHotelOwnKitchen = () => {},
  handleThirdPartyKitchen = () => {},
}) => {
  return (
    <CustomModal close={close} heading={"Add Kitchen"}>
      <div className="w-[45vw]">
        <div className="text-[18px] leading-7 font-normal text-[#959595] flex items-center justify-center my-4">
          Are you in hurry? you can choose quick Add Kitchen and than later you
          can fill full form Are you in hurry? you can choose quick Add Kitchen
          and than later you can fill full form
        </div>
        <div className="grid grid-cols-2 items-center gap-6 w-full mt-12">
          <button
            type="button"
            onClick={handleHotelOwnKitchen}
            className={`h-16 flex items-center justify-center gap-2 border-2 border-base-primary text-base-primary rounded-[10px] bg-[#00a35e14] hover:border-base-primary_hover hover:text-white hover:bg-base-primary_hover duration-300 ease-[0.3s]`}
          >
            <TbSquareRoundedPlus size={38} />
            <div className="text-[20px] leading-6 font-bold">
              Hotel Own Kitchen
            </div>
          </button>
          <button
            type="button"
            onClick={handleThirdPartyKitchen}
            className={`h-16 flex items-center justify-center gap-2 border-2 border-base-primary text-base-primary rounded-[10px] bg-[#00a35e14] hover:border-base-primary_hover hover:text-white hover:bg-base-primary_hover duration-300 ease-[0.3s]`}
          >
            <TbSquareRoundedPlus size={38} />
            <div className="text-[20px] leading-6 font-bold">
              Third Party Kitchen
            </div>
          </button>
        </div>
      </div>
    </CustomModal>
  );
};

export default AddAndQuickKitchen;
