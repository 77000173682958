import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CloseButton from "../../components/buttons/CloseButton";
import SaveOrNot  from "../../components/modals/SaveOrNot";
import Inputfield from "../../components/form/InputField";
import SelectFromArray from "../../components/form/SelectFromArray";
import AddSubmitButton from "../../components/buttons/AddSubmitButton";

const AddThirdPartyKitchen = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [kitchenName, setKitchenName] = useState("");
  const [kitchenLocation, setKitchenLocation] = useState("");
  const [contactPersonName, setContactPersonName] = useState("Rishi Kumar");
  const [contactPersonNumber, setContactPersonNumber] =
    useState("+911345678978");
  const [commissionFromThirdParty, setCommissionFromThirdParty] =
    useState("10%");
  //click open
  const handleClickOpen = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  const handleSubmit = () => {
    setOpen(false);
    props.addKitchen({
      hotelId: props?.hotel?._id,
      kitchenName,
      kitchenLocation,
      images: [
        {
          id: "64ba543306370748f2930b54",
          url: "https://ik.imagekit.io/rzb2n7n7p/hotelMainPhoto_hhKKVUXpX",
        },
        {
          id: "64ba544706370748f2936662",
          url: "https://ik.imagekit.io/rzb2n7n7p/hotelPhotos_EuV9MUASr",
        },
      ],
    });
  };
  const KITCHEN_LOCATIONS = ["In Hotel", "Third Party Kitchen"];

  return (
    <>
      <div className="flex flex-col border border-[#d0d5dde8] rounded-lg bg-white relative mt-6 p-8">
        <div className="flex items-center justify-between">
          <div className="font-bold text-[30px] leading-[37.8px] text-[#07090D] tracking-[-1.5%]">
            {t("Add Third Party kitchen")}
          </div>
          <CloseButton onClick={props.close} />
        </div>

        <form onSubmit={handleClickOpen} className="w-full mt-4">
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-8">
            <Inputfield
              label={t("1045")}
              placeholder={t("1046")}
              value={kitchenName}
              onChange={(e) => setKitchenName(e.target.value)}
              required={true}
              minLength={3}
              maxLength={30}
            />
            <SelectFromArray
              label={t("Kitchen Location")}
              toolTipMessage={t("1048")}
              value={kitchenLocation}
              onChange={(e) => setKitchenLocation(e.target.value)}
              options={KITCHEN_LOCATIONS}
            />
            <Inputfield
              label={t("Contact Person Name")}
              message={"..."}
              placeholder={"Enter Name"}
              value={contactPersonName}
              onChange={(e) => setContactPersonName(e.target.value)}
              minLength={3}
              maxLength={30}
            />
            <Inputfield
              label={t("Contact Person Number")}
              placeholder={t("Enter Number")}
              value={contactPersonNumber}
              onChange={(e) => setContactPersonNumber(e.target.value)}
              minLength={10}
              maxLength={30}
            />
            <Inputfield
              label={t("Contact Person Name")}
              placeholder={t("Enter Commission")}
              message={"..."}
              value={commissionFromThirdParty}
              onChange={(e) => setCommissionFromThirdParty(e.target.value)}
              minLength={10}
              maxLength={30}
            />
          </div>
          <div className="flex items-center justify-end pt-7">
            <AddSubmitButton
              text={t("1059")}
              loading={props?.kitchen?.loading}
            />
          </div>
        </form>
      </div>
      <SaveOrNot
        formSubmit={handleSubmit}
        open={open}
        close={() => setOpen(false)}
      />
    </>
  );
};

export default AddThirdPartyKitchen;
