import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { TOKEN } from "../../../constants";

// add employe leave action
export const addEmployeeLeaveAction = createAsyncThunk(
  "employeeLeave/addEmployeeLeaveAction",
  async (credentials, thunkAPI, formData) => {
    try {
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/employee/leave`;
      const response = await axios.post(url, formData, credentials);
      localStorage.setItem(TOKEN, response.data.token);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

//Add employee slice
export const addEmployeeLeaveSlice = createSlice({
  name: "employeeLeave/addEmployeeLeaveSlice",
  initialState: {
    loading: true,
    isadded: false,
    employee: {},
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addEmployeeLeaveAction.pending, (state) => {
        state.loading = true;
        state.isadded = false;
        state.error = null;
      })
      .addCase(addEmployeeLeaveAction.fulfilled, (state, action) => {
        state.loading = false;
        state.isadded = true;
        state.employee = action.payload.employee;
      })
      .addCase(addEmployeeLeaveAction.rejected, (state, action) => {
        state.loading = false;
        state.isadded = false;
        state.error = action.payload?.message || "Failed to add employee leave";
      });
  },
});

//   Delete employee leave Action
export const deleteEmployeeLeaveAction = createAsyncThunk(
  "employeeLeave/deleteEmployeeLeaveAction",
  async ({ leaveId, token }, thunkAPI) => {
    try {
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/employee/leave/${leaveId}`;
      const response = await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);
// delete employee leave slice
export const deleteEmployeeLeaveSlice = createSlice({
  name: "employeeLeave/deleteEmployeeLeaveSlice",
  initialState: {
    loading: true,
    isdelete: false,
    employee: {},
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addEmployeeLeaveAction.pending, (state) => {
        state.loading = true;
        state.isdelete = false;
        state.error = null;
      })
      .addCase(addEmployeeLeaveAction.fulfilled, (state, action) => {
        state.loading = false;
        state.isdelete = true;
        state.employee = action.payload.employee;
      })
      .addCase(addEmployeeLeaveAction.rejected, (state, action) => {
        state.loading = false;
        state.isdelete = false;
        state.error =
          action.payload?.message || "Failed to delete employee leave";
      });
  },
});
