import React, { useState } from "react";
import CustomeTable from "../../components/widget/CustomeTable";

const AllRoomRate = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [value, setValue] = useState("");

  const rowData = [
    {
      mealPlane: "American Meal Plan(AP)",
      roomType: "Deluxe Room",
      roomTariffMin: "$29.00",
      roomTariffMax: "$35.00",
      mealPrice: "$10.00",
      tax: "$10.00",
      min: "$49.00",
      max: "$55.00",
    },
    {
      mealPlane: "American Meal Plan(AP)",
      roomType: "Deluxe Room",
      roomTariffMin: "$29.00",
      roomTariffMax: "$35.00",
      mealPrice: "$10.00",
      tax: "$10.00",
      min: "$49.00",
      max: "$55.00",
    },
    {
      mealPlane: "American Meal Plan(AP)",
      roomType: "Deluxe Room",
      roomTariffMin: "$29.00",
      roomTariffMax: "$35.00",
      mealPrice: "$10.00",
      tax: "$10.00",
      min: "$49.00",
      max: "$55.00",
    },
    {
      mealPlane: "European Meal Plan(AP)",
      roomType: "Deluxe Room",
      roomTariffMin: "$29.00",
      roomTariffMax: "$35.00",
      mealPrice: "$10.00",
      tax: "$10.00",
      min: "$49.00",
      max: "$55.00",
    },
  ];
  const getInputs = ({ value }) => {
    return (
      <div className="h-full flex items-center">
        <div className="h-8 w-full flex items-center px-2 text-[12] leading-6 font-medium text-[#667085] bg-[#F6F6F6]">
          {value}
        </div>
      </div>
    );
  };
  const columnDefs = [
    {
      headerName: "Meal Plane",
      field: "mealPlane",
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Room Type",
      field: "roomType",
      filter: true,
      cellRenderer: getInputs,
    },
    {
      headerName: "Room Tariff Minimum",
      field: "roomTariffMin",
      filter: true,
      cellRenderer: getInputs,
    },
    {
      headerName: "Room Tariff Maximum",
      field: "roomTariffMax",
      filter: true,
      cellRenderer: getInputs,
    },
    {
      headerName: "Meal Price",
      field: "mealPrice",
      filter: true,
      cellRenderer: getInputs,
    },
    {
      headerName: "Tax",
      field: "tax",
      filter: true,
      cellRenderer: getInputs,
    },
    {
      headerName: "Minimum",
      field: "min",
      filter: true,
      cellRenderer: getInputs,
    },
    {
      headerName: "Maximum",
      field: "max",
      filter: true,
      cellRenderer: getInputs,
    },
  ];

  return (
    <CustomeTable
      tableName={"Room Rate List Table"}
      rowData={rowData}
      setSelectedRows={setSelectedRows}
      columnDefs={columnDefs}
    />
  );
};

export default AllRoomRate;
