import React, { useState } from "react";
import { useTranslation } from "react-i18next";  
import AddButton from "../../../components/buttons/AddButton";
import AddReservation from "./AddReservation";
import { registerEvent } from "../../../logs";
import { isObjEmpty } from "../../../utils";

const HotelCheckIn = () => {
  const { t } = useTranslation();
  const [addButton, setAddButton] = useState(false);
  const [addReservation, setAddReservation] = useState({}); 
  //register event for Add inventory
  const registerEventAndOpen = () => {
    setAddButton(true);
    registerEvent("add reservation button open", "add item reservation", {
      addButton,
    });
  };
  // register evnet on close add inventry component
  const close = () => {
    if (!isObjEmpty(addReservation)) setAddReservation(null);
    setAddButton(false);
    registerEvent("add roaster button close", "add item roaster", {
      addButton,
    });
  };
  const headingData = {
    heading: t("Hotel Check In"),
    seperator: [
      {
        text: t("134"),
      },
      {
        text: t("Check In"),
      },
    ],
  };
  return (
    <div className="page-container max-w-full">
      <div className="w-full flex justify-between items-end max-w-7xl mx-auto">
        {/* <Seperator  data={headingData} /> */}
        <AddButton text={t("Add Reservaton")} onClick={registerEventAndOpen} />
      </div>
      <div>
        {!isObjEmpty(addReservation) || addButton ? (
          <AddReservation close={close} />
        ) : null}
      </div>
      
    
    </div>
  );
};

export default HotelCheckIn;
