import React, { useEffect, useState } from "react";
import SelectFromObject from "../../components/form/SelectFromObject";
import Inputfield from "../../components/form/InputField";
import { HiOutlineMail } from "react-icons/hi";
import { sendInviteAction } from "../../lib/slices/hotel/onboardingSlice";
import { useSelector } from "react-redux";

const AddMoreSupAdmin = () => {
  const [position, setPosition] = useState("OWNER");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const { user } = useSelector((state) => state.user);

  const { hotel } = useSelector((state) => state.hotel);
 
  const sendInviteLink = () => {
    const credentials = {
      hotelId: hotel?._id,
      sender: {
        name: user.name,
        position: user.role,
      },
      receiver: {
        name,
        email,
        position,
      },
    };
    const sendLink = sendInviteAction(credentials);
    console.log("check action :", sendLink);
  };
  //   hotelId, sender, receiver

  return (
    <div className="">
      <div>
        <h2 className="font-medium text-[#07090D] mb-2 ">
          Select who is super admin
        </h2>
        <SelectFromObject
          value={position}
          setValue={setPosition}
          options={{
            OWNER: "OWNER",
            GENERAL_MANAGER: "GENERAL MANAGER",
          }}
        />
      </div>
      {/* details */}
      <div className="mt-4">
        <h2 className="font-medium text-[#07090D] mb-2">Detail</h2>
        <Inputfield
          placeholder={"Full Name"}
          value={name}
          onChange={(e) => setName(e.target.value)}
          min={3}
          max={30}
        />
        <div className="relative mt-4">
          <Inputfield
            className={"pl-12"}
            type="email"
            placeholder={"Enter Email"}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            min={3}
            max={1000}
          />
          <HiOutlineMail
            size={24}
            className="text-[#A1A1AA] absolute top-3 left-4"
          />
        </div>
      </div>
      <button
        type="button"
        className="h-12 w-full rounded-md bg-base-primary hover:bg-base-primary_hover duration-300 ease-[0.3s] text-white text-sm font-medium mt-4"
        onClick={() => sendInviteLink()}
      >
        Send Invite Link
      </button>
    </div>
  );
};

export default AddMoreSupAdmin;
