import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import  CustomeTable  from "../../components/widget/CustomeTable";

const BulkProductionAutomatedCostDataTable = () => {
  const [datas, setDatas] = useState([]);
  const { menuItems } = useSelector((state) => state.AllMenuItem);
  const [selectedRows, setSelectedRows] = useState([]);

  const [inputValue, setInputValue] = useState("1");
  useEffect(() => {
    if (menuItems && Array.isArray(menuItems)) {
      let newData = [];
      menuItems?.map((item) => {
        newData.push({
          sn: item?.sn,
          noOfPlates: item?.noOfPlates,
          quantityrequiredFor_1_type: item?.quantityrequiredFor_1_type,
          quantityrequiredFor_2_type: item?.quantityrequiredFor_2_type,
          quantityrequiredFor_3_type: item?.quantityrequiredFor_3_type,
          //   _id: item?._id,
        });
      });
      if (newData?.length) {
        setDatas(newData);
      }
    }
  }, [menuItems]);
  const tableData = [
    {
      sn: "1",
      noOfPlates: "10",
    },
    {
      sn: "2",
      noOfPlates: "20",
    },
    {
      sn: "3",
      noOfPlates: "30",
    },
    {
      sn: "4",
      noOfPlates: "40",
    },
    {
      sn: "5",
      noOfPlates: "50",
    },
  ];
  const ViewInputs = () => {
    const OPTIONS = { kg: "kg", gm: "gm" };
    const [value, setValue] = useState([]);
    return (
      <div className=" h-full flex items-center justify-center">
        <div className="relative flex items-center text-[#667085]">
          <input
            className={`border-r-0  focus:border-r-0 w-full h-8 text-[16px] `}
            type="text"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            // max={max}
            // min={min}
            // maxLength={maxLength}
            // minLength={minLength}
            placeholder={"Enter Value"}
            // required={required}
            // readOnly={readOnly}
          />
          <select
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            className="w-[32%] capitalize absolute right-0 p-[2px] h-8 text-[16px] "
          >
            {Object.keys(OPTIONS)?.map((item, index) => (
              <option value={item} key={index}>
                {OPTIONS[item].replace("_", " ").toLowerCase()}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  };
  const columnDef = [
    {
      headerName: "S.No",
      field: "sn",
      minWidth: 70,
      filter: true,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "No Of Plates",
      field: "noOfPlates",
      filter: true,
    },
    {
      headerName: "Quantity Required For 1 Type",
      minWidth: 200,
      cellRenderer: ViewInputs,
    },
    {
      headerName: "Quantity Required For 2 Type",
      minWidth: 200,
      cellRenderer: ViewInputs,
    },
    {
      headerName: "Quantity Required For 3 Type",
      minWidth: 200,
      cellRenderer: ViewInputs,
    },
  ];
  return (
    <div>
      {/* {datas.length ? ( */}
      <CustomeTable
        tableName={"Automated List Table"}
        size="h-[42vh]"
        rowData={tableData}
        columnDefs={columnDef}
        setSelectedRows={setSelectedRows}
      />
      {/* ) : null} */}
    </div>
  );
};

export default BulkProductionAutomatedCostDataTable;
