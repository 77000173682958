import React from "react"; 

const TabConfigButton = ({ name, currentIndex, buttonIndex, setIndex }) => { 
  const setOptionTabIndex = () => {
    setIndex(buttonIndex);
  };

  return (
    <button
      className={`px-6 h-12 last:mr-0 capitalize rounded-lg whitespace-nowrap min-w-fit overflow-auto text-[15px] border ${+currentIndex !== buttonIndex
          ? "border-[#E8E8E8] text-black bg-white"
          : " border-base-primary text-base-primary bg-[#EEF6F2]"
        }`}
      onClick={() => setOptionTabIndex()}
    >
      {name}
    </button>
  );
};

export default TabConfigButton;
