import React from "react";
import ToolTip from "../common/ToolTip";

const SelectFromArray = ({
  label,
  value,
  onChange,
  options,
  toolTipMessage,
  required = false,
  className,
}) => {
  // array will be like : ["VALUE_1", "VALUE_2"...."VALUE_3"];
  return (
    <div>
      <div className="flex justify-between capitalize">
        <label>{label}</label>
        <ToolTip Message={toolTipMessage} />
      </div>
      <div className="relative flex items-center">
        <select
          value={value}
          onChange={onChange}
          required={required}
          className={`${className}`}
        >
          {options?.map((item, index) => (
            <option value={item} key={index}>
              {item}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default SelectFromArray;
