/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux"; 
import totalRepairIcon from "../../images/icons/totalRepairIcon.svg";
import completeRepairIcon from "../../images/icons/completeRepairIcon.svg";
import pendingRepairIcon from "../../images/icons/pendingRepairIcon.svg";
import totalValueItem from "../../images/icons/totalValueItem.svg";
import ReportsCard from "./ReportsCard";
import CustomeTable from "../../components/widget/CustomeTable";

const RepairMaintenanceReports = (props) => {
  // const { t } = useTranslation();
  const [datas, setDatas] = useState([]);
  const { sales } = useSelector((state) => state.SalesReport);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    props.getAssetsFromToDateAll(
      props?.hotel?._id,
      props?.fromDate,
      props?.toDate
    );
  }, [props?.fromDate, props?.toDate]);

  useEffect(() => {
    if (sales && Array.isArray(sales) && sales?.length) {
      let expense = sales?.map((d) => {
        return {
          repairName: d?.repairName,
          type: d?.type,
          description: d?.description,
          date: d?.date,
          cost: d?.cost,
        };
      });
      setDatas(expense);
    }
  }, [sales]);

  const tableData = [
    {
      _id: "RM001",
      repairName: "ROO1 Electrical",
      type: "Room Maintenance",
      description: "Description will be here",
      date: "2024-01-12",
      cost: "$500",
    },
    {
      _id: "RM002",
      repairName: "ROO1 Electrical",
      type: "Room Maintenance",
      description: "Description will be here",
      date: "2024-01-12",
      cost: "$500",
    },
    {
      _id: "RM003",
      repairName: "ROO1 Electrical",
      type: "Room Maintenance",
      description: "Description will be here",
      date: "2024-01-12",
      cost: "$500",
    },
    {
      _id: "RM004",
      repairName: "ROO1 Electrical",
      type: "Room Maintenance",
      description: "Description will be here",
      date: "2024-01-12",
      cost: "$500",
    },
    {
      _id: "RM005",
      repairName: "ROO1 Electrical",
      type: "Room Maintenance",
      description: "Description will be here",
      date: "2024-01-12",
      cost: "$500",
    },
  ];

  const columnDefs = [
    {
      headerName: "Maintenance ID",
      field: "_id",
      minWidth: 200,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Repair Name",
      field: "repairName",
      filter: true,
    },
    {
      headerName: "Type",
      field: "type",
      filter: true,
    },
    {
      headerName: "Description",
      field: "description",
      filter: true,
    },
    {
      headerName: "Date",
      field: "date",
      filter: true,
    },
    {
      headerName: "Cost",
      field: "cost",
      filter: true,
    },
  ];

  return (
    <>
      <div className="page-container">
        <div className="grid grid-cols-4 items-center gap-4 my-4">
          <ReportsCard
            icon={totalRepairIcon}
            iconBgColor={"bg-[#F1FADF]"}
            heading={"Total Repairs"}
            value={"1258"}
          />
          <ReportsCard
            icon={completeRepairIcon}
            iconBgColor={"bg-[#E8EBFF]"}
            heading={"Completed Repairs"}
            value={"800"}
          />
          <ReportsCard
            icon={pendingRepairIcon}
            iconBgColor={"bg-[#FCEEDD]"}
            heading={"Pending Repair"}
            value={"1258"}
          />
          <ReportsCard
            icon={totalValueItem}
            iconBgColor={"bg-[#FCEAEA]"}
            heading={"Total Cost"}
            value={"$1258"}
          />
        </div>

        {/* {datas?.datas?.length ? ( */}
        <CustomeTable
          tableName={"Repair Maintenance Report table"}
          columnDefs={columnDefs}
          rowData={tableData}
          setSelectedRows={setSelectedRows}
        />
        {/* ) : null} */}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  data: state.AssetsReport,
});

const mapDispatchToProps = (dispatch) => ({ 
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RepairMaintenanceReports);
