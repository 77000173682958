import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { registerEvent } from "../../logs";
import { useTranslation } from "react-i18next";
import CustomeTable from "../../components/widget/CustomeTable";
import { deleteHotelAssetsAction } from "../../lib/slices/asset/hotelAssetSlice";
import DeleteButton from "../../components/widget/CustomeTable";
import EditButton from "../../components/buttons/EditButton";

const AllAssets = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [datas, setDatas] = useState([]);
  const [open, setOpen] = useState(false);
  const [assetId, setAssetId] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);

  // get all assets selector
  const { assets, loading, error } = useSelector((state) => state.hotelAssets);
  
  useEffect(() => {
    if (assets && Array.isArray(assets)) {
      const data = assets.map((asset) => {
        return {
          name: asset.name,
          brand: asset.brand,
          description: asset.description,
          pricePerItem: asset.pricePerItem,
          availableQuantity: asset.availableQuantity,
          thresholdValue: asset.thresholdValue,
          consumeIn: asset.consumeIn,
          vendorId: asset.vendorId,
          id: asset.id,
        }
      });

      setDatas(data);
    }
  }, [assets]);

  //set asset for edit
  const setAssetForEdit = async (id) => {
    if (assets && assets?.assets && assets?.assets?.length) {
      const asset = assets?.assets?.filter((x) => x?._id === id);
      setAssetId(asset[0]);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  //open modal for confiramtion of delete asset or not
  const openDeleteModal = async (id) => {
    if (id) {
      setAssetId(id);
      setOpen(true);
      registerEvent("delete asset modal", "all assets", { id });
    }
  };

  //delete asset
  const deleteAsset = (assetId, hotelId) => {
    if (assetId) {
      dispatch(deleteHotelAssetsAction({ assetId, hotelId }));
    }
  };
 

  const viewAssetsActions = (id) => {
    return (
      <div className="h-full flex items-center gap-4">
        <EditButton onClick={(value) => openDeleteModal(value)} />
        <DeleteButton onClick={setAssetForEdit} />
      </div>
    );
  };

  const columnDefs = [
    {
      headerName: "Assets ID",
      field: "id",
      minWidth: 150,
      filter: true,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Name",
      field: "name",
      filter: true,
    },
    {
      headerName: "Brand",
      field: "brand",
      filter: true,
    },
    {
      headerName: "Description",
      field: "description",
      filter: true,
    },
    {
      headerName: "Available Quantity",
      field: "availableQuantity",
      filter: true,
    },
    {
      headerName: "Threshold Value",
      field: "thresholdValue",
      filter: true,
    },
    {
      headerName: "Consume In",
      field: "consumeIn",
      filter: true,
    },
    {
      headerName: "Vendor Id",
      field: "vendorId",
      filter: true,
    },
    {
      headerName: "Actions",
      field: "_id",
      minWidth: 150,
      cellRenderer: viewAssetsActions,
    },
  ];

  return (
    <>
      <CustomeTable
        tableName={"Assets Table"}
        columnDefs={columnDefs}
        rowData={datas}
        setSelectedRows={setSelectedRows}
      />
    </>
  );
};

export default AllAssets;
