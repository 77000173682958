import React from "react";
import { useTranslation } from "react-i18next";
import Inputfield from "../../components/form/InputField";

const RoomTypeRoomNumbers = ({
  value,
  setValue,
  hotelRooms,
  setHotelRooms,
}) => {
  const { t } = useTranslation();
  //set total rooms in one category and validate
  const setRoomInOneCategory = (e) => {
    let enteredValue = +e.target.value;
    setValue(e.target.value);
    if (enteredValue <= hotelRooms?.length) {
      const result = hotelRooms?.slice(0, enteredValue);
      setHotelRooms(result);
    } else {
      let arr = [];
      [...Array(enteredValue - hotelRooms?.length)].forEach((element) => {
        arr.push({ roomNumber: "" });
      });
      setHotelRooms([...hotelRooms, ...arr]);
    }
  };

  return (
    <>
      <Inputfield
        label={t("592")}
        message={t("593")}
        placeholder={t("594")}
        value={value}
        onChange={setRoomInOneCategory}
        type="number"
        required={true}
        max={50}
      />
    </>
  );
};

export default RoomTypeRoomNumbers;
