import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ISADMIN, RESET_PASSWORD_TOKEN, TOKEN } from "../../../constants";
import authAxios from "../../services/authAxios";

//authenticate owner
export const ownerAuthAction = createAsyncThunk(
  "auth/ownerAuthenticate",
  async (_, thunkAPI) => {
    try {
      const response = await authAxios.get("/api/hotel/owner");
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// get employee action
export const employeeAuthAction = createAsyncThunk(
  "employeeAuth/employeeAuthAction",
  async (credentials, thunkAPI) => {
    try {
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/employee/auth`;
      const response = await authAxios.get(url, credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// Helper function for handling pending state
const handleAuthPending = (state) => {
  state.loading = true;
  state.error = null;
};

// Helper function for handling fulfilled state
const handleAuthFulfilled = (state, action) => {
  state.user = action.payload;
  state.loading = false;
  state.error = null;
};

// Helper function for handling rejected state
const handleAuthRejected = (state, action) => {
  state.loading = false;
  state.error = action.payload || "An error occurred";
};


//  authenticate hotel owner slice
export const ownerAuthSlice = createSlice({
  name: "auth/ownerAuthenticateSlice",
  initialState: {
    user: null,
    error: null,
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(ownerAuthAction.pending, handleAuthPending)
      .addCase(ownerAuthAction.fulfilled, handleAuthFulfilled)
      .addCase(ownerAuthAction.rejected, handleAuthRejected)
      .addCase(employeeAuthAction.pending, handleAuthPending)
      .addCase(employeeAuthAction.fulfilled, handleAuthFulfilled)
      .addCase(employeeAuthAction.rejected, handleAuthRejected)
  },
});

// Owner register
export const ownerRegisterAction = createAsyncThunk(
  "login/ownerRegisterAction",
  async (credentials, thunkAPI) => {
    try {
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/register`;
      const response = await axios.post(url, credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// owner login action
export const ownerLoginAction = createAsyncThunk(
  "auth/ownerLoginAction",
  async (credentials, thunkAPI) => {
    try {
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/login`;
      const response = await axios.post(url, credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// google auth action
export const googleAuthAction = createAsyncThunk(
  "auth/googleAuthAction",
  async (credentials, thunkAPI) => {
    try {
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/oAuth`;
      const response = await axios.post(url, credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// reset password action
export const resetPasswordAction = createAsyncThunk(
  "auth/reset-passwordAction",
  async (credentials, thunkAPI) => {
    try {
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/reset-password`;
      const response = await axios.post(url, credentials, {
        headers: {
          "x-auth-token": localStorage.getItem(RESET_PASSWORD_TOKEN)
        }
      });
      if (response?.data?.token) {
        localStorage.removeItem(RESET_PASSWORD_TOKEN)
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// change password action
export const changePasswordAction = createAsyncThunk(
  "auth/changePasswordAction",
  async (credentials, thunkAPI) => {
    try {
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/change-password`;
      const response = await axios.post(url, credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// verify email action
export const verifyEmailAction = createAsyncThunk(
  "auth/verifyEmailAction",
  async (credentials, thunkAPI) => {
    try {
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/verify-email`;
      const response = await axios.post(url, credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

export const employeeLoginAction = createAsyncThunk(
  "employeeAuth/employeeLoginAction",
  async (credentials, thunkAPI) => {
    try {
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/employee/auth/login`;
      const response = await axios.post(url, credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// Helper function for handling pending state
const handlePending = (state) => {
  state.loading = true;
  state.error = null;
};

// Helper function for handling fulfilled state
const handleFulfilled = (state, action) => {
  state.loading = false;
  if (action?.payload?.token) {
    state.isAuthenticated = true
    localStorage.setItem(TOKEN, action?.payload?.token);
    localStorage.setItem(ISADMIN, 'yiowejf0s9ie')
  } else if (action?.payload?._id) {
    state.isAuthenticated = true
  } else {
    state.isAuthenticated = false;
  }
};

// Helper function for handling rejected state
const handleRejected = (state, action) => {
  state.loading = false;
  state.error = action.payload || "An error occurred";
};

// Helper function for handling fulfilled state
const handleEmployeeFulfilled = (state, action) => {
  state.loading = false;
  if (action?.payload?.token) {
    state.isAuthenticated = true
    localStorage.setItem(TOKEN, action?.payload?.token);
    localStorage.removeItem(ISADMIN)
  } else if (action?.payload?._id) {
    state.isAuthenticated = true
  } else {
    state.isAuthenticated = false;
  }
};

export const authTokenSlice = createSlice({
  name: "auth/authTokenSlice",
  initialState: {
    isAuthenticated: Boolean(localStorage.getItem(TOKEN)) || false,
    error: null,
    loading: false,
  },
  reducers: {
    logoutAction: (state) => {
      state.isAuthenticated = false;
      localStorage.removeItem(TOKEN);
      sessionStorage.clear();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(ownerRegisterAction.pending, handlePending)
      .addCase(ownerRegisterAction.fulfilled, handleFulfilled)
      .addCase(ownerRegisterAction.rejected, handleRejected)
      .addCase(ownerLoginAction.pending, handlePending)
      .addCase(ownerLoginAction.fulfilled, handleFulfilled)
      .addCase(ownerLoginAction.rejected, handleRejected)
      .addCase(resetPasswordAction.pending, handlePending)
      .addCase(resetPasswordAction.fulfilled, handleFulfilled)
      .addCase(resetPasswordAction.rejected, handleRejected)
      .addCase(changePasswordAction.pending, handlePending)
      .addCase(changePasswordAction.fulfilled, handleFulfilled)
      .addCase(changePasswordAction.rejected, handleRejected)
      .addCase(googleAuthAction.pending, handlePending)
      .addCase(googleAuthAction.fulfilled, handleFulfilled)
      .addCase(googleAuthAction.rejected, handleRejected)
      .addCase(verifyEmailAction.pending, handlePending)
      .addCase(verifyEmailAction.fulfilled, handleFulfilled)
      .addCase(verifyEmailAction.rejected, handleRejected)
      .addCase(employeeLoginAction.pending, handlePending)
      .addCase(employeeLoginAction.fulfilled, handleEmployeeFulfilled)
      .addCase(employeeLoginAction.rejected, handleRejected)
      .addCase(employeeAuthAction.pending, handlePending)
      .addCase(employeeAuthAction.fulfilled, handleEmployeeFulfilled)
      .addCase(employeeAuthAction.rejected, (state, action) => {
        state.isAuthenticated = false;
      })
      .addCase(ownerAuthAction.fulfilled, handleFulfilled)
      .addCase(ownerAuthAction.rejected, (state, action) => {
        state.isAuthenticated = false;
      });
  },
});

export const { logoutAction } = authTokenSlice.actions;

// verify email send otp action
export const verifyEmailSendOTPAction = async (credentials) => {
  try {
    const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/verify-email/send-otp`;
    const response = await axios.post(url, credentials);
    return response.data;
  } catch (error) {
    return error?.response?.data?.message
  }
}

// forget password send otp action
export const forgetPasswordSendOTPAction = async (credentials) => {
  try {
    const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/forgot-password/send-otp`;
    const response = await axios.post(url, credentials);
    return response.data;
  } catch (error) {
    return error?.response?.data?.message
  }
}

// forget password verify otp action
export const forgetPasswordVerifyOTPAction = async (credentials) => {
  try {
    const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/forgot-password/verify-otp`;
    const response = await axios.post(url, credentials);
    response?.data?.token && localStorage.setItem(RESET_PASSWORD_TOKEN, response.data.token);
    return response.data;
  } catch (error) {
    return error?.response?.data?.message
  }
}
