import React, { useState } from "react";
import CustomeTable from "../../../components/widget/CustomeTable";

const AllBankAccounts = () => {
  const [selectedRows, setSelectedRows] = useState([]);

  const rowData = [
    {
      bankName: "Barclays",
      branchName: "Gulberg Town",
      accountNickName: "Stephin",
      accountNumber: "****1234",
      paymentReceivedType: "Corporate",
    },
    {
      bankName: "Barclays",
      branchName: "Gulberg Town",
      accountNickName: "Stephin",
      accountNumber: "****1234",
      paymentReceivedType: "Corporate",
    },
    {
      bankName: "Barclays",
      branchName: "Gulberg Town",
      accountNickName: "Stephin",
      accountNumber: "****1234",
      paymentReceivedType: "Corporate",
    },
    {
      bankName: "Barclays",
      branchName: "Gulberg Town",
      accountNickName: "Stephin",
      accountNumber: "****1234",
      paymentReceivedType: "Corporate",
    },
    {
      bankName: "Barclays",
      branchName: "Gulberg Town",
      accountNickName: "Stephin",
      accountNumber: "****1234",
      paymentReceivedType: "Corporate",
    },
  ];
  const banckAccountActions = () => {
    return (
      <div className="h-full flex gap-4 items-center">
        {/* <EditButton />
        <DeleteButton /> */}
      </div>
    );
  };
  const columnDefs = [
    {
      headerName: "Bank Name",
      field: "bankName",
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Branch Name",
      field: "branchName",
      filter: true,
    },
    {
      headerName: "Account Number",
      field: "accountNumber",
      filter: true,
    },
    {
      headerName: "Account Nick Name",
      field: "accountNickName",
      filter: true,
    },
    {
      headerName: "Payment Received Type",
      field: "paymentReceivedType",
      filter: true,
    },
    {
      headerName: "Actions",
      field: "id",
      minWidth: 200,
      filter: true,
      cellRenderer: banckAccountActions
    },
  ];
  return (
    <CustomeTable
      tableName={"Bank Account Table"}
      rowData={rowData}
      setSelectedRows={setSelectedRows}
      columnDefs={columnDefs}
    />
  );
};

export default AllBankAccounts;
