import React, { useState } from "react";
import CustomeTable from "../../../components/widget/CustomeTable";
import AddButton from "../../../components/buttons/AddButton";
import CreateNewConfigList from "./CreateMinibarList";

const RoomMinibarList = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [isCreateMinibarList, setIsCreateMinibarList] = useState(false);

  const rowData = [
    {
      minibarId: "R101",
      minibarConfigurationListName: "minibar 1",
      action: ["View Minibar", "Add Minibar"],
    },
    {
      minibarId: "R102",
      minibarConfigurationListName: "minibar 1",
      action: ["View Minibar", "Add Minibar"],
    },
    {
      minibarId: "R103",
      minibarConfigurationListName: "minibar 1",
      action: ["View Minibar", "Add Minibar"],
    },
  ];

  const RoomMinibarActions = ({ value }) => {
    return (
      <div className="h-full flex items-center gap-4">
        {value?.map((v) => (
          <button
            type="button"
            key={v}
            className="h-8 px-2 rounded-md border border-[#D2D2D2] hover:bg-gray-300 duration-300 ease-[0.3s] text-[14px] leading-6 text-[#252C32]"
          >
            {v}
          </button>
        ))}
        {/* <EditButton />
        <DeleteButton /> */}
      </div>
    );
  };

  const columnDefs = [
    {
      headerName: "Minibar Id",
      field: "minibarId",
      maxWidth:150,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Minibar Configuration List Name",
      field: "minibarConfigurationListName",
      filter: true,
    },
    {
      headerName: "Actions",
      field: "action",
      filter: true,
      cellRenderer: RoomMinibarActions,
    },
  ];

  return (
    <div>
      <div className="flex items-center justify-end">
        <AddButton
          text={"Create Minibar List"}
          onClick={() => setIsCreateMinibarList(true)}
        />
      </div>
      {isCreateMinibarList ? (
        <CreateNewConfigList close={() => setIsCreateMinibarList(false)} />
      ) : null}
      <div className="mt-4">
        <CustomeTable
          tableName={"Room Minibar Item List Table"}
          rowData={rowData}
          setSelectedRows={setSelectedRows}
          columnDefs={columnDefs}
        />
      </div>
    </div>
  );
};

export default RoomMinibarList;
