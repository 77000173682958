import React, { useState } from "react";
import MinibarItems from "./MinibarItems";
import AddButton from "../../../components/buttons/AddButton";
import CustomeTable from "../../../components/widget/CustomeTable";

const RoomMinibarItems = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [isMinibarItems, setIsMinibarItems] = useState(false);

  const rowData = [
    {
      itemId: "R101",
      foodAndBeveragesName: "Red Bull (250ml)",
      foodAndBeveragesType: "Snacks",
      dietaryPreferences:"Vegeterian",
      priceToCustomer:"$10",
    },
    {
      itemId: "R102",
      foodAndBeveragesName: "Red Bull (250ml)",
      foodAndBeveragesType: "Snacks",
      dietaryPreferences:"Vegeterian",
      priceToCustomer:"$10",
    },
    {
      itemId: "R103",
      foodAndBeveragesName: "Red Bull (250ml)",
      foodAndBeveragesType: "Snacks",
      dietaryPreferences:"Vegeterian",
      priceToCustomer:"$10",
    },
  ];

  const RoomMinibarActions = ({ value }) => {
    return (
      <div className="h-full flex items-center gap-4">
        {/* <EditButton />
        <DeleteButton /> */}
      </div>
    );
  };

  const columnDefs = [
    {
      headerName: "Item Id",
      field: "itemId",
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Food & Baverages Name",
      field: "foodAndBeveragesName",
      filter: true,
    },
    {
      headerName: "F&B Type",
      field: "foodAndBeveragesType",
      filter: true,
    },
    {
      headerName: "Dietary Preferences",
      field: "dietaryPreferences",
      filter: true,
    },
    {
      headerName: "Price To Customer",
      field: "priceToCustomer",
      filter: true,
    },
    {
      headerName: "Actions",
      field: "",
      minWidth: 200,
      filter: true,
      cellRenderer: RoomMinibarActions,
    },
  ];

  return (
    <div>
      <div className="flex items-center justify-end">
        <AddButton
          text={"Minibar Items"}
          onClick={() => setIsMinibarItems(true)}
        />
      </div>
      {isMinibarItems ? (
        <MinibarItems close={() => setIsMinibarItems(false)} />
      ) : null}
      <div className="mt-4">
        <CustomeTable
          tableName={"Minibar Item List Table"}
          rowData={rowData}
          setSelectedRows={setSelectedRows}
          columnDefs={columnDefs}
        />
      </div>
    </div>
  );
};

export default RoomMinibarItems;
