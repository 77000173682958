import React, { useState } from "react";
import EditButton from "../../components/buttons/EditButton";
import DeleteButton from "../../components/buttons/DeleteButton";
import SubmitButton from "../../components/buttons/SubmitButton";
import CheckRadioInputs from "../../components/form/CheckRadioInputs";
import AddLaundryItem from "./AddLaundryItem";
import CancelButton from "../../components/buttons/CancelButton";
import CustomeTable from "../../components/widget/CustomeTable";

const LaundryConfig = (props) => {
  const [isLaundryServices, setIsLaundryServices] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isOpenAddItem, setIsOpenAddItem] = useState(false);
  const [isSelectValue, setIsSelectValue] = useState("");
  const [price,setPrice] = useState("$5")

  const tableData = [
    {
      id: "1",
      image: "",
      name: "shirt",
    },
    {
      id: "2",
      image: "",
      name: "shirt",
    },
    {
      image: "",
      name: "shirt",
    },
    {
      image: "",
      name: "shirt",
    },
    {
      image: "",
      name: "shirt",
    },
    {
      image: "",
      name: "shirt",
    },
  ];

  // show image
  const showItemImage = () => {
    return (
      <div className="flex items-center justify-start">
        <img
          src={`https://w7.pngwing.com/pngs/972/557/png-transparent-blue-dress-shirt-t-shirt-dress-shirt-dress-shirt-tshirt-blue-image-file-formats-thumbnail.png`}
          alt=""
          className="h-9 w-10 rounded-lg bg-[#00000099] my-[2px]"
        />
      </div>
    );
  };
  function selectItem() {
    return (
      <input
        type="checkbox"
        value={isSelectValue}
        onChange={(e) => setIsSelectValue(e.target.value)}
        className={`size-5 border border-base-primary accent-current shadow-none mt-[10px]`}
      />
    );
  }
  function setItemPrice() {
    return (
      <div className="flex items-center h-full">
        <input
          type="text"
          value={price}
          onChange={(e)=>setPrice(e.target.value)}
          className="h-7 w-full text-[14px] leading-6 tracking-[-0.6%] rounded-md bg-[#F6F6F6] text-base-primary px-2 "
        />
      </div>
    );
  }
  const laundryItemaction = () => {
    return (
      <div className="h-full flex items-center gap-4">
        <EditButton />
        <DeleteButton />
      </div>
    );
  };
  const columnDefs = [
    {
      headerName: "Image",
      field: "image",
      filter: true,
      cellRenderer: showItemImage,
    },
    {
      headerName: "Name",
      field: "name",
      filter: true,
    },
    {
      headerName: "Laundry",
      field: "laundry",
      filter: true,
      cellRenderer: selectItem,
    },
    {
      headerName: "Set Laundry Price",
      field: "setLaundryPrice",
      filter: true,
      cellRenderer: setItemPrice,
    },
    {
      headerName: "Iron",
      field: "iron",
      filter: true,
      cellRenderer: selectItem,
    },
    {
      headerName: "Set Iron Price",
      field: "setIronPrice",
      filter: true,
      cellRenderer: setItemPrice,
    },
    {
      headerName: "Dry Cleaning",
      field: "dryCleaning",
      filter: true,
      cellRenderer: selectItem,
    },
    {
      headerName: "Set Dry Cleaning Price",
      field: "setDryCleaningPrice",
      filter: true,
      cellRenderer: setItemPrice,
    },
    {
      headerName: "Actions",
      field: "_id",
      minWidth: 200,
      filter: true,
      cellRenderer: laundryItemaction,
    },
  ];

  return (
    <div className="border border-[#E4E4E7] rounded-[10px] bg-white p-6 mt-6">
      <div className="flex items-center justify-between">
        <h2 className="text-[30px] leading-[37.8px] font-bold text-[#07090D] tracking-[-1.5%]">
          laundry Configuration
        </h2>
        <div className="flex items-center gap-4">
          <CancelButton onClick={props.close} />
          <SubmitButton
            type="button"
            text={"Add Item"}
            onClick={() => setIsOpenAddItem(true)}
          />
        </div>
      </div>
      <div className="flex items-center justify-between my-4">
        <h2 className="text-[18px] leading-5 font-medium text-[#344054] ">
          Create Laundry Price List
        </h2>
        <CheckRadioInputs
          label={"Laundry Services"}
          value={isLaundryServices}
          onChange={() => setIsLaundryServices(!isLaundryServices)}
        />
      </div>
      {isOpenAddItem ? (
        <AddLaundryItem close={() => setIsOpenAddItem(false)} />
      ) : null}
      <div className="w-full">
        <CustomeTable
          size={"h-[70vh]"}
          columnDefs={columnDefs}
          rowData={tableData}
          setSelectedRows={setSelectedRows}
        />
      </div>
    </div>
  );
};

export default LaundryConfig;
