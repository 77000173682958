import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { TOKEN } from "../../../constants";

// get all guest queries Action
export const getAllGuestQueriesAction = createAsyncThunk(
  "guestSupport/getAllGuestQueriesAction",
  async ({ credentials, hotelId }, thunkAPI) => {
    try {
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/guest/queries?hotelId=${hotelId}`;
      const response = await axios.get(url, { headers: credentials });
      localStorage.setItem(TOKEN, response.data.token);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// get all guest queries  slice
export const getAllGuestQueriesSlice = createSlice({
  name: "guestSupport/getAllGuestQueriesSlice",
  initialState: {
    loading: true,
    queries: [],
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllGuestQueriesAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllGuestQueriesAction.fulfilled, (state, action) => {
        state.loading = false;
        state.queries = action.payload.queries;
      })
      .addCase(getAllGuestQueriesAction.rejected, (state, action) => {
        state.loading = false;
        state.queries = [];
        state.error = action.payload?.message || "Failed to get all Guest";
      });
  },
});

// answer queries Action
export const ansQueryAction = createAsyncThunk(
  "guestSupport/ansQueryAction",
  async ({ credentials, answer, queryId }, thunkAPI) => {
    try {
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/employee/query/answer`;
      const response = await axios.post(
        url,
        { answer, queryId },
        { headers: credentials }
      );
      localStorage.setItem(TOKEN, response.data.token);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// answer queries slice
export const ansQuerySlice = createSlice({
  name: "guestSupport/ansQuerySlice",
  initialState: {
    loading: true,
    answer: {},
    isadded: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(ansQueryAction.pending, (state) => {
        state.loading = true;
        state.isadded = false;
        state.error = null;
      })
      .addCase(ansQueryAction.fulfilled, (state, action) => {
        state.loading = false;
        state.isadded = true;
        state.answer = action.payload.answer;
      })
      .addCase(ansQueryAction.rejected, (state, action) => {
        state.loading = false;
        state.isadded = false;
        state.answer = [];
        state.error = action.payload?.message || "Failed to answer query";
      });
  },
});
