import React, { useState } from "react";
import PropertyDetails from "./property-details/PropertyDetails";
import TaxCategories from "./taxes/TaxCategories";
import SettingsHeader from "./Tabs";
import WifiConfig from "./wifi/WifiConfig";
import PropertyInfo from "./property-details/PropertyInfo";
import EmployeePermissions from "./employepermission";
import PaymentModes from "./payment-modes";
import BankAccountConfig from "./bank-account";
import AutoSyncBookingConfig from "./booking-config/AutoSyncBookingConfig";
import Employees from "./employepermission/Employees";
import CorporateAccount from "./bank-account/CorporateAccount";
import LocalTravelAgent from "./travel-agent";

const Settings = () => {
  const [index, setIndex] = useState(1);

  const skipTheTab = () => {
    setIndex(index + 1);
  }

  return (
    <div>
      <SettingsHeader index={index} setIndex={setIndex} />
      {index === 1 ? <PropertyDetails skipTheTab={skipTheTab} /> : null}
      {index === 2 ? <PropertyInfo skipTheTab={skipTheTab} /> : null}
      {index === 3 ? <Employees /> : null}
      {/* {index === 3 ? <ContactInfo   /> : null} */}
      {index === 4 ? <PaymentModes /> : null}
      {index === 5 ? <BankAccountConfig /> : null}
      {index === 6 ? <AutoSyncBookingConfig /> : null}
      {index === 7 ? <CorporateAccount /> : null}
      {/* {index === 7 ? <BookingSourceConfig  /> : null} */}
      {index === 8 ? <LocalTravelAgent /> : null}
      {index === 9 ? <TaxCategories /> : null}
      {index === 10 ? <WifiConfig /> : null}
      {index === 11 ? <EmployeePermissions /> : null}
      {/* {index === 11 ? <LoginCredentials   /> : null} */}
    </div>
  );
};

export default Settings;
