import { useTranslation } from "react-i18next";
import ToolTip from "../../../components/common/ToolTip";

const InputPropertyTimeZone = ({
  value,
  setValue,
  options,
  required = false,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div>
        <div className="flex items-center justify-between">
          <label className="text-sm font-semibold text-[#344054]">
            {`${t("365")} *`}
          </label>
          <ToolTip Message={t("366")} />
        </div>
        <div className="relative flex items-center">
          <select
            onChange={(e) => setValue(e.target.value)}
            value={value}
            required={required}
          >
            {options?.map((val, index) => (
              <option value={val.offset} key={index}>
                {val.value}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
};

export default InputPropertyTimeZone;
