import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { TOKEN } from "../../../constants";

// guest check-in code generated action
export const guestCheckInCodeGeneratedAction = createAsyncThunk(
  "checkInCode/guestCheckInCodeGeneratedAction",
  async (credentials, thunkAPI, formData) => {
    try {
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/employee/checkInCode/generate`;
      const response = await axios.post(url, formData, credentials);
      localStorage.setItem(TOKEN, response.data.token);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// guest check-in code generated slice
export const guestCheckInCodeGeneratedSlice = createSlice({
  name: "checkInAndOutCode/guestCheckInCodeGeneratedSlice",
  initialState: {
    loading: true,
    isgenerated: false,
    code: {},
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(guestCheckInCodeGeneratedAction.pending, (state) => {
        state.loading = true;
        state.isgenerated = false;
        state.error = null;
      })
      .addCase(guestCheckInCodeGeneratedAction.fulfilled, (state, action) => {
        state.loading = false;
        state.isgenerated = true;
        state.code = action.payload.code;
      })
      .addCase(guestCheckInCodeGeneratedAction.rejected, (state, action) => {
        state.loading = false;
        state.isgenerated = false;
        state.error =
          action.payload?.message || "Failed to guest Check In Code Generated";
      });
  },
});

// guest check-out code action
export const guestCheckOutCodeAction = createAsyncThunk(
  "checkInAndOutCode/guestCheckOutCodeAction",
  async (credentials, thunkAPI, formData) => {
    try {
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/employee/checkInCode/checkOut`;
      const response = await axios.put(url, formData, credentials);
      localStorage.setItem(TOKEN, response.data.token);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// guest check-Out code slice
export const guestCheckOutCodeSlice = createSlice({
  name: "checkInCode/guestCheckOutCodeSlice",
  initialState: {
    loading: true,
    isout: false,
    code: {},
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(guestCheckOutCodeAction.pending, (state) => {
        state.loading = true;
        state.isout = false;
        state.error = null;
      })
      .addCase(guestCheckOutCodeAction.fulfilled, (state, action) => {
        state.loading = false;
        state.isout = true;
        state.code = action.payload.code;
      })
      .addCase(guestCheckOutCodeAction.rejected, (state, action) => {
        state.loading = false;
        state.isout = false;
        state.error =
          action.payload?.message || "Failed to guest Check In Out Code";
      });
  },
});
