import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllRoomAction } from "../lib/slices/room/roomSlice";
import { getAllBookingAction } from "../lib/slices/booking/getBookingByConditionsSlice";

const ApiCallsBefore = () => {
  const dispatch = useDispatch();

  const { hotel } = useSelector((state) => state.hotel);
  const callApis = (hotelId) => {
    // await props?.getKitchens(hotelId);
    // await props.getEmployees(hotelId);
    dispatch(getAllRoomAction(hotelId));
    dispatch(getAllBookingAction(hotelId));
  };

  useEffect(() => {
    if (hotel && hotel?._id) {
      callApis(hotel?._id);
    }
  }, [hotel?._id]);

  return null;
};

export default ApiCallsBefore 
