import React, { useEffect, useState } from "react"; 
import { useSelector } from "react-redux";
import { isObjEmpty } from "../../utils";
import ProfilePhone from "./ProfilePhone";
import { AiOutlineMail } from "react-icons/ai";
import { SlLocationPin } from "react-icons/sl";

const EmployeeProfile = () => { 
    const { user } = useSelector((state) => state.user); 
    const [name, setName] = useState("")
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");

    useEffect(() => {
        if (!isObjEmpty(user)) {
            setName(user?.name);
            setEmail(user?.email);
            setAddress(user?.address);
            setPhone(user?.phone);
        }
    }, [user]);

    return (
        <div className="py-6 w-full border border-[#E5E9EB] rounded-[10px] reletive bg-white overflow-hidden">
            <div className="px-8 flex justify-between items-center">
                <p className="capitalize text-[22px] h-12 text-[#07090D] leading-[22.27px] font-semibold tracking-[-0.8px]">
                    {name}
                </p>

            </div>
            {/* profile text */}
            <div className="gap-4">
                <div className="grid grid-cols-2 gap-4 pl-8 items-center">
                    <ProfilePhone phone={phone} setPhone={setPhone} readOnly={true} />
                    <div className="flex items-center gap-1">
                        <AiOutlineMail />
                        <input
                            value={email}
                            onChange={(e) => setEmail(e?.target?.value)}
                            type="email"
                            required
                            className={"ext-[20px] text-[#6E7C87] leading-[25.2px] font-normal tracking-[-0.8px] border-none none-focus shadow-none"}
                            readOnly={true}
                        />
                    </div>
                </div>

                <div className="flex items-center gap-1 ml-8">
                    <SlLocationPin />
                    <input
                        value={address}
                        onChange={(e) => setAddress(e?.target?.value)}
                        required
                        className={"text-[20px] text-[#6E7C87] leading-[25.2px] font-normal tracking-[-0.8px] border-none none-focus shadow-none"}
                        readOnly={true}
                    />
                </div>
            </div>
        </div >
    );
};

export default EmployeeProfile;
