import React, { useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import UploadButton from "../buttons/UploadButton";
import DownloadButton from "../buttons/DownloadButton";
import PrintButton from "../buttons/PrintButton";

const CustomeTable = ({
  rowData,
  columnDefs,
  setSelectedRows,
  rowHeight,
  tableName,
  showButtons=true,
  pagination = true,
  size = "h-[100vh]"
}) => {
  const rowSelection = "multiple";
  const paginationPageSize = 100;
  const paginationPageSizeSelector = [20, 50, 100];

  const getSelectedRowData = (event) => {
    const selectedData = event.api.getSelectedRows();
    setSelectedRows(selectedData);
  };

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100,
      // allow every column to be aggregated
      enableValue: true,
      // allow every column to be grouped
      enableRowGroup: true,
      // allow every column to be pivoted
      enablePivot: true,
      filter: true,
    };
  }, []);
  const autoGroupColumnDef = useMemo(() => {
    return {
      minWidth: 200,
    };
  }, []);

  return (
    <div>
      {showButtons ? (
        <div className="flex items-center justify-between gap-4 mb-4">
          <div className="text-[22px] leading-[27.72px] text-black font-bold capitalize">
            {tableName}
          </div>
          <div className="flex items-center gap-4">
            <PrintButton />
            <UploadButton />
            <DownloadButton />
          </div>
        </div>
      ) : null}

      <div className={`ag-theme-quartz ${size}`}>
        <AgGridReact
          rowHeight={rowHeight}
          rowData={rowData}
          columnDefs={columnDefs}
          pagination={pagination}
          paginationPageSize={paginationPageSize}
          paginationPageSizeSelector={paginationPageSizeSelector}
          rowSelection={rowSelection}
          suppressRowClickSelection={true}
          onSelectionChanged={getSelectedRowData}
          allowDragFromColumnsToolPanel={true}
          defaultColDef={defaultColDef}
          autoGroupColumnDef={autoGroupColumnDef}
          sideBar={"columns"}
          suppressColumnsToolPanel={true}
        />
      </div>
    </div>
  );
};

export default CustomeTable;
