import React, { useState } from "react";
import CustomeTable from "../../../components/widget/CustomeTable";

const AllPackages = () => {
  const [selectedRows, setSelectedRows] = useState([]);

  const rowData = [
    {
      packageId: "001",
      packageName: "Explorer's Package",
      minNoOfRoomBookedTogether: "06",
      minNoOfNight: "06",
      packageAvailableFor: "Corporate",
      roomTypeAdded: "Deluxe Room",
      mealPlanAdded: "Breakfast",
      servicesAdded: "Airport Transfer",
      availabilityFor: "Corporate",
    },
    {
      packageId: "002",
      packageName: "Explorer's Package",
      minNoOfRoomBookedTogether: "06",
      minNoOfNight: "06",
      packageAvailableFor: "Corporate",
      roomTypeAdded: "Deluxe Room",
      mealPlanAdded: "Breakfast",
      servicesAdded: "Airport Transfer",
      availabilityFor: "Corporate",
    },
    {
      packageId: "003",
      packageName: "Explorer's Package",
      minNoOfRoomBookedTogether: "06",
      minNoOfNight: "06",
      packageAvailableFor: "Corporate",
      roomTypeAdded: "Deluxe Room",
      mealPlanAdded: "Breakfast",
      servicesAdded: "Airport Transfer",
      availabilityFor: "Corporate",
    },
  ];
  const packageAction = () => {
    return (
      <div className="h-full flex items-center gap-2">
        {/* <EditButton />
        <DeleteButton /> */}
      </div>
    );
  };
  const columnDefs = [
    {
      headerName: "Package Id",
      field: "packageId",
      minWidth: 200,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Package Name",
      field: "packageName",
      filter: true,
    },
    {
      headerName: "Minimum No Of Room Booked Together",
      field: "minNoOfRoomBookedTogether",
      filter: true,
    },
    {
      headerName: "Minimum No Of Night",
      field: "minNoOfNight",
      filter: true,
    },
    {
      headerName: "Package Available For",
      field: "packageAvailableFor",
      filter: true,
    },
    {
      headerName: "Room Type Added",
      field: "roomTypeAdded",
      filter: true,
    },
    {
      headerName: "Meal Plan Added",
      field: "mealPlanAdded",
      filter: true,
    },
    {
      headerName: "Services Added",
      field: "servicesAdded",
      filter: true,
    },
    {
      headerName: "Availability For",
      field: "availabilityFor",
      filter: true,
    },
    {
      headerName: "Actions",
      field: "id",
      minWidth: 200,
      cellRenderer: packageAction,
    },
  ];

  return (
    <CustomeTable
      tableName={"Package List Table"}
      rowData={rowData}
      setSelectedRows={setSelectedRows}
      columnDefs={columnDefs}
    />
  );
};

export default AllPackages;
