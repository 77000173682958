import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

// get assets report by year
export const assetsReportByYearAction = createAsyncThunk(
  "reports/assetsReportByYearAction",
  async (credentials, thunkAPI) => {
    try {
      const { hotelId, fromYear, toYear } = credentials || {};
      if (!hotelId || !fromYear || !toYear) return;
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/reports/assets/by-year`;
      const response = await axios.post(url, credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

//get assets report by year slice
export const assetsReportByYearSlice = createSlice({
  name: "reports/assetsReportByYearSlice",
  initialState: {
    loading: false,
    assetsreport: [],
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(assetsReportByYearAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(assetsReportByYearAction.fulfilled, (state, action) => {
        state.loading = false;
        state.assetsreport = action.payload.assetsreport;
      })
      .addCase(assetsReportByYearAction.rejected, (state, action) => {
        state.loading = false;
        state.assetsreport = null;
        state.error = action.payload || "Failed to Assets Report By Year";
      });
  },
});

// get assets report by year for one asset action
export const assetsReportForOneAction = createAsyncThunk(
  "reports/assetsReportByYearForOneAction",
  async (credentials, thunkAPI) => {
    try {
      const { hotelId, fromYear, toYear } = credentials || {};
      if (!hotelId || !fromYear || !toYear) return;
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/reports/assets/by-year`;
      const response = await axios.post(url, credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

//get assets report slice
export const assetsReportForOneSlice = createSlice({
  name: "reports/assetsReportByYearForOneSlice",
  initialState: {
    loading: false,
    assetsreport: [],
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(assetsReportForOneAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(assetsReportForOneAction.fulfilled, (state, action) => {
        state.loading = false;
        state.assetsreport = action.payload.assetsreport;
      })
      .addCase(assetsReportForOneAction.rejected, (state, action) => {
        state.loading = false;
        state.assetsreport = null;
        state.error =
          action.payload || "Failed to assets Report by year For One ";
      });
  },
});
