import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  MENU_TYPE,
  ITEM_QUANTITY_MEASURMENT,
  PORTION_FOR,
  PORTION_SIZE,
} from "../../constants";
import { registerEvent } from "../../logs";
import SaveOrNot from "../../components/modals/SaveOrNot";
import { isObjEmpty } from "../../utils";
import { useTranslation } from "react-i18next";
import Inputfield from "../../components/form/InputField";
import SelectFromObject from "../../components/form/SelectFromObject";
import SelectTrueFalse from "../../components/form/SelectTrueFalse"; 
import CheckBoxInput from "../../components/form/CheckBoxInput";
import AddSubmitButton from "../../components/buttons/AddSubmitButton";
import CustomerMenuDataTable from "./CustomerMenuDataTable";
import BulkProductionCostDataTable from "./BulkProductionCostDataTable";
import BulkProductionAutomatedCostDataTable from "./BulkProductionAutomatedCostDataTable";
import CloseButton from "../../components/buttons/CloseButton";
import InputTextArea from "../../components/form/InputTextArea";
import UnitsMeassureInputfield from "../../components/form/UnitsMeassureInputfield";
import SubmitButton from "../../components/buttons/SubmitButton";
import {
  addItemToKitchenMenuAction,
  updateKitchenMenuItemAction,
} from "../../lib/slices/kitchen/menuSlice";

const AddItemToMenu = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isadded, loading, error } = useSelector(
    (state) => state.addItemToKitchenMenu
  );
  console.log("check add item to menu :", isadded, loading, error);
  const { loading: updateLoading } = useSelector(
    (state) => state.updateKicthenMenuItem
  );
  console.log("check update item to menu :", updateLoading);
  const [quantityMeasurement, setQuantityMesurment] = useState(
    ITEM_QUANTITY_MEASURMENT.PIECE
  );
  const [images, setImages] = useState([]);
  const [isMeasurable, setIsMeasurable] = useState(false);
  const [open, setOpen] = useState(false);
  const [foodAndBeveragesName, setFoodAndBeveragesName] = useState("");
  const [priceToCustomer, setPriceToCustomer] = useState("");
  const [portionFor, setPortionFor] = useState(PORTION_FOR[1]);
  const [portionSize, setPortionSize] = useState(PORTION_SIZE.full);
  const [dishItemDescription, setDishItemDescription] = useState("");
  const [availableIn, setAvailableIn] = useState([]);
  const [menuType, setMenuType] = useState(Object.keys(MENU_TYPE)[0]);
  // eslint-disable-next-line no-unused-vars
  const [linkToMenu, setLinkToMenu] = useState(false);
  // add new state
  const [foodAndBeverageType, setFoodAndBeverageType] = useState("");
  const [dietaryPreference, setDietaryPreference] = useState("");
  const [available, setAvailable] = useState("");
  // const [quantityPer10, setQuantityPer10] = useState([{ QUANTITY_PER10 }]);
  const [quantity, setQuantity] = useState("1");
  const [productionCostPerkg, setProductionCostPerkg] = useState("1");
  const [cuisineType, setCuisineType] = useState([]);
  const [cuisineSubCategory, setCuisineSubCategory] = useState([]);
  //new fields
  const [isRoomService, setIsRoomService] = useState(false);
  const [isRestaurantDining, setIsRestaurantDining] = useState(false);
  const [isBarAndCafe, setIsBarAndCafe] = useState(false);
  const [isBuffet, setIsBuffet] = useState(false);
  const [isAlaCarteMenu, setIsAlaCarteMenu] = useState(false);
  const [productionQuantity, setProductionQuantity] = useState("1");
  const [isAutomated, setIsAutomated] = useState(true);
  const [isManually, setIsManually] = useState(false);

  //submiting the form input data.
  const handleSubmit = async () => {
    const formData = {
      kitchenId: props?.kitchen?._id,
      foodAndBeveragesName,
      images,
      priceToCustomer: 100,
      portionFor,
      quantityMeasurement,
      quantity: !isMeasurable ? "1" : quantity,
      // isNonVeg,
      dietaryPreference,
      dishItemDescription,
      isRoomService,
      isRestaurantDining,
      isBarAndCafe,
      isBuffet,
      productionQuantity,
      productionCostPerkg,
      portionSize,
      isMeasurable,
      availableIn,
      menuType,
      linkToMenu,
    };

    if (isObjEmpty(props.menu)) {
      dispatch(addItemToKitchenMenuAction(formData));
      registerEvent("add menu item", "add menu item", formData);
    } else {
      dispatch(
        updateKitchenMenuItemAction({ ...formData, itemId: props?.menu?.id })
      );
      registerEvent("update menu item", "add menu item", formData);
    }
  };

  //open modal for confirmation
  const openConfirmationModal = (e) => {
    e.preventDefault();
    setOpen(true);
    registerEvent("confirmation modal open", "add menu item");
  };

  // close confimation modal without confimation
  const closeModal = () => setOpen(false);
  // message
  useEffect(() => {
    if (isadded) {
      // notifySuccess("1067");
      // dispatch({ type: ADD_ITEM_TO_MENU_RESET });
    }

    if (error) {
      // notifyError("1068");
      // dispatch(clearError());
    }
  }, [isadded, error]);

  useEffect(() => {
    if (!isObjEmpty(props?.menu)) {
      setFoodAndBeveragesName(props?.menu?.foodAndBeveragesName);
      setQuantityMesurment(props?.menu?.quantityMeasurement);
      setImages(props?.menu?.images);
      setDietaryPreference(props?.menu?.dietaryPreference);
      setDishItemDescription(props?.menu?.dishItemDescription);
      setIsRoomService(props?.menu?.isRoomService);
      setIsRestaurantDining(props?.menu?.isRestaurantDining);
      setIsBarAndCafe(props?.menu?.isBarAndCafe);
      setIsBuffet(props?.menu?.isBuffet);
      setProductionQuantity(props?.menu?.productionQuantity);
      setProductionCostPerkg(props?.menu?.productionCostPerkg);
      setPriceToCustomer(props?.priceToCustomer);
      setPortionFor(props?.menu?.portionFor);
      setPortionSize(props?.menu?.portionSize);
      setQuantity(props?.menu?.quantity);
      setMenuType(props?.menu?.menuType);
      setAvailableIn(props?.menu?.availableIn);
    }
  }, [props?.menu]);

  const handleManually = () => {
    setIsManually(true);
    setIsAutomated(false);
  };
  const handleAutomated = () => {
    setIsAutomated(true);
    setIsManually(false);
  };
  return (
    <>
      <form
        onSubmit={openConfirmationModal}
        className={`w-full mx-auto overflow-hidden duration-300 border border-[#D0D5DD] rounded-lg bg-white relative mt-6`}
      >
        {/* Add Food and Beverage Items */}
        <div className="p-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center justify-center text-[30px] text-[#07090D] font-bold leading-[37.8px] tracking-[-1.5%]">
              {isObjEmpty(props?.menu) ? t("1069") : t("1070")}
            </div>
            <CloseButton onClick={props.close} />
          </div>
          <div className="grid grid-cols-4 gap-4 mt-6">
            <Inputfield
              label={t("Food & Beverages Name")}
              placeholder={t("Enter Dish Item Name")}
              value={foodAndBeveragesName}
              onChange={(e) => setFoodAndBeveragesName(e.target.value)}
              maxLength={40}
              minLength={3}
              required={true}
            />
            <SelectFromObject
              label={t("F&B Type")}
              options={{}}
              value={foodAndBeverageType}
              setValue={setFoodAndBeverageType}
            />
            <SelectTrueFalse
              label={t("1072")}
              value={dietaryPreference}
              setValue={setDietaryPreference}
            />
            <SelectFromObject
              label={t("Cuisine Type")}
              options={{}}
              value={cuisineType}
              setValue={setCuisineType}
            />
            <SelectFromObject
              label={t("Cuisine Sub Category")}
              options={{}}
              value={cuisineSubCategory}
              setValue={setCuisineSubCategory}
            />
            <div className="col-span-3">
              <InputTextArea
                rows={8}
                label={t("Dish Item Description")}
                placeholder={t("Add a description of the item")}
                value={dishItemDescription}
                onChange={(e) => setDishItemDescription(e.target.value)}
              />
            </div>
          </div>
          {/* image */}
          {/* <div className="w-[23.5%]">
              <label className="text-sm font-medium text-[#344054]">
                {t("1075")}
              </label>
              <MultipleImageUpload
                images={images}
                setImages={setImages}
                thumbnail={img}
              />
            </div> */}
        </div>

        <hr className="text-[#D0D5DD] w-full mt-6" />

        {/*  Available in- La Carte Menu */}
        <div className="p-4">
          <div className="flex items-center text-[30px] text-[#07090D] leading-[37.8px]s font-bold tracking-[-1.5%]">
            Available in- La Carte Menu
          </div>
          <div className="w-[217px]">
            <CheckBoxInput
              value={isAlaCarteMenu}
              onChange={() => setIsAlaCarteMenu(!isAlaCarteMenu)}
              utility={"pt-4"}
              label2={t("Ala Carte Menu")}
            />
          </div>
          <div className="grid grid-cols-4 gap-4 w-full items-center">
            <SelectFromObject
              label={t("878")}
              options={{}}
              value={available}
              setValue={setAvailable}
            />
            <CheckBoxInput
              value={isRoomService}
              onChange={() => setIsRoomService(!isRoomService)}
              label={t("1076")}
              label2={t("407")}
            />
            <CheckBoxInput
              value={isRestaurantDining}
              onChange={() => setIsRestaurantDining(!isRestaurantDining)}
              label2={t("1077")}
              utility={"pt-4"}
            />
            <CheckBoxInput
              value={isBarAndCafe}
              onChange={() => setIsBarAndCafe(!isBarAndCafe)}
              utility={"pt-4"}
              label2={t("1078")}
            />
            <SelectFromObject
              label={t("873")}
              options={PORTION_SIZE}
              value={portionSize}
              setValue={setPortionSize}
            />
            <SelectFromObject
              label={t("872")}
              options={PORTION_FOR}
              value={portionFor}
              setValue={setPortionFor}
            />
            <UnitsMeassureInputfield
              label="Quantity"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
              options={{ KG: "Kg", GM: "gm" }}
            />
            <Inputfield
              label={t("1079")}
              placeholder={t("489")}
              value={priceToCustomer}
              onChange={(e) => setPriceToCustomer(e.target.value)}
            />
          </div>
          <div className="flex items-end justify-end my-6">
            {/* <SimpleButton sizing="w-[152px] h-10" text={t("Add Price")} /> */}
            <SubmitButton type="button" text={t("Add Price")} />
          </div>
          <div className="my-4">
            <CustomerMenuDataTable />
          </div>
        </div>

        <hr className="text-[#D0D5DD] w-full mt-4" />

        {/*  Available in- Buffet */}
        <div className="p-4">
          <div className="flex items-center text-[30px] text-[#07090D] font-bold leading-[37.8px] tracking-[-1.5%]">
            Available in- Buffet
          </div>
          <div className={``}>
            <CheckBoxInput
              value={isBuffet}
              onChange={() => setIsBuffet(!isBuffet)}
              utility={"pt-4"}
              label2={t("1001")}
            />
          </div>
          {/* new design --- add funtionality for manually or automated*/}
          <div className="relative h-10 w-[260px] flex items-center text-[16px] leading-6 font-medium bg-white overflow-hidden border border-[#D0D5DD] rounded-lg">
            <button
              type="button"
              className={`h-10 px-8 ${
                isManually
                  ? "w-fit bg-base-primary text-white border-r rounded-lg border-[#D0D5DD] "
                  : "hover:text-base-primary"
              }`}
              onClick={handleManually}
            >
              Manually
            </button>
            <button
              type="button"
              className={`h-10 px-6 absolute right-0 ${
                isAutomated
                  ? "w-fit bg-base-primary text-white rounded-lg border-l border-[#D0D5DD]"
                  : "hover:text-base-primary"
              }`}
              onClick={handleAutomated}
            >
              Automated
            </button>
          </div>

          {isManually ? (
            <div className="w-full mt-4">
              <div className="grid grid-cols-4 items-end gap-4">
                <UnitsMeassureInputfield
                  label="Production Quantity"
                  value={productionQuantity}
                  onChange={(e) => setProductionQuantity(e.target.value)}
                  options={{ KG: "Kg", GM: "gm" }}
                />
                <Inputfield
                  label={t("1082")}
                  value={productionCostPerkg}
                  onChange={(e) => setProductionCostPerkg(e.target.value)}
                />
                {/* <SimpleButton text={t("Add Price")} sizing="w-[152px] h-10"/> */}
                <SubmitButton type="button" text={t("Add Price")} />
              </div>
              <div className="my-6">
                <BulkProductionCostDataTable />
              </div>
            </div>
          ) : null}

          {isAutomated ? (
            <div className="my-6">
              <BulkProductionAutomatedCostDataTable />{" "}
            </div>
          ) : null}

          <div className="flex justify-end gap-4 items-baseline mb-4">
            <AddSubmitButton
              width={188}
              text={t("Add To Menu")}
              loading={loading || updateLoading}
            />
          </div>
        </div>
      </form>
      <SaveOrNot isopen={open} close={closeModal} formSubmit={handleSubmit} />
    </>
  );
};

export default AddItemToMenu;
