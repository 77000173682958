import React, { useState } from "react";
import CustomeTable from "../../components/widget/CustomeTable";
import Inspection from "./Inspection";
import Assign from "./Assign";
import RoomAvailability from "./RoomAvailability";
import RoomCleaningStatus from "./RoomCleaningStatus";

const RoomCleaning = () => {
  const [selectedRows, setSelectedRows] = useState([]);

  const rowData = [
    {
      _id: 213,
      room_no: "100",
      status: "cleaning",
      availability: "vacant",
      inspection_status: "inspected",
      assign_to: "assign",
    },
    {
      _id: 3213,
      room_no: "101",
      status: "dirty",
      availability: "Occupied",
      inspection_status: "Pending",
      assign_to: "E023 Masud , E024 Rajot",
    },
    {
      _id: 2413,
      room_no: "102",
      status: "cleaned",
      availability: "Occupied",
      inspection_status: "inspected",
      assign_to: "assign",
    },
    {
      _id: 21343,
      room_no: "103",
      status: "cleaning",
      availability: "vacant",
      inspection_status: "inspected",
      assign_to: "E023 Masud , E024 Rajot",
    },
    {
      _id: 2138,
      room_no: "104",
      status: "dirty",
      availability: "Occupied",
      inspection_status: "Pending",
      assign_to: "assign",
    },
  ];

  const columnDef = [
    {
      headerName: "Room ID",
      field: "_id",
      minWidth: 100,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Room No",
      field: "room_no",
      minWidth: 100,
      filter: true,
    },
    {
      headerName: "Status",
      field: "status",
      filter: true,
      minWidth: 150,
      cellRenderer: RoomCleaningStatus,
    },
    {
      headerName: "availability",
      field: "availability",
      filter: true,
      minWidth: 150,
      cellRenderer: RoomAvailability,
    },
    {
      headerName: "inspection status",
      field: "inspection_status",
      filter: true,
      minWidth: 150,
      cellRenderer: Inspection,
    },
    {
      headerName: "assigned to",
      field: "assign_to",
      filter: true,
      minWidth: 150,
      cellRenderer: Assign,
    },
  ];

  return (
    <div>
      <CustomeTable
        tableName={"Room Cleaning table"}
        rowData={rowData}
        columnDefs={columnDef}
        setSelectedRows={setSelectedRows}
      />
    </div>
  );
};
export default RoomCleaning;
