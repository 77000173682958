import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import  CustomeTable   from "../../components/widget/CustomeTable";

const BulkProductionCostDataTable = () => {
  const [datas, setDatas] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const { menuItems } = useSelector((state) => state.AllMenuItem);

  useEffect(() => {
    if (menuItems && Array.isArray(menuItems)) {
      let newData = [];
      menuItems?.map((item) => {
        newData.push({
          sn: item?.sn,
          productionQuantity: item?.productionQuantity,
          productionCostPerkg: item?.productionCostPerkg,
          productionCostPerGm: item?.productionCostPerGm,
          //   _id: item?._id,
        });
      });
      if (newData?.length) {
        setDatas(newData);
      }
    }
  }, [menuItems]);
  const tableData = [
    {
      sn: "1",
      productionQuantity: "1 - 5 Kg",
      productionCostPerkg: "$ 100",
      productionCostPerGm: "$ 0.01",
    },
    {
      sn: "2",
      productionQuantity: "5 - 10 Kg",
      productionCostPerkg: "$ 500",
      productionCostPerGm: "$ 0.05",
    },
    {
      sn: "3",
      productionQuantity: "10 - 15 kg",
      productionCostPerkg: "$ 1000",
      productionCostPerGm: "$ 0.010",
    },
    {
      sn: "4",
      productionQuantity: "15 - 20 kg",
      productionCostPerkg: "$ 1200",
      productionCostPerGm: "$ 0.15",
    },
    {
      sn: "5",
      productionQuantity: "20 - 25 kg",
      productionCostPerkg: "$ 1500",
      productionCostPerGm: "$ 0.05",
    },
  ];
  const columnDef = [
    {
      headerName: "S.No",
      field: "sn",
      maxWidth: 100,
      minWidth: 60,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Production Quantity",
      field: "productionQuantity",
      filter: true,
    },
    {
      headerName: "Production Cost Per kg",
      field: "productionCostPerkg",
      filter: true,
    },
    {
      headerName: "Production Cost Per Gm",
      field: "productionCostPerGm",
      filter: true,
    },
  ];
  return (
    <div>
      {/* {datas.length ? ( */}
      <CustomeTable
        size="h-[42vh]"
        tableName={"Bulk Production Coasting List Table"}
        rowData={tableData}
        columnDefs={columnDef}
        setSelectedRows={setSelectedRows}
      />
      {/* ) : null} */}
    </div>
  );
};

export default BulkProductionCostDataTable;
