import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  PROPERTY_TYPE,
  LANGUAGES,
  TIME_ZONES,
  COUNTRY_CURRENCY,
  ONBOARDING_STAGES,
} from "../../constants";
import ToolTip from "../../components/common/ToolTip";
import { Country, State, City } from "country-state-city";
import { useTranslation } from "react-i18next";
import {
  addHotelAction,
  updateHotelAction,
} from "../../lib/slices/hotel/hotelSlice";
import GetInputs from "./GetInputs";
import PreviousButton from "./PreviousButton";
import NextButton from "./NextButton";
import PhoneWithCode from "../../components/form/PhoneWithCode";

const OnboardAddHotel = ({ setStep }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { hotel } = useSelector((state) => state.hotel);
  const [propertyName, setPropertyName] = useState(hotel?.propertyName || "");
  const [propertyType, setPropertyType] = useState(hotel?.propertyType || PROPERTY_TYPE.HOTELS);
  const [totalRooms, setTotalRooms] = useState(hotel?.totalRooms || 1);
  const [phone, setPhone] = useState(hotel?.propertyContact?.phone || "");
  const [email, setEmail] = useState(hotel?.propertyContact?.email || "")
  const [language, setLanguage] = useState(hotel?.language || "EN");
  const [timeZoneOffset, setTimeZoneOffset] = useState(hotel?.timeZoneOffset || 5);
  const [currency, setCurrency] = useState(hotel?.currency || "USD");
  const [streetAddress, setStreetAddress] = useState(hotel?.streetAddress || "");
  const [country, setCountry] = useState(hotel?.propertyAddress?.country || Country.getAllCountries()[0]?.name);
  const [state, setState] = useState(hotel?.propertyAddress?.state || "");
  const [city, setCity] = useState(hotel?.propertyAddress?.city || "");
  const [pinCode, setPinCode] = useState(hotel?.propertyAddress?.pinCode || "");
  const [countryIsoCode, setCountryIsoCode] = useState(Country.getAllCountries()[0]?.isoCode);
  const [stateIsoCode, setStateIsoCode] = useState("");

  const formSubmit = (e) => {
    e.preventDefault();
    const formData = {
      propertyName,
      propertyType,
      totalRooms: +totalRooms,
      propertyContact: {
        phone,
        email,
      },
      // new update field
      language,
      timeZoneOffset,
      currency,
      propertyAddress: {
        streetAddress,
        country,
        state,
        city,
        pinCode,
      },
    };
    if (hotel?._id) {
      dispatch(
        updateHotelAction({
          ...formData,
          stage: ONBOARDING_STAGES.ONBOARDING,
          hotelId: hotel?._id,
        })
      );
    } else {
      dispatch(addHotelAction(formData));
    }
  };

  useEffect(() => {
    if (countryIsoCode) {
      const stateName = State.getStatesOfCountry(countryIsoCode)[0]?.name;
      const iso = State.getStatesOfCountry(countryIsoCode)[0]?.isoCode;
      setState(stateName);
      setStateIsoCode(iso);
    }
  }, [countryIsoCode]);

  useEffect(() => {
    if (countryIsoCode && stateIsoCode) {
      const cityy = City.getCitiesOfState(countryIsoCode, stateIsoCode)[0]
        ?.name;
      setCity(cityy);
    }
  }, [stateIsoCode]);

  const goToPreviousPage = () => {
    setStep(0)
  }

  return (
    <div className="flex items-start flex-col justify-center pb-6">
      <form className="mx-auto" onSubmit={formSubmit}>
        <div className="text-center text-2xl sm:text-3xl md:text-[40px] capitalize mb-8 font-bold leading-[72px] tracking-[-1.5%] mt-12">
          {t("905")}
        </div>
        <div className="lg:w-[540px] md:w-full grid grid-cols-1 gap-4">
          <GetInputs
            label={`What is ypur Property Name ?`}
            min={3}
            max={1000}
            required={true}
            message={t("899")}
            placeholder={"Enter Your Property Name"}
            value={propertyName}
            setValue={setPropertyName}
          />
          {/* sm:w-[410px] */}
          <div className="relative w-full">
            <label>{`${t("906")} *`}</label>
            <div className="relative flex items-center w-full gap-2">
              <select 
                onChange={(e) => setPropertyType(e?.target?.value)}
                value={propertyType}
              >
                {Object.keys(PROPERTY_TYPE)?.map((value) => (
                  <option key={value} value={value}>
                    {value?.replace("_", " ")?.toLowerCase()}
                  </option>
                ))}
              </select>
              <ToolTip Message={t("1118")} />
            </div>
          </div>
          <GetInputs
            label={`Number Of Rooms ?`}
            type="number"
            min={0}
            max={100}
            required={true}
            message={t("1119")}
            value={totalRooms}
            setValue={setTotalRooms}
          />
          <div className="relative max-w-full w-full">
            <div className="relative flex items-center w-full gap-2">
              <PhoneWithCode setPhone={setPhone} phone={phone} label={t("908")} />
              <ToolTip Message={t("910")} />
            </div>
          </div>

          <div className="max-w-full w-full gap-2">
            <label>{`${t("911")} `}</label>
            <div className="flex items-center w-full gap-2">
              <input
                type="email"
                onChange={(e) => setEmail(e?.target?.value)}
                value={email}
                required={true}
                placeholder="Property Email" 
              />
              <ToolTip Message={t("912")} />
            </div>
          </div>
          {/* language */}
          <div className="">
            <label>{`${t("Language")} *`}</label>
            <div className="flex items-center w-full gap-2">
              <select
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
                className="capitalize"
                required={true}
              >
                {Object.keys(LANGUAGES)?.map((item) => (
                  <option value={item} key={item} className="capitalize">
                    {LANGUAGES[item].replace("_", " ").toLowerCase()}
                  </option>
                ))}
              </select>
              <ToolTip Message={t("916")} />
            </div>
          </div>
          {/* time zone and currency */}
          <div className="">
            <label htmlFor="timezoneAndCurrency">
              Time Zone & Currency{" "}
              <span className="text-[12px] leading-5 text-[#6E7C87]">
                (Time zone and currency cannot be changed once saved)
              </span>
            </label>
            <div className="flex items-center gap-2">
              <div className="flex items-center gap-2 ">
                <select
                  value={timeZoneOffset}
                  onChange={(e) => setTimeZoneOffset(e.target.value)}
                >
                  {TIME_ZONES?.map((timeZones) => {
                    return (
                      <option value={timeZones?.offset} key={timeZones?.text}>{timeZones?.text}</option>
                    );
                  })}
                </select>
                <ToolTip Message={t("916")} />
              </div>
              {/* currency */}
              <div className="flex items-center gap-2">
                <select
                  value={currency}
                  onChange={(e) => setCurrency(e.target.value)}
                >
                  {COUNTRY_CURRENCY.map((crr) => {
                    return (
                      <option key={crr.code} value={crr.code}>
                        {crr.name} - {crr.code}
                      </option>
                    );
                  })}
                </select>
                <ToolTip Message={t("916")} />
              </div>
            </div>
          </div>

          <div className="max-w-full w-full gap-2">
            <label>{`${t("913")} *`}</label>
            <div className="relative flex items-center w-full gap-2">
              <input
                type="text"
                onChange={(e) => setStreetAddress(e?.target?.value)}
                value={streetAddress}
                required={true}
                minLength={5}
                maxLength={100}
                placeholder={t("914")} 
              />
              <ToolTip Message={t("1120")} />
            </div>
            <div className="flex items-center w-full mt-4 gap-6">
              <div className="flex items-center w-full gap-2">
                <select 
                  onChange={(e) => {
                    setCountry(e.target.innerText);
                    setCountryIsoCode(e?.target?.value);
                  }}
                  value={countryIsoCode}
                  required={true}
                >
                  {Country.getAllCountries()?.map((value) => (
                    <option value={value?.isoCode} key={value?.isoCode}>
                      {value?.name}
                    </option>
                  ))}
                </select>
                <ToolTip Message={t("916")} />
              </div>
              <div className="flex items-center w-full gap-2">
                <select 
                  onChange={(e) => {
                    setState(e?.target?.innerText);
                    setStateIsoCode(e?.target?.value);
                  }}
                  value={stateIsoCode}
                  required={true}
                >
                  {State.getStatesOfCountry(countryIsoCode)?.map((value) => (
                    <option value={value?.isoCode} key={value?.isoCode}>
                      {value?.name}
                    </option>
                  ))}
                </select>
                <ToolTip Message={t("917")} />
              </div>
            </div>
            <div className="flex items-center w-full mt-4 gap-6">
              <div className="flex items-center w-full gap-2">
                <select 
                  onChange={(e) => setCity(e.target.value)}
                  value={city}
                  required={true}
                >
                  {City.getCitiesOfState(countryIsoCode, stateIsoCode)?.map(
                    (value) => (
                      <option value={value?.name} key={value?.name}>
                        {value?.name}
                      </option>
                    )
                  )}
                </select>
                <ToolTip Message={t("918")} />
              </div>
              <div className="flex items-center w-full gap-2">
                <input
                  type="text"
                  onChange={(e) => setPinCode(e?.target?.value)}
                  value={pinCode}
                  placeholder="Pincode"
                  required={true} 
                />
                <ToolTip Message={t("919")} />
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center mt-6 justify-between">
          <PreviousButton handleClick={goToPreviousPage} />
          <NextButton />
        </div>
      </form>
    </div>
  );
};

export default OnboardAddHotel;
