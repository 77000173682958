import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

// add vendor action
export const addVendorAction = createAsyncThunk(
  "vendor/addVendorAction",
  async (credentials, thunkAPI) => {
    try {
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/vendor`;
      const response = await axios.post(url, credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

//Add room slice
export const addVendorSlice = createSlice({
  name: "vendor/addVendorSlice",
  initialState: {
    loading: false,
    isadded: false,
    vendor: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addVendorAction.pending, (state) => {
        state.loading = true;
        state.isadded = false;
        state.error = null;
      })
      .addCase(addVendorAction.fulfilled, (state, action) => {
        state.loading = false;
        state.isadded = true;
        state.vendor = action.payload.vendor;
      })
      .addCase(addVendorAction.rejected, (state, action) => {
        state.loading = false;
        state.isadded = false;
        state.error = action.payload || "Failed to add vendor";
      });
  },
});

//get vendor by id action
export const getVendorByIdAction = createAsyncThunk(
  "vendor/getVendorByIdAction",
  async (credentials, thunkAPI) => {
    try {
      const { VENDOR_ID, HOTEL_ID } = credentials || {};
      if (!VENDOR_ID || !HOTEL_ID) return;
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/vendor?vendorId=${VENDOR_ID}&hotelId=${HOTEL_ID}`;
      const response = await axios.get(url, credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);
//get vendor by id slice
export const getVendorByIdSlice = createSlice({
  name: "asset/getHotelAssetByIdSlice",
  initialState: {
    loading: false,
    vendor: {},
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getVendorByIdAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getVendorByIdAction.fulfilled, (state, action) => {
        state.loading = false;
        state.vendor = action.payload.vendor;
      })
      .addCase(getVendorByIdAction.rejected, (state, action) => {
        state.loading = false;
        state.vendor = null;
        state.error = action.payload || "Failed to get vendor";
      });
  },
});

//get all vendor action
export const getAllVendorAction = createAsyncThunk(
  "vendor/getAllVendorAction",
  async (credentials, thunkAPI) => {
    try {
      const { HOTEL_ID } = credentials || {};
      if (!HOTEL_ID) return;
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/vendor/all?hotelId=${HOTEL_ID}`;
      const response = await axios.get(url, credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

//get all vendor slice
export const getAllVendorSlice = createSlice({
  name: "asset/getHotelAssetByIdSlice",
  initialState: {
    loading: false,
    vendor: {},
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllVendorAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllVendorAction.fulfilled, (state, action) => {
        state.loading = false;
        state.vendor = action.payload.vendor;
      })
      .addCase(getAllVendorAction.rejected, (state, action) => {
        state.loading = false;
        state.vendor = [];
        state.error = action.payload || "Failed to get all vendor";
      });
  },
});
