import React from "react";

const NotificationCard = () => {
  return (
    <div className="w-full h-[72px] border-b border-[#E4E4E7] last:border-b-0 p-4 tracking-[-0.6%] capitalize">
      <div className="flex items-center justify-between">
        <h2 className="text-[16px] leading-5 font-semibold">Jessica Drew</h2>
        <h3 className="text-[12px] leading-4 font-normal text-[#707991]">
          50 Minutes Ago
        </h3>
      </div>
      <div className="flex items-center justify-between mt-1">
        <h2 className="text-[14px] leading-[18px] font-normal text-[#707991]">
          Payment Approval
        </h2>
        <div className="flex items-center gap-4">
          <button
            type="button"
            className="h-[21px] w-[62px] border border-[#E5E9EB] rounded text-[12px] leading-3 font-semibold text-[#F04438] hover:text-[#F6F8F9] hover:bg-[#F04438]"
          >
            Decline
          </button>
          <button
            type="button"
            className="h-[21px] w-[62px] border border-[#E5E9EB] rounded text-[12px] leading-3 font-semibold text-[#F6F8F9] bg-base-primary hover:bg-base-primary_hover"
          >
            Accept
          </button>
        </div>
      </div>
    </div>
  );
};
export default NotificationCard;
