import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { isObjEmpty } from "../../utils";
import { ONBOARDING_STAGES } from "../../constants";
import { updateHotelAction } from "../../lib/slices/hotel/hotelSlice";
import PreviousButton from "./PreviousButton";
import NextButton from "./NextButton";

const PropertyApproval = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [streetAddress, setStreetAddress] = useState("");
  const [confirm, setConfirm] = useState(false);

  const { hotel } = useSelector((state) => state.hotel);


  useEffect(() => {
    if (!isObjEmpty(hotel)) {
      if (hotel?.streetAddress) setStreetAddress(hotel?.streetAddress);
    }
  }, [hotel]);

  const formSubmit = (e) => {
    e.preventDefault();
    if (!confirm) return;
    const formData = {
      hotelId: hotel?._id,
      streetAddress,
      stage: ONBOARDING_STAGES.DASHBOARD,
    };
    dispatch(updateHotelAction(formData));
  };

  const goToPrev = () => {
    const formData = {
      hotelId: hotel?._id,
      streetAddress,
      stage: ONBOARDING_STAGES.ONBOARDING,
    };
    dispatch(updateHotelAction(formData));
  };

  return (
    <>
      <div className="flex items-start flex-col w-full pb-6 px-4 sm:px-6">
        <form onSubmit={formSubmit}>
          <div className="text-center text-[40px] leading-[48px] font-bold tracking-[-1.5%] text-[#07090D] mb-4">
            Search Your Property Name
          </div>
          <div>
            <label className="inline-block mb-1 text-sm font-semibold">
              {t("923")}
            </label>
            <input value={streetAddress} onChange={(e) => setStreetAddress(e.target.value)} />
          </div>
          <div className="w-full flex items-center my-3">
            <input
              type={"checkbox"}
              value={confirm}
              onChange={() => setConfirm(!confirm)}
              id="property-is-belongs-to-me"
              className="w-fit shadow-none accent-current bg-[#B0BABF]"
              required={true}
            />
            <label
              className="inline-block text-sm font-semibold ml-2"
              htmlFor="property-is-belongs-to-me"
            >
              {t("924")}
            </label>
          </div>
          {/* <div className="w-fit px-4 py-2 rounded-md capitalize border border-base-primary font-semibold mt-6 text-base-primary">
            {t("925")}
          </div> */}
          <div className="flex items-center mt-6 justify-between">
            <PreviousButton handleClick={goToPrev} />
            <NextButton />
          </div>
        </form>
      </div>
    </>
  );
};
export default PropertyApproval;
