import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoadSpinner from "../../components/common/loader/LoadSpinner";
import { ROUTES } from "../../constants";
import AuthEmailInput from "./AuthEmailInput";
import { useTranslation } from "react-i18next";
import { forgetPasswordSendOTPAction, forgetPasswordVerifyOTPAction } from "../../lib/slices/auth/authSlice";
import { formatTime } from "../../utils/dateAndTime";

const ForgotPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isOptSent, setIsOtpSent] = useState(false);
  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(0); // Timer set to 2 minutes (120 seconds)

  //sent email otp
  const sendOtp = () => {
    if (!emailError && email) {
      setLoading(true)
      forgetPasswordSendOTPAction({ email }).then((res) => {
        setIsOtpSent(true)
        setLoading(false)
        setTimer(120)
        // notifySuccess(res?.message)
      }).catch((error) => {
        // notifyError(error)
        setLoading(false)
      })
    };
  } 

  const handleOptSent = (e) => {
    e.preventDefault();
    sendOtp()
  }

  // verify email otp
  const handleVerifyEmailOTP = (e) => {
    e.preventDefault();
    if (email && otp) {
      setLoading(true)
      forgetPasswordVerifyOTPAction({ email, otp }).then((res) => {
        setLoading(false)
        navigate(ROUTES.PASSWORD_RESET)
      }).catch((error) => {
        setLoading(false)
        // notifyError(error)
      })
    };
  }

  useEffect(() => {
    if (timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => clearInterval(countdown); // Cleanup the interval
    }
  }, [timer]);


  return (
    <>
      <div className="w-full min-h-screen p-4 relative">
        <div className="w-[450px] mx-auto max-w-full rounded flex flex-col shadow-md my-8 bg-[#FFFFFF]">
          <div className="w-full pt-6">
            <h1 className="text-3xl font-medium text-center text-base-primary capitalize">
              {t("954")}
            </h1>
            <p className="text-[#616161] w-full text-center pt-2 px-4">
              {t("955")}
            </p>
          </div>
          <form
            className="w-full p-8 grid grid-cols-1 gap-4"
            onSubmit={!isOptSent ? handleOptSent : handleVerifyEmailOTP}
          >
            <AuthEmailInput
              email={email}
              setEmail={setEmail}
              emailError={emailError}
              setEmailError={setEmailError}
              readOnly={isOptSent}
            />
            {isOptSent ? <>
              <div className="w-full mb-6 relative sm:mb-8">
                <input
                  type="text"
                  name="otp"
                  value={otp}
                  maxLength={6}
                  minLength={6}
                  onChange={(e) => setOtp(e.target.value)}
                  className="w-full text-center outline-none border border-gray-300 py-3 focus:border-black rounded-3xl"
                  placeholder="Enter OTP"
                />
                <div className="mt-1">{timer ?
                  <p>Resend in {formatTime(timer)}</p> : <button disabled={timer} type="button" onClick={() => sendOtp()}>Resend</button>}
                </div>
              </div>
            </>
              : null}


            <button
              className="inline-block h-12 w-full bg-base-primary text-white rounded-3xl font-semibold py-0 disabled:bg-base-rgba_primary_7"
              disabled={emailError || loading || !email} type="submit"
            >
              {loading ? <LoadSpinner /> : <>{isOptSent ? "Verify Otp" : "Send otp"}</>}
            </button>
          </form>
          <div className="w-full text-center pb-6">
            <Link to={ROUTES.LOGIN} className="text-base-primary  ml-2">
              {t("957")}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
