import React from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { useDispatch, useSelector } from "react-redux";
import { googleAuthAction } from "../../lib/slices/auth/authSlice";
import { FaGoogle } from "react-icons/fa";

const ContinueWithGoogle = () => {
  const dispatch = useDispatch();
  const {loading} = useSelector((state)=>state.auth);

  // login with google response
  const responseSuccessGoogle = (res) => {
    if (res?.access_token) {
      dispatch(googleAuthAction({ access_token: res?.access_token }));
    }
  };

  //login with google failure
  const responseFailureGoogle = () => { };

  // new google login
  const login = useGoogleLogin({
    onSuccess: responseSuccessGoogle,
    onError: responseFailureGoogle,
  });
  return (
    <>
      <button type="button" onClick={() => login()} disabled={loading}
        className="flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >
        <FaGoogle className="w-5 h-5 mr-2 text-blue-500" />
        Continue with Google
      </button>
    </>
  );
};

export default ContinueWithGoogle;
