import React from "react";
import TabConfigButton from "../../components/buttons/TabConfigButton";

const RoomsHeader = ({ index, setIndex }) => {
  const roomHeader = [
    {
      index: 1,
      name: "Room Amenities",
    },
    {
      index: 2,
      name: "Room Mini Bar Items",
    },
    {
      index: 3,
      name: "Room Minibar List",
    },
    {
      index: 4,
      name: "All Rooms",
    },
    {
      index: 5,
      name: "Food Buffet Price",
    },
    {
      index: 6,
      name: "Room Rate",
    },
    {
      index: 7,
      name: "QR Code",
    },
    {
      index: 8,
      name: "Add Service",
    },
    {
      index: 9,
      name: "Package",
    },
  ];
  return (
    <div className="w-full flex flex-wrap items-center gap-4">
      {roomHeader?.map((header) => {
        const { index: id, name } = header;
        return (
          <TabConfigButton
            key={index}
            name={name} 
            buttonIndex={id}
            currentIndex={index}
            setIndex={setIndex}
          />
        );
      })}
    </div>
  );
};

export default RoomsHeader;
