import React from "react";
import { useDispatch } from "react-redux"; 
import { togglehamburger } from "../../lib/slices/toggleEvent";

const MenuItems = ({ options, setOptions, Icon, name, active, setActive }) => {
  const dispatch = useDispatch();
  const hamburgerOpen = () => {
    setOptions(options);
    setActive(name);
    dispatch(togglehamburger(true));
  };
  
  return (
    <button
      type="button"
      onClick={hamburgerOpen}
      className={`flex flex-col items-center justify-center w-[75px] min-h-[75px] rounded-lg capitalize border ${
        active ? "text-white bg-base-primary" : "text-[#000000] bg-transparent"
      }`}
    >
      <Icon className="size-[25px] mb-[5px]" />
      <div className="text-[10px] leading-[10px] font-medium">{name}</div>
    </button>
  );
};

export default MenuItems;
