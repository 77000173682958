import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux"; 
import ReportsCard from "./ReportsCard";
import lowestExpenseIcon from "../../images/icons/lowestExpenseIcon.svg";
import highestExpenseIcon from "../../images/icons/highestExpenseIcon.svg";
import totalExpenseIcon from "../../images/icons/totalExpenseIcon.svg";
import ExpenseTopCategory from "./ExpenseTopCategory";
import CustomeTable from "../../components/widget/CustomeTable";

const ExpenseReport = (props) => {
  // const { t } = useTranslation();
  const [datas, setDatas] = useState([]);
  const { sales } = useSelector((state) => state.SalesReport);
  const [selectedRows, setSelectedRows] = useState([]);
  useEffect(() => {
    props.getAssetsFromToDateAll(
      props?.hotel?._id,
      props?.fromDate,
      props?.toDate
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.fromDate, props?.toDate]);

  useEffect(() => {
    if (sales && Array.isArray(sales) && sales?.length) {
      let expense = sales?.map((d) => {
        return {
          expenseID: d?.expenseID,
          date: d?.date,
          expenseType: d?.expenseType,
          amount: d?.amount,
        };
      });
      setDatas(expense);
    }
  }, [sales]);

  const tableData = [
    {
      expenseID: "EX001",
      date: "2024-01-11",
      expenseType: "House Keeping",
      amount: "$1000",
    },
    {
      expenseID: "EX002",
      date: "2024-01-11",
      expenseType: "Payroll",
      amount: "$1000",
    },
    {
      expenseID: "EX003",
      date: "2024-01-11",
      expenseType: "Payroll",
      amount: "$1000",
    },
    {
      expenseID: "EX004",
      date: "2024-01-11",
      expenseType: "Payroll",
      amount: "$1000",
    },
    {
      expenseID: "EX005",
      date: "2024-01-11",
      expenseType: "Payroll",
      amount: "$1000",
    },
    {
      expenseID: "EX006",
      date: "2024-01-11",
      expenseType: "House Keeping",
      amount: "$1000",
    },
    {
      expenseID: "EX007",
      date: "2024-01-11",
      expenseType: "House Keeping",
      amount: "$1000",
    },
  ];
  const columnDefs = [
    {
      headerName: "Expense ID",
      field: "expenseID",
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Date",
      field: "date",
      filter: true,
    },
    {
      headerName: "Expense Type",
      field: "expenseType",
      filter: true,
    },
    {
      headerName: "Amount",
      field: "amount",
      filter: true,
    },
  ];

  return (
    <>
      <div className="page-container">
        <div className="my-4 grid grid-cols-3 items-center gap-3">
          <ReportsCard
            icon={totalExpenseIcon}
            iconBgColor={"bg-[#E8EBFF]"}
            heading={"Total Expense"}
            value={"1258"}
          />
          <ReportsCard
            icon={highestExpenseIcon}
            iconBgColor={"bg-[#FCEEDD]"}
            heading={"Highest Expense Category"}
            value={"Payroll"}
          />
          <ReportsCard
            icon={lowestExpenseIcon}
            iconBgColor={"bg-[#FCEAEA]"}
            heading={"Lowest Expense Category"}
            value={"House Keeping"}
          />
        </div>
        <div className="w-full flex items-center gap-4 mb-4">
          <div className="h-[444px] w-[65%] border border-[#E5E9EB] bg-white rounded-[15px] text-5xl flex items-center justify-center">
            Insert chart here
          </div>
          <div className="w-[35%] h-[444px] border border-[#E5E9EB] bg-white overflow-hidden rounded-[10px] pb-2">
            <ExpenseTopCategory />
          </div>
        </div>
        {/* {datas?.datas?.length ? ( */}
        <CustomeTable
          tableName={"Expense Report tabel"}
          columnDefs={columnDefs}
          rowData={tableData}
          setSelectedRows={setSelectedRows}
        />
        {/* ) : null} */}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({ 
});

const mapDispatchToProps = (dispatch) => ({ 
});

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseReport);
