import React from "react";
import FoodHeader from "./FoodHeader";
import { useParams } from "react-router-dom";
import FoodCategory from "./food-category";
import CreateFoodOrder from "./create-order";
import AmericanCuisine from "./food-category/AmericanCuisine";
import ThaiCuisine from "./food-category/ThaiCuisine";

const FoodOrder = (props) => {
  const { index } = useParams();

  return (
    <div className="flex">
      {/* left */}
      <div className="w-4/6 mr-[26px]">
        <FoodHeader params={index} />
        {/* content */}
        <div className="mt-4">
          {+index === 1 ? <FoodCategory /> : null}
          {+index === 2 ? <AmericanCuisine /> : null}
          {+index === 3 ? <ThaiCuisine /> : null}
        </div>
      </div>
      {/* right */}
      <div className="w-full">
        <CreateFoodOrder />
      </div>
    </div>
  );
};

export default FoodOrder;
