import React from "react";
import { Link } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import { useTranslation } from "react-i18next"; 

const RecentActivity = () => {
  let arr = ["a", 'b', 'c'];

  const { t } = useTranslation();

  return (
    <>
      <div >
        <div className="text-base font-bold text-[#18181B]">
          {t("20")}
        </div>
        <div className="text-[13px] leading-[22px] font-normal text-gray-500">
          {t("21")}
        </div>
      </div>
      <div className="my-3">
        {arr?.map((item, index) => (
          <div className="flex w-[251px] py-2 items-center " key={index}>
            <div className="w-9 h-9 min-w-[36px] rounded-full relative overflow-hidden">
              {t("22")}
              {/* <img
                src={item}
                className="h-full w-full absolute top-0 left-0 object-cover"
                alt=""
              /> */}
            </div>
            <div className="font-bold text-[13px] leading-[22px] pl-4">
              Jenny Wilson
              <div className="w-full h-[22px] text-[13px] leading-[22px] font-normal text-[#71717A]">
                {t("23")}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="relative">
        <Link
          to={"#"}
          className="font-bold text-[12px] tracking-[1px] text-normal leading-[18px] uppercase opacity-50 flex items-center"
        >
          {t("24")}
          <IoIosArrowForward size={14} style={{ marginLeft: ".5rem" }} />
        </Link>
      </div>
    </>
  );
};

export default RecentActivity;
