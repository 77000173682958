import React, { useState } from "react";
import SelectFromObject from "../../../components/form/SelectFromObject";

const OrderForTable = () => {
  const [tableNo, setTableNo] = useState([0]);
  const [orderAssignTo, setOrderAssignTo] = useState([0]);
  const TABLE_NO = {
    table:"Select Table",
    table01: "T001",
    table02: "T002",
    table03: "T003",
    table04: "T004",
    table05: "T005",
  };
  const ORDER_ASSIGN_TO= {
    table:"Order Assign To",
    table01: "Rakesh",
    table02: "sanjay",
    table03: "rahul",
    table04: "manish",
    table05: "ankit",
  };
  return (
    <div className="flex flex-col gap-4">
      <SelectFromObject
        value={tableNo}
        setValue={setTableNo}
        options={TABLE_NO}
      />
       <SelectFromObject
        value={orderAssignTo}
        setValue={setOrderAssignTo}
        options={ORDER_ASSIGN_TO}
      />
    </div>
  );
};

export default OrderForTable;
