import React, { useEffect, useState } from "react";
import { getAvailableRooms } from "../../utils";
import { useTranslation } from "react-i18next";
import DashboardCard from "../../components/common/card/DashboardCard";
import { FaCalendarAlt } from "react-icons/fa";
import { AiOutlineHistory } from "react-icons/ai";
import { HiUserGroup } from "react-icons/hi";
import { IoIosPeople } from "react-icons/io";
import { BiDoorOpen } from "react-icons/bi";
import { useSelector } from "react-redux";

const PropertyStatus = ({ bookings, date }) => {
  const { open } = useSelector((state) => state.hamburger)
  const [totalBookings, setTotalBookings] = useState(0);
  const [totalCheckIn, setTotalCheckIn] = useState(0);
  const [totalCheckOut, setTotalCheckOut] = useState(0);
  const [totalAvailableRooms, setTotalAvailableRooms] = useState(0);
  const [totalCheckOutPending, setTotalCheckOutPending] = useState(0);
  const { t } = useTranslation();

  // const { hamburger } = useSelector((state) => state.Hamburger);

  //computing data
  const computingData = async () => {
    let books = 0;
    let checkIn = 0;
    let checkOut = 0;
    let checkOutPending = 0;

    bookings &&
      bookings.length &&
      bookings.map((booking) => {
        // bookings count
        if (booking.bookingDate) {
          let newDate = booking.bookingDate.slice(0, 10);
          if (newDate === date) {
            books += 1;
          }
        }

        //checkin count
        if (booking.checkInDate) {
          let newDate = booking.checkInDate.slice(0, 10);
          if (newDate === date) {
            checkIn += 1;
          }
        }

        //checkOut count
        if (booking.checkOutDate) {
          let newDate = booking.checkOutDate.slice(0, 10);
          if (newDate === date) {
            checkOut += 1;
          }
        }

        //checkout pending
        if (booking.checkOutDate && booking.checkInDate) {
          let newCheckIn = booking.checkInDate.slice(0, 10);
          let newCheckout = booking.checkOutDate.slice(0, 10);
          if (newCheckIn < date && newCheckout >= date) {
            checkOutPending += 1;
          }
        }
      });

    setTotalBookings(totalBookings);
    setTotalCheckIn(totalCheckIn);
    setTotalCheckOut(totalCheckOut);
    setTotalCheckOutPending(totalCheckOutPending);
    const rooms = await getAvailableRooms();
    setTotalAvailableRooms(rooms.length);
  };

  useEffect(() => {
    computingData();
  }, [bookings, date]);

  return (
    <div
      className={`
        ${open ? "lg:grid-cols-3" : "lg:grid-cols-4"
        } 
      grid grid-cols-1 font-pjs sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4`}
    >
      <DashboardCard
        name={t("5")}
        Icon={IoIosPeople}
        percantage={"+36"}
        totalCounts={"12,426"}
      />
      <DashboardCard
        name={t("6")}
        Icon={BiDoorOpen}
        percantage={"-16"}
        totalCounts={"3,585"}
      />
      <DashboardCard
        name={t("7")}
        Icon={FaCalendarAlt}
        percantage={"+31"}
        totalCounts={"14,382"}
      />
      <DashboardCard
        name={t("8")}
        Icon={FaCalendarAlt}
        percantage={"+31"}
        totalCounts={"1,426"}
      />
      <DashboardCard
        name={t("9")}
        Icon={FaCalendarAlt}
        percantage={"-31"}
        totalCounts={"2,485"}
      />
      <DashboardCard
        name={t("10")}
        Icon={AiOutlineHistory}
        percantage={"+43"}
        totalCounts={" 4,382"}
      />
      <DashboardCard
        name={t("11")}
        Icon={FaCalendarAlt}
        percantage={"-31"}
        totalCounts={"4,382"}
      />
      <DashboardCard
        name={t("835")}
        Icon={HiUserGroup}
        percantage={"-31"}
        totalCounts={"12,426"}
      />
    </div>
  );
};

export default PropertyStatus;
