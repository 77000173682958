import { HOTEL_ROOM_DETAILS } from "../constants";

//get all available rooms according to checkin and checkout date
//return array of room numbers
let rooms = []; //@global rooms
//rooms is object of -> { roomNumber, roomType, adults, children }

export const getAvailableRooms = async () => {
  const data = sessionStorage.getItem(HOTEL_ROOM_DETAILS);
  if(data) {
    const parsedData = JSON.parse(data);
    return Object.keys(parsedData);
  }

  return [];
};

//getting maxAdults and maxChildren in a room
export const getAdultsAndChildrenInRoom = async (roomNo) => {
  const data = rooms[roomNo];
  return [data?.adults, data?.children];
};


