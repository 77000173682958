import React from "react";
// import Seperator from "../../components/layouts/Seperator";
import { useTranslation } from "react-i18next"; 
import { useState } from "react";
import { getToday } from "../../utils";
import { IoIosArrowDown } from "react-icons/io";

const FilterHeader = ({ month, setMonth }) => {
  const { t } = useTranslation();
  const [date, setDate] = useState(getToday())
  const headingData = {
    heading: t("68"),
    seperator: [
      {
        text: t("75"),
      },
      {
        text: t("31"),
      },
    ],
  };

  return (
    <>
      <div className="w-full flex items-center justify-between mx-auto mb-6">
        {/* <Seperator data={headingData} /> */}
        <div className="flex gap-2">
          <div>            
          </div>
          <div className="">
            <button className=" w-[129px] h-10 border rounded-md text-[#18181B] bg-white px-6">
             <div className="flex justify-between items-center">
              <div className="text-[14px] leading-[17.64px] tracking-[-0.8px] font-normal">All Hotels</div>
                <IoIosArrowDown/>
             </div>
            </button>
          </div>
          <div className="flex w-[308px] h-10 border rounded-[6px] box-border flex-row justify-center items-center gap-[14px] p-3 bg-white text-[#18181B]">
            <input
              value={date}
              onChange={(e) => setDate(e.target.value)}
              type={"date"}
              className="flex w-1/2 my-[10px] bg-white items-center border-none shadow-none rounded h-5 font-normal cursor-pointer select-none text-[14px] leading-[18px] tracking-[-0.8px]"
            />
            <input
              value={date}
              onChange={(e) => setDate(e.target.value)}
              type={"date"}
              className="flex w-1/2 my-[10px] bg-white items-center border-none shadow-none rounded h-5 font-normal cursor-pointer select-none text-[14px] leading-[18px] tracking-[-0.8px]"
            />
          </div> 
        </div>
      </div>
    </>
  );
};

export default FilterHeader;
