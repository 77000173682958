import React, { useEffect, useState } from "react"; 
import { useSelector } from "react-redux";
import PageLoader from "../../components/common/loader/PageLoader"; 
import { ONBOARDING_STAGES, ROUTES } from "../../constants"; 
import OnboardAddHotel from "./OnboardAddHotel";
import OnboardContact from "./OnboardContact";
import PropertyApproval from "./PropertyApproval";
import GetStarted from "./GetStarted";
import { useNavigate } from "react-router-dom";

const OnboardingPages = () => { 
  const navigate = useNavigate();
  const [step, setStep] = useState(0);

  const { loading } = useSelector((state) => state.user);
  const { hotel } = useSelector((state) => state.hotel);

  useEffect(() => {
    if ( hotel && hotel._id) {
      if (hotel?.stage === ONBOARDING_STAGES.PRE_ONBOARDING) setStep(1);
      if (hotel?.stage === ONBOARDING_STAGES.ONBOARDING) setStep(2);
      if (hotel?.stage === ONBOARDING_STAGES.CONTACT) setStep(3);
      if (hotel?.stage === ONBOARDING_STAGES.DASHBOARD) {
        navigate(ROUTES.DASHBOARD)
      }
    }
  }, [hotel]);


  return (
    <>
      <div className="relative min-h-[100vh-4rem]">
        {loading ? (
          <PageLoader />
        ) : (
          <div className="relative mx-auto bg-white w-full h-full">
            {step === 0 ? <GetStarted setStep={setStep} /> :
              <div className="flex items-center justify-center w-3/5 mx-auto">
                {step === 1 ? <OnboardAddHotel setStep={setStep} /> : null}
                {step === 2 ? <OnboardContact /> : null}
                {step === 3 ? <PropertyApproval /> : null}
                {/* {hotel?.stage === ONBOARDING_STAGES.APPROVEL_PENDING ? <ApprovalPending /> : null} */}
              </div>}
          </div>
        )}
      </div>
    </>
  );
};

export default OnboardingPages;
