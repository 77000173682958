import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { TOKEN } from "../../../constants";

//  employee update profile action
export const updateHotelEmployeeAction = createAsyncThunk(
  "employeeProfile/updateHotelEmployeeAction",
  async (credentials, thunkAPI) => {
    try {
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/employee/update`;
      const response = await axios.put(url, credentials);
      localStorage.setItem(TOKEN, response.data.token);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);
//employee update profile slice
export const updateHotelEmployeeSlice = createSlice({
  name: "employeeProfile/updateHotelEmployeeSlice",
  initialState: {
    loading: true,
    isupdated: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateHotelEmployeeAction.pending, (state) => {
        state.loading = true;
        state.isupdated = false;
        state.error = null;
      })
      .addCase(updateHotelEmployeeAction.fulfilled, (state, action) => {
        state.loading = false;
        state.isupdated = true;
        state.token = action.payload.token;
      })
      .addCase(updateHotelEmployeeAction.rejected, (state, action) => {
        state.loading = false;
        state.isupdated = false;
        state.error =
          action.payload?.message || "Failed to update hotel employee";
      });
  },
});

// employee delete profile action
export const deleteHotelEmployeeProfileAction = createAsyncThunk(
  "employeeProfile/deleteHotelEmployeeProfileAction",
  async ({ employeeId, token }, thunkAPI) => {
    try {
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/employee/${employeeId}`;
      const response = await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// employee delete profile slice
export const deleteHotelEmployeeProfileSlice = createSlice({
  name: "employeeProfile/deleteHotelEmployeeProfileSlice",
  initialState: {
    loading: true,
    isdeleted: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateHotelEmployeeAction.pending, (state) => {
        state.loading = true;
        state.isdeleted = false;
        state.error = null;
      })
      .addCase(updateHotelEmployeeAction.fulfilled, (state, action) => {
        state.loading = false;
        state.isdeleted = true;
        state.token = action.payload.token;
      })
      .addCase(updateHotelEmployeeAction.rejected, (state, action) => {
        state.loading = false;
        state.isdeleted = false;
        state.error =
          action.payload?.message || "Failed to delete hotel employee";
      });
  },
});
