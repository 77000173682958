import React, { useState } from "react";
import Cheque from "./Cheque";
import CloseButton from "../../../components/buttons/CloseButton";
import ConfigCorporateWallet from "./ConfigCorporateWallet";
import ConfigTravelAgent from "./ConfigTravelAgent";
import Cash from "./Cash";
import Pos from "./Pos";

const AddPaymentMode = (props) => {
  const [index, setIndex] = useState(1);
  const sideMenu = [
    { id: 1, name: "Cheque" },
    { id: 2, name: "POS Payment" },
    { id: 3, name: "Corporate Wallet" },
    { id: 4, name: "Travel Agent Wallet" },
    { id: 5, name: "Payment Gateway" },
    { id: 6, name: "Cash" },
    { id: 7, name: "Online Payment" },
  ];

  const handleSideMenu = (id) => {
    setIndex(id);
  };

  return (
    <div className="w-full flex gap-0 border border-[#D0D5DD] rounded-[10px] bg-white overflow-hidden mt-6">
      {/* left side--sidebar */}
      <div className="w-1/4 border-r border-[#D0D5DD]">
        {sideMenu.map((menu) => {
          const { name, id } = menu;
          return (
            <button
              type="button"
              key={id}
              className={`h-20 w-full border-b border-[#D0D5DD] flex items-center justify-start pl-4 last:border-b-0 text-[16px] leading-[20.16px] font-medium text-[#000000] hover:bg-base-primary hover:text-white duration-300 ease-[0.3s] ${
                index === id ? "bg-base-primary text-white" : ""
              }`}
              onClick={() => handleSideMenu(id)}
            >
              {id}. {name}
            </button>
          );
        })}
        <button type="button" className="h-20 px-4 w-full text-base-primary bg-white hover:bg-base-primary hover:text-white duration-300 ease-[0.3s] ">
        Add New Payment Method
        </button>
      </div>
      {/* right side--content */}
      <div className="w-full">
        <div className="flex justify-end pr-4 pt-4">
          <CloseButton onClick={props.close} />
        </div>
        {index === 1 ? <Cheque /> : null}
        {index === 2 ? <Pos/> : null}
        {index === 3 ? <ConfigCorporateWallet/> :null}
        {index === 4 ? <ConfigTravelAgent/> :null}
        {index === 5 ? <div>Payment Gateway screen Missing</div> :null}
        {index === 6 ? <Cash/> :null}
      </div>
    </div>
  );
};

export default AddPaymentMode;
