import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoadSpinner from "../../components/common/loader/LoadSpinner";
import { Link } from "react-router-dom";
import { ROUTES } from "../../constants";
import AuthNameInput from "./AuthNameInput";
import AuthPasswordInput from "./AuthPasswordInput";
import AuthEmailInput from "./AuthEmailInput";
import ContinueWithGoogle from "../../components/common/ContinueWithGoogle";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ownerRegisterAction } from "../../lib/slices/auth/authSlice";

const SignupPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  //validation error
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const { isAuthenticated, loading } = useSelector((state) => state.auth);

  //submiting the form input data.
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !nameError &&
      !emailError &&
      !passwordError &&
      name &&
      email &&
      password
    ) {
      const formData = {
        name,
        email,
        password,
      };
      localStorage.clear();
      sessionStorage.clear();
      dispatch(ownerRegisterAction(formData));
    }
  };

  //redirecting the user after login
  useEffect(() => {
    if (isAuthenticated) {
      navigate(ROUTES.DASHBOARD);
    }
  }, [isAuthenticated]);

  return (
    <>
      <div className="min-h-screen p-4 relative flex flex-col justify-center">
        <div className="w-[450px] mx-auto max-w-full rounded-lg flex flex-col border my-8 bg-[#FFFFFF]">
          <div className="w-full p-6">
            <h1 className="text-3xl font-medium text-center text-base-primary">
              {t("803")}
            </h1>
          </div>
          <form
            className="w-full px-8 grid grid-cols-1 gap-4"
            onSubmit={handleSubmit}
          >
            <AuthNameInput
              name={name}
              setName={setName}
              nameError={nameError}
              setNameError={setNameError}
            />
            <AuthEmailInput
              email={email}
              setEmail={setEmail}
              setEmailError={setEmailError}
              emailError={emailError}
            />

            <AuthPasswordInput
              password={password}
              setPassword={setPassword}
              passwordError={passwordError}
              setPasswordError={setPasswordError}
            />

            <button
              className="inline-block h-12 w-full bg-base-primary text-[#FFFFFF] rounded-3xl font-medium disabled:bg-base-rgba_primary_8"
              disabled={loading}
            >
              {loading ? <LoadSpinner text={t("798")} /> : <>{t("803")}</>}
            </button>
          </form>
          <div className="w-full flex flex-col items-center relative p-8 gap-4">
            <div className="flex w-full items-center">
              <span className="border-b flex border-[#D0D5DD] w-full"></span>
              <p className="whitespace-nowrap mx-2">{t("800")}</p>
              <span className="border-b flex border-[#D0D5DD] w-full"></span>
            </div>
            <ContinueWithGoogle />
            <div className="w-full text-center">
              {t("804")}
              <Link to={ROUTES.LOGIN} className="text-blue-500 ml-2">
                {t("805")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignupPage;
