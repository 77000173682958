import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { TOKEN } from "../../../constants";
import authAxios from "../../services/authAxios";

// Base URL
const BASE_URL = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/employee/booking`;

// Thunk action creators || getAllBookingAction
export const getAllBookingAction = createAsyncThunk(
  "booking/getAllBooking",
  async (hotelId, thunkAPI) => {
    try {
      let url = BASE_URL;
      if (hotelId) {
        url += `?hotelId=${hotelId}`;
      }
      const response = await authAxios.get(url);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// getBookingByStatusAction
export const getBookingByStatusAction = createAsyncThunk(
  "booking/getBookingByStatus",
  async ({ credentials, status, hotelId }, thunkAPI) => {
    try {
      let url = `${BASE_URL}/findByStatus?status=${status}`;
      if (hotelId) {
        url += `&hotelId=${hotelId}`;
      }
      const response = await axios.get(url, { headers: credentials });
      localStorage.setItem(TOKEN, response.data.token);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// getBookingByIdAction
export const getBookingByIdAction = createAsyncThunk(
  "booking/getBookingById",
  async ({ credentials, bookingId }, thunkAPI) => {
    try {
      let url = `${BASE_URL}?bookingId=${bookingId}`;
      const response = await axios.get(url, { headers: credentials });
      localStorage.setItem(TOKEN, response.data.token);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// getBookingByPhoneNoAction
export const getBookingByPhoneNoAction = createAsyncThunk(
  "booking/getBookingByPhoneNo",
  async ({ credentials, phone }, thunkAPI) => {
    try {
      let url = `${BASE_URL}?phone=${phone}`;
      const response = await axios.get(url, { headers: credentials });
      localStorage.setItem(TOKEN, response.data.token);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// getBookingByCustomerNameAction
export const getBookingByCustomerNameAction = createAsyncThunk(
  "booking/getBookingByCustomerName",
  async ({ credentials, name }, thunkAPI) => {
    try {
      let url = `${BASE_URL}?customerName=${name}`;
      const response = await axios.get(url, { headers: credentials });
      localStorage.setItem(TOKEN, response.data.token);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// Helper function for handling pending state
const handlePending = (state) => {
  state.loading = true;
  state.error = null;
};

// Helper function for handling fulfilled state
const handleFulfilled = (state, action) => {
  state.loading = false;
  state.bookings = action.payload.bookings;
};

// Helper function for handling rejected state
const handleRejected = (state, action) => {
  state.loading = false;
  state.error = action.payload || "An error occurred";
};

// Combined  booking slice
export const getBookingByCondionsSlice = createSlice({
  name: "booking",
  initialState: {
    loading: false,
    bookings: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllBookingAction.pending, handlePending)
      .addCase(getAllBookingAction.fulfilled, handleFulfilled)
      .addCase(getAllBookingAction.rejected, handleRejected)

      .addCase(getBookingByStatusAction.pending, handlePending)
      .addCase(getBookingByStatusAction.fulfilled, handleFulfilled)
      .addCase(getBookingByStatusAction.rejected, handleRejected)

      .addCase(getBookingByIdAction.pending, handlePending)
      .addCase(getBookingByIdAction.fulfilled, handleFulfilled)
      .addCase(getBookingByIdAction.rejected, handleRejected)

      .addCase(getBookingByPhoneNoAction.pending, handlePending)
      .addCase(getBookingByPhoneNoAction.fulfilled, handleFulfilled)
      .addCase(getBookingByPhoneNoAction.rejected, handleRejected)

      .addCase(getBookingByCustomerNameAction.pending, handlePending)
      .addCase(getBookingByCustomerNameAction.fulfilled, handleFulfilled)
      .addCase(getBookingByCustomerNameAction.rejected, handleRejected);
  },
});
