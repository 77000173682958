import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux"; 
import CustomeTable from "../../components/widget/CustomeTable";

const SecurityReports = (props) => {
  // const { t } = useTranslation();
  const [datas, setDatas] = useState([]);
  const { sales } = useSelector((state) => state.SalesReport);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    props.getAssetsFromToDateAll(
      props?.hotel?._id,
      props?.fromDate,
      props?.toDate
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.fromDate, props?.toDate]);

  useEffect(() => {
    if (sales && Array.isArray(sales) && sales?.length) {
      let expense = sales?.map((d) => {
        return {
          date: d?.date,
          location: d?.location,
          time: d?.time,
          type: d?.type,
          officerName: d?.officerName,
        };
      });
      setDatas(expense);
    }
  }, [sales]);

  const tableData = [
    {
      id: "SC001",
      date: "24-01-12",
      location: "Parking Lot",
      time: "1:00 pm",
      type: "Security clearance",
      officerName: "John Doe",
    },
    {
      id: "SC002",
      date: "24-01-12",
      location: "Parking Lot",
      time: "1:00 pm",
      type: "Security clearance",
      officerName: "John Doe",
    },
    {
      id: "SC003",
      date: "24-01-12",
      location: "Parking Lot",
      time: "1:00 pm",
      type: "Security clearance",
      officerName: "John Doe",
    },
    {
      id: "SC004",
      date: "24-01-12",
      location: "Parking Lot",
      time: "1:00 pm",
      type: "Security clearance",
      officerName: "John Doe",
    },
    {
      id: "SC005",
      date: "24-01-12",
      location: "Parking Lot",
      time: "1:00 pm",
      type: "Security clearance",
      officerName: "John Doe",
    },
  ];

  const columnDefs = [
    {
      headerName: "Security ID",
      field: "id",
      minWidth: 200,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Date",
      field: "date",
      filter: true,
    },
    {
      headerName: "Location",
      field: "location",
      filter: true,
    },
    {
      headerName: "Time",
      field: "time",
      filter: true,
    },
    {
      headerName: "Type",
      field: "type",
      filter: true,
    },
    {
      headerName: "Officer Name",
      field: "officerName",
      filter: true,
    },
  ];

  return (
    <div className="page-container">
      {/* {datas?.datas?.length ? ( */}
      <CustomeTable
        tableName={"Security Report Table"}
        columnDefs={columnDefs}
        rowData={tableData}
        setSelectedRows={setSelectedRows}
      />
      {/* ) : null} */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: state.AssetsReport,
});

const mapDispatchToProps = (dispatch) => ({ 
});

export default connect(mapStateToProps, mapDispatchToProps)(SecurityReports);
