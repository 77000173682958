import React, { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import GridHeader from "./GridHeader";
import { HOTEL_ROOM_DETAILS, ROOM_TYPES } from "../../constants";
import PageLoader from "../../components/common/loader/PageLoader"; 
import RoomAvailable from "./RoomAvailable";
import RoomStatus from "./RoomStatus";
import FilterHeader from "./FilterHeader";
import RoomType from "./RoomType";

const ReservationGrid = (props) => {
  const [month, setMonth] = useState(new Date().getMonth());

  const [bookings, setBookings] = useState([]); 

  const [dates, setDates] = useState([]);
  const [loading, setLoading] = useState(true); 
  const [roomTypes, setRoomTypes] = useState([])

  //get number of days in a month
  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  //create an array of dates.
  const getMonthDates = (year, month) => {
    const days = getDaysInMonth(year, month);
    const monthDates = Array(days)
      .fill(0)
      .map((date, index) => {
        const currentDate = new Date(year, month, 1);
        currentDate.setDate(currentDate.getDate() + index);
        return new Date(currentDate);
      });
    setDates(monthDates);
  };

  //set times
  useEffect(() => {
    const date = new Date();
    let year = date.getFullYear();
    const thisMonth = date.getMonth();

    if (month === thisMonth) {
      // console.log("same month", month, year);
    } else if (month < thisMonth) {
      if (thisMonth - month >= 6) {
        //  goto next year starting months
        year = year + 1;
      }
      // console.log("Previous month", month, year);
    } else if (month > thisMonth) {
      if (month - thisMonth > 6) {
        //  goto previous year ending months
        year = year - 1;
      }
      // console.log("Future month", month, year);
    }
    getMonthDates(year, month);
  }, [month]);

  const getBookedRooms = async () => {
    const bookedRooms = await JSON.parse(
      sessionStorage.getItem(HOTEL_ROOM_DETAILS)
    );

    const roomType = await JSON.parse(sessionStorage.getItem(ROOM_TYPES));
    setRoomTypes(roomType)
    setBookings(bookedRooms); 
    setLoading(false);
  };

  useEffect(() => {
    getBookedRooms();
  }, []);



  return (
    <>
      {(props?.bookings && props?.bookings?.loading) || loading ? (
        <PageLoader />
      ) : (
        <div className="page-container">
          <div className="flex flex-col overflow-auto">
            <FilterHeader month={month} setMonth={setMonth} />
            <div className="border border-t-0 border-b-0 rounded-t-lg overflow-hidden bg-white">
              <GridHeader dates={dates} month={month} setMonth={setMonth} />
              {roomTypes?.map((room, index) => (
                <RoomType
                  key={index}
                  bookings={bookings}
                  days={dates} 
                  roomType={room} 
                />
              ))}
              <RoomAvailable datas={dates} />
            </div>
            <div className="bg-white rounded-b-lg border">
              <RoomStatus />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  hotel: state.Hotel,
});

const mapDispatchToProps = (dispatch) => ({ 
});

export default connect(mapStateToProps, mapDispatchToProps)(ReservationGrid);
