import React from "react";
import InputButton from "../InputButton";
import OutlineButton from "../../../components/buttons/OutlineButton";
import SubmitButton from "../../../components/buttons/SubmitButton";

const Cheque = () => {
  return (
    <div className="px-8 pb-8">
      <div className="text-[24px] leading-6 font-semibold">
        Payment Mode Configuration Of Cheque
      </div>
      <div className="flex flex-col gap-12 mt-10">
        <div className="w-full flex gap-4 items-end justify-between">
          <div className="w-2/3">
            <InputButton label={"Employee Authorize to Receive Cheque"} />
          </div>
          <OutlineButton sizing={"h-12"} text={"Authorise Employees List"} />
        </div>
        <div className="w-full flex gap-4 items-end justify-between">
          <div className="w-2/3">
            <InputButton
              label={"Employee Authorize to Depositing Cheque at Bank"}
            />
          </div>
          <OutlineButton sizing={"h-12"} text={"Authorise Employees List"} />
        </div>
        <div className="w-full flex gap-4 items-end justify-between">
          <div className="w-2/3">
            <InputButton
              label={"Employee Authorize Verify Payment Received By Cheque"}
            />
          </div>
          <OutlineButton sizing={"h-12"} text={"Authorise Employees List"} />
        </div>
        <div className="flex justify-end"><SubmitButton sizing={"h-12 w-[183px]"} text={"Save"}/></div>
      </div>
    </div>
  );
};

export default Cheque;
