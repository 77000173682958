import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

// bookings occupency report By month action
export const bookingsOccupencyReportByMonthAction = createAsyncThunk(
  "reports/bookingsOccupencyReportByMonthAction",
  async (credentials, thunkAPI) => {
    try {
      const { hotelId, fromData, toMonth } = credentials || {};
      if (!hotelId || fromData || !toMonth) return;
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/reports/bookings/occupancy/by-month`;
      const response = await axios.post(url, credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

//bookings occupency report by month slice
export const bookingsOccupencyReportByMonthSlice = createSlice({
  name: "reports/bookingsOccupencyReportByMonthSlice",
  initialState: {
    loading: false,
    occupancies: [],
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(bookingsOccupencyReportByMonthAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        bookingsOccupencyReportByMonthAction.fulfilled,
        (state, action) => {
          state.loading = false;
          state.occupancies = action.payload.occupancies;
        }
      )
      .addCase(
        bookingsOccupencyReportByMonthAction.rejected,
        (state, action) => {
          state.loading = false;
          state.occupancies = null;
          state.error =
            action.payload || "Failed to bookings occupeny Report By month";
        }
      );
  },
});
