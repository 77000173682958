import React from "react";
import SubmitButton from "../../components/buttons/SubmitButton";
import OutlineButton from "../../components/buttons/OutlineButton";

const RoleName = () => {
  return (
    <div className="border border-[#D0D3DD] rounded-md flex flex-col items-center justify-center gap-4 bg-white text-[#344054] px-4 py-8">
      <div className="text-[20px] leading-6 font-semibold  uppercase">
        Role Name
      </div>
      <div className="text-base mb-2">{`( Hr Manager )`}</div>
      <SubmitButton type="button" text={"Listed Employees"} sizing="w-full h-10"/>
      <OutlineButton text={"Add Employees"} sizing="w-full h-10"/>
      <button
        type="button"
        className="h-10 w-full border border-[#FF0004] rounded-md text-[12px] leading-5 font-medium text-[#FF0004] hover:text-white hover:bg-[#FF0004] duration-300 ease-[0.3s] shadow-[0_1px_2px_0_rgba(16,24,40,0.05)]"
      >
        Remove Employee
      </button>
    </div>
  );
};

export default RoleName;
