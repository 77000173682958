import React, { useState } from "react";
import { FiArrowLeft, } from "react-icons/fi";
import { useSelector } from "react-redux";
import LoadSpinner from "../../components/common/loader/LoadSpinner";

const PreviousButton = ({ handleClick = () => { } }) => {
    const { loading } = useSelector((state) => state.hotel);
    const [clicked, setClicked] = useState(false);

    const goToPreviousPage = () => {
        handleClick()
        setClicked(true)
    }

    return (
        <>
            <button
                className="w-[120px] h-12 flex items-center justify-center gap-2 rounded-md hover:bg-gray-200 bg-[#FFFFFF] border border-[#E5E9EB] text-[#252C32] capitalize"
                onClick={goToPreviousPage}
                disabled={loading}
                type="button"
            >
                {loading && clicked ? (
                    <LoadSpinner />
                ) : (
                    <>
                        <FiArrowLeft size={18} />
                        Previous
                    </>
                )}
            </button>
        </>
    );
};
export default PreviousButton;
