import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

// get All Bookings sales Report Action
export const getAllBookingsSalesReportAction = createAsyncThunk(
  "reports/getAllBookingsSalesReportAction",
  async (credentials, thunkAPI) => {
    try {
      const { hotelId } = credentials || {};
      if (!hotelId) return;
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/reports/bookings/occupancy/all`;
      const response = await axios.post(url, credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

//get get All Bookings sales Report Slice
export const getAllBookingsSalesReportSlice = createSlice({
  name: "reports/getAllBookingsSalesReportSlice",
  initialState: {
    loading: false,
    sales: [],
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllBookingsSalesReportAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllBookingsSalesReportAction.fulfilled, (state, action) => {
        state.loading = false;
        state.sales = action.payload.sales;
      })
      .addCase(getAllBookingsSalesReportAction.rejected, (state, action) => {
        state.loading = false;
        state.sales = null;
        state.error =
          action.payload || "Failed to get All Bookings Sales Report";
      });
  },
});
