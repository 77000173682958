import React from "react";
import Inputfield from "../form/InputField";
import InputTextArea from "../form/InputTextArea"; 
import { MdOutlineManageAccounts } from "react-icons/md";
import { TfiLocationPin } from "react-icons/tfi";
import CustomModal from "./CustomModal";
import SubmitButton from "../buttons/SubmitButton";

const CreateShiftSwap = ({
  shiftDate,
  setShiftDate,
  time,
  setTime,
  employeeName,
  setEmployeeName,
  role,
  setRole,
  branch,
  setBranch,
  swapWith,
  setSwapWith,
  reason = "Adam Smith I need to attend my brothers wedding on 21'st I am travelling to Vegas with my family for 2 days.",
  setReason,
  close = () => {},
}) => {
  return (
    <CustomModal close={close} heading={"Create Swap"}>
      <div className="h-[72vh] w-[45vw]">
        <div className="grid grid-cols-2 items-center gap-4 mt-6">
          <Inputfield
            label={"Shift Date"}
            type="date"
            value={shiftDate}
            onChange={(e) => setShiftDate(e.target.value)}
            placeholder={"MM/DD/YY"}
            minLength={3}
            maxLength={50}
          />

          <Inputfield
            label={"Time"}
            type="time"
            value={time}
            onChange={(e) => setTime(e.target.value)}
            placeholder={"11:00am - 6:00pm"}
            minLength={3}
            maxLength={50}
          />

          {/* <Inputfield
            label={"Employee"}
            value={employeeName}
            onChange={(e) => setEmployeeName(e.target.value)}
            placeholder={"Enter Name"}
            minLength={3}
            maxLength={50}
            icon={<LuUser2 size={17} />}
          /> */}

          <Inputfield
            label={"Role"}
            value={role}
            onChange={(e) => setRole(e.target.value)}
            placeholder={"Manager"}
            minLength={3}
            maxLength={50}
            icon={<MdOutlineManageAccounts size={17} />}
          />
          <Inputfield
            label={"Where"}
            value={branch}
            onChange={(e) => setBranch(e.target.value)}
            placeholder={"Main Branch"}
            minLength={3}
            maxLength={50}
            icon={<TfiLocationPin size={17} />}
          />
          {/* <Inputfield
            label={"Swap with"}
            value={swapWith}
            onChange={(e) => setSwapWith(e.target.value)}
            placeholder={"Main Branch"}
            minLength={3}
            maxLength={50}
            icon={<LuUser2 size={17} />}
          /> */}
        </div>
        <div className="my-4">
          <InputTextArea
            rows={4}
            label={"Reason"}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            placeholder="Type Your Reason here..."
          />
        </div>
        <SubmitButton
          type="button"
          text={"Request Swap"}
          className={"h-12 w-full"}
          onClick={""}
        />
      </div>
    </CustomModal>
  );
};

export default CreateShiftSwap;
