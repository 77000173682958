import React from "react";
import { useTranslation } from "react-i18next";
import { FaArrowRight } from "react-icons/fa6";
import onboardImage from '../../assets/images/onboardingimage.png'

const GetStarted = ({ setStep }) => {
  const { t } = useTranslation();
  const completeStep = () => {
    setStep(1);
  };

  return (
    <div className={`min-h w-full relative grid grid-cols-2 items-center`}>
      <div className="flex flex-col justify-center items-start px-4">
        <div className="capitalize text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold break-words">
          <div className="">{t("900")}</div>
          <span className="text-base-primary pr-2">{t("929")}</span>
          {t("901")}
        </div>
        <div className="flex items-center text-[14px] text-[#252C32] leading-5 font-medium gap-4 mt-6">
          <button
            type="button"
            className="bg-base-primary hover:bg-base-primary_hover flex items-center gap-3 px-4 h-10 rounded-md text-white duration-300 ease-[0.3s]"
            onClick={completeStep}
          >
            {t("902")}
            <FaArrowRight />
          </button>
        </div>
      </div>
      <div className="w-full h-full relative">

      <img
        src={onboardImage}
        alt=""
        className="absolute top-0 left-0 h-full w-full object-cover"
      />
    </div>
    </div>
  );
};

export default GetStarted;
