
import Dashboard from "../pages/dashboard";
import CustomersList from "../pages/customers";
import ReservationGrid from "../pages/reservation";
import Support from "../pages/support/Support";
import FoodMenu from "../pages/food";
import AllHotel from "../pages/hotel"; 
import AllReviews from "../pages/reviews";
import Profile from "../pages/user/Profile";
import Reports from "../pages/reports";
import ChangePassword from "../pages/user/ChangePassword";
import RoomTypes from "../pages/room";
import Kitchen from "../pages/kitchen";
import HouseKeeping from "../pages/housekeeping";
import EmployeePage from "../pages/employee";
import AssetsPage from "../pages/assets";
import ExpensePage from "../pages/expense";
import InventoryPage from "../pages/inventory";
import MenuItemPage from "../pages/kitchen-menu";
import VendorPage from "../pages/vendor";
import BookingPage from "../pages/booking";
import Settings from "../pages/settings";
import HotelInventory from "../pages/inventory/HotelInventory";
import CustomerDetails from "../pages/customers/CustomerDetails";
import BookingDetails from "../pages/booking/BookingDetails";
import BuffetPage from "../pages/kitchen/BuffetPage";
import AccessControl from "../pages/access/AccessControl";
import MapIngredients from "../pages/kitchen-menu/MapIngredients";
import CreateBuffetMenu from "../pages/kitchen/CreateBuffetMenu";
import FAndBDetails from "../pages/kitchen-menu/FAndBDetails";
import CheckOutTomarrow from "../pages/housekeeping/CheckOutTomarrow";
import RoomServiceRequest from "../pages/housekeeping/RoomServiceRequest";
import VendorDetails from "../pages/vendor/VendorDetails";
import VendorHistory from "../pages/vendor/VendorHistory";
import CancelBooking from "../pages/booking/CancelBooking";
import IssueReported from "../pages/housekeeping/IssueReported";
import HousekeepingAmenities from "../pages/housekeeping/HousekeepingAmenities";
import AlaCarte from "../pages/kitchen-menu/AlaCarte";
import PurchaseOrder from "../pages/vendor/PurchaseOrder";
import RoasterManagement from "../pages/roaster";
import BuffetDetails from "../pages/kitchen/BuffetDetails";
import CheckInventoryDetails from "../pages/kitchen/CheckInventoryDetails";
import KitchenOperations from "../pages/kitchen-operations";
import QrCode from "../pages/qrcode";
import EmployeeDetails from "../pages/employee/EmployeeDetails";
import ExpenseDetails from "../pages/expense/ExpenseDetails";
import LogsChange from "../pages/logs-change";
import HotelCheckIn from "../pages/settings/hotel-check-in";
import Laundry from "../pages/laundry";
import Tutorial from "../pages/tutorial";
import { ROUTES, PERMISSIONS } from "../constants"; 
import Spa from "../pages/spa/Index"; 
import VerifyEmailOTP from "../pages/auth/VerifyEmailOTP";
import NotFound404 from "../components/NotFound404";

const routes = [
    {
        path: '/Tutorial',
        element: <Tutorial />,
        permissions: []
    }, 
    {
        path: ROUTES.VERIFY_EMAIL_OTP,
        element: <VerifyEmailOTP />,
        permissions: []

    },
    {
        path: ROUTES.DASHBOARD,
        element: <Dashboard />,
        permissions: []
    },
    {
        path: ROUTES.BOOKINGS,
        element: <BookingPage />,
        permissions: [PERMISSIONS.RECEPTION, PERMISSIONS.BOOKING]
    },
    {
        path: ROUTES.BOOKING,
        element: <BookingDetails />,
        permissions: [PERMISSIONS.RECEPTION, PERMISSIONS.BOOKING]
    },
    {
        path: ROUTES.CANCEL_BOOKING,
        element: <CancelBooking />,
        permissions: [PERMISSIONS.RECEPTION, PERMISSIONS.BOOKING]
    },
    {
        path: ROUTES.FOOD_MENU,
        element: <FoodMenu />,
        permissions: [PERMISSIONS.RECEPTION, PERMISSIONS.ORDER]
    },
    {
        path: ROUTES.ROOMS,
        element: <RoomTypes />,
        permissions: [PERMISSIONS.RECEPTION]
    },
    {
        path: ROUTES.LAUNDRY,
        element: <Laundry />,
        permissions: [PERMISSIONS.RECEPTION, PERMISSIONS.HOUSE_KEEPING]
    },
    {
        path: ROUTES.QR_CODES,
        element: <QrCode />,
        permissions: [PERMISSIONS.RECEPTION]
    },
    {
        path: ROUTES.HOTELS,
        element: <AllHotel />,
        permissions: []
    },
    {
        path: ROUTES.REPORTS,
        element: <Reports />,
        permissions: []
    },
    {
        path: ROUTES.ACCESS_CONTROL,
        element: <AccessControl />,
        permissions: []
    },
    {
        path: ROUTES.CUSTOMERS,
        element: <CustomersList />,
        permissions: [PERMISSIONS.RECEPTION,]
    },
    {
        path: ROUTES.CUSTOMER_DETAILS,
        element: <CustomerDetails />,
        permissions: [PERMISSIONS.RECEPTION,]
    },
    {
        path: ROUTES.RESERVATION_GRID,
        element: <ReservationGrid />,
        permissions: [PERMISSIONS.RECEPTION,]
    },
    {
        path: ROUTES.SUPPORT,
        element: <Support />,
        permissions: [PERMISSIONS.RECEPTION,]
    },
    {
        path: ROUTES.EXPENSES,
        element: <ExpensePage />,
        permissions: [PERMISSIONS.RECEPTION,]
    },
    {
        path: ROUTES.EXPENSE,
        element: <ExpenseDetails />,
        permissions: [PERMISSIONS.RECEPTION,]
    },
    {
        path: ROUTES.EMPLOYEES,
        element: <EmployeePage />,
        permissions: [PERMISSIONS.RECEPTION,]
    },
    {
        path: ROUTES.EMPLOYEE_DETAILS,
        element: <EmployeeDetails />,
        permissions: [PERMISSIONS.RECEPTION,]
    },
    {
        path: ROUTES.EMPLOYEE_ROASTER,
        element: <RoasterManagement />,
        permissions: [PERMISSIONS.RECEPTION,]
    },
    {
        path: ROUTES.HOTEL_CHECKIN,
        element: <HotelCheckIn />,
        permissions: [PERMISSIONS.RECEPTION,]
    },
    {
        path: ROUTES.REVIEWS,
        element: <AllReviews />,
        permissions: [PERMISSIONS.RECEPTION,]
    },
    {
        path: ROUTES.HOUSE_KEEPING,
        element: <HouseKeeping />,
        permissions: [PERMISSIONS.RECEPTION, PERMISSIONS.HOUSE_KEEPING]

    },
    // Kitchen Routes
    {
        path: ROUTES.KITCHENS,
        element: <Kitchen />,
        permissions: [PERMISSIONS.KITCHEN]
    },
    {
        path: ROUTES.KITCHEN_MENU,
        element: <MenuItemPage />,
        permissions: [PERMISSIONS.KITCHEN, PERMISSIONS.ORDER]
    },
    {
        path: ROUTES.KITCHEN_INVENTORY,
        element: <InventoryPage />,
        permissions: [PERMISSIONS.KITCHEN, PERMISSIONS.ORDER]
    },
    {
        path: ROUTES.KITCHEN_BUFFETS,
        element: <BuffetPage />,
        permissions: [PERMISSIONS.KITCHEN, PERMISSIONS.ORDER]
    },
    {
        path: ROUTES.KITCHEN_BUFFET,
        element: <BuffetDetails />,
        permissions: [PERMISSIONS.KITCHEN, PERMISSIONS.ORDER]
    },
    {
        path: ROUTES.KITCHEN_BUFFET_INVENTORY,
        element: <CheckInventoryDetails />,
        permissions: [PERMISSIONS.KITCHEN, PERMISSIONS.ORDER]
    },
    {
        path: ROUTES.ALACARTE,
        element: <AlaCarte />,
        permissions: [PERMISSIONS.KITCHEN, PERMISSIONS.ORDER]
    },
    {
        path: ROUTES.KITCHEN_OPERATIONS,
        element: <KitchenOperations />,
        permissions: [PERMISSIONS.KITCHEN, PERMISSIONS.ORDER]
    },
    // User Profile and Settings
    {
        path: ROUTES.MY_PROFILE,
        element: <Profile />,
        permissions: []

    },
    {
        path: ROUTES.PASSWORD_CHANGE,
        element: <ChangePassword />,
        permissions: []

    },
    {
        path: ROUTES.ASSETS,
        element: <AssetsPage />,
        permissions: [PERMISSIONS.RECEPTION, PERMISSIONS.ASSETS]
    },
    {
        path: ROUTES.LOGS_CHANGE,
        element: <LogsChange />,
        permissions: []

    },
    {
        path: ROUTES.VENDORS,
        element: <VendorPage />,
        permissions: [PERMISSIONS.RECEPTION]
    },
    {
        path: ROUTES.INVENTORY,
        element: <HotelInventory />,
        permissions: [PERMISSIONS.RECEPTION,]
    },
    {
        path: ROUTES.KITCHEN_MENU_INGREDIENTS,
        element: <MapIngredients />,
        permissions: [PERMISSIONS.KITCHEN,]
    },
    {
        path: ROUTES.FAB_ITEMS,
        element: <FAndBDetails />,
        permissions: [PERMISSIONS.KITCHEN, PERMISSIONS.ORDER]
    },
    {
        path: ROUTES.ADD_BUFFET_MENU,
        element: <CreateBuffetMenu />,
        permissions: [PERMISSIONS.KITCHEN, PERMISSIONS.ORDER]
    },
    {
        path: ROUTES.CHECKOUT,
        element: <CheckOutTomarrow />,
        permissions: [PERMISSIONS.RECEPTION, PERMISSIONS.BOOKING]
    },
    {
        path: ROUTES.ROOM_SERVICE_REQUEST,
        element: <RoomServiceRequest />,
        permissions: [PERMISSIONS.RECEPTION]
    },
    {
        path: ROUTES.ISSUE_REPORTED,
        element: <IssueReported />,
        permissions: [PERMISSIONS.RECEPTION]
    },
    {
        path: ROUTES.HOUSE_KEEPING_AMENITY,
        element: <HousekeepingAmenities />,
        permissions: [PERMISSIONS.RECEPTION]
    },
    {
        path: ROUTES.PURCHASE_ORDER,
        element: <PurchaseOrder />,
        permissions: [PERMISSIONS.RECEPTION]
    },
    {
        path: ROUTES.VENDOR,
        element: <VendorDetails />,
        permissions: [PERMISSIONS.RECEPTION]
    },
    {
        path: ROUTES.SPA,
        element: <Spa />,
        permissions: [PERMISSIONS.RECEPTION]
    },
    {
        path: ROUTES.VENDOR_HISTORY,
        element: <VendorHistory />,
        permissions: [PERMISSIONS.RECEPTION]
    },
    {
        path: ROUTES.HOTEL_CONFIG,
        element: <Settings />,
        permissions: []
    },
    {
        path: '*',
        element: <NotFound404 />,
        permissions: []
    }
];


export default routes