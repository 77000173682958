import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authAxios from "../../services/authAxios";

// get hotel service Action
export const getHotelServicesAction = createAsyncThunk(
  "GET_HOTEL_SERVICE_ACTION",
  async (credentials, thunkAPI) => {
    try {
      const { hotelId, category } = credentials;
      if (!hotelId) return;
      const url = `/api/hotel/owner/hotels/hotel/service/all?hotelId=${hotelId}&category=${category}`;
      const response = await authAxios.get(url);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// add hotel service action
export const addHotelServiceAction = createAsyncThunk(
  "ADD_HOTEL_SERVIC_ACTION",
  async (credentials, thunkAPI) => {
    try {
      const url = `/api/hotel/owner/hotels/hotel/service`;
      const response = await authAxios.post(url, credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// update hotel service action
export const updateHotelServiceAction = createAsyncThunk(
  "UPDATE_HOTEL_SERVIC_ACTION",
  async (credentials, thunkAPI) => {
    try {
      const url = `api/hotel/owner/hotels/hotel/service`;
      const response = await authAxios.put(url, credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// delete hotel service action
export const deleteHotelServiceAction = createAsyncThunk(
  "DELETE_HOTEL_SERVIC_ACTION",
  async (credentials, thunkAPI) => {
    try {
      const url = `api/hotel/owner/hotels/hotel/service`;
      const response = await authAxios.delete(url, credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// Helper function for handling pending state
const handlePending = (state) => {
  state.loading = true;
  state.error = null;
};

// Helper function for handling fulfilled state
const handleFulfilled = (state, action) => {
  state.loading = false;
  state.wifi = action.payload;
};

// Helper function for handling rejected state
const handleRejected = (state, action) => {
  state.loading = false;
  state.error = action.payload || "An error occurred";
};

// hotel service slice
export const hotelServiceSlice = createSlice({
  name: "HOTEL_SERVICE_SLICE",
  initialState: {
    loading: false,
    services: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getHotelServicesAction.pending, handlePending)
      .addCase(getHotelServicesAction.fulfilled, handleFulfilled)
      .addCase(getHotelServicesAction.rejected, handleRejected)
      .addCase(addHotelServiceAction.pending, handlePending)
      .addCase(addHotelServiceAction.fulfilled, handleFulfilled)
      .addCase(addHotelServiceAction.rejected, handleRejected)
      .addCase(updateHotelServiceAction.pending, handlePending)
      .addCase(updateHotelServiceAction.fulfilled, handleFulfilled)
      .addCase(updateHotelServiceAction.rejected, handleRejected)
      .addCase(deleteHotelServiceAction.pending, handlePending)
      .addCase(deleteHotelServiceAction.fulfilled, handleFulfilled)
      .addCase(deleteHotelServiceAction.rejected, handleRejected)
  },
});