import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { TOKEN } from "../../../constants";

export const addBookingAction = createAsyncThunk(
  "booking/addBookingAction",
  async (credentials, thunkAPI) => {
    try {
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/employee/booking`;
      const response = await axios.post(url, credentials);
      localStorage.setItem(TOKEN, response.data.token);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

//Add booking slice
export const addBookingSlice = createSlice({
  name: "booking/addBookingSlice",
  initialState: {
    loading: false,
    isbooked: false,
    booking: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addBookingAction.pending, (state) => {
        state.loading = true;
        state.isbooked = false;
        state.error = null;
      })
      .addCase(addBookingAction.fulfilled, (state, action) => {
        state.loading = false;
        state.isbooked = true;
        state.booking = action.payload.booking;
      })
      .addCase(addBookingAction.rejected, (state, action) => {
        state.loading = false;
        state.isbooked = false;
        state.error = action.payload || "Failed to add booking";
      });
  },
});
// update booking action
export const updateBookingAction = createAsyncThunk(
  "booking/updateBookingAction",
  async ({ credentials, formData }, thunkAPI) => {
    try {
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/employee/booking`;
      const response = await axios.put(url, formData, {
        headers: credentials,
      });
      localStorage.setItem(TOKEN, response.data.token);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

//update booking slice
export const updateBookingSlice = createSlice({
  name: "booking/updateBookingSlice",
  initialState: {
    loading: false,
    isupdated: false,
    booking: {},
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateBookingAction.pending, (state) => {
        state.loading = true;
        state.isupdated = false;
        state.error = null;
      })
      .addCase(updateBookingAction.fulfilled, (state, action) => {
        state.loading = false;
        state.isupdated = true;
        state.booking = action.payload.booking;
      })
      .addCase(updateBookingAction.rejected, (state, action) => {
        state.loading = false;
        state.isupdated = false;
        state.error = action.payload || "Failed to update booking";
      });
  },
});

// delete booking action
export const deleteBookingAction = createAsyncThunk(
  "booking/deleteBookingAction",
  async ({ credentials, bookingId, hotelId }, thunkAPI) => {
    try {
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/employee/booking/${hotelId}/${bookingId}`;
      const response = await axios.delete(url, {
        headers: credentials,
      });
      localStorage.setItem(TOKEN, response.data.token);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

//delete booking slice
export const deleteBookingSlice = createSlice({
  name: "booking/deleteBookingSlice",
  initialState: {
    loading: true,
    isdeleted: false,
    message: "",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteBookingAction.pending, (state) => {
        state.loading = true;
        state.isdeleted = false;
        state.error = null;
      })
      .addCase(deleteBookingAction.fulfilled, (state, action) => {
        state.loading = false;
        state.isdeleted = true;
        state.message = action.payload.message;
      })
      .addCase(deleteBookingAction.rejected, (state, action) => {
        state.loading = false;
        state.isdeleted = false;
        state.error = action.payload || "Failed to delete booking";
      });
  },
});
