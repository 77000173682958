import React from "react";
import ToolTip from "../common/ToolTip";
import { useTranslation } from "react-i18next";
import { City } from "country-state-city";
import { useEffect } from "react";

const CityNameSeletector = ({
  city,
  setCity,
  required = false,
  countryCode,
  stateCode,
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    if (countryCode && stateCode) {
      const cityy = City.getCitiesOfState(countryCode, stateCode)[0]?.name;
      setCity(cityy);
    }
  }, [stateCode]);

  return (
    <>
      <div>
        <div className="flex justify-between">
          <label>
            {t("576")}
          </label>
          <ToolTip Message={t("430")} />
        </div>
        <div className="relative flex items-center">
          <select
            className="border border-[#D0D5DD] w-full h-12 text-sm py-2 px-3 rounded-md focus:border-base-primary"
            onChange={(e) => setCity(e.target.value)}
            value={city}
            required={required}
          >
            {City.getCitiesOfState(countryCode, stateCode)?.map((value) => (
              <option value={value?.name} key={value?.name}>
                {value?.name}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
};

export default CityNameSeletector;
