/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants";
import EditButton from "../../components/buttons/EditButton";
import DeleteButton from "../../components/buttons/DeleteButton";
import CustomeTable from "../../components/widget/CustomeTable";

const AllCreateRoaster = () => {
  const [selectedRows, setSelectedRows] = useState([]);

  const tableData = [
    {
      _id: "EX001",
      rosterName: "Vendor Bill",
      position: "Manager",
      role: "Manage Staff",
      noOfShifts: "02",
      workArea: "Working on First Floor",
      timeFormat: "123",
      action: "",
    },
    {
      _id: "EX001",
      rosterName: "Vendor Bill",
      position: "Manager",
      role: "Manage Staff",
      noOfShifts: "02",
      workArea: "Working on First Floor",
      timeFormat: "123",
      action: "",
    },
    {
      _id: "EX001",
      rosterName: "Vendor Bill",
      position: "Manager",
      role: "Manage Staff",
      noOfShifts: "02",
      workArea: "Working on First Floor",
      timeFormat: "123",
      action: "",
    },
    {
      _id: "EX001",
      rosterName: "Vendor Bill",
      position: "Manager",
      role: "Manage Staff",
      noOfShifts: "02",
      workArea: "Working on First Floor",
      timeFormat: "123",
      action: "",
    },
    {
      _id: "EX001",
      rosterName: "Vendor Bill",
      position: "Manager",
      role: "Manage Staff",
      noOfShifts: "02",
      workArea: "Working on First Floor",
      timeFormat: "123",
      action: "",
    },
  ];
  const RoasterAction = () => {
    const navigate = useNavigate();
    const AssignShift = () => {
      console.log("Assign Shift");
      navigate(ROUTES.ASSIGN_SHIFT);
    };
    const deleteRoaster = () => {
      console.log("Delete The Employee");
    };
    const updateRoaster = () => {
      console.log("Update The Employee");
    };
    return (
      <div className="flex items-center gap-4 mt-[5px]">
        <button
          className="text-[14px] leading-6 font-normal tracking-[-0.6%] text-base-primary hover:text-base-primary_hover"
          onClick={AssignShift}
        >
          Assign Shift
        </button>
        <EditButton onClick={updateRoaster} />
        <DeleteButton onClick={deleteRoaster} />
      </div>
    );
  };
  const columnDefs = [
    {
      headerName: "Roaster ID",
      field: "_id",
      minWidth: 150,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Roster Name",
      field: "rosterName",
      filter: true,
    },
    {
      headerName: "Position",
      field: "position",
      filter: true,
    },
    {
      headerName: "Role",
      field: "role",
      filter: true,
    },
    {
      headerName: "No. Of Shifts",
      field: "noOfShifts",
      filter: true,
    },
    {
      headerName: "Work Area",
      field: "workArea",
      filter: true,
    },
    {
      headerName: "Time Format",
      field: "timeFormat",
      filter: true,
    },
    {
      headerName: "Actions",
      field: "_id",
      minWidth: 280,
      cellRenderer: RoasterAction,
    },
  ];

  return (
    <div>
      <CustomeTable
        tableName={"Roaster List Table"}
        columnDefs={columnDefs}
        rowData={tableData}
        setSelectedRows={setSelectedRows}
      />
    </div>
  );
};

export default AllCreateRoaster;
