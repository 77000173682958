import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { TOKEN } from "../../../constants";

// get hotel guest by code Action
export const getHotelGuestByCodeAction = createAsyncThunk(
  "employeeReview/getHotelGuestByCodeAction",
  async ({ credentials, code }, thunkAPI) => {
    try {
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/guest/hotel?checkInCode=${code}`;
      const response = await axios.get(url, { headers: credentials });
      localStorage.setItem(TOKEN, response.data.token);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

//get hotel guest slice
export const getHotelGuestByCodeSlice = createSlice({
  name: "getHotelGuest/getHotelGuestByCodeSlice",
  initialState: {
    loading: true,
    hotel: {},
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getHotelGuestByCodeAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getHotelGuestByCodeAction.fulfilled, (state, action) => {
        state.loading = false;
        state.hotel = action.payload.hotel;
      })
      .addCase(getHotelGuestByCodeAction.rejected, (state, action) => {
        state.loading = false;
        state.hotel = [];
        state.error = action.payload?.message || "Failed to get hotel Guest";
      });
  },
});
