import React, { useState } from "react";
import ToolTip from "../common/ToolTip";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";

const Inputfield = ({
  label,
  value,
  onChange,
  type = "text",
  max,
  min,
  maxLength,
  minLength,
  message,
  placeholder,
  required = false,
  readOnly = false,
  icon,
  className,
  optionalText = false,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className="w-full relative">
      <div className={`flex items-center justify-between`}>
        <label>
          {label}
          {optionalText ? (
            <span className="text-[12px] leading-5 font-medium">
              {optionalText}
            </span>
          ) : null}
        </label>
        <ToolTip Message={message} />
      </div>
      <input
        className={`${className}`}
        type={
          type === "password" ? (!showPassword ? "password" : "text") : type
        }
        value={value}
        onChange={onChange}
        max={max}
        min={min}
        maxLength={maxLength}
        minLength={minLength}
        placeholder={placeholder}
        required={required}
        readOnly={readOnly}
      />
      {icon ? (
        <button
          type="button"
          className="absolute right-4 top-8 text-[#344054] hover:text-base-primary duration-300 ease-[0.3s]"
        >
          {icon}
        </button>
      ) : null}
      {type === "password" ? (
        <button
          type="button"
          className="absolute right-6 top-4 text-[#07090D] hover:text-base-primary duration-300 ease-[0.3s]"
          onClick={() => setShowPassword(!showPassword)}
        >
          {showPassword ? <FaRegEye size={18} /> : <FaRegEyeSlash size={18} />}
        </button>
      ) : null}
    </div>
  );
};

export default Inputfield;
