import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropertyContact from "./PropertyContact";
import { ONBOARDING_STAGES } from "../../constants";
import { updateHotelAction } from "../../lib/slices/hotel/hotelSlice";
import NextButton from "./NextButton";
import PreviousButton from "./PreviousButton";

const OnboardContact = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { hotel, } = useSelector((state) => state.hotel);

  const nextStep = () => {
    // if (hotel?.contacts.length) {
    const formData = {
      hotelId: hotel?._id,
      stage: ONBOARDING_STAGES.CONTACT,
    };
    dispatch(updateHotelAction(formData));
    // }
  };

  const goToPrev = () => {
    const formData = {
      hotelId: hotel?._id,
      stage: ONBOARDING_STAGES.PRE_ONBOARDING,
    };
    dispatch(updateHotelAction(formData));
  };

  return (
    <>
      <div className="flex items-start flex-col w-full pb-6">
        <div className="mx-auto px-4">
          <div className="relative flex flex-col ">
            <label className="inline-block text-center text-[40px] leading-[72px] font-bold tracking-[-1.5%]">
              {t("Add Property Contacts")}
            </label>
            <PropertyContact />
          </div>
          <div className="flex items-center mt-6 justify-between">
            <PreviousButton handleClick={goToPrev} />
            <NextButton handleClick={nextStep} />
          </div>
        </div>
      </div>
    </>
  );
};
export default OnboardContact;
