/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {  
  BILLING_TO,
  BOOKING_CANCEL_REASON,
  ID_NUMBER, 
  PAYMENT_METHODS,
  ROUTES,
} from "../../constants";
import { useTranslation } from "react-i18next";
// import Seperator from "../../components/layouts/Seperator";
// import DetailsHeading from "../../components/layouts/DetailsHeading";
import { useSelector } from "react-redux";
import Inputfield from "../../components/form/InputField";
import AddBookingCheck from "./AddBookingCheck"; 
import SelectFromObject from "../../components/form/SelectFromObject";

function CancelBooking() {
  const { t } = useTranslation();
  const [booking, setBooking] = useState(null);
  const { bookings } = useSelector((state) => state?.AllBooking);
  const [cancelReason, setCancelReason] = useState("temp");
  // eslint-disable-next-line no-unused-vars
  const [cancelBy, setCancelBy] = useState();
  // eslint-disable-next-line no-unused-vars
  const [cancelationFee, setCancelationFee] = useState();
  const [isRefundable, setIsRefundable] = useState(false);
  const [refundableMethod, setRefundableMethod] = useState(PAYMENT_METHODS.CASH);
  const [refundable, setRefundable] = useState("");
  const [refundableAmount, setRefundableAmount] = useState("");
  const [refundTo, setRefundTo] = useState('guest');
  const [taxExemption, setTaxExemption] = useState("");
  const [cancelationNotes, setCancelationNotes] = useState("");
  const [emailCancelationDetails, setEmailCancelationDetails] = useState("");
  useEffect(() => {
    const bookingId = sessionStorage.getItem(ID_NUMBER);
    if (bookingId) {
      const data = bookings?.filter((x) => x?._id === bookingId);
      setBooking(data[0]);
    }
  }, [bookings]);
  const headingData = {
    heading: t("133"),
    seperator: [
      {
        text: t("134"),
      },
      {
        link: ROUTES.BOOKINGS,
        text: t("35"),
      },
      {
        text: t("Cancel Booking"),
      },
    ],
  };
  return (
    <div className="page-container">
      <div className="flex justify-between items-end">
        {/* <Seperator data={headingData} textColor={"text-[#F04438]"} /> */}
      </div>
      {/* Booking Information */}

      <div className="text-[22px] leading-[22.72px] tracking-[-0.8px] font-bold text-[#000000] mt-4">
        {t("135")}
      </div>
      <div className="bg-[#FFFFFF] rounded-[10px] h-[158px] mt-4 border px-8 py-6">
        <div className="grid grid-cols-6 items-center gap-8">
          {/* <DetailsHeading
            heading={t("175")}
            subHeading={booking?.bookingName}
          />
          <DetailsHeading
            heading={t("510")}
            subHeading={booking?.bookingEmail}
          />
          <DetailsHeading
            heading={t("496")}
            subHeading={booking?.bookingType}
          />
          <DetailsHeading
            heading={t("160")}
            subHeading={booking?.bookingSource}
          />
          <DetailsHeading
            heading={t("137")}
            subHeading={booking?.bookingSourceId}
          />
          <DetailsHeading
            heading={t("138")}
            subHeading={booking?.bookingDate?.slice(0, 10)}
          />
          <DetailsHeading
            heading={t("570")}
            subHeading={booking?.checkInDate?.slice(0, 10)}
          />
          <DetailsHeading
            heading={t("571")}
            subHeading={booking?.checkOutDate?.slice(0, 10)}
          />
          <DetailsHeading heading={t("507")} subHeading="2" />
          <DetailsHeading
            heading={t("508")}
            subHeading={booking?.roomDetails?.length}
          />
          <DetailsHeading heading={t("572")} subHeading="No" /> */}
        </div>
      </div>

      {/* Billing Information */}

      <div className="text-[22px] leading-[22.72px] tracking-[-0.8px] font-bold text-[#000000] py-3 ">
        {t("569")}
      </div>
      <div className="rounded-[10px] h-[171px] p-8 border bg-[#FFFFFF]">
        <div className="grid grid-cols-7 items-center gap-8">
          {/* <DetailsHeading heading={t("552")} subHeading="Fully Paid" />
          <DetailsHeading heading={t("554")} subHeading="Credit Card" />
          <DetailsHeading heading={t("150")} subHeading="$1000" />
          <DetailsHeading heading={t("580")} subHeading="$600" />
          <DetailsHeading heading={t("581")} subHeading="$400" />
          <DetailsHeading heading={t("582")} subHeading="Yes" />
          <DetailsHeading heading={t("560")} subHeading="11-05-2023" />
          <DetailsHeading heading={t("563")} subHeading="Guest" />
          <DetailsHeading heading={t("564")} subHeading="No" /> */}
        </div>
      </div>

      {/* Cancel Details */}
      <div className="text-[22px] leading-[22.72px] tracking-[-0.8px] font-bold text-[#F04438] my-4">
        {t("Cancel Details")}
      </div>
      <div className="text-sm font-medium text-[#344054]">Select Room</div>
      <div className="w-[720px] h-[130px] rounded-[10px] border border-[#E5E9EB]  bg-[#FFFFFF]">
        <table className="w-full ">
          <thead className="h-11">
            <tr className="text-[14px] leading-6 text-[#6E7C87] tracking-[-0.6%] font-normal ">
              <th>
                <input
                  type="checkbox"
                  className="h-4 w-4 accent-current mt-1 ml-1"
                />
              </th>
              <th>S.No</th>
              <th>Room Type</th>
              <th>Rate Type</th>
              <th>No. of Rooms</th>
              <th>Room No.</th>
            </tr>
          </thead>
          <tbody className="text-[14px] leading-6 text-[#07090D] tracking-[-0.6%]">
            <tr className="border-t h-11">
              <td>
                <input
                  type="checkbox"
                  className="h-4 w-4 accent-current mt-1 ml-4"
                />
              </td>
              <td className="pl-2">01</td>
              <td className="pl-3">Deluxe </td>
              <td className="pl-32">American Plan (AP)</td>
              <td className="pl-4">1</td>
              <td className="pl-3">101</td>
            </tr>
            <tr className="border-t h-11">
              <td>
                <input
                  type="checkbox"
                  className="h-4 w-4 accent-current mt-1 ml-4"
                />
              </td>
              <td className="pl-2">01</td>
              <td className="pl-3">Deluxe </td>
              <td className="pl-32">American Plan (AP)</td>
              <td className="pl-4">1</td>
              <td className="pl-3">101</td>
            </tr>
          </tbody>
        </table>
      </div>
      {/* reason cancelation */}
      <div className="w-full  my-4 gap-4 flex items-center">
        <div className="w-[514px]">
          <SelectFromObject
            utility={"w-[514px]"}
            label={t("Cancel Reason")}
            options={BOOKING_CANCEL_REASON}
            value={cancelReason}
            setValue={setCancelReason}
          />
        </div>
        <div className="w-full">
          <Inputfield
            utility={"w-[312px]"}
            label={t("Cancel By")}
            placeholder={t("Rajeev")}
            value={cancelBy}
          />
        </div>
        <div className="w-full">
          <Inputfield
            utility={"w-full"}
            label={t("Cancelation Fee")}
            placeholder={t("Enter an amount")}
            value={cancelationFee}
          />
        </div>
      </div>
      <div className="w-[254px]">
        <AddBookingCheck
          label={t("558")}
          message={t("559")}
          text={isRefundable ? t("113") : t("514")}
          value={refundable}
          setValue={setRefundable}
        />
      </div>
      <div className="w-full grid grid-cols-4 gap-4  items-center my-4">
        <SelectFromObject
          label={t("554")}
          toolTipMessage={t("555")}
          options={PAYMENT_METHODS}
          value={refundableMethod}
          onChange={(e) => setRefundableMethod(e?.target?.value)}
        />
        <Inputfield
          label={t("Refundable Amount")}
          placeholder={t("Enter an amount")}
          value={refundableAmount}
          type="number"
          onChange={(e) => setRefundableAmount(e.target.value)}
          required={true}
        />
        <SelectFromObject
          label={t("Refund To")}
          value={refundTo}
          options={BILLING_TO}
          onChange={(e) => setRefundTo(e.target.value)}
        />
        <AddBookingCheck
          label={t("564")}
          message={t("565")}
          text={t("514")}
          value={taxExemption}
          setValue={setTaxExemption}
        />
      </div>

      <div>
        <label className="text-sm font-medium text-[#344054]">
          {t("Cancelation Note")}
        </label>
        <textarea
          type="text"
          placeholder={t("Enter cancelation notes")}
          className="h-32 rounded-lg p-3 border border-[#D0D5DD] w-full"
          value={cancelationNotes}
          onChange={(e) => setCancelationNotes(e?.target?.value)}
        ></textarea>

        <div className="flex gap-2 pt-3 pb-2">
          <input
            type="checkbox"
            className="h-4 w-4 mt-1 accent-current"
            value={emailCancelationDetails}
            onChange={(e) =>
              setEmailCancelationDetails(!emailCancelationDetails)
            }
          />
          <label
            htmlFor="label"
            className="text-[14px] leading-6 font-normal text-[#07090D]"
          >
            {t("Email Cancelation Details")}
          </label>
        </div>
      </div>
      <div className="my-4 flex items-end justify-end gap-4">
        <button
          className="h-10 px-6 border border-[#D0D5DD] rounded-md bg-[#FFFFFF] hover:bg-[#D0D5DD] text-[14px] leading-[17.64px] tracking-[-0.6%]
        text-[#07090D] font-semibold"
        >
          Back
        </button>
        <button
          className="h-10 px-4 rounded-md bg-[#F04438] text-[14px] text-[#F6F8F9] leading-[17.64px] tracking-[-0.6%]
         font-semibold hover:bg-[#ff4b4b]"
        >
          Cancel Booking
        </button>
      </div>
    </div>
  );
}

export default CancelBooking;
