import React, { useState, useEffect } from "react"; 
import { registerEvent } from "../../../logs";
import { useTranslation } from "react-i18next";
import InputTextArea from "../../../components/form/InputTextArea"; 
import SkipButton from "../../../components/buttons/SkipButton";
import AddSubmitButton from "../../../components/buttons/AddSubmitButton";
import Inputfield from "../../../components/form/InputField";
import SelectFromObject from "../../../components/form/SelectFromObject";
import { useDispatch, useSelector } from "react-redux";
import { updateHotelSettingAction } from "../../../lib/slices/hotel/hotelSlice";

const PropertyInfo = ({skipTheTab}) => {
  const { t } = useTranslation(); 
  const dispatch = useDispatch()
  const {hotel, loading} = useSelector(state=>state.hotel);
  const [tagline, setTagline] = useState("");
  const [commonAreas, setCommonAreas] = useState([]);
  const [propertyDescription, setPropertyDescription] = useState("");
  const [refundPolicy, setRefundPolicy] = useState("");
  const [termsAndConditions, setTermsAndConditions] = useState("");
  const [rulesAndRegulations, setRulesAndRegulations] = useState(""); 


  useEffect(() => {
    if (hotel?._id) {
      setTagline(hotel?.tagline);
      setPropertyDescription(hotel?.propertyDescription);
      setRefundPolicy(hotel?.refundPolicy);
      setTermsAndConditions(hotel?.termsAndConditions);
      setRulesAndRegulations(hotel?.rulesAndRegulations); 
    }
  }, [hotel]);

  //submit the form
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      hotelId: hotel?._id,
      tagline,
      propertyDescription,
      termsAndConditions,
      rulesAndRegulations, 
    };
 
    dispatch(updateHotelSettingAction(formData))
    registerEvent("UPDATE PROPERTY DETAILS", "PROPERTY DETAILS", formData);
  };
  
  return (
    <> 
        <div className="w-full max-w-full border border-[#E4E4E7] rounded-lg bg-white overflow-hidden p-8">
          <h2 className="text-[30px] leading-[37.8px] text-[#07090D] font-bold tracking-[-1.5%]">
            {t("707")}
          </h2>
          <form
            className="flex flex-col gap-4 w-full mt-4"
            onSubmit={handleSubmit}
          >
            <div className="grid grid-cols-2 gap-6 items-center">
              <Inputfield
                value={tagline}
                onChange={(e) => setTagline(e?.target?.value)}
                label={t("708")}
                message={t("709")}
                placeholder={t("Enter Your Tagline here...")}
              />
              <SelectFromObject
                label={t("Common Areas")}
                value={commonAreas}
                setValue={setCommonAreas}
                options={{}}
                toolTipMessage={"select Common areas..."}
              />
            </div>

            <InputTextArea
              value={propertyDescription}
              onChange={(e) => setPropertyDescription(e?.target?.value)}
              label={t("Property Description")}
              message={t("715")}
              placeholder={t("Enter Property Description here...")}
              placeholderColor={true}
              rows={3}
            />
            <InputTextArea
              value={refundPolicy}
              onChange={(e) => setRefundPolicy(e?.target?.value)}
              label={t("Reufund Policy")}
              message={t("718")}
              placeholder={t("Enter Refund Policy here..")}
              placeholderColor={true}
              rows={3}
            />
            <InputTextArea
              value={termsAndConditions}
              onChange={(e) => setTermsAndConditions(e?.target?.value)}
              label={t("723")}
              message={t("724")}
              placeholder={t("725")}
              placeholderColor={true}
              rows={3}
            />
            <InputTextArea
              value={rulesAndRegulations}
              onChange={(e) => setRulesAndRegulations(e?.target?.value)}
              label={t("Property Rules And Regulations here...")}
              message={t("727")}
              placeholder={t("Enter Property Rules And Regulations")}
              placeholderColor={true}
              rows={3}
            />
            <div className="w-full flex justify-end items-center gap-3 mt-4">
              <SkipButton text={t("260")} onClick={skipTheTab} />
              <AddSubmitButton
                text={t("65")}
                width={88}
                disabled={loading}
              />
            </div>
          </form>
        </div> 
    </>
  );
};

export default PropertyInfo;
