import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

// get inventory Action
export const getInventoryAction = createAsyncThunk(
  "inventory/getInventoryAction",
  async ({ credentials }, thunkAPI) => {
    try {
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/kitchen/employee/inventory`;
      const response = await axios.get(url, { headers: credentials });
      // localStorage.setItem(TOKEN, response.data.token);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// get inventory slice
export const getInventorySlice = createSlice({
  name: "inventory/getInventorySlice",
  initialState: {
    loading: false,
    inventory: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getInventoryAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getInventoryAction.fulfilled, (state, action) => {
        state.loading = false;
        state.inventory = action.payload.inventory;
      })
      .addCase(getInventoryAction.rejected, (state, action) => {
        state.loading = false;
        state.inventory = null;
        state.error = action.payload || "Failed to get Inventory";
      });
  },
});

// lost inventory report action
export const lostInventoryReportAction = createAsyncThunk(
  "inventory/lostInventoryReportAction",
  async (credentials, thunkAPI) => {
    try {
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/kitchen/employee/inventory/report`;
      const response = await axios.post(url, credentials);
      // localStorage.setItem(TOKEN, response.data.token);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

//lost inventory report  slice
export const lostInventoryReportSlice = createSlice({
  name: "inventory/lostInventoryReportSlice",
  initialState: {
    loading: false,
    isadded: false,
    report: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(lostInventoryReportAction.pending, (state) => {
        state.loading = true;
        state.isadded = false;
        state.error = null;
      })
      .addCase(lostInventoryReportAction.fulfilled, (state, action) => {
        state.loading = false;
        state.isadded = true;
        state.report = action.payload.report;
      })
      .addCase(lostInventoryReportAction.rejected, (state, action) => {
        state.loading = false;
        state.isadded = false;
        state.error =
          action.payload || "Failed to lost Inventory Report";
      });
  },
});

// get employee order in queue Action
export const getEmployeeOrderInQueueAction = createAsyncThunk(
  "inventory/getEmployeeOrderInQueueAction",
  async ({ credentials }, thunkAPI) => {
    try {
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/kitchen/employee/order/queue`;
      const response = await axios.get(url, { headers: credentials });
      // localStorage.setItem(TOKEN, response.data.token);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// get Employee Order In Queue slice
export const getEmployeeOrderInQueueSlice = createSlice({
  name: "inventory/getEmployeeOrderInQueueSlice",
  initialState: {
    loading: false,
    order: [],
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getEmployeeOrderInQueueAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getEmployeeOrderInQueueAction.fulfilled, (state, action) => {
        state.loading = false;
        state.order = action.payload.order;
      })
      .addCase(getEmployeeOrderInQueueAction.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.payload ||
          "Failed to get employee order inventory in queue";
      });
  },
});

// change status of order action
export const changeStatusOfOrderAction = createAsyncThunk(
  "inventory/changeStatusOfOrderAction",
  async (credentials, thunkAPI) => {
    try {
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/kitchen/employee/order/status`;
      const response = await axios.put(url, credentials);
      // localStorage.setItem(TOKEN, response.data.token);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

//change Status Of Order Slice
export const changeStatusOfOrderSlice = createSlice({
  name: "inventory/changeStatusOfOrderSlice",
  initialState: {
    loading: false,
    isupdated: false,
    order: {},
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(changeStatusOfOrderAction.pending, (state) => {
        state.loading = true;
        state.isupdated = false;
        state.error = null;
      })
      .addCase(changeStatusOfOrderAction.fulfilled, (state, action) => {
        state.loading = false;
        state.isupdated = true;
        state.order = action.payload.order;
      })
      .addCase(changeStatusOfOrderAction.rejected, (state, action) => {
        state.loading = false;
        state.isupdated = false;
        state.error =
          action.payload || "Failed to change Status Of Order";
      });
  },
});
