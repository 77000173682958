import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { TOKEN } from "../../../constants";

// get all Employee Review Action
export const getAllEmployeeReviewAction = createAsyncThunk(
  "employeeReview/getAllEmployeeReviewAction",
  async (credentials, thunkAPI, hotelId) => {
    try {
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/employee/review?hotelId=${hotelId}`;
      const response = await axios.get(url, credentials);
      localStorage.setItem(TOKEN, response.data.token);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

//get all Employee Review slice
export const getAllEmployeeReviewSlice = createSlice({
  name: "employeeReview/getAllEmployeeReviewSlice",
  initialState: {
    loading: true,
    reviews: [],
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllEmployeeReviewAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllEmployeeReviewAction.fulfilled, (state, action) => {
        state.loading = false;
        state.reviews = action.payload.reviews;
      })
      .addCase(getAllEmployeeReviewAction.rejected, (state, action) => {
        state.loading = false;
        state.reviews = [];
        state.error =
          action.payload?.message || "Failed to get all Employee Review ";
      });
  },
});

// approve Employee Review Action
export const approveEmployeeReviewAction = createAsyncThunk(
  "employeeReview/approveEmployeeReviewAction",
  async ({ token, hotelId, reviewId }, thunkAPI) => {
    try {
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/${hotelId}/employee/review/${reviewId}/approve`;
      const response = await axios.post(url, null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      localStorage.setItem(TOKEN, response.data.token);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);
//approve Employee Review slice
export const approveEmployeeReviewSlice = createSlice({
  name: "employeeReview/approveEmployeeReviewSlice",
  initialState: {
    loading: true,
    isapproved: false,
    reviews: [],
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllEmployeeReviewAction.pending, (state) => {
        state.loading = true;
        state.isapproved = false;
        state.error = null;
      })
      .addCase(getAllEmployeeReviewAction.fulfilled, (state, action) => {
        state.loading = false;
        state.isapproved = true;
        state.reviews = action.payload.reviews;
      })
      .addCase(getAllEmployeeReviewAction.rejected, (state, action) => {
        state.loading = false;
        state.isapproved = false;
        state.reviews = [];
        state.error =
          action.payload?.message || "Failed to Approve Employee Review ";
      });
  },
});

// reply Employee Review by hotel Id Action
export const replyEmployeeReviewByHotelIdAction = createAsyncThunk(
  "employeeReview/replyEmployeeReviewByHotelIdAction",
  async ({ token, hotelId, formData, reviewId }, thunkAPI) => {
    try {
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/${hotelId}/employee/review/${reviewId}/reply`;
      const response = await axios.post(url, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      localStorage.setItem(TOKEN, response.data.token);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);
//approve Employee Review slice
export const replyEmployeeReviewByHotelIdSlice = createSlice({
  name: "employeeReview/replyEmployeeReviewByHotelIdSlice",
  initialState: {
    loading: true,
    isreplyed: false,
    reviews: [],
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(replyEmployeeReviewByHotelIdAction.pending, (state) => {
        state.loading = true;
        state.isreplyed = false;
        state.error = null;
      })
      .addCase(
        replyEmployeeReviewByHotelIdAction.fulfilled,
        (state, action) => {
          state.loading = false;
          state.isreplyed = true;
          state.reviews = action.payload.reviews;
        }
      )
      .addCase(replyEmployeeReviewByHotelIdAction.rejected, (state, action) => {
        state.loading = false;
        state.isreplyed = false;
        state.reviews = [];
        state.error =
          action.payload?.message || "Failed to Approve Employee Review ";
      });
  },
});

//   Delete employee leave Action
export const deleteEmployeeReviewByHotelIdAction = createAsyncThunk(
  "employeeReview/deleteEmployeeReviewByHotelIdAction",
  async ({ hotelId, reviewId, token }, thunkAPI) => {
    try {
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/${hotelId}/employee/review/${reviewId}`;
      const response = await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);
// delete employee review by Hotel Id leave slice
export const deleteEmployeeReviewByHotelIdSlice = createSlice({
  name: "employeeReview/deleteEmployeeReviewByHotelIdSlice",
  initialState: {
    loading: true,
    isdelete: false,
    reviews: [],
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteEmployeeReviewByHotelIdAction.pending, (state) => {
        state.loading = true;
        state.isdelete = false;
        state.error = null;
      })
      .addCase(
        deleteEmployeeReviewByHotelIdAction.fulfilled,
        (state, action) => {
          state.loading = false;
          state.isdelete = true;
          state.employee = action.payload.employee;
        }
      )
      .addCase(
        deleteEmployeeReviewByHotelIdAction.rejected,
        (state, action) => {
          state.loading = false;
          state.isdelete = false;
          state.error =
            action.payload?.message || "Failed to delete employee review";
        }
      );
  },
});
