import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

// add expense action
export const addExpenseAction = createAsyncThunk(
  "expense/addExpenseAction",
  async (credentials, thunkAPI) => {
    try {
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/employee/expense`;
      const response = await axios.post(url, credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// add expense slice
export const addExpenseSlice = createSlice({
  name: "expense/addExpenseSlice",
  initialState: {
    loading: false,
    isadded: false,
    expense: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addExpenseAction.pending, (state) => {
        state.loading = true;
        state.isadded = false;
        state.error = null;
      })
      .addCase(addExpenseAction.fulfilled, (state, action) => {
        state.loading = false;
        state.isadded = true;
        state.expense = action.payload.expense;
      })
      .addCase(addExpenseAction.rejected, (state, action) => {
        state.loading = false;
        state.isadded = false;
        state.error = action.payload || "Failed to add Expense";
      });
  },
});

// get all expense Action
export const getAllExpenseAction = createAsyncThunk(
  "guestSupport/getAllGuestQueriesAction",
  async ({ credentials, hotelId }, thunkAPI) => {
    try {
      if (!hotelId) return;
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/employee/expense/all?hotelId=${hotelId}`;
      const response = await axios.get(url, { headers: credentials });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// get all expense slice
export const getAllExpenseSlice = createSlice({
  name: "expense/getAllExpenseSlice",
  initialState: {
    loading: false,
    expenses: [],
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllExpenseAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllExpenseAction.fulfilled, (state, action) => {
        state.loading = false;
        state.expenses = action.payload.expenses;
      })
      .addCase(getAllExpenseAction.rejected, (state, action) => {
        state.loading = false;
        //   state.expenses = [];
        state.error = action.payload || "Failed to get all expense";
      });
  },
});
