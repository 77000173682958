import React, { useState } from "react";
import CloseButton from "../../../components/buttons/CloseButton";
import Inputfield from "../../../components/form/InputField";
import SelectFromObject from "../../../components/form/SelectFromObject";
import InputTextArea from "../../../components/form/InputTextArea";
import SubmitButton from "../../../components/buttons/SubmitButton";

const AddServices = ({ close = () => {} }) => {
  const [serviceName, setServiceName] = useState("");
  const [serviceType, setServiceType] = useState([]);
  const [servicePrice, setServicePrice] = useState([]);
  const [serviceTimings, setServiceTimings] = useState([]);
  const [description, setDescription] = useState("");

  return (
    <div className="relative w-full border border-[] rounded-[10px] bg-white capitalize p-6">
      <div className="text-[30px] leading-[37.8px] tracking-[-1.5%] text-[#07090D] text-center font-bold">
        Add Service
      </div>
      <div className="absolute right-6 top-8">
        <CloseButton onClick={close} />
      </div>
      <div className="grid grid-cols-3 items-center gap-6 mt-6">
        <Inputfield
          label={"Service Name"}
          placeholder={"Enter Service name"}
          value={serviceName}
          onChange={(e) => setServiceName(e.target.value)}
          min={3}
          max={30}
        />
        <SelectFromObject
          label={"Service Type"}
          options={{ serviceType: "Service Type" }}
          value={serviceType}
          setValue={setServiceType}
          toolTipMessage={"select Service type"}
        />
        <Inputfield
          label={"Service Price"}
          placeholder={"Enter Service Price"}
          message={"enter Service price"}
          value={servicePrice}
          onChange={(e) => setServicePrice(e.target.value)}
          min={3}
          max={30}
        />
        <Inputfield
          type="time"
          label={"Service Timings"}
          message={"enter Service Timings"}
          value={serviceTimings}
          onChange={(e) => setServiceTimings(e.target.value)}
          min={3}
          max={30}
        />
        <div className="col-span-2 mt-[2px]">
          <InputTextArea
            className={"h-12"}
            label={"Description"}
            placeholder={"Note..."}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            minLength={10}
            maxLength={500}
          />
        </div>
      </div>
      <div className="flex items-center justify-end mt-4 mb-2">
        <SubmitButton text={"Add Service"} />
      </div>
    </div>
  );
};

export default AddServices;
