import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ROUTES } from "../../constants";
import { useSelector } from "react-redux";

const DashBoardHead = ({ date, setDate }) => {
  const {user} =useSelector((state)=>state.user);
  const { t } = useTranslation();
  return (
    <div className="flex flex-col sm:flex-row justify-between gap-4">
      <div>
        <h2 className="font-bold tracking-[-0.8px] text-[#18181B] leading-[38px] text-xl sm:text-2xl md:text-3xl">
          Hey {user?.name}
        </h2>
        <p className="text-[#00000099] font-normal text-base md:text-[20px] leading-[25px] tracking-[-0.8px]">
          {t("3")}
        </p>
      </div>
      <div className="flex gap-4 items-end">
        <div className="relative w-[308px] h-10 flex flex-row justify-center items-center gap-3 border border-[#E4E4E7] rounded-md box-border bg-white text-[#18181B] p-3">
          <input
            value={date}
            onChange={(e) => setDate(e.target.value)}
            type={"date"}
            className="flex w-1/2 my-[10px] bg-white items-center border-none shadow-none rounded h-5 font-normal cursor-pointer select-none text-[14px] leading-[18px] tracking-[-0.8px]"
          />
          <input
            value={date}
            onChange={(e) => setDate(e.target.value)}
            type={"date"}
            className="flex w-1/2 my-[10px] bg-white items-center border-none shadow-none rounded h-5 font-normal cursor-pointer select-none text-[14px] leading-[18px] tracking-[-0.8px]"
          />
        </div>
        <Link
          to={ROUTES.REPORTS}
          className="flex justify-between items-center rounded-[6px] px-4 h-10 w-[110px] bg-base-primary hover:bg-base-primary_hover transition-all duration-[0.3s] ease-[0s] text-[#F6F8F9] text-[14px] leading-[14px] font-semibold tracking-[-0.6%]"
        > 
            {t("4")} 
        </Link>
      </div>
    </div>
  );
};

export default DashBoardHead;
