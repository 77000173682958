import React, { useState, useEffect } from "react";
import SaveOrNot from "../../components/modals/SaveOrNot";
import { useDispatch, useSelector } from "react-redux"; 
import { useTranslation } from "react-i18next";
import Inputfield from "../../components/form/InputField";
import SelectFromArray from "../../components/form/SelectFromArray";  
import AddSubmitButton from "../../components/buttons/AddSubmitButton";
import CloseButton from "../../components/buttons/CloseButton";
import { DAYS_NAME } from "../../constants";
import SelectFromObject from "../../components/form/SelectFromObject";
import { FiCornerRightDown } from "react-icons/fi";
import { TbHotelService } from "react-icons/tb";

const AddKitchen = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { hotel } = useSelector((state) => state.hotel);

  const { kitchen, isadded, loading, error } = useSelector(
    (state) => state.addKitchen
  );
  console.log("add kitchen", kitchen, isadded, loading, error);

  const [open, setOpen] = useState(false);
  //add new state
  const [kitchenName, setKitchenName] = useState("");
  const [kitchenLocation, setKitchenLocation] = useState("");
  const [kitchenManager, setKitchenManager] = useState("");
  const [measureUnit, setMeasureUnit] = useState("");
  const [isProvideRoomForAlacart, setIsProvideRoomForAlacart] = useState("");
  const [selectDay, setSelectDay] = useState([]);
  const [startServiceTime, setStartServiceTime] = useState("");
  const [endServiceTime, setEndServiceTime] = useState("");
  const [addMoreService, setAddMoreService] = useState([1]);

  // const [managerContact, setManagerContact] = useState("");
  // const [headChef, setHeadChef] = useState("");
  // const [headChefContact, setHeadChefContact] = useState("");
  // const [inventoryManager, setInventoryManager] = useState("");
  // const [inventoryManagerContact, setInventoryManagerContact] = useState("");
  // const [buffetManager, setBuffetManager] = useState("");
  // const [buffetManagerContact, setBuffetManagerContact] = useState("");
  // const [menuManager, setMenuManager] = useState("");
  // const [menuManagerContact, setMenuManagerContact] = useState("");

  //click open
  const handleClickOpen = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  const handleSubmit = () => {
    setOpen(false);
   
  };

  useEffect(() => {
    if (kitchen?.error) { 
    }
    if (props?.kitchen?.isadded) {
      // notifySuccess("1043");
      // dispatch({ type: ADD_KITCHEN_RESET });
    }
  }, [kitchen]);

  const KITCHEN_LOCATIONS = ["In Hotel", "Third Party Kitchen"];

  const MEASURE_UNIT = ["Kilo-gram", "Pounds"];
  const YES_OR_NO = ["Yes", "No"];

  const handleAddMoreService = () => {
    setAddMoreService([...addMoreService, {}]);
  };

  return (
    <>
      <div className="flex flex-col border border-[#d0d5dde8] rounded-lg bg-white relative mt-6 p-8">
        <div className="flex items-center justify-between">
          <div className="font-bold text-[30px] leading-[37.8px] text-[#07090D] tracking-[-1.5%]">
            {t("Add Hotel Own Kitchen")}
          </div>
          <CloseButton onClick={props.close} />
        </div>

        <form onSubmit={handleClickOpen} className="w-full mt-4">
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-8">
            <Inputfield
              label={t("1045")}
              placeholder={t("1046")}
              value={kitchenName}
              onChange={(e) => setKitchenName(e.target.value)}
              required={true}
              minLength={3}
              maxLength={30}
            />
            <SelectFromArray
              label={t("Kitchen Location")}
              toolTipMessage={t("1048")}
              value={kitchenLocation}
              onChange={(e) => setKitchenLocation(e.target.value)}
              options={KITCHEN_LOCATIONS}
            />
            <Inputfield
              label={t("Kitchen Manager")}
              placeholder={t("1050")}
              value={kitchenManager}
              onChange={(e) => setKitchenManager(e.target.value)}
              minLength={3}
              maxLength={30}
            />
            <SelectFromArray
              label={t("Measure Unit")}
              toolTipMessage={t("..")}
              value={measureUnit}
              onChange={(e) => setMeasureUnit(e.target.value)}
              options={MEASURE_UNIT}
            />
            <SelectFromArray
              label={t("Do you provide room for AlaCart")}
              toolTipMessage={t("..")}
              value={isProvideRoomForAlacart}
              onChange={(e) => setIsProvideRoomForAlacart(e.target.value)}
              options={YES_OR_NO}
            />
          </div>
          <div className="flex items-center justify-between my-4">
            <div className="font-bold text-[20px] leading-5 text-[#07090D] tracking-[-1.5%] ">
              {t("Room Service Timming")}
            </div>
            <button
              type="button"
              onClick={handleAddMoreService}
              className="flex items-center justify-center gap-2 h-10 w-fit px-4 rounded-md bg-base-primary hover:bg-base-primary_hover shadow-[0_1px_2px_0_rgba(16,24,40,0.05)] text-sm font-medium text-[#F6F8F9] transition-all duration-[0.3s] ease-[0s] capitalize"
            >
              <TbHotelService size={24} />
              Add More Service
            </button>
          </div>
          {addMoreService?.map((services, index) => (
            <div className="capitalize mb-4 border-b-2 pb-4" key={services}>
              <div className="flex gap-2 text-[16px] leading-4 font-semibold mb-2">
                service {index + 1} <FiCornerRightDown className="mt-[6px]" />
              </div>
              <div className="grid grid-cols-3 items-center gap-8">
                <SelectFromObject
                  label={t("Select Day")}
                  value={selectDay}
                  setValue={setSelectDay}
                  options={DAYS_NAME}
                  toolTipMessage={t("..")}
                />
                <Inputfield
                  type="time"
                  message={"enter room service time "}
                  label={t("Start Service Time")}
                  placeholder={t("Enter Start Time")}
                  value={startServiceTime}
                  onChange={(e) => setStartServiceTime(e.target.value)}
                />
                <Inputfield
                  type="time"
                  message={"enter room service time "}
                  label={t("End Service Time")}
                  placeholder={t("Enter End Time")}
                  value={endServiceTime}
                  onChange={(e) => setEndServiceTime(e.target.value)}
                />
              </div>
            </div>
          ))}

          <div className="flex items-center justify-end pt-7">
            <AddSubmitButton
              text={t("1059")}
              loading={props?.kitchen?.loading}
            />
          </div>
        </form>
      </div>
      <SaveOrNot
        formSubmit={handleSubmit}
        open={open}
        close={() => setOpen(false)}
      />
    </>
  );
};

export default AddKitchen;
