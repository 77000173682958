import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authAxios from "../../services/authAxios";

//get all hotel asset action
export const getAllHotelAssetAction = createAsyncThunk(
  "GET_HOTEL_ALL_ASSET_ACTION",
  async (hotelId, thunkAPI) => {
    try {
      if (!hotelId) return;
      const url = `/api/hotel/employee/assets/all?hotelId=${hotelId}`;
      const response = await authAxios.get(url);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// add hotel assets action
export const addHotelAssetsAction = createAsyncThunk(
  "ADD_HOTEL_ASSET_ACTION",
  async (credentials, thunkAPI) => {
    try {
      const url = `/api/hotel/employee/assets`;
      const response = await authAxios.post(url, credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// update hotel asset action
export const updateHotelAssetsAction = createAsyncThunk(
  "UPDATE_HOTEL_ASSET_ACTION",
  async (credentials, thunkAPI) => {
    try {
      const url = `/api/hotel/employee/assets`;
      const response = await authAxios.put(url, credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// delete hotel assets action
export const deleteHotelAssetsAction = createAsyncThunk(
  "DELETE_HOTEL_ASSET_ACTION",
  async (creadentials, thunkAPI) => {
    try {
      const url = `/api/hotel/employee/assets`;
      const response = await authAxios.delete(url,creadentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// hotel assets slice
export const hotelAssetSlice = createSlice({
  name: "HOTEL_ASSET_SLICE",
  initialState: {
    loading: true,
    isadded: false,
    isupdated: false,
    isdeleted: false,
    assets: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addHotelAssetsAction.pending, (state) => {
        state.loading = true;
        state.isadded = false;
        state.error = null;
      })
      .addCase(addHotelAssetsAction.fulfilled, (state, action) => {
        state.loading = false;
        state.isadded = true;
        state.assets = action.payload;
      })
      .addCase(addHotelAssetsAction.rejected, (state, action) => {
        state.loading = false;
        state.isadded = false;
        state.error = action.payload;
      })
      .addCase(getAllHotelAssetAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllHotelAssetAction.fulfilled, (state, action) => {
        state.loading = false;
        state.assets = action.payload;
      })
      .addCase(getAllHotelAssetAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateHotelAssetsAction.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.isupdated = false;
      })
      .addCase(updateHotelAssetsAction.fulfilled, (state, action) => {
        state.loading = false;
        state.isadded = true;
        state.assets = action.payload;
      })
      .addCase(updateHotelAssetsAction.rejected, (state, action) => {
        state.loading = false;
        state.isupdated = false;
        state.error = action.payload;
      })
      .addCase(deleteHotelAssetsAction.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.isdeleted = false;
      })
      .addCase(deleteHotelAssetsAction.fulfilled, (state, action) => {
        state.loading = false;
        state.isdeleted = true;
        state.assets = action.payload;
      })
      .addCase(deleteHotelAssetsAction.rejected, (state, action) => {
        state.loading = false;
        state.isdeleted = false;
        state.error = action.payload;
      })
  },
});

//get hotel asset by asset id
export const getHotelAssetByIdAction = createAsyncThunk(
  "GET_HOTEL_ASSET_ACTION",
  async (creadentials, thunkAPI) => {
    try {
      const {hotelId, assetId} = creadentials;
      if (!assetId || !hotelId) return;
      const url = `/api/hotel/employee/assets?assetId=${assetId}&hotelId=${hotelId}`;
      const response = await authAxios.get(url);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

//get hotel guest by id slice
export const getHotelAssetByIdSlice = createSlice({
  name: "GET_HOTEL_ASSET_SLICE",
  initialState: {
    loading: false,
    asset: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getHotelAssetByIdAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getHotelAssetByIdAction.fulfilled, (state, action) => {
        state.loading = false;
        state.asset = action.payload.asset;
      })
      .addCase(getHotelAssetByIdAction.rejected, (state, action) => {
        state.loading = false; 
        state.error = action.payload;
      });
  },
});
