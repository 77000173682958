import React from "react";
import Logo from "../common/Logo";
import ProfileButton from "./ProfileButton";
import { ROUTES } from "../../constants";
import CircularProgressWithLabel from "./CircularProgressWithLabel";
import Notification from "./Notification";
import SearchBar from "./SearchBar";

const TopBar = ({ configurePercentage }) => {
  return (
    <div
      className={`h-16 flex items-center justify-between px-4 border-b border-b-gray-200 z-[1050] w-full bg-white`}
    >
      <div className="select-none flex items-center h-full py-2">
        <div className="flex w-fit gap-4 items-center justify-between md:px-4">
          <Logo url={ROUTES.DASHBOARD} />
          {/* <div className="flex items-center mt-1 ml-16">
              <HamBurgerBtn />
            </div> */}
        </div>
        {/* <Tracker /> */}
        <div className="ml-32 hidden md:flex">
          <SearchBar />
        </div>
        {/* <div className="flex ml-9">
            <button className="h-10 w-[74px] bg-base-primary rounded-[6px] text-sm font-semibold text-white items-center tracking-[-0.006em]">
              {t("1")}
            </button>
          </div> */}
        {/* {props.isadmin ? <TrackLocation hotel={props.hotel} /> : null} */}
      </div>
      <div className="relative flex items-center justify-center ml-4">
        {configurePercentage ? (
          <CircularProgressWithLabel value={configurePercentage} />
        ) :  
          null}
        {/* <div className="flex items-center justify-center mx-4">
            <ThemePicker setTheme={props.setTheme} />
          </div> */}
        {/* <LanguageSwitcher /> */}
        <Notification />

        <ProfileButton />
      </div>
    </div>
  );
};

export default TopBar;
