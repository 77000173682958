import React, { useRef, useState } from "react";
import SaveOrNot from "../../components/modals/SaveOrNot";
import { connect } from "react-redux"; 
import LoadSpinner from "../../components/common/loader/LoadSpinner";
import { useTranslation } from "react-i18next";
import Inputfield from "../../components/form/InputField";
import { FiArrowRight } from "react-icons/fi";
import { BUFFET_PURPOSE, BUFFET_TYPE, DAYS_NAME } from "../../constants"; 
import CloseButton from "../../components/buttons/CloseButton"; 
import SelectTab from "../../components/buttons/SelectTab";

const AddBuffet = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [buffetName, setBuffetName] = useState("");
  const [buffetType, setBuffetType] = useState(BUFFET_TYPE.HOTEL_GUEST);
  const [buffetPurpose, setBuffetPurpose] = useState([]);
  const [buffetPriceAndPerson, setBuffetPriceAndPerson] = useState("");
  const [buffetSaved, setBuffetSaved] = useState([]);
  const [day, setDay] = useState([]);
  const [noOfPlate, setNoOfPlate] = useState("");
  const [maximumNoOfPlates, setMaximumNoOfPlates] = useState([]);
  const [active, setActive] = useState(false);
  console.log("get value :", buffetPurpose);

  const handleClickOpen = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  const handleSubmit = () => {
    setOpen(false);
    props.addKitchen({
      hotelId: props?.hotel?._id,
      buffetName,
      buffetType,
      buffetPurpose,
      buffetSaved,
      day,
      maximumNoOfPlates,
      noOfPlate,
    });
  };

  const handleBuffetPurpose = (e, index) => {
    const getValue = e.target.value;
    console.log("Selected Value:", getValue);
    setBuffetPurpose(getValue);
  };

  return (
    <>
      <div className="w-full flex flex-col border border-[#D0D5DD] overflow-hidden rounded-lg bg-white relative my-6 p-6 mx-auto">
        <div className="font-bold text-center text-[30px] leading-9 text-black tracking-tighter">
          {t("1036")}
        </div>
        <div className="absolute top-9 right-12">
          <CloseButton onClick={props.close} />
        </div>
        {/* md:gap-24 lg:px-16 lg:gap-32 md:px-4 mt-6 pt-4 */}
        <form
          className="grid grid-cols-2 md:gap-24 lg:px-16 lg:gap-32 md:px-4 mt-6 pt-4"
          onSubmit={handleClickOpen}
        >
          <div className="space-y-4">
            <Inputfield
              label={t("1037")}
              placeholder={t("1038")}
              message={"Enter your Buffet Name..."}
              value={buffetName}
              onChange={(e) => setBuffetName(e.target.value)}
              required={true}
            />
            <SelectTab
              label={"Day"}
              value={day}
              setValue={setDay}
              options={DAYS_NAME}
              message={"...."}
            />
            <SelectTab
              label={"Buffet Type"}
              value={buffetType}
              setValue={setBuffetType}
              options={BUFFET_TYPE}
              message={"...."}
            />
          </div>

          <div className="space-y-4">
            <SelectTab
              label={"Buffet Purpose"}
              value={buffetPurpose}
              setValue={setBuffetPurpose}
              options={BUFFET_PURPOSE}
              message={"...."}
            />

            <Inputfield
              label={t("No. of Plates")}
              placeholder={t("Manual Plates Entry")}
              message={"enter Manual Plates you want..."}
              value={noOfPlate}
              onChange={(e) => setNoOfPlate(e.target.value)}
              min={0}
              maxLength={10}
              required={true}
            />
          </div>

          {/* <div className="w-full border">
            <div className="flex items-center justify-between">
              <label htmlFor="forThis">Buffet Purpose</label>
              <ToolTip Message={"buffet purpose"} />
            </div>
            <div className="grid grid-cols-2 items-center gap-4">
              {Object.keys(BUFFET_PURPOSE)?.map((bp, index) => (
                <button
                  name="forThis"
                  type="button"
                  className={`h-12 w-full flex items-center justify-center capitalize border border-[#D0D5DD] rounded-lg text-base text-[#667085]  ${
                    buffetPurpose === BUFFET_PURPOSE[bp] ? "border-base-primary text-base-primary" : ""
                  }`}
                  value={BUFFET_PURPOSE[bp]}
                  onClick={(e) => handleBuffetPurpose(e, index)}
                  key={index}
                >
                  {" "}
                  {bp.replace("_", " ").toLocaleLowerCase()}{" "}
                </button>
              ))}
            </div>
          </div> */}

          {/* <div className="space-y-6">
           
            <SelectFromObject
              label={t("1039")}
              toolTipMessage={t("1040")}
              options={BUFFET_TYPE}
              value={buffetType}
              setValue={setBuffetType}
            />
            <SelectFromObject
              label={t("Buffet Saved")}
              toolTipMessage={t("this is buffet saved")}
              options={{}}
              value={buffetSaved}
              setValue={setBuffetSaved}
            />
            <SelectFromObject
              label={t("Day")}
              toolTipMessage={t("select day")}
              options={DAYS_NAME}
              value={day}
              setValue={setDay}
            />
          </div>
          <div className="space-y-6">
            <SelectFromObject
              label={t("1041")}
              toolTipMessage={"Please select buffet purpose"}
              options={BUFFET_PURPOSE}
              value={buffetPurpose}
              setValue={setBuffetPurpose}
            />
            <Inputfield
              label={t("Buffet Price/Person")}
              placeholder={t("$12")}
              message={"enter Buffet Price and Persion..."}
              value={buffetPriceAndPerson}
              onChange={(e) => setBuffetPriceAndPerson(e.target.value)}
              min={0}
              maxLength={10}
              required={true}
            />
            <Inputfield
              label={t("No. of Plates")}
              placeholder={t("Manual Plates Entry")}
              message={"enter Manual Plates you want..."}
              value={noOfPlate}
              onChange={(e) => setNoOfPlate(e.target.value)}
              min={0}
              maxLength={10}
              required={true}
            />
            <SelectFromObject
              label={t("Maximum No. of Plates")}
              toolTipMessage={t("select Maximum No. of Plates")}
              options={{}}
              value={maximumNoOfPlates}
              setValue={setMaximumNoOfPlates}
            />
          </div> */}
        </form>
        <div className="flex justify-end pt-8 lg:pr-16 md:pr-4">
          <button
            className="h-10 w-[148px] bg-base-primary hover:bg-base-primary_hover rounded-md text-white flex justify-center gap-2 items-center transition-all duration-300 ease-[0s]"
            type="submit"
          >
            <div className="text-[16px] leading-5 font-medium ">
              {props.kitchen && props.kitchen.loading ? (
                <LoadSpinner
                  text={!props.asset ? <>{t("336")}</> : <>{t("1036")}</>}
                />
              ) : (
                <>{!props.asset ? <>{t("1036")}</> : <>{t("328")}</>}</>
              )}
            </div>
            <FiArrowRight className="" />
          </button>
        </div>
      </div>
      <SaveOrNot
        formSubmit={handleSubmit}
        isopen={open}
        close={() => setOpen(false)}
      />
    </>
  );
};

const mapStateToProps = (state) => ({ 
});
const mapDispatchToProps = (dispatch) => ({ 
});
export default connect(mapStateToProps, mapDispatchToProps)(AddBuffet);
