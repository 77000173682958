import React from "react";
import ToolTip from "../../components/common/ToolTip";

const GetInputs = ({
  label,
  type = "text",
  value,
  setValue,
  message,
  placeholder,
  min,
  max,
  required,
}) => {    
  return (
    <div className="relative max-w-full w-full">
      <label className="text-sm font-medium text-[#344054]">{label}</label>
      <div className="relative flex items-center w-full gap-2">
        <input
          type={type}
          onChange={(e) => setValue(e?.target?.value)}
          value={value}
          placeholder={placeholder}
          minLength={min}
          maxLength={max}
          required={required} 
        />
        <ToolTip Message={message} />
      </div>
    </div>
  );
};

export default GetInputs;
