import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

// Add employee action
export const addEmployeeAction = createAsyncThunk(
  "employee/addEmployeeAction",
  async (credentials, thunkAPI) => {
    try {
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/employee/add`;
      const response = await axios.post(url, credentials);
      // localStorage.setItem(TOKEN, response.data.token);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);
//Add employee slice
export const addEmployeeSlice = createSlice({
  name: "employee/addEmployeeSlice",
  initialState: {
    loading: false,
    isadded: false,
    employee: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addEmployeeAction.pending, (state) => {
        state.loading = true;
        state.isadded = false;
        state.error = null;
      })
      .addCase(addEmployeeAction.fulfilled, (state, action) => {
        state.loading = false;
        state.isadded = true;
        state.employee = action.payload.employee;
      })
      .addCase(addEmployeeAction.rejected, (state, action) => {
        state.loading = false;
        state.isadded = false;
        state.error = action.payload || "Failed to add employee";
      });
  },
});

// get AllHotel Employee Action
export const getAllHotelEmployeeAction = createAsyncThunk(
  "employee/getAllHotelEmployeeAction",
  async (credentials, thunkAPI, hotelId) => {
    try {
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/employee/all?hotelId=${hotelId}`;
      const response = await axios.get(url, credentials);
      // localStorage.setItem(TOKEN, response.data.token);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);
//get All Hotel Employee slice
export const getAllHotelEmployeeSlice = createSlice({
  name: "employee/getAllHotelEmployeeSlice",
  initialState: {
    loading: false,
    employees: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllHotelEmployeeAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllHotelEmployeeAction.fulfilled, (state, action) => {
        state.loading = false;
        state.employees = action.payload.employees;
      })
      .addCase(getAllHotelEmployeeAction.rejected, (state, action) => {
        state.loading = false;
        state.employees = [];
        state.error = action.payload || "Failed to load all hotel employee";
      });
  },
});

//get hotel employee by id action
export const getHotelEmployeeByIdAction = createAsyncThunk(
  "employee/getHotelEmployeeByIdAction",
  async (credentials, thunkAPI, employeeId, hotelId) => {
    try {
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/employee?empId=${employeeId}&hotelId=${hotelId}`;
      const response = await axios.get(url, credentials);
      // localStorage.setItem(TOKEN, response.data.token);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);
// get hotel employee by id slice
export const getHotelEmployeeByIdSlice = createSlice({
  name: "employee/getHotelEmployeeByIdSlice",
  initialState: {
    loading: false,
    employee: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getHotelEmployeeByIdAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getHotelEmployeeByIdAction.fulfilled, (state, action) => {
        state.loading = false;
        state.employee = action.payload.employee;
      })
      .addCase(getHotelEmployeeByIdAction.rejected, (state, action) => {
        state.loading = false;
        state.employee = null;
        state.error = action.payload || "Failed to get hotel employee by id";
      });
  },
});
