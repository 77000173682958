import { RxCross2 } from "react-icons/rx";
import EditButton from "../../components/buttons/EditButton";

export function AllFoodAction() {
  return (
    <div className="flex items-center h-full gap-4">
      <button
        type="button"
        className="h-[30px] w-[88px] flex items-center justify-center border border-base-primary rounded bg-[#00a35e33] text-base-primary text-[14px] leading-6 hover:bg-base-primary hover:text-white duration-300 ease-[0.3s]"
      //   onClick={}
      >
        {"View Bill"}
      </button>
      <button
        type="button"
        className="h-[30px] w-[88px] flex items-center justify-center border border-base-primary rounded bg-[#00a35e33] text-base-primary text-[14px] leading-6 hover:bg-base-primary hover:text-white duration-300 ease-[0.3s]"
      //   onClick={}
      >
        {"View List"}
      </button>
      <EditButton />
      <button
        type="button"
        className="h-[30px] pl-[3px] pr-2 flex items-center justify-center gap-2 border border-[#F04438] text-[#F04438] hover:bg-[#F04438] hover:text-white rounded-md group duration-300 ease-[0.3s]"
      >
        <div className="rounded flex items-center justify-center bg-[#F04438] group-hover:bg-white  p-[3px]">
          <div className="rounded-full bg-white flex items-center justify-center group-hover:bg-[#F04438] p-[3px]">
            <RxCross2 size={10} />
          </div>
        </div>
        <div className="tracking-[-0.006em] text-[14px] leading-6 font-normal">
          Cancel
        </div>
      </button>
    </div>
  );
}

export function FoodStatus({ value }) {
  return (
    <div className="h-full flex items-center justify-center">
      {value === "In Queue" ? (
        <div className="h-7 w-[88px] flex items-center justify-center border border-[#EFD703] rounded text-[#EFD703] text-[14px] leading-6 bg-[#fff50033]">
          {value.replace("", " ")}
        </div>
      ) : null}
      {value === "Delivered" ? (
        <div className="h-7 w-[88px] flex items-center justify-center border border-base-primary rounded bg-[#00a35e33] text-base-primary text-[14px] leading-6">
          {value}
        </div>
      ) : null}
    </div>
  );
}

export function YesOrNo({ value }) {
  return (
    <div
      className={`${value === "Yes" ? "text-base-primary" : "text-[#FF2424]"}`}
    >
      {value}
    </div>
  );
}
