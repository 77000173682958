import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"; 
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import LoadSpinner from "../../components/common/loader/LoadSpinner";
import { isObjEmpty } from "../../utils";
import PageLoader from "../../components/common/loader/PageLoader";
import { registerEvent } from "../../logs";
import { ISADMIN, TOKEN, ROUTES } from "../../constants";
import { useTranslation } from "react-i18next";
import { getAllRoomAction } from "../../lib/slices/room/roomSlice";

const EmployeeLogin = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    employee,
    error: authError,
    loading: authloading,
  } = useSelector((state) => state.EmployeeAuth);

  const { error, loading, token } = useSelector((state) => state.EmployeeLogin);
  const {
    rooms,
    loading: roomgetting,
    error: roomerror,
  } = useSelector((state) => state.AllRoom);
  const [show, setShow] = useState(false);
  const [employeeId, setEmployeeId] = useState("");
  const [password, setPassword] = useState("");
  const [hotelId, setHotelId] = useState("");
  const [checkingconfig, setCheckingconfig] = useState(false);

  //state for show and hide password
  const showPassword = () => {
    setShow(!show);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = {
      employeeId,
      password,
      hotelId,
    };
    registerEvent(
      "click employee login button",
      "employee login page",
      formData
    );

    // dispatch(employeeLoginAction(formData));
    localStorage.clear();
    sessionStorage.clear();
  };

  //login employee with credentials
  useEffect(() => {
    if (typeof token === "string") {
      localStorage.setItem(ISADMIN, false);
      localStorage.setItem(TOKEN, token);
      // dispatch(authenticateEmployee());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  //

  // authenticate employee
  useEffect(() => {
    if (employee && !isObjEmpty(employee)) {
      dispatch(getAllRoomAction(employee?.hotelId));
      setCheckingconfig(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employee]);

  useEffect(() => {
    if (rooms && rooms?.length && !roomgetting) {
      navigate(ROUTES.BOOKINGS);
    } else if (checkingconfig) { 
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rooms]);

  //clear error
  useEffect(() => {
    if (error || authError || roomerror) {
      // dispatch(clearError());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, authError]);

  return (
    <>
      {authloading || roomgetting ? (
        <div className="h-screen flex items-center">
          <PageLoader />
        </div>
      ) : (
        <div className="w-full bg-[#f3f3f3] min-h-screen">
          <div className="w-[480px] max-w-full mx-auto py-8 px-4">
            <div className="w-full flex flex-col border rounded-lg bg-white">
              <div className="w-full pt-4 text-center">
                <h1 className="text-3xl font-semibold text-base-primary">
                  {t("220")}
                </h1>
              </div>
              <form
                className="w-full p-6 sm:p-8 md:pb-8"
                onSubmit={handleSubmit}
              >
                <div className="w-full mb-6 relative sm:mb-8">
                  <input
                    type="text"
                    onChange={(e) => setEmployeeId(e.target.value)}
                    value={employeeId}
                    placeholder={t("205")}
                    className={style}
                    required={true}
                  />
                </div>
                <div className="w-full mb-6 relative sm:mb-8">
                  <input
                    type={show ? "text" : "password"}
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    placeholder={t("211")}
                    className={style}
                    required={true}
                    minLength={4}
                    maxLength={8}
                  />

                  <span
                    className="absolute top-1/2 -translate-y-1/2 px-2 select-none cursor-pointer text-black right-0"
                    onClick={showPassword}
                  >
                    {show ? (
                      <AiOutlineEye style={{ fontSize: "1.3rem" }} />
                    ) : (
                      <AiOutlineEyeInvisible style={{ fontSize: "1.3rem" }} />
                    )}
                  </span>
                </div>
                <div className="w-full mb-6 relative sm:mb-8">
                  <input
                    type="text"
                    onChange={(e) => setHotelId(e.target.value)}
                    value={hotelId}
                    placeholder={t("221")}
                    className={style}
                    required={true}
                  />
                </div>
                <button
                  className="w-full text-center h-11 py-0 bg-base-primary text-xl text-white select-none cursor-pointer relative rounded font-semibold"
                  disabled={loading}
                >
                  {loading ? <LoadSpinner /> : <>{t("220")}</>}
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EmployeeLogin;

const style =
  "w-full outline-none border border-gray-300 p-3 py-2 focus:border-base-primary rounded";
