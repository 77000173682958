import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DeleteOrNot from "../../components/modals/DeleteOrNot";
import { ROUTES } from "../../constants"; 
import { isObjEmpty } from "../../utils";
import { useTranslation } from "react-i18next";
import  CustomeTable from "../../components/widget/CustomeTable";
import SubmitButton from "../../components/buttons/SubmitButton";
import { deletekitchenAction } from "../../lib/slices/kitchen/kitchenSlice";

const AllKitchen = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { kitchens, loading, error } = useSelector(
    (state) => state.getAllKitchen
  );
  console.log("get all kichens", kitchens, loading, error);
  const [datas, setDatas] = useState([]);
  const [open, setOpen] = useState(false);
  const [kitchenId, setKitchenId] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (props?.kitchen && !isObjEmpty(props?.kitchen?.kitchen)) {
      setDatas([
        {
          Kitchen_id: "1214",
          name: props?.kitchen?.kitchen?.name,
          location: props?.kitchen?.kitchen?.address,
          manager: props?.kitchen?.kitchen?.manager,
          managerContact: props?.kitchen?.kitchen?.managerContact,
          // images: props?.kitchen?.kitchen?.images,
          _id: props?.kitchen?.kitchen?._id,
        },
      ]);
    }
  }, [props?.kitchen]);

  //delete the kitchen
  const deleteKitchen = () => {
    if (kitchenId) {
      setKitchenId(kitchenId);
      dispatch(deletekitchenAction(kitchenId));
      setOpen(false);
    }
  };

  //view Kitchen Actions buttons
  const ViewKitchenActions = () => {
    const setKitchenAndRedirect = (link) => {
      if (link) {
        navigate(link);
      }
    };
    return (
      <div className="h-full flex items-center gap-2">
        <SubmitButton
          type="button"
          className="h-[30px] px-3"
          text={t("F&B")}
          onClick={() => setKitchenAndRedirect(ROUTES.KITCHEN_MENU)}
        />
        <SubmitButton
          type="button"
          className="h-[30px] px-3"
          text={t("Leftovers")}
          onClick={() => {}}
        />

        <SubmitButton
          type="button"
          className="h-[30px] px-3"
          text={t("1001")}
          onClick={() => setKitchenAndRedirect(ROUTES.KITCHEN_BUFFETS)}
        />
        <SubmitButton
          type="button"
          className="h-[30px] px-3"
          text={t("1064")}
          onClick={() => setKitchenAndRedirect(ROUTES.ALACARTE)}
        />
        <SubmitButton
          type="button"
          className="h-[30px] px-3"
          text={t("Commission Settlement")}
          onClick={() => setKitchenAndRedirect(ROUTES.COMMISSION_SETTLEMENT)}
        />
        <SubmitButton
          type="button"
          className="h-[30px] px-3"
          text={t("Kitchen Inventory")}
          onClick={() => setKitchenAndRedirect(ROUTES.KITCHEN_INVENTORY)}
        />
        <SubmitButton
          type="button"
          className="h-[30px] px-3"
          text={t("Kitchen Staff")}
          onClick={() => setKitchenAndRedirect(ROUTES.KiTCHEN_STAFF)}
        />
        <SubmitButton
          type="button"
          className="h-[30px] px-3"
          text={t("Setting")}
          onClick={() => {}}
        />
      </div>
    );
  };

  const columnDefs = [
    {
      headerName: "Kitchen ID",
      field: "Kitchen_id",
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Name",
      field: "name",
      filter: true,
    },
    {
      headerName: "Location",
      field: "location",
      filter: true,
    },
    {
      headerName: "Manager Name",
      field: "manager",
      filter: true,
    },
    // {
    //   headerName: "Contact",
    //   field: "managerContact",
    //   minWidth: 150,
    //   filter: true,
    // },
    {
      headerName: "Action",
      filter: true,
      minWidth: 835,
      cellRenderer: ViewKitchenActions,
    },
  ];

  return (
    <>
      <div className="relative">
        {datas.length ? (
          <CustomeTable
            tableName={"Kitchen List Table"}
            columnDefs={columnDefs}
            rowData={datas}
            setSelectedRows={setSelectedRows}
          />
        ) : null}
      </div>

     
      <DeleteOrNot
        formSubmit={deleteKitchen}
        open={open}
        close={() => setOpen(false)}
      />
    </>
  );
};

export default AllKitchen;
