import React, { useState } from "react";
import SubmitButton from "../../../components/buttons/SubmitButton";
import SelectBox from "../../../components/form/SelectBox";
import ShiftButton from "../../../components/buttons/ShiftButton";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";

const Services = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const rowData = [
    {
      servicesNames: "Airport Transfer",
      type: "$Transportation",
      price: "$12",
    },
    {
      servicesNames: "Airport Transfer",
      Type: "$Transportation",
      price: "$12",
    },
    {
      servicesNames: "Airport Transfer",
      type: "$Transportation",
      price: "$12",
    },
    {
      servicesNames: "Airport Transfer",
      type: "$Transportation",
      price: "$12",
    },
  ];

  const columnDefs = [
    {
      headerName: "Services Names",
      field: "servicesNames",
      checkboxSelection: true,
    },
    {
      headerName: "Type",
      field: "type",
    },
    {
      headerName: "Price",
      field: "price",
    },
  ];
  return (
    <div className="text-[#07090D] px-6">
      <div className="text-[30px] leading-[37.8px] font-bold tracking-[-1.5%]">
        Services
      </div>
      <p className="text-[20px] leading-5">
        Lorem ipsum amit epic alit thu Lorem ipsum amit epic alit thu{" "}
      </p>
      <div className="grid grid-cols-10 w-full mt-12">
        {/* Left side */}
        <div className="col-span-4">
          <SelectBox
            tableSize={"h-[58vh]"}
            heading="Select Services"
            columnDefs={columnDefs}
            rowData={rowData}
            setSelectedRows={setSelectedRows}
            btnName={"Add Servies"}
          />
        </div>

        {/* Buttons for add and remove */}
        <div className="col-span-2 flex flex-col items-center justify-center gap-6 px-5">
          <ShiftButton
            className="h-10 w-full"
            name={"Add"}
            icon={<AiOutlineDoubleRight size={18} />}
          />
          <ShiftButton
            className="h-10 w-full"
            name={"Remove"}
            icon={<AiOutlineDoubleLeft size={18} />}
          />
        </div>

        {/* Right side */}
        <div className="col-span-4 w-full">
          <SelectBox
            tableSize={"h-[58vh]"}
            heading={"Selected Services"}
            showBtn={false}
            columnDefs={columnDefs}
            rowData={rowData}
          />
        </div>
      </div>
      <div className="flex items-center justify-end gap-6 mt-6">
        <button
          type="button"
          className="h-12 w-[148px] border border-[#6E7C87] rounded-md text-[20px]  leading-5 font-semibold hover:bg-[#6E7C87] hover:text-white duration-300 ease-[0.3s]"
        >
          Skip
        </button>
        <SubmitButton
          type="button"
          className={"h-12 w-[160px] text-[20px] leading-5"}
          text={"Next"}
        />
      </div>
    </div>
  );
};

export default Services;
