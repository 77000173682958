import React, { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai"; 
import { validatePassword } from "../../utils/validations";

const PasswordInput = ({
  Placeholder,
  password,
  setPassword,
  passwordError,
  setPasswordError,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  //handle change
  const enterValidePassword = (val) => {
    setPassword(val);
    setPasswordError(validatePassword(val));
  };

  return (
    <>
      <div className="w-full relative">
        <input
          type={showPassword ? "text" : "password"}
          onChange={(e) => enterValidePassword(e.target.value)}
          value={password}
          placeholder={Placeholder}
          maxLength={15}
          className="w-full outline-none border border-[#D0D5DD] p-2 focus:border-base-primary pr-12 rounded"
        />
        <span
          className="absolute top-1/2 right-0 -translate-y-1/2 px-4 select-none cursor-pointer text-black"
          onClick={() => setShowPassword(!showPassword)}
        >
          {showPassword ? (
            <AiOutlineEye style={{ fontSize: "1.5rem" }} />
          ) : (
            <AiOutlineEyeInvisible style={{ fontSize: "1.5rem" }} />
          )}
        </span>
        <div className="absolute -bottom-5 left-0"> 
        </div>
      </div>
    </>
  );
};

export default PasswordInput;
