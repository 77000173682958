import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PageLoader from "../../components/common/loader/PageLoader";
import { useTranslation } from "react-i18next";
import { ID_NUMBER, ROUTES } from "../../constants";
import { useNavigate } from "react-router-dom";
// import Seperator from "../../components/layouts/Seperator";
import CustomeTable from "../../components/widget/CustomeTable";
import HideEmail from "./HideEmail";
import HidePhone from "./HidePhone";
import AddButton from "../../components/buttons/AddButton";
import { registerEvent } from "../../logs";
import AddGuest from "./AddGuest";

//view customer details
const ViewCustomerDetails = ({ v }) => {
  const navigate = useNavigate();

  const viewDetails = () => {
    localStorage.setItem(ID_NUMBER, v?.data?._id);
    navigate(ROUTES.CUSTOMER_DETAILS);
  };
  return (
    <button
      onClick={viewDetails}
      className="text-[14px] leading-6 font-normal tracking-[-0.6%] text-base-primary hover:text-base-primary_hover"
    >
      View Details
    </button>
  );
};

const CustomersList = (props) => {
  const { t } = useTranslation();
  const { bookings, loading, error } = useSelector(
    (state) => state.getBookingByConditions
  );
  console.log(
    "check all bookings in customer list :",
    bookings,
    loading,
    error
  );
  const data = bookings;
  const [loadings, setLoadings] = useState(loading);
  const [datas, setDatas] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [open, setOpen] = useState(false);
  //set all bookings as customers(datas)
  useEffect(() => {
    setLoadings(true);
    let array = [];
    if (Array.isArray(data?.bookings)) {
      data?.bookings.map((room) => {
        if (Array.isArray(room?.roomDetails)) {
          room.roomDetails.map((guest) => {
            if (Array.isArray(guest?.guestsDetails)) {
              guest?.guestsDetails.map((guest) => {
                array.push({
                  ...guest,
                  name: guest?.name,
                  gender: guest?.gender,
                  phone: guest?.phone,
                  email: guest?.email,
                  country: "India",
                  _id: guest?._id,
                });
              });
            }
          });
        }
      });
      if (array?.length) setDatas(array);
    }
    setLoadings(false);
  }, [data?.bookings]);

  const columnDefs = [
    {
      headerName: "Customer ID",
      field: "_id",
      minWidth: 150,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Guest Name",
      field: "name",
      filter: true,
    },
    {
      headerName: "Gender",
      field: "gender",
      filter: true,
    },
    {
      headerName: "phone",
      field: "phone",
      filter: true,
      cellRenderer: HidePhone,
    },
    {
      headerName: "email",
      field: "email",
      filter: true,
      cellRenderer: HideEmail,
    },
    {
      headerName: "country",
      field: "country",
      filter: true,
    },
    {
      headerName: "address",
      field: "address",
      minWidth: 110,
      filter: true,
    },
    {
      headerName: "Actions",
      field: "_id",
      minWidth: 150,
      cellRenderer: ViewCustomerDetails,
    },
  ];

  const headingData = {
    heading: t("68"),
    seperator: [
      {
        text: t("Guest Management"),
      },
      {
        text: t("Guest"),
      },
    ],
  };

  const addGuestModal = () => {
    setOpen(true);
    registerEvent("click add guest button", "all guest page");
  };

  //close add guest modal
  const close = () => {
    setOpen(false);
    // setUpdateData(null);
    registerEvent("close add guest component", "add guest page");
  };
  return (
    <>
      {props?.data?.loading || loading ? (
        <PageLoader />
      ) : (
        <div className="page-container mx-auto relative">
          <div className="w-full flex items-end justify-between mx-auto mb-6">
            {/* <Seperator data={headingData} /> */}
            <AddButton text={t("Add Guest")} onClick={addGuestModal} />
          </div>
          {open ? <AddGuest close={close} /> : null}

          <div className="mt-4">
            <CustomeTable
              tableName={"Guests Table"}
              columnDefs={columnDefs}
              rowData={datas}
              setSelectedRows={setSelectedRows}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default CustomersList;
