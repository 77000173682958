// calculate total day stay
export const getTotalDayStay = (checkInDate, checkOutDate) => {
  let date1 = new Date(checkInDate?.slice(0, 10));
  let date2 = new Date(checkOutDate.slice(0, 10));
  let timeDiff = date2.getTime() - date1.getTime();
  let dayDiff = timeDiff / (1000 * 3600 * 24);
  return dayDiff;
};

//today date @return yyyy-mm-dd
export const getToday = () => {
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = today.getFullYear();
  return yyyy + "-" + mm + "-" + dd;
};

//today date @return yyyy-mm-dd
export const getMonth = () => {
  let today = new Date();
  // let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = today.getFullYear();
  return yyyy + "-" + mm;
};

//future and past date @return yyyy-mm-dd
export const getPastFutureDate = (day) => {
  let today = new Date();
  today.setDate(today.getDate() + day);
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = today.getFullYear();
  return yyyy + "-" + mm + "-" + dd;
};

//get this month first date @20**-**-01 yyyy-mm-dd
export const getFirstDayOfMonth = () => {
  const today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = today.getFullYear();
  return yyyy + "-" + mm + "-" + dd;
};

//get this week first date @yy-mm-dd
export const getFirstDayOfWeek = () => {
  const date = new Date();
  const day = date.getDay(); // 👉️ get day of week
  const yyyy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, "0");
  // 👇️ day of month - day of week (-6 if Sunday), otherwise +1
  const diff = String(date.getDate() - day + (day === 0 ? -6 : 1));

  return yyyy + "-" + mm + "-" + diff;
};

//convert date formate @yyyy-mm-dd
export const getFormatedDate = (inputDate) => {
  //parse the input date
  const date = new Date(inputDate);

  //extract the parts of the date
  let dd = String(date.getDate()).padStart(2, "0");
  let mm = String(date.getMonth() + 1).padStart(2, "0");
  let yyyy = date.getFullYear();

  return yyyy + "-" + mm + "-" + dd;
};

//get year ago today
export const getYearAgoToday = (count) => {
  //parse year back count
  const today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = today.getFullYear() - count;

  return yyyy + "-" + mm + "-" + dd;
};

export const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const secs = seconds % 60;
  return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
};