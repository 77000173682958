import React, { useRef, useState } from "react";
import { TbBell } from "react-icons/tb";
import NotificationCard from './NotificationCard'
import { useOutsideClick } from "../../utils";
const Notification = () => {
  const [isOpen, setIsOpen] = useState(false);
  const closeModalRef = useRef(null);
  const close = () => setIsOpen(false);
  useOutsideClick(closeModalRef, close);
  // when notification not seen show count notification number
  const dataCount = true;
  const number = 4;
  return (
    <>
      <div className="relative">
        {dataCount ? (
          <div
            className={`absolute -top-[15px]
        ${
          number <= 0
            ? "hidden"
            : number <= 99
            ? "right-[5px]  w-5 p-1"
            : number > 99 && "w-fit px-1 -right-0"
        } 
        border h-5 flex items-center justify-center rounded-t-full rounded-br-full bg-base-primary text-white text-[9px] font-bold`}
          >
            {number}
          </div>
        ) : null}

        <button
          type="button"
          className=" mr-4 h-7 w-7 flex items-center justify-center text-[#3F3F46] group-hover:text-base-primary"
          onClick={() => setIsOpen(!isOpen)}
        >
          <TbBell size={24} className="font-extralight" />
        </button>
        {/* opacity-0  group-hover:flex group-hover:opacity-100 */}
        {isOpen ? (
          <div ref={closeModalRef} className="absolute top-[35px] right-6 z-auto w-[364px] h-[293px] bg-white rounded shadow-[0_4px_4px_0_rgba(0,0,0,0.2)] shadow-inner-2xl overflow-x-auto scroll-barguest duration-300 border px-1 pt-1 pb-2">
            {
             Array.from({ length: 15 }).map((_,index)=>(
                <NotificationCard key={index}/>
              ))
            }
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Notification;
