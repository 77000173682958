import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteOrNot from "../../components/modals/DeleteOrNot";
import { registerEvent } from "../../logs"; 
import BackGroundLoadSpinner from "../../components/common/loader/BackGroundLoadSpinner";
import { useTranslation } from "react-i18next";
import { ID_NUMBER, ROUTES } from "../../constants";
import { useNavigate } from "react-router-dom"; 
import CustomeTable from "../../components/widget/CustomeTable";
import LinksButton from "../../components/buttons/LinksButton"; 

const AllVendor = ({ setVendor }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [datas, setDatas] = useState([]);
  const { vendors } = useSelector((state) => state.AllVendor);
  const { hotel } = useSelector((state) => state.Hotel);
  const [selectedRows, setSelectedRows] = useState([]);
  const navigate = useNavigate();
  const { loading, isdeleted, error } = useSelector(
    (state) => state.DeleteVendorProfile
  );
  const [deleteId, setDeleteId] = useState("");
  const [confirmDelete, setConfirmDelete] = useState(false);

  //get employees array from redux and set employees as datas
  useEffect(() => {
    if (vendors && Array.isArray(vendors) && vendors?.length) {
      let arr = [];

      //modify employee datas (filter )
      vendors.map((employee) => {
        arr.push({
          vendorId: employee?.vendorId,
          name: employee?.name,
          vendorName: employee?.vendorName,
          productType: employee?.productType,
          email: employee?.email,
          phone: employee?.phone,
          // address: employee?.address,
          _id: employee?.vendorId,
        });
      });
      setDatas(arr);
    }
  }, [vendors]);

  useEffect(() => {
    if (error) {
      // notifyError(t("777"));
      // dispatch({ type: CLEAR_ERRORS });
    }

    if (isdeleted) {
      // dispatch(getAllVendorAction(hotel?._id));
      // notifySuccess(t("778"));
      // dispatch({ type: DELETE_VENDOR_RESET });
    }
  }, [isdeleted, error]);

  //set employee in session storage and redirect to next page
  const setEmployee = (id) => {
    registerEvent("redirect using link", "all employee page", { id });
    if (id) {
      const vendor = vendors?.filter((x) => x?.vendorId === id);
      setVendor(vendor[0]);
    }
  };

  //delete employee
  const openConfirmationModal = (id) => {
    if (id) {
      setDeleteId(id);
      setConfirmDelete(true);
      registerEvent("click on delete employee button", "all employee page", {
        id,
      });
    }
  };
  const deleteEmployee = () => {
    // if (deleteId) dispatch(deleteVendorProfileAction(hotel?._id, deleteId));
  };
  const vendorEditBtn = (value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setEmployee(value);
  };

  // purchase order page
  const setValueRedirectPurchaseOrder = (id) => {
    if (id) {
      sessionStorage.setItem(ID_NUMBER, id);
      navigate(`${ROUTES.PURCHASE_ORDER}`);
    }
  };
  // view details page
  const setValueRedirectViewDetails = (id) => {
    if (id) {
      sessionStorage.setItem(ID_NUMBER, id);
      navigate(`${ROUTES.VENDOR}`);
    }
  };

  // history page
  const setValueRedirectHistory = (id) => {
    if (id) {
      sessionStorage.setItem(ID_NUMBER, id);
      navigate(`${ROUTES.VENDOR_HISTORY}`);
    }
  };

  const ViewVendorAction = () => {
    return (
      <>
        <div className="h-full flex items-center gap-4">
          <LinksButton
            name={t("Purchase Order")}
            onClick={setValueRedirectPurchaseOrder}
          />
          <LinksButton
            name={t("Details")}
            onClick={setValueRedirectViewDetails}
          />
          <LinksButton name={t("History")} onClick={setValueRedirectHistory} />
          {/* <EditButton onClick={vendorEditBtn} /> */}
          {/* <DeleteButton onClick={openConfirmationModal} /> */}
        </div>
      </>
    );
  };

  const columnDefs = [
    {
      headerName: "Vendor ID",
      field: "vendorId",
      minWidth: 150,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Name",
      field: "name",
      filter: true,
    },
    {
      headerName: "Vendor Name",
      field: "vendorName",
      filter: true,
    },
    {
      headerName: "Vendor Type",
      field: "productType",
      filter: true,
    },
    {
      headerName: "Email",
      field: "email",
      filter: true,
    },
    {
      headerName: "Contact",
      field: "phone",
      filter: true,
    },
    {
      headerName: "Status",
      field: "status",
      filter: true,
    },
    {
      headerName: "Actions",
      field: "_id",
      minWidth: 450,
      cellRenderer: ViewVendorAction,
    },
  ];
  return (
    <>
      <div className="mt-6">
        {datas?.length ? (
          <CustomeTable
            tableName={"Vendor List Table"}
            columnDefs={columnDefs}
            rowData={datas}
            setSelectedRows={setSelectedRows}
          />
        ) : null}
      </div>
      {confirmDelete ? (
        <DeleteOrNot
          formSubmit={deleteEmployee}
          open={confirmDelete}
          close={() => setConfirmDelete(false)}
        />
      ) : null}
      {loading ? <BackGroundLoadSpinner /> : null}
    </>
  );
};

export default AllVendor;
