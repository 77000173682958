import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineSearch } from "react-icons/ai";
import { ROUTES } from "../../constants";
import { useState } from "react";
import SearchQuery from "./SearchQuery";
import { useOutsideClick } from "../../utils";

const SearchBar = () => {
  const { t } = useTranslation();
  const [query, setQuery] = useState("");
  const [filterQueries, setFilterQueries] = useState([]);
  const [open, setOpen] = useState(false);

  const SEARCH_QUERIES = [
    {
      query: t("29"),
      path: ROUTES.DASHBOARD,
    },
    {
      query: t("30"),
      path: ROUTES.HOTELS,
    },
    {
      query: t("31"),
      path: ROUTES.RESERVATION_GRID,
    },
    {
      query: t("32"),
      path: ROUTES.CUSTOMERS,
    },
    {
      query: t("33"),
      path: `${ROUTES.HOUSE_KEEPING}/1`,
    },
    {
      query: t("34"),
      path: ROUTES.ROOMS,
    },
    {
      query: t("35"),
      path: ROUTES.BOOKINGS,
    },
    {
      query: t("36"),
      path: ROUTES.KITCHENS,
    },
    {
      query: t("37"),
      path: ROUTES.EMPLOYEES,
    },
    {
      query: t("38"),
      path: ROUTES.EXPENSES,
    },

    {
      path: ROUTES.REPORTS,
      query: t("40"),
    },
    {
      path: ROUTES.REVIEWS,
      query: t("41"),
    },
    {
      path: ROUTES.ASSETS,
      query: t("43"),
    },
    {
      path: ROUTES.ROOM_TARIIF_AUTOMATION,
      query: t("1006"),
    },
    {
      path: ROUTES.HOTEL_CHECKIN,
      query: t("1007"),
    },
    {
      path: ROUTES.HOUSE_KEEPING,
      query: t("1008"),
    },
    {
      path: ROUTES.ACCESS_CONTROL,
      query: t("1005"),
    },
    {
      path: ROUTES.SUPPORT,
      query: t("46"),
    },
    {
      path: `${ROUTES.HOTEL_CONFIG}/1`,
      query: t("48"),
    },
  ];

  const handleChange = (event) => {
    let value = event?.target?.value;
    setQuery(value);

    let arr = [];
    if (value) {
      value = value?.toLowerCase();
      SEARCH_QUERIES?.map((qr) => {
        const text = qr?.query?.toLowerCase();
        if (text.includes(value)) {
          arr.push(qr);
        }
      });
    }
    setFilterQueries(arr);
  };

  const ref = useRef();

  useOutsideClick(ref, setOpen);

  return (
    <>
      <div
        className={
          "relative h-10 w-[446px] lg:w-[400px] xl:w-[600px] max-w-full border rounded-[10px] flex items-center"
        }
        ref={open ? ref : null}
      >
        <span className="text-xl text-[#A1A1AA] absolute left-2 top-2.5">
          <AiOutlineSearch />
        </span>
        <input
          className="h-full text-sm w-full rounded-[10px] border-none shadow-none pl-8"
          placeholder={t("1022")}
          onChange={handleChange}
          value={query}
          onFocus={() => setOpen(true)}
        />
        <div
          className={`absolute top-12 border left-0 bg-white shadow-sm rounded-lg w-full h-fit max-h-72 overflow-auto sidebar-scroll flex flex-col items-start ${open ? "" : "hidden"
            }`}
        >
          {filterQueries && filterQueries?.length
            ? filterQueries?.map((qr) => (
              <SearchQuery data={qr} setQuery={setQuery} key={qr?.query} />
            ))
            : SEARCH_QUERIES?.map((qr) => (
              <SearchQuery data={qr} setQuery={setQuery} key={qr?.query} />
            ))}
        </div>
      </div>
    </>
  );
};

export default SearchBar;
