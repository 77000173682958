import React from "react";
import Cards from "./Cards";
import laundry_tshirt from "../../images/laundry_tshirt.png";
import order_tshirt from "../../images/order_tshirt.png";
import OrderCards from "./OrderCards";
import CloseButton from "../../components/buttons/CloseButton";

const AddLaundryItem = (props) => {
  return (
    <div className="w-full mx-auto border border-[#E4E4E7] rounded-[10px] overflow-hidden bg-white  my-4">
      <div className="flex items-center justify-between p-4">
        <p className="text-[30px] leading-[37.8px] font-bold text-[#07090D] tracking-[-1.5%]">
          Add Laundry Items
        </p>
        <CloseButton onClick={props.close} />
      </div>

      <form action="" className="w-full">
        <div className="h-10 flex items-center text-[20px] leading-[25.6px] font-medium px-4">
          Select Item
        </div>
        {/* select item */}
        <div className="grid lg:grid-cols-6 w-full gap-4">
          {/* card section */}
          <div className="lg:col-span-4 md:col-span-6">
            <div className="grid lg:grid-cols-3 md:grid-cols-2 lg:gap-8 md:gap-4 h-[100vh] overflow-scroll overflow-y-auto scroll-barguest p-6">
              {Array.from({ length: 10 }).map((_, index) => (
                <Cards
                  key={index}
                  image={laundry_tshirt}
                  heading={"T Shirt (Laundry)"}
                  paragraph={
                    "Detail of T-Shirt  including all things if required "
                  }
                  price={"₹250"}
                  onClick={"#"}
                />
              ))}
            </div>
          </div>
          {/* order section  rounded-tr-none rounded-bl-none */}
          <div className="lg:col-span-2 md:col-span-6 overflow-hidden border-[#E4E4E7] border-l border-t rounded-tl-[10px]">
            <div className="flex flex-col h-[55vh] overflow-scroll scroll-barguest overflow-y-auto px-4 pt-2">
              {Array.from({ length: 5 }).map((_, index) => (
                <OrderCards
                  key={index}
                  image={order_tshirt}
                  heading={"T Shirt (Laundry)"}
                  price={"₹250"}
                />
              ))}
            </div>

            <div className="flex flex-col border-t border-[#E5E9EB] text-[16px] leading-7 tracking-[-1.5%] text-[#6E7C87] font-normal mt-4 p-4">
              <div className="flex items-center justify-between">
                <p className="w-28">Total</p>:
                <p className="text-[#07090D] font-medium w-28 text-end">
                  1579.00
                </p>
              </div>
              <div className="flex items-center justify-between">
                <p className="w-28">Discount</p>:
                <p className="text-[#07090D] font-medium w-28 text-end">
                  00.00
                </p>
              </div>
              <div className="flex items-center justify-between">
                <p className="w-28">Tax</p>:
                <p className="text-[#07090D] font-medium w-28 text-end">147</p>
              </div>
            </div>
            <div className="border-t-[2px] border-dashed mx-4 border-[#6E7C87] pt-2">
              <div className="flex items-center justify-between text-[16px] leading-7 font-semibold tracking-[-1.5%] text-[#07090D]">
                <p>Net Payable</p>
                <p>1600</p>
              </div>
              <button
                type="button"
                className="h-12 w-full rounded-[10px] bg-base-primary hover:bg-base-primary_hover text-white text-[16px] leading-3 font-normal mt-8 mb-4"
              >
                Create Order
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddLaundryItem;
