import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

// add to item kitchen menu action
export const addItemToKitchenMenuAction = createAsyncThunk(
  "kitchenMenu/addItemToKitchenMenuAction",
  async (credentials, thunkAPI) => {
    try {
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/kitchen/menu/add-item`;
      const response = await axios.post(url, credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// add to item kitchen menu slice
export const addItemToKitchenMenuSlice = createSlice({
  name: "kitchen/addItemToKitchenMenuSlice",
  initialState: {
    loading: false,
    isadded: false,
    menuItem: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addItemToKitchenMenuAction.pending, (state) => {
        state.loading = true;
        state.isadded = false;
        state.error = null;
      })
      .addCase(addItemToKitchenMenuAction.fulfilled, (state, action) => {
        state.loading = false;
        state.isadded = true;
        state.menuItem = action.payload.menuItem;
      })
      .addCase(addItemToKitchenMenuAction.rejected, (state, action) => {
        state.loading = false;
        state.isadded = false;
        state.error =
          action.payload || "Failed to add Item To Kitchen Menu";
      });
  },
});

// get all kitchen menu Action
export const getAllKitchenMenuItemAction = createAsyncThunk(
  "kitchen/getAllKitchenMenuItemAction",
  async ( credentials, thunkAPI) => {
    try {
      const { kitchenId } = credentials || {};
      if (!kitchenId) return;
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/kitchen/menu?id=${kitchenId}`;
      const response = await axios.get(url, credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// get all kitchen slice
export const getAllKitchenMenuItemSlice = createSlice({
  name: "kitchen/getAllKitchenMenuItemSlice",
  initialState: {
    loading: false,
    menuItems: [],
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllKitchenMenuItemAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllKitchenMenuItemAction.fulfilled, (state, action) => {
        state.loading = false;
        state.menuItems = action.payload.menuItems;
      })
      .addCase(getAllKitchenMenuItemAction.rejected, (state, action) => {
        state.loading = false;
        state.kitchens = null;
        state.error =
          action.payload || "Failed to get All Kitchen Menu";
      });
  },
});

// update kitchen menu action
export const updateKitchenMenuItemAction = createAsyncThunk(
  "kicthenMenu/updateKitchenMenuItemAction",
  async (credentials, thunkAPI) => {
    try {
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/kitchen/menu/update-item`;
      const response = await axios.put(url, credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

//update kitchen menu slice
export const updateKitchenMenuItemSlice = createSlice({
  name: "kicthenMenu/updatekitchenMenuItemSlice",
  initialState: {
    loading: false,
    isupdated: false,
    menuItem: {},
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateKitchenMenuItemAction.pending, (state) => {
        state.loading = true;
        state.isupdated = false;
        state.error = null;
      })
      .addCase(updateKitchenMenuItemAction.fulfilled, (state, action) => {
        state.loading = false;
        state.isupdated = true;
        state.menuItem = action.payload.menuItem;
      })
      .addCase(updateKitchenMenuItemAction.rejected, (state, action) => {
        state.loading = false;
        state.isupdated = false;
        state.error =
          action.payload || "Failed to update kitchen Menu Item";
      });
  },
});

// delete kitchen Menu action
export const deletekitchenMenuItemAction = createAsyncThunk(
  "kitchenMenu/deletekitchenMenuItemAction",
  async ( credentials, thunkAPI) => {
    try {
      const { kitchenId, itemId } = credentials || {};
      if (!kitchenId || !itemId) return;
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/kitchen/menu/delete-item`;
      const response = await axios.delete(url, {
        headers: credentials,
      });
      // localStorage.setItem(TOKEN, response.data.token);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);
//  delete kitchen menu slice
export const deletekitchenMenuItemSlice = createSlice({
  name: "kitchenMenu/deletekitchenMenuItemSlice",
  initialState: {
    loading: false,
    menuItem: {},
    isdeleted: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deletekitchenMenuItemAction.pending, (state) => {
        state.loading = true;
        state.isdeleted = false;
        state.error = null;
      })
      .addCase(deletekitchenMenuItemAction.fulfilled, (state, action) => {
        state.loading = false;
        state.isdeleted = true;
        state.menuItem = action.payload.menuItem;
      })
      .addCase(deletekitchenMenuItemAction.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.payload || "Failed to delete kicthen menu Item";
      });
  },
});
