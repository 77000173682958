import React, { useEffect } from "react"; 
import SupportLeft from "./SupportLeft";
import SupportRight from "./SupportRight";
import { connect, useSelector } from "react-redux"; 
import { useTranslation } from "react-i18next";
import { BiDotsVerticalRounded, BiSearch } from "react-icons/bi";
import { GoSearch } from "react-icons/go"; 

const Support = (props) => {
  const { t } = useTranslation();

  const { hotel } = useSelector((state) => state.Hotel);

  useEffect(() => {
    props.getQueries(hotel?._id);
  }, [hotel]);

  useEffect(() => {
    if (
      props?.queries &&
      props?.queries?.queries &&
      Array.isArray(props?.queries?.queries)
    ) {
    }
  }, [props.queries]);

  const headingData = {
    heading: t("941"),
    seperator: [
      {
        text: t("Guest Management"),
      },
      {
        text: t("46"),
      },
    ],
  };

  return (
    <div className="page-container">
      {/* <Seperator data={headingData} /> */}
      <div className="border w-full flex rounded-lg shadow-sm bg-white max-h-[calc(100vh-9rem)] overflow-hidden mt-6">
        {/* left side */}
        <div className="md:w-[364px] w-full flex flex-col">
          <div className="relative p-3">
            <BiSearch
              size={18}
              className="text-[#6E7C87] absolute top-[30px] left-6"
            />
            <input
              type="search"
              placeholder="Search For People"
              className="pl-9 h-[50px] text-[16px] leading-3 font-normal bg-[#F6F6F6]"
            />
          </div>
          <SupportLeft />
        </div>

        {/* right side */}
        <div className="w-full border-l border-[#D9DCE0]">
          <div className="flex items-center justify-between px-3 py-4 border-b border-[#D9DCE0]">
            <div className="flex items-center gap-4">
              {/* profile pic here */}
              <div className="size-10 rounded-[100px] border border-[#D9DCE0] flex justify-center items-center">
                {/* <img
                  src={salman}
                  alt=""
                  className="w-full rounded-[100px] bg-no-repeat"
                /> */}
              </div>
              {/* name and last seen */}
              <div>
                <div className="text-[16px] leading-5 font-semibold tracking-[-0.6%]">
                  David Moore
                </div>
                <div className="text-[14px] leading-[18px] font-normal tracking-[-0.6%] text-[#707991]">
                  Last seen 5 minuts ago
                </div>
              </div>
            </div>
            {/* serch and menu */}
            <div className="flex justify-end items-center gap-1 text-[#6E7C87]">
              <div className="size-10 rounded-full flex justify-center items-center">
                <GoSearch size={20} />
              </div>
              <div className="size-10 rounded-full flex justify-center items-center">
                <BiDotsVerticalRounded size={24} />
              </div>
            </div>
          </div>
          <SupportRight />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  queries: state.AllQuery,
});

const mapDispatchToProps = (dispatch) => ({ 
});

export default connect(mapStateToProps, mapDispatchToProps)(Support);
