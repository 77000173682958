import React, { useState } from "react";
import  CustomeTable   from "../../components/widget/CustomeTable";
import Assign from "./Assign";
import RoomCleaningTime from "./RoomCleaningTime";
import CheckStatus from "./CheckStatus";

const CheckOutTomarrow = () => {
  const [selectedRows, setSelectedRows] = useState([]);

  const tableData = [
    {
      room_no: "100",
      checkouttime: "10:00 AM",
      cleaningtime: "10:15 AM",
      cleaninstatus: "completed",
      assign_to: "assign",
    },
    {
      room_no: "101",
      checkouttime: "10:00 AM",
      cleaningtime: "10:15 AM",
      cleaninstatus: "In Progress",
      assign_to: "E023 Masud , E024 Rajot",
    },
    {
      room_no: "102",
      checkouttime: "10:00 AM",
      cleaningtime: "10:15 AM",
      cleaninstatus: "completed",
      assign_to: "assign",
    },
    {
      room_no: "103",
      checkouttime: "10:00 AM",
      cleaningtime: "10:15 AM",
      cleaninstatus: "Not Started",
      assign_to: "E023 Masud , E024 Rajot",
    },
    {
      room_no: "104",
      checkouttime: "10:00 AM",
      cleaningtime: "10:15 AM",
      cleaninstatus: "In Progress",
      assign_to: "assign",
    },
  ];

  const columnDefs = [
    {
      headerName: "Room ID",
      field: "_id",
      minWidth: 150,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Room No",
      field: "room_no",
      minWidth: 150,
      filter: true,
    },
    {
      headerName: "Checkout Time",
      field: "checkouttime",
      minWidth: 150,
      filter: true,
    },
    {
      headerName: "Cleaning time",
      field: "cleaningtime",
      minWidth: 150,
      filter: true,
      cellRenderer: RoomCleaningTime,
    },
    {
      headerName: "Cleaning Status",
      field: "cleaninstatus",
      minWidth: 150,
      filter: true,
      cellRenderer: CheckStatus,
    },
    {
      headerName: "assigned to",
      field: "assign_to",
      minWidth: 150,
      filter: true,
      cellRenderer: Assign,
    },
  ];
  return (
    <div className="relative">
      <CustomeTable
        tableName={"Checkout Tomorrow table"}
        rowData={tableData}
        setSelectedRows={setSelectedRows}
        columnDefs={columnDefs}
      />
    </div>
  );
};

export default CheckOutTomarrow;
