import React from "react";
import ToolTip from "../common/ToolTip"; 

const InputDate = ({ label, value, setValue, valueError, toolTipMessage }) => {
  return (
    <>
      <div className="flex justify-between pr-2">
        <label className="text-sm font-semibold text-[#344054]">{label}</label>
        <ToolTip Message={toolTipMessage} />
      </div>
      <div className="relative flex items-center">
        <input
          type="date"
          onChange={(e) => setValue(e.target.value)}
          value={value}
          className="border border-[#D0D5DD] w-full text-sm py-2 px-3 rounded-md focus:border-base-primary mr-2 h-10"
        />
      </div> 
    </>
  );
};

export default InputDate;
