import React, { useState, useEffect } from "react";
import { registerEvent } from "../../logs";
import AddExpense from "./AddExpense";
import AllExpense from "./AllExpense";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
// import Seperator from "../../components/layouts/Seperator";
import AddButton from "../../components/buttons/AddButton";
import ExpenseHeader from "./ExpenseHeader";
import { getAllExpenseAction } from "../../lib/slices/expense/expenseSlice";
import { getAllVendorAction } from "../../lib/slices/vendor/vendorSlice";

const ExpensePage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [addButton, setAddButton] = useState(false);
  const { hotel } = useSelector((state) => state.hotel);
  console.log("check hotel in expense : ", hotel);
  const [index, setIndex] = useState(1);
  //register event
  const registerEventAndOpen = () => {
    setAddButton(true);
    registerEvent("add expense button open", "add asset", { addButton });
  };

  // register evnet on close add asset component
  const close = () => {
    setAddButton(false);
    registerEvent("add expense button close", "add asset", { addButton });
  };

  const headingData = {
    heading: t("243"),
    seperator: [
      {
        text: t("241"),
      },
      {
        text: t("243"),
      },
    ],
  };

  useEffect(() => {
    if (hotel?._id) {
      dispatch(getAllExpenseAction(hotel?._id));
      dispatch(getAllVendorAction(hotel?._id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="page-container">
      <div className="flex justify-between items-end">
        {/* <Seperator data={headingData} /> */}
        <div className="flex gap-2">
          <AddButton text={t("242")} onClick={registerEventAndOpen} />
        </div>
      </div>
      <div className="">
        <ExpenseHeader index={index} setIndex={setIndex} />
      </div>
      {addButton && <AddExpense close={close} hotel={hotel} />}
      {+index === 1 ? <AllExpense hotel={hotel} /> : null}
    </div>
  );
};

export default ExpensePage;
