import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { TOKEN } from "../../../constants";

// employe permission action -- incomplete
export const employeePermissionAction = createAsyncThunk(
  "employeePermission/employeePermissionAction",
  async (credentials, thunkAPI) => {
    try {
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/employee/permission`;
      const response = await axios.post(url, credentials);
      localStorage.setItem(TOKEN, response.data.token);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

//Add employee work slice
export const employeePermissionSlice = createSlice({
  name: "employeePermission/employeePermissionSlice",
  initialState: {
    loading: true,
    isadded: false,
    employee: {},
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(employeePermissionAction.pending, (state) => {
        state.loading = true;
        state.isadded = false;
        state.error = null;
      })
      .addCase(employeePermissionAction.fulfilled, (state, action) => {
        state.loading = false;
        state.isadded = true;
        state.employee = action.payload.employee;
      })
      .addCase(employeePermissionAction.rejected, (state, action) => {
        state.loading = false;
        state.isadded = false;
        state.error =
          action.payload?.message || "Failed to employee permission";
      });
  },
});

// Delete employee permission Action
export const deleteEmployeePermissionAction = createAsyncThunk(
  "employeePermission/deleteEmployeePermissionAction",
  async ({ hotelId, employeeId, extraWorkId }, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const token = state.auth.token; // Assuming the token is stored in the auth slice of your Redux state
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/employee/extra-work/${hotelId}/${employeeId}/${extraWorkId}`;
      const response = await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error?.response?.data?.message || error.message
      );
    }
  }
);
// delete employee leave slice
export const deleteEmployeePermissionSlice = createSlice({
  name: "employeePermission/deleteEmployeePermissionSlice",
  initialState: {
    loading: true,
    isdelete: false,
    employee: {},
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteEmployeePermissionAction.pending, (state) => {
        state.loading = true;
        state.isdelete = false;
        state.error = null;
      })
      .addCase(deleteEmployeePermissionAction.fulfilled, (state, action) => {
        state.loading = false;
        state.isdelete = true;
        state.employee = action.payload.employee;
      })
      .addCase(deleteEmployeePermissionAction.rejected, (state, action) => {
        state.loading = false;
        state.isdelete = false;
        state.error =
          action.payload?.message || "Failed to delete employee permission";
      });
  },
});
