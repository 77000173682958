import axios from 'axios';
import { TOKEN } from '../../constants';

const getToken = () => {
    return localStorage.getItem(TOKEN);
};

// Utility function to check connectivity
const isOnline = () => window.navigator.onLine;

// Create an Axios instance
const authAxios = axios.create({
    baseURL: process.env.REACT_APP_BASE_API_URL,
});

// Add a request interceptor to include the Authorization header and validate connectivity
authAxios.interceptors.request.use(
    (config) => {
        if (!isOnline()) {
            console.warn("Offline: Cannot make API call");
            return Promise.reject(new Error("No internet connection"));
        }

        const token = getToken();
        if (token) {
            config.headers['x-auth-token'] = token;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default authAxios;
