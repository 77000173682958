import React, { useState } from "react";
import CustomeTable from "../../components/widget/CustomeTable";
import { MdSettingsBackupRestore } from "react-icons/md";

const AllLogs = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const rowData = [
    {
      moduleChange: "Customer security settings changed",
      dateAndTime: "07-8-2024 12:40AM",
      employeeName: "Muhammad salman",
      employeeID: "E001",
      designation: "HR Manager",
      department: "Finance",
    },
    {
      moduleChange:
        "Updated hamza115588ameer@gmail notification setting to not receive Google market research mes",
      dateAndTime: "07-8-2024 12:40AM",
      employeeName: "Muhammad salman",
      employeeID: "E001",
      designation: "HR Manager",
      department: "Finance",
    },
  ];

  const chnageLogsActions = () => {
    return (
      <button className="size-[33px] rounded-md flex items-center justify-center transition-all duration-[1s] ease-[0.5s] text-white bg-base-primary hover:bg-base-primary_hover mt-1">
        <MdSettingsBackupRestore size={20} className="transition-all duration-[1.5s] hover:-rotate-[720deg]" />
      </button>
    );
  };

  const columnDef = [
    {
      headerName: "Module Change",
      field: "moduleChange",
      maxWidth: 300,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Date / Time",
      field: "dateAndTime",
      minWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Employee Name",
      field: "employeeName",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Employee ID",
      field: "employeeID",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Designation",
      field: "designation",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Department",
      field: "department",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Restore",
      minWidth: 200,
      cellRenderer: chnageLogsActions,
    },
  ];
  return (
    <div>
      <CustomeTable
        rowData={rowData}
        columnDefs={columnDef}
        setSelectedRows={setSelectedRows}
      />
    </div>
  );
};

export default AllLogs;
