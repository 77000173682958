import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

// update vendor profile action
export const updateVendorProfileAction = createAsyncThunk(
  "vendorProfile/updateVendorProfileAction",
  async (credentials, thunkAPI) => {
    try {
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/vendor`;
      const response = await axios.put(url, credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);
//update vendor profile slice
export const updateVendorProfileSlice = createSlice({
  name: "vendorProfile/updateVendorProfileSlice",
  initialState: {
    loading: false,
    isupdated: false,
    vendor: {},
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateVendorProfileAction.pending, (state) => {
        state.loading = true;
        state.isupdated = false;
        state.error = null;
      })
      .addCase(updateVendorProfileAction.fulfilled, (state, action) => {
        state.loading = false;
        state.isupdated = true;
        state.vendor = action.payload.vendor;
      })
      .addCase(updateVendorProfileAction.rejected, (state, action) => {
        state.loading = false;
        state.isupdated = false;
        state.error = action.payload || "Failed to update vendor Profile";
      });
  },
});

// delete vendor profile action
export const deleteVendorProfileAction = createAsyncThunk(
  "vendorProfile/deleteVendorProfileAction",
  async (credentials, thunkAPI) => {
    try {
      const { hotelId, vendorId } = credentials || {};
      if (!hotelId || !vendorId) return;
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/vendor?vendorId=${vendorId}&hotelId=${hotelId}`;
      const response = await axios.delete(url, {
        headers: credentials,
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);
//  delete vendor profile slice
export const deleteVendorProfileSlice = createSlice({
  name: "vendorProfile/deleteVendorProfileSlice",
  initialState: {
    loading: false,
    vendor: {},
    isdeleted: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteVendorProfileAction.pending, (state) => {
        state.loading = true;
        state.isdeleted = false;
        state.error = null;
      })
      .addCase(deleteVendorProfileAction.fulfilled, (state, action) => {
        state.loading = false;
        state.isdeleted = true;
        state.vendor = action.payload.vendor;
      })
      .addCase(deleteVendorProfileAction.rejected, (state, action) => {
        state.loading = false;
        // state.vendor = [];
        state.error = action.payload || "Failed to delete vendor profile";
      });
  },
});
