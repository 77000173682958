import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { registerEvent } from "../../logs";
import AddBooking from "./AddBooking";
import AllBooking from "./AllBooking";
import { useTranslation } from "react-i18next";
// import Seperator from "../../components/layouts/Seperator";
import AddButton from "../../components/buttons/AddButton";
// import { getAllBookingAction } from "../../actions/pms/bookingGet";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants";
// import { DeleteButton, EditButton } from "../../components";
import SubmitButton from "../../components/buttons/SubmitButton";
import InputDate from "../../components/form/InputDate";

const BookingPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [booking, setBooking] = useState(null);
  const { hotel } = useSelector((state) => state.Hotel);
  const [data, setDate] = useState("");
  // const addBookingModalOpen = () => {
  //   setOpen(true);
  //   if (booking && booking?._id) setBooking(null);
  //   registerEvent("click add booking button", "all booking page");
  // };

  const EditsBooking = () => {
    navigate(ROUTES.BOOKINGS);
  };
  const CancelBooking = () => {
    navigate(ROUTES.CANCEL_BOOKING);
  };
  const openAddBooking = () => {
    navigate(ROUTES.ADD_BOOKING);
  };

  //close add employee modal
  const close = () => {
    setOpen(false);
    if (booking && booking?._id) setBooking(null);
    registerEvent("close add booking component", "add booking page");
  };

  useEffect(() => {
    // dispatch(getAllBookingAction(hotel?._id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const headingData = {
    heading: t("133"),
    seperator: [
      {
        text: t("Front Desk"),
      },
      {
        text: t("35"),
      },
    ],
  };

  return (
    <div className="page-container">
      <div className="flex justify-between items-end mx-auto">
        {/* <Seperator data={headingData} /> */}
        <div className="flex items-center">
          <SubmitButton type="button" text={"Today"} />
          <InputDate
            value={data}
            setValue={(e) => setDate(e.target.value)}
            className={"h-10 w-[196px]"}
          />
          <div className="flex gap-2">
          <SubmitButton
            type="button"
            className="h-10 w-[132px]"
            text={"Auto Sync"}
          />
          <SubmitButton
            type="button"
            className="h-10 w-[182px]"
            text={"Automation Rule"}
          />
          <AddButton text={t("132")} onClick={() => openAddBooking()} />
          </div>
        </div>
      </div>
      {open || (booking && booking?._id) ? (
        <div className="mt-6">
          <AddBooking close={close} hotel={hotel} booking={booking} />
        </div>
      ) : null}
      <div className="mt-6">
        <AllBooking setBooking={setBooking} hotel={hotel} />
      </div>
    </div>
  );
};

export default BookingPage;
