import React, { useEffect, useState } from "react";
import {  useSelector } from "react-redux";
import ReportsCard from "./ReportsCard";
import checkAllIcon from "../../images/icons/checkAllIcon.svg";
import doubleCheckIcon from "../../images/icons/doubleCheckIcon.svg";
import storageIcon from "../../images/icons/storageIcon.svg";
import assetCameraIcon from "../../images/icons/assetCameraIcon.svg";
import CustomeTable from "../../components/widget/CustomeTable";

const AssetsReport = (props) => {
  // const { t } = useTranslation();
  const [datas, setDatas] = useState([]);
  const { sales } = useSelector((state) => state.SalesReport);

  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    props.getAssetsFromToDateAll(
      props?.hotel?._id,
      props?.fromDate,
      props?.toDate
    );
  }, [props?.fromDate, props?.toDate]);

  useEffect(() => {
    if (sales && Array.isArray(sales) && sales?.length) {
      let expense = sales?.map((d) => {
        return {
          _id: d?._id,
          assetName: d?.assetName,
          consumedIn: d?.consumedIn,
          inUse: d?.inUse,
          available: d?.available,
          price: d?.price,
        };
      });
      setDatas(expense);
    }
  }, [sales]);
  const tableData = [
    {
      _id: "EX001",
      assetName: "LCD TV",
      consumedIn: "Lobby",
      inUse: "10",
      available: "5",
      price: "$10,000",
    },
    {
      _id: "EX002",
      assetName: "Air Conditioner",
      consumedIn: "Restaurant",
      inUse: "10",
      available: "3",
      price: "$10,000",
    },
    {
      _id: "EX003",
      assetName: "Refrigerator",
      consumedIn: "Room 101",
      inUse: "8",
      available: "2",
      price: "$10,000",
    },
    {
      _id: "EX004",
      assetName: "LCD TV",
      consumedIn: "Lobby",
      inUse: "10",
      available: "2",
      price: "$10,000",
    },
    {
      _id: "EX005",
      assetName: "Air Conditioner",
      consumedIn: "Restaurant",
      inUse: "8",
      available: "5",
      price: "$10,000",
    },
  ];

  const columnDefs = [
    {
      headerName: "Assets ID",
      field: "_id",
      minWidth: 150,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Asset Name",
      field: "assetName",
      filter: true,
    },
    {
      headerName: "In Use",
      field: "inUse",
      filter: true,
    },
    {
      headerName: "Available",
      field: "available",
      filter: true,
    },
    {
      headerName: "Price",
      field: "price",
      filter: true,
    },
  ];
  return (
    <div className="page-container">
      <div className="grid grid-cols-4 items-center gap-4 my-4">
        <ReportsCard
          icon={checkAllIcon}
          iconBgColor={"bg-[#F1FADF]"}
          heading={"Total Assets"}
          value={"1258"}
        />
        <ReportsCard
          icon={doubleCheckIcon}
          iconBgColor={"bg-[#E8EBFF]"}
          heading={"In Use"}
          value={"800"}
        />
        <ReportsCard
          icon={storageIcon}
          iconBgColor={"bg-[#FCEEDD]"}
          heading={"In Storage"}
          value={"425"}
        />
        <ReportsCard
          icon={assetCameraIcon}
          iconBgColor={"bg-[#FCEAEA]"}
          heading={"Assets Utilization"}
          value={"425"}
        />
      </div>
      {/* {datas?.datas?.length?( */}
      <CustomeTable
        tableName={"Assets Report table"}
        columnDefs={columnDefs}
        rowData={tableData}
        setSelectedRows={setSelectedRows}
      />
      {/* ):null} */}
    </div>
  );
};

// const mapStateToProps = (state) => ({
//   data: state.AssetsReport,
// });

// const mapDispatchToProps = (dispatch) => ({
//   getAllTimeAsset: (hotelId) => dispatch(assetsReportAllTime(hotelId)),
//   //get assets report by date
//   getAssetsReportAll: (hotelId, assetId) =>
//     dispatch(assetsReportAllTimeForOne(hotelId, assetId)),
//   getAssetsFromToDateAll: (hotelId, fromDate, toDate) =>
//     dispatch(assetsReportAllTimeByDate(hotelId, fromDate, toDate)),
// });

export default AssetsReport;
