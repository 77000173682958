import React from 'react';

export default function CircularProgressWithLabel({ value = 0 }) {
  const radius = 20; // Radius of the circle
  const circumference = 2 * Math.PI * radius; // Circumference of the circle
  const strokeDashoffset = circumference * (1 - value / 100); // Offset for the progress


  return (
    <div className="relative inline-flex items-center justify-center">
      {/* Circular progress */}
      <svg className="w-16 h-16 -rotate-90">
        <circle
          className="text-gray-300"
          strokeWidth="5"
          stroke="currentColor"
          fill="transparent"
          r="20"
          cx="32"
          cy="32"
        />
        <circle
          className="text-base-primary"
          strokeWidth="5"
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          strokeLinecap="round"
          stroke="currentColor"
          fill="transparent" 
          r="20"
          cx="32"
          cy="32"
        />
      </svg>
      {/* Label */}
      <div className="absolute text-center text-gray-700 text-sm">
        {Math.round(value)}%
      </div>
    </div>
  );
}

