import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; 
import LoadSpinner from "../../components/common/loader/LoadSpinner";
import { useDispatch, useSelector } from "react-redux"; 
import { ROLE, ROUTES } from "../../constants";
import BackGroundLoadSpinner from "../../components/common/loader/BackGroundLoadSpinner";
import { useTranslation } from "react-i18next";
import { ownerAuthAction, verifyEmailAction, verifyEmailSendOTPAction } from "../../lib/slices/auth/authSlice";

const VerifyEmailOTP = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [timer, setTimer] = useState(120); // Timer set to 2 minutes (120 seconds)
  const [otp, setOtp] = useState("");
  const { loading } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.user);
  useEffect(() => {
    if (timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => clearInterval(countdown); // Cleanup the interval
    }  
  }, [timer]);


  // Submit OTP
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!otp || otp.length !== 6) {
      // notifyError(t("989"));
      return;
    }
    dispatch(verifyEmailAction({ otp, email: user?.email }));
    dispatch(ownerAuthAction());
  };

  const sendOtp = () => {
    verifyEmailSendOTPAction({ email: user?.email }).then((res) => {
      setTimer(120);
    }).catch((error)=>{
      console.log(error)
      // notifyError(error)
    })
  }

  useEffect(() => {
    sendOtp()
  }, []);

  // Redirect owner after authenticated
  useEffect(() => {
    if ((user && user?.isEmailVerfied) || user?.role !== ROLE.OWNER ) {
      navigate(ROUTES.ONBOARDING);
    }
  }, [user]);

  return (
    <>
      <div className="w-full bg-[#fcf5ec] min-h-screen p-4 relative">
        <div className="w-[450px] mx-auto max-w-full rounded flex flex-col shadow-md my-8 bg-[#FFFFFF]">
          <div className="w-full pt-6">
            <h1 className="text-3xl font-medium text-center text-base-primary capitalize">
              {t("961")}
            </h1>
            <p className="text-[#616161] w-full text-center pt-2">
              {t("962")}
              <strong>{user?.email}</strong>
            </p>
          </div>
          <form className="w-full p-8" onSubmit={handleSubmit}>
            <div className="w-full mb-6 relative sm:mb-8 flex justify-between">
              <input
                type="text"
                name="otp"
                value={otp}
                maxLength={6}
                minLength={6}
                onChange={(e) => setOtp(e.target.value)}
                className="w-full tracking-[1rem] sm:tracking-[2rem] text-center outline-none border border-gray-300 py-3 focus:border-black rounded-3xl"
              />
              <button type="button" onClick={()=>sendOtp()}>Resend</button>
            </div>
            <button
              className="inline-block h-12 w-full bg-base-primary text-[#FFFFFF] rounded-3xl font-semibold"
              disabled={loading}
            >
              {loading ? <LoadSpinner /> : <>{t("963")}</>}
            </button>
          </form>
        </div>
      </div>
      {loading ? <BackGroundLoadSpinner /> : null}
    </>
  );
};

export default VerifyEmailOTP;
