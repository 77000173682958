import React, { useEffect, useState } from "react";
import TaxSelectionOption from "./TaxSelectionOption";
import { useTranslation } from "react-i18next";
import Inputfield from "../../../components/form/InputField";

const TaxCitizenTariff = ({
  roomTaxType,
  setRoomTaxType,
  childPrice,
  setChildPrice,
  adultPrice,
  setAdultPrice,
  options, 
}) => {
  const { t } = useTranslation();
  const [isChildrenPer, setIsChildrenPer] = useState(false);
  const [isAdultPer, setIsAdultPer] = useState(false);

  useEffect(() => {
    if (adultPrice) setIsAdultPer(true);
    else setIsAdultPer(false);
    if (childPrice) setIsChildrenPer(true);
    else setIsChildrenPer(false);
  }, [isChildrenPer, isAdultPer, adultPrice, childPrice]);

  return (
    <>
      <div className="grid grid-cols-3 gap-3 mt-6">
        <TaxSelectionOption
          value={roomTaxType}
          setValue={setRoomTaxType}
          label={t("444")}
          options={options}
        />
        <Inputfield
          value={adultPrice}
          onChange={(e) => setAdultPrice(e?.target?.value)}
          label={t("445")}
          required={true}
          min={0}
          max={100}
        />
        <Inputfield
          value={childPrice}
          onChange={(e) => setChildPrice(e?.target?.value)}
          label={t("447")}
          required={true}
          min={0}
          max={100}
        />
      </div>
    </>
  );
};

export default TaxCitizenTariff;
