import React from "react";
import CustomModal from "./CustomModal";
import Inputfield from "../form/InputField";
import { GoCopy } from "react-icons/go";
import SubmitButton from "../buttons/SubmitButton";

const ChangePassword = ({
  pass,
  setPass,
  newPass,
  setNewPass,
  reEnterPass,
  setReEnterPass,
  close = () => {},
}) => {
  return (
    <CustomModal close={close} heading={"Change Password"}>
      <div className="w-[40vw] flex flex-col gap-4 pt-6">
        <Inputfield
          type="password"
          label={"Current Password"}
          value={pass}
          onChange={(e) => setPass(e.target.value)}
          icon={<GoCopy size={22} />}
          placeholder={"Enter Current Password"}
        />
        <Inputfield
          type="password"
          label={"New Password"}
          value={newPass}
          onChange={(e) => setNewPass(e.target.value)}
          icon={<GoCopy size={22} />}
          placeholder={"Enter New Password"}
        />
        <Inputfield
          type="password"
          label={"Re-Enter New Password"}
          value={reEnterPass}
          onChange={(e) => setReEnterPass(e.target.value)}
          icon={<GoCopy size={22} />}
          placeholder={"Re-Enter New Password"}
        />
        <div className="w-full mt-12 mb-2">
          <SubmitButton className="w-full h-12" text={"Change Password"} />
        </div>
      </div>
    </CustomModal>
  );
};

export default ChangePassword;
