import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { TOKEN } from "../../../constants";

// add employe work action
export const addEmployeeExtraWorkAction = createAsyncThunk(
  "employeeWork/addEmployeeExtraWorkAction",
  async (credentials, thunkAPI, formData) => {
    try {
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/employee/extra-work`;
      const response = await axios.post(url, formData, credentials);
      localStorage.setItem(TOKEN, response.data.token);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

//Add employee work slice
export const addEmployeeExtraWorkSlice = createSlice({
  name: "employeeWork/addEmployeeLeaveSlice",
  initialState: {
    loading: true,
    isadded: false,
    employee: {},
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addEmployeeExtraWorkAction.pending, (state) => {
        state.loading = true;
        state.isadded = false;
        state.error = null;
      })
      .addCase(addEmployeeExtraWorkAction.fulfilled, (state, action) => {
        state.loading = false;
        state.isadded = true;
        state.employee = action.payload.employee;
      })
      .addCase(addEmployeeExtraWorkAction.rejected, (state, action) => {
        state.loading = false;
        state.isadded = false;
        state.error =
          action.payload?.message || "Failed to add employee extra work";
      });
  },
});

// Delete employee leave Action
export const deleteEmployeeExtraWorkAction = createAsyncThunk(
  "employeeWork/deleteEmployeeExtraWorkAction",
  async ({ hotelId, employeeId, extraWorkId }, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const token = state.auth.token; // Assuming the token is stored in the auth slice of your Redux state
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/employee/extra-work/${hotelId}/${employeeId}/${extraWorkId}`;
      const response = await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error?.response?.data?.message || error.message
      );
    }
  }
);
// delete employee leave slice
export const deleteEmployeeExtraWorkSlice = createSlice({
  name: "employeeWork/deleteEmployeeWorkSlice",
  initialState: {
    loading: true,
    isdelete: false,
    employee: {},
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteEmployeeExtraWorkAction.pending, (state) => {
        state.loading = true;
        state.isdelete = false;
        state.error = null;
      })
      .addCase(deleteEmployeeExtraWorkAction.fulfilled, (state, action) => {
        state.loading = false;
        state.isdelete = true;
        state.employee = action.payload.employee;
      })
      .addCase(deleteEmployeeExtraWorkAction.rejected, (state, action) => {
        state.loading = false;
        state.isdelete = false;
        state.error =
          action.payload?.message || "Failed to delete employee work";
      });
  },
});
