import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import { ToastContainer } from "react-toastify";
import { THEMES, ROUTES } from "./constants";
import routes from "./middlewares/Routes";
import OnBoardingRoutes from "./middlewares/OnBoardingRoutes";
import LandingPage from "./pages";
import LoginPage from "./pages/auth/LoginPage";
import SignupPage from "./pages/auth/SignupPage";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ResetPassword from "./pages/auth/ResetPassword";
import EmployeeLogin from "./pages/employee/EmployeeLogin"; 
import Tutorial from "./pages/tutorial";
import NotFound404 from "./components/NotFound404";
import AdminAndEmployeeRoute from "./middlewares";
import { GoogleOAuthProvider } from "@react-oauth/google";
import OnboardingPages from "./pages/onBoarding";

function App() {
  const [theme, setTheme] = useState(THEMES.GREEN);

  return (
    <div className={theme}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_ID}>
        <Router>
          <Routes>
            {/* Tutorial route */}
            <Route path="tutorial" element={<Tutorial />} />

            {/* Public routes */}
            <Route path={ROUTES.LANDING_PAGE} element={<LandingPage />} />
            <Route path={ROUTES.LOGIN} element={<LoginPage />} />
            <Route path={ROUTES.REGISTER} element={<SignupPage />} />
            <Route path={ROUTES.PASSWORD_FORGOT} element={<ForgotPassword />} />
            <Route path={ROUTES.PASSWORD_RESET} element={<ResetPassword />} />
            <Route path={ROUTES.EMPLOYEE_LOGIN} element={<EmployeeLogin />} /> 

            {/* Onboarding routes */}
            <Route element={<OnBoardingRoutes />}>
              <Route path={ROUTES.ONBOARDING} element={<OnboardingPages />} />
            </Route>

            {/* Admin and employee shared routes */}
            <Route element={<AdminAndEmployeeRoute />}>
              {routes.map(({ path, element, permissions }, index) => (
                <Route key={index} path={path} element={element} />
              ))}
            </Route>

            {/* Catch all - 404 Not Found */}
            <Route path="*" element={<NotFound404 />} />
          </Routes>
          <ToastContainer style={{ marginTop: "50px" }} />
        </Router>
      </GoogleOAuthProvider>
    </div>
  );
}

export default App;
