import React, { useState } from "react";
import CloseButton  from "../../../components/buttons/CloseButton";
import SelectFromObject from "../../../components/form/SelectFromObject";
import { ROOM_AMENITIES } from "../../../constants"; 
import SubmitButton from "../../../components/buttons/SubmitButton";
import { RxCross2 } from "react-icons/rx"; 

const RoomAmenityConfig = (props) => {
  const [amenitiesListName, setAmenitiesListName] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);

  const handleSelectAmenity = (item) => {
    if (selectedItems.includes(item)) {
      setSelectedItems(
        selectedItems.filter((selectedItem) => selectedItem !== item)
      );
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  const handleRemoveValue = (value) => {
    setSelectedItems(
      selectedItems.filter((selectedItem) => selectedItem !== value)
    );
  };

  return (
    <div className="relative w-full bg-white border border-[#D0D5DD] rounded-[10px] p-6 mt-4">
      <div className="text-center text-[30px] leading-[37.8px] text-[#07090D] font-bold">
        Room Amenity Configuration
      </div>
      <div className="absolute right-6 top-8">
        <CloseButton onClick={props.close} />
      </div>
      <div className="w-2/5 mt-6">
        <SelectFromObject
          label={"Room Amenity List Name"}
          options={{
            roomAmenityList1: "Room Amenity List 1",
            roomAmenityList2: "Room Amenity List 2",
            roomAmenityList3: "Room Amenity List 3",
          }}
          value={amenitiesListName}
          setValue={setAmenitiesListName}
        />
      </div>
      <div className="w-full h-[69vh] overflow-hidden mt-6 border border-[#D0D5DD] pl-4 pt-4 rounded-[10px]">
        <div className="text-[24px] leading-[30.24px] font-bold tracking-[-1.5%] text-[#07090D]">
          Select Room Amenities
        </div>
        <ul className="bg-white h-[60vh] overflow-y-auto overflow-x-hidden dropdown-scroll flex flex-wrap text-base text-[#000000] capitalize gap-4 py-4 pr-2">
          {selectedItems.map((value) => (
            <div
              key={value}
              className="flex items-center justify-between px-4 py-2 border border-base-primary rounded-lg bg-[#00a35e33]"
            >
              <div>{value.replace("_", " ")}</div>
              <button
                type="button"
                onClick={() => handleRemoveValue(value)}
                className="ml-2"
              >
                <RxCross2
                  size={18}
                  className="transition-all ease-in-out duration-300 scale-90 hover:scale-110 hover:text-[#F04438]"
                />
              </button>
            </div>
          ))}

          {Object.keys(ROOM_AMENITIES).map((item) => (
            <li key={item}>
              <button
                type="button"
                onClick={() => handleSelectAmenity(item)}
                className={`
                cursor-pointer select-none h-12 px-4 flex items-center gap-3 border border-[#D0D5DD] rounded-lg`}
              >
                {ROOM_AMENITIES[item].replace("_", " ")}
              </button>
            </li>
          ))}
        </ul>
      </div>
      <div className="flex items-center justify-end gap-4 mt-6">
        {/* <SubmitButton
            type="button"
            text={"Add Amenities"}
          /> */}
        <SubmitButton type="button" text={"save List"} />
      </div>
    </div>
  );
};

export default RoomAmenityConfig;
