import React, { useState } from "react"; 
import SpaConfig from "./SpaConfig";
import CheckRadioInputs from "../../components/form/CheckRadioInputs";

const SpaPage = (props) => {
  const [isSpaServices, setIsSpaServices] = useState(false);
  const track = {
    heading: "Spa",
    seperator: [
      {
        text: "Front Desk",
      },
      {
        text: "spa",
      },
    ],
  };

  return (
    <div className="page-container">
      <div className="flex items-end justify-between">
        {/* <Seperator data={track} /> */}
        <CheckRadioInputs
          label={"Spa Services"}
          value={isSpaServices}
          onChange={() => setIsSpaServices(!isSpaServices)}
        />
      </div>
      <SpaConfig />
    </div>
  );
};

export default SpaPage;
