import React, { useEffect, useState } from "react";
import {
  HiOutlineOfficeBuilding,
  HiOutlineUserGroup,
  HiOutlineUsers,
} from "react-icons/hi";
import { TiArrowLeft } from "react-icons/ti";
import { LuShoppingBag } from "react-icons/lu";
import { GiDesk, GiMagicBroom } from "react-icons/gi";
import { RiHome5Line } from "react-icons/ri";
import { TbExchange, TbReportAnalytics, TbSettingsCog } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { MdOutlineSoupKitchen } from "react-icons/md";
import MenuItems from "./MenuItems";
import SingleMenu from "./SingleMenu";
import { PiHandCoins } from "react-icons/pi";
import { LiaUserCogSolid } from "react-icons/lia";
import { ROUTES } from "../../constants";
import { togglehamburger } from "../../lib/slices/toggleEvent";

const SideBar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { open } = useSelector((state) => state.hamburger);
  const routes = [
    // home
    {
      name: t("All hotels"),
      Icon: HiOutlineOfficeBuilding,
      options: [{ link: ROUTES.HOTELS, name: "Hotels" }],
    },
    {
      name: t("Deshboard"),
      Icon: RiHome5Line,
      options: [{ link: ROUTES.DASHBOARD, name: "overview" }],
    },

    {
      name: t("front desk"),
      Icon: GiDesk,
      options: [
        { link: ROUTES.ROOMS, name: "Rooms" },
        { link: ROUTES.RESERVATION_GRID, name: "Reservation" },
        { link: ROUTES.BOOKINGS, name: "Booking" },
        { link: ROUTES.COUNTER_CASH_WALLET, name: "Counter Cash Wallet" },
        { link: ROUTES.NIGHT_AUDIT, name: "Night Audit" },
        { link: ROUTES.FOOD, name: "food order" },
        { link: ROUTES.SPA, name: "Spa" },
        { link: ROUTES.LAUNDRY, name: "Laundry" },
        { link: ROUTES.GUEST_FOLIO, name: "Guest Folio" },
        { link: ROUTES.GUEST_ISSUE_REPORT, name: "Guest Issue Report" },
        { link: ROUTES.REPORT_ISSUE_TICKET, name: "Report Issue Ticket" },
        { link: ROUTES.EQUIPMENT, name: "Equipments" },
      ],
    },
    {
      name: t("Housekeeping & Maintenance"),
      Icon: GiMagicBroom,
      options: [
        { link: ROUTES.HOUSE_KEEPING, name: "House Keeping" },
        { link: ROUTES.MAINTENANCE, name: "Maintenance" },
      ],
    },
    {
      name: t("Inventory Management"),
      Icon: LuShoppingBag,
      options: [
        { link: ROUTES.ASSETS, name: "Assets" },
        { link: ROUTES.PURCHASE_MANAGEMENT, name: "Purchase" },
      ],
    },
    {
      name: t("Financial Management"),
      Icon: PiHandCoins,
      options: [
        { link: ROUTES.FINANCES, name: "Finances" },
        { link: ROUTES.EXPENSES, name: "Expense" },
      ],
    },
    {
      name: t("Employee Management"),
      Icon: HiOutlineUsers,
      options: [
        { link: ROUTES.EMPLOYEES, name: "Employees" },
        { link: ROUTES.EMPLOYEE_ROASTER, name: "Roaster Management" },
      ],
    },
    {
      name: t("Guest Management"),
      Icon: HiOutlineUserGroup,
      options: [
        { link: ROUTES.CUSTOMERS, name: "Guests" },
        { link: ROUTES.GUEST_SUPPORT, name: "Guest Support" },
        { link: ROUTES.REVIEWS, name: "Reviews" },
      ],
    },

    {
      name: t("Vendor Management"),
      Icon: LiaUserCogSolid,
      options: [
        { link: ROUTES.VENDORS, name: "Vendors" },
        { link: ROUTES.ASSETS, name: "Assets" },
      ],
    },
    {
      name: t("Kitchen Management"),
      Icon: MdOutlineSoupKitchen,
      options: [{ link: ROUTES.KITCHENS, name: "Kitchen" }],
    },
    {
      name: t("Reports"),
      Icon: TbReportAnalytics,
      options: [
        { link: ROUTES.ROOM_BOOKING_REPORT, name: "Room Booking" },
        { link: ROUTES.ROOM_OCCUPANCY_REPORT, name: "Room Occupency" },
        { link: ROUTES.ASSETS_REPORT, name: "Assets" },
        { link: ROUTES.EXPENCE_REPORT, name: "Expence" },
        { link: ROUTES.EMPLOYEE_SALARY_REPORT, name: "Employee Salary" },
        { link: ROUTES.INVENTORY_REPORT, name: "Inventory" },
        { link: ROUTES.PURCHASE_ORDER_REPORT, name: "Purchase Order" },
        {
          link: ROUTES.THIRD_PARTY_SERVICE_REPORT,
          name: "Thirdy Party Service",
        },
        { link: ROUTES.REPAIR_MAINTENANCE_REPORT, name: "Repair Maintenance" },
        { link: ROUTES.SECURITY_REPORT, name: "Security" },
        { link: ROUTES.ROASTER_REPORT, name: "Roaster" },
      ],
    },
    {
      name: t("Change log"),
      Icon: TbExchange,
      options: [{ link: ROUTES.HOTEL_CONFIG, name: "Settings" }],
    },
    {
      name: t("Pms Configuration"),
      Icon: TbSettingsCog,
      options: [
        { link: ROUTES.HOTEL_CONFIG, name: "Settings" },
        { link: ROUTES.ACCESS_CONTROL, name: "Access Control" },
      ],
    },
  ];


  const [active, setActive] = useState(t("Deshboard"));
  const [options, setOptions] = useState([]);
  const [activeOption, setActiveOption] = useState("");
  //close side menu if sidemnu open in small devices 

  const url = window.location.pathname;


  useEffect(() => {
    if (url) {
      routes.forEach((route) => {
        route.options?.forEach((option) => {
          if (option.link === url) {
            setActive(route.name);
            setOptions(route.options)
            setActiveOption(option.name);
          }
        });
      });
    }
  }, []);

  const hamburgerClose = () => {
    dispatch(togglehamburger(false));
  };

  return (
    <div className="relative flex flex-1">
      <div
        className={`bg-white h-[calc(100vh-4rem)] w-[90px] flex flex-col gap-4 items-center select-none duration-300 overflow-y-auto sidebar-scroll overflow-x-hidden shadow-sm z-[10000] py-4 border-r`}
      >
        {routes?.map((route) => (
          <MenuItems
            key={route?.name}
            name={route?.name}
            Icon={route?.Icon}
            options={route?.options}
            setOptions={setOptions}
            setActive={setActive}
            active={active === route.name}
          />
        ))}
      </div>
      <div
        className={`bg-white overflow-x-hidden transition-all duration-300 ease-[0s] z-[9999] overflow-y-auto sidebar-scroll ${open ? "w-[200px]" : "hidden"
          }`}
      >
        <button
          type="button"
          onClick={hamburgerClose}
          className="text-sm whitespace-nowrap leading-[21px] flex items-center text-[#738391] border-b p-6 w-full capitalize"
        >
          <TiArrowLeft size={22} />
          {active}
        </button>
        <SingleMenu
          options={options}
          activeOption={activeOption}
          setActiveOption={setActiveOption}
        />
      </div>
    </div>
  );
};

export default SideBar;
