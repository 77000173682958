import React from "react";
import ToolTip from "../../components/common/ToolTip";

function AddBookingCheck({ label, message, text, value, setValue }) {
  return (
    <>
      <div>
        <div className="flex justify-between items-center">
          <label className="text-[14px] leading-6 font-medium text-[#344054]">
            {label}
          </label>
          <ToolTip Message={message} />
        </div>
        <div
          className={`w-fit relative flex justify-between items-center py-1 h-10`}
        >
          <input
            type={"checkbox"}
            checked={value}
            id={label}
            onChange={() => setValue(!value)}
            value={value}
            className={"checkBoxControl"}
          />
          <label
            htmlFor={label}
            className={`text-sm font-normal text-[#344054] ml-3 capitalize`}
          >
            {text}
          </label>
        </div>
      </div>
    </>
  );
}

export default AddBookingCheck;
