import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { isObjEmpty } from "../../utils";
import { registerEvent } from "../../logs";
import AllKitchen from "./AllKitchen";
import { useTranslation } from "react-i18next";
import AddButton from "../../components/buttons/AddButton"; 
import AddAndQuickKitchen from "../../components/modals/AddAndQuickKitchen";
import HotelOwnKitchen from "./HotelOwnKitchen";
import ThirdPartyKitchen from "./ThirdPartyKitchen";

const Kitchen = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [openHotelOwnKitchen, setOpenHotelOwnKitchen] = useState(false);
  const [openThirdPartyKitchen, setOpenThirdPartyKitchen] = useState(false);
  const [kitchen, setKitchen] = useState(null);
  const { hotel } = useSelector((state) => state.hotel);
  console.log("hotel", hotel);
  //open modal
  const openHotelOwnKitchenModal = () => {
    setOpenHotelOwnKitchen(true);
    setOpen(false);
    setOpenThirdPartyKitchen(false);
    registerEvent(
      t("open hotel own kitchen modal"),
      t("add hotel own kitchen")
    );
  };

  const openThirdPartyKitchenModal = () => {
    setOpenThirdPartyKitchen(true);
    setOpenHotelOwnKitchen(false);
    setOpen(false);
    registerEvent(
      t("open hotel own kitchen modal"),
      t("add hotel own kitchen")
    );
  };

  //close hotel own kitchen modal
  const closeHotelOwnKitchenModal = () => setOpenHotelOwnKitchen(false);

  //close third party kitchen modal
  const closeThirdPartyKitchenModal = () => setOpenThirdPartyKitchen(false);

  useEffect(() => {
    if (hotel?._id) props?.getKitchen(hotel?._id);
  }, [hotel?._id]);

  const data = {
    heading: t("36"),
    seperator: [
      {
        text: t("Kitchen Management"),
      },
      {
        text: t("36"),
      },
    ],
  };

  return (
    <div className="page-container">
      <div className="flex justify-between items-end">
        {/* <Seperator data={data} /> */}
        <div className="flex gap-2">
          <AddButton text={t("265")} onClick={() => setOpen(true)} />
        </div>
      </div>
      {/* open hotel own kitchen */}
      {isObjEmpty(kitchen) && openHotelOwnKitchen ? (
        <div className="mt-6">
          <HotelOwnKitchen
            closeHotelOwnKitchenModal={closeHotelOwnKitchenModal}
          />
        </div>
      ) : null}

      {isObjEmpty(kitchen) && openThirdPartyKitchen ? (
        <div className="mt-6">
          <ThirdPartyKitchen
            closeThirdPartyKitchenModal={closeThirdPartyKitchenModal}
          />
        </div>
      ) : null}

      {open ? (
        <AddAndQuickKitchen
          close={() => setOpen(false)}
          handleHotelOwnKitchen={() => openHotelOwnKitchenModal()}
          handleThirdPartyKitchen={() => openThirdPartyKitchenModal()}
        />
      ) : null}
      {!openThirdPartyKitchen ? (
        <div className="mt-6">
          <AllKitchen setKitchen={setKitchen} hotel={hotel} />
        </div>
      ) : null}
    </div>
  );
};

export default Kitchen;
