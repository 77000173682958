import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ISADMIN } from "../../../constants";
import axios from "axios";

// get all kitchen inventory stock Action
export const getAllKitchenInventoryStockAction = createAsyncThunk(
  "inventoryStock/getAllKitchenInventoryStockAction",
  async ({ credentials }, kitchenId, thunkAPI) => {
    try {
      if (!kitchenId) return;
      let url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/kitchen/employee/stocks/all`;
      const isadmin = localStorage.getItem(ISADMIN);
      if (isadmin)
        url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/kitchen/stocks/all?kitchenId=${kitchenId}`;
      const response = await axios.get(url, { headers: credentials });
      // localStorage.setItem(TOKEN, response.data.token);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// get All Kitchen Inventory Stock Slice
export const getAllKitchenInventoryStockSlice = createSlice({
  name: "inventoryStock/getAllKitchenInventoryStockSlice",
  initialState: {
    loading: false,
    stocks: [],
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllKitchenInventoryStockAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllKitchenInventoryStockAction.fulfilled, (state, action) => {
        state.loading = false;
        state.stocks = action.payload.stocks;
      })
      .addCase(getAllKitchenInventoryStockAction.rejected, (state, action) => {
        state.loading = false;
        // state.stocks = [];
        state.error =
          action.payload || "Failed to get all Kitchen Inventory Stock";
      });
  },
});

// get kitchen inventory stock by id Action
export const getKitchenInventoryStockAction = createAsyncThunk(
  "inventoryStock/getKitchenInventoryStockAction",
  async ({ credentials }, kitchenId, stockId, thunkAPI) => {
    try {
      if (!stockId) return;

      let url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/kitchen/employee/stocks?id=${stockId}`;
      const isadmin = localStorage.getItem(ISADMIN);
      if (isadmin)
        url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/kitchen/stocks?id=${stockId}&kitchenId=${kitchenId}`;
      const response = await axios.get(url, { headers: credentials });
      // localStorage.setItem(TOKEN, response.data.token);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// get Kitchen Inventory Stock by id Slice
export const getKitchenInventoryStockSlice = createSlice({
  name: "inventoryStock/getKitchenInventoryStockSlice",
  initialState: {
    loading: false,
    stock: {},
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getKitchenInventoryStockAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getKitchenInventoryStockAction.fulfilled, (state, action) => {
        state.loading = false;
        state.stock = action.payload.stocks;
      })
      .addCase(getKitchenInventoryStockAction.rejected, (state, action) => {
        state.loading = false;
        state.stock = null;
        state.error =
          action.payload || "Failed to get Kitchen Inventory Stock by id";
      });
  },
});

// update kitchen inventory stock action
export const updateKitchenInventoryStockAction = createAsyncThunk(
  "inventoryStock/updateKitchenAction",
  async (credentials, thunkAPI, hotelId, formData) => {
    try {
      let url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/kitchen/employee/stocks`;
      const isadmin = localStorage.getItem(ISADMIN);
      if (isadmin)
        url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/kitchen/stocks`;

      const response = await axios.put(url, formData, credentials);
      // localStorage.setItem(TOKEN, response.data.token);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

//update kitchen inventory stock slice
export const updateKitchenInventoryStockSlice = createSlice({
  name: "inventoryStock/updateKitchenInventoryStockSlice",
  initialState: {
    loading: false,
    isupdated: false,
    stock: {},
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateKitchenInventoryStockAction.pending, (state) => {
        state.loading = true;
        state.isupdated = false;
        state.error = null;
      })
      .addCase(updateKitchenInventoryStockAction.fulfilled, (state, action) => {
        state.loading = false;
        state.isupdated = true;
        state.stock = action.payload.stock;
      })
      .addCase(updateKitchenInventoryStockAction.rejected, (state, action) => {
        state.loading = false;
        state.isupdated = false;
        state.error =
          action.payload || "Failed to update kitchen kitchen stock";
      });
  },
});
