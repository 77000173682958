import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

// get food menu Action
export const getFoodMenuAction = createAsyncThunk(
  "foodOrder/getFoodMenuAction",
  async (credentials, thunkAPI) => {
    try {
      const { hotelId } = credentials || {};
      if (!hotelId) return;
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/employee/kitchen/menu?hotelId=${hotelId}`;
      const response = await axios.get(url, credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// get food Menu slice
export const getFoodMenuSlice = createSlice({
  name: "foodOrder/getFoodOrderSlice",
  initialState: {
    loading: false,
    menu: [],
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFoodMenuAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getFoodMenuAction.fulfilled, (state, action) => {
        state.loading = false;
        state.menu = action.payload.menu;
      })
      .addCase(getFoodMenuAction.rejected, (state, action) => {
        state.loading = false;
        state.menu = null;
        state.error = action.payload || "Failed to get food menu";
      });
  },
});
