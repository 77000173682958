import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ID_NUMBER, ROUTES } from "../../constants";
import DeleteOrNot from "../../components/modals/DeleteOrNot";
import EditButton from "../../components/buttons/EditButton";
import CustomeTable from "../../components/widget/CustomeTable";
import BookingStatus from "./BookingStatus";
import LinksButton from "../../components/buttons/LinksButton";
import { RxCross2 } from "react-icons/rx";
import SelectPackage from "../../components/modals/SelectPackage";
import AddServices from "../../components/modals/AddServices";
import { deleteBookingAction } from "../../lib/slices/booking/bookingSlice";

const AllBooking = (props) => {
  const dispatch = useDispatch();
  const { bookings, loading, error } = useSelector(
    (state) => state.getBookingByConditions
  );
  console.log("check all bookings slice :", bookings, loading, error);

  const [datas, setDatas] = useState([]);
  const [BookingId, setBookingId] = useState("");
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [isSelectPackage, setIsSelectPackage] = useState(false);
  const [isAddServices, setIsAddServices] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const navigate = useNavigate();
  //set bookings as datas
  useEffect(() => {
    if (
      props.bookings &&
      Array.isArray(props?.bookings?.bookings) &&
      props?.bookings?.bookings?.length
    ) {
      const arr = props?.bookings?.bookings?.reverse()?.map((booking) => {
        return {
          name: booking?.bookingName,
          source: booking?.bookingSource,
          bookingDate: booking?.bookingDate,
          checkInDate: booking?.checkInDate,
          checkOutDate: booking?.checkOutDate,
          roomTypeNo: booking?.roomType,
          status: booking?.bookingStatus,
          code: booking?.bookingCode,
          _id: booking?._id,
        };
      });
      setDatas(arr);
    }
    console.log(props?.bookings?.bookings);
  }, [props?.bookings]);

  //set update data
  // const updateTheBooking = async (id) => {
  //   if (id) {
  //     const booking = await props?.bookings?.bookings?.filter(
  //       (x) => x?._id === id
  //     );
  //     console.log(booking[0]);
  //     // props.setBooking(booking[0]);
  //     window.scrollTo({
  //       top: 0,
  //       behavior: "smooth",
  //     });
  //   }
  // };

  //delete booking confirmation modal open
  // const deleteTheBooking = (id) => {
  //   if (id) {
  //     setBookingId(id);
  //     setDeleteConfirmation(true);
  //   }
  // };

  // delete booking confirm that delete this booking with id-: BookingId
  const deleteBooking = (id, hotelId) => {
    if (BookingId) {
      dispatch(deleteBookingAction(id, hotelId));
    }
  };

  // confirm that do not delete this booking with id : BookingId
  const notDeleteBooking = () => {
    setDeleteConfirmation(false);
    setBookingId(null);
  };

  //view Booking Actions
  const ViewBookingActions = ({ v }) => {
    const viewDetails = () => {
      localStorage.setItem(ID_NUMBER, v?.data?._id);
      navigate(ROUTES.BOOKING);
    };
    return (
      <div className="flex items-center h-full gap-2">
        <LinksButton name={"View Details"} onClick={viewDetails} />
        <LinksButton
          name={"Add Package"}
          onClick={() => setIsSelectPackage(true)}
        />
        <LinksButton
          name={"Add Services"}
          onClick={() => setIsAddServices(true)}
        />
        <EditButton />
        <button
          type="button"
          className="h-[30px] pl-[3px] pr-2 flex items-center justify-center gap-2 border border-[#F04438] text-[#F04438] hover:bg-[#F04438] hover:text-white rounded-md group duration-300 ease-[0.3s]"
        >
          <div className="rounded flex items-center justify-center bg-[#F04438] group-hover:bg-white  p-[3px]">
            <div className="rounded-full bg-white flex items-center justify-center group-hover:bg-[#F04438] p-[3px]">
              <RxCross2 size={10} />
            </div>
          </div>
          <div className="tracking-[-0.006em] text-[14px] leading-6 font-normal">
            Cancel
          </div>
        </button>
      </div>
    );
  };
  const columnDefs = [
    {
      headerName: "Booking ID",
      field: "_id",
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Booking Name",
      field: "name",
      filter: true,
    },
    {
      headerName: "Booking Source",
      field: "source",
      filter: true,
    },
    // {
    //   headerName: "Booking Date",
    //   field: "bookingDate",
    //   filter: true,
    // },
    {
      headerName: "Check-In",
      field: "checkInDate",
      filter: true,
    },
    {
      headerName: "Check-Out",
      field: "checkOutDate",
      filter: true,
    },
    {
      headerName: "Booking Type",
      field: "bookingType",
      filter: true,
    },
    {
      headerName: "Room Type-No",
      field: "roomTypeNo",
      filter: true,
    },
    {
      headerName: "No Of Nights",
      field: "roomTypeNo",
      filter: true,
    },
    {
      headerName: "Check-In Code",
      field: "code",
      filter: true,
    },
    {
      headerName: "Booking Status",
      field: "status",
      filter: true,
      minWidth: 150,
      cellRenderer: BookingStatus,
    },
    {
      headerName: "Total Price",
      field: "totalPrice",
      filter: true,
    },
    {
      headerName: "Advance Paid",
      field: "advancePaid",
      filter: true,
    },
    {
      headerName: "Actions",
      field: "_id",
      minWidth: 480,
      cellRenderer: ViewBookingActions,
    },
  ];

  return (
    <div className="relative">
      {isSelectPackage ? (
        <SelectPackage close={() => setIsSelectPackage(false)} />
      ) : null}
      {isAddServices ? (
        <AddServices close={() => setIsAddServices(false)} />
      ) : null}
      {datas?.length ? (
        <CustomeTable
          tableName={"Booking List Table"}
          columnDefs={columnDefs}
          rowData={datas}
          setSelectedRows={setSelectedRows}
        />
      ) : null}
      <DeleteOrNot
        formSubmit={deleteBooking}
        open={deleteConfirmation}
        close={notDeleteBooking}
      />
    </div>
  );
};
export default AllBooking;
