import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants";
import { isObjEmpty } from "../../utils";
import { registerEvent } from "../../logs";
import AddInventory from "./AddInventory";
import AllInventory from "./AllInventory";
import { useTranslation } from "react-i18next"; 
import AddButton from "../../components/buttons/AddButton";
// import Seperator from "../../components/form/Seperator";
import AddQuantity from "./AddQuantity";

const InventoryPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [addButton, setAddButton] = useState(false);
  const [addQuantity, setAddQuantity] = useState(false);
  const [updateInventory, setUpdateInventory] = useState(false);
  const [showUpdateInventory, setShowUpdateInventory] = useState(false);
  const { kitchens, loading: gettingKitchen } = useSelector(
    (state) => state.getAllKitchen
  );
  console.log("kitchen in inventory", kitchens, gettingKitchen);
  const { hotel } = useSelector((state) => state.hotel);
  console.log("hotel in inventory", hotel);
  const { isadmin } = useSelector((state) => state.IsAdmin);
  const [inventory, setInventory] = useState({});

  //register event for Add inventory
  const registerEventAndOpen = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setAddButton(true);
    setUpdateInventory(true);
    setShowUpdateInventory(true);
    registerEvent("add inventory button open", "add item inventory", {
      addButton,
      updateInventory,
      showUpdateInventory,
    });
  };
  // register evnet on close add inventry component
  const close = () => {
    if (!isObjEmpty(inventory)) setInventory(null);
    setAddButton(false);
    setUpdateInventory(false);
    setShowUpdateInventory(false);
    registerEvent("add inventory button close", "add item inventory", {
      addButton,
      updateInventory,
      showUpdateInventory,
    });
  };
  //register event for add quantity
  const registerEventAndOpened = () => {
    setAddQuantity(true);
    registerEvent("add Quantity button open", "add item Quantity", {
      addQuantity,
    });
  };

  // register evnet on close add quantity component
  const closed = () => {
    if (!isObjEmpty(inventory)) setInventory(null);
    setAddQuantity(false);
    registerEvent("add Quantity button close", "add item Quantity", {
      addQuantity,
    });
  };

  // if there no kitchen
  useEffect(() => {
    if (isObjEmpty(kitchens) && !gettingKitchen && isadmin)
      navigate(ROUTES.KITCHENS);
    else if (kitchens) {
      // dispatch(getOwnerInventoryAction(kitchens?._id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kitchens]);

  const data = {
    heading: t("Kladee's Cafe - Inventory"),
    seperator: [
      {
        text: t("134"),
      },
      {
        link: ROUTES.KITCHENS,
        text: t("36"),
      },
      { text: t("44") },
    ],
  };

  return (
    <div className="page-container">
      <div className="flex justify-between items-end">
        {/* <Seperator data={data} /> */}
        <div className="flex gap-2">
          {/* text={t("1035")} */}
          <AddButton text={t("1113")} onClick={registerEventAndOpen} />
        </div>
      </div>
      {!isObjEmpty(inventory) || addButton ? (
        <AddInventory
          close={close}
          kitchen={kitchens}
          hotel={hotel}
          inventory={inventory}
          setInventory={setInventory}
        />
      ) : null}

      {/* add quantity */}
      {!isObjEmpty(inventory) || addQuantity ? (
        <AddQuantity close={closed} />
      ) : null}

      <AllInventory
        updateInventory={registerEventAndOpen}
        isShowUpdate={showUpdateInventory}
        kitchen={kitchens}
        hotel={hotel}
        setInventory={setInventory}
        isadmin={isadmin}
        handleAddQuantity={registerEventAndOpened}
      />
    </div>
  );
};

export default InventoryPage;
