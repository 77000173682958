import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Inputfield from "../../components/form/InputField";
import ManagerIcon from "../../images/icons/ManagerIcon.svg";
import messageIcon2 from "../../images/icons/messageIcon2.svg";
import NewJobIcon from "../../images/icons/NewJobIcon.svg";
import ShiftTimming from "../../components/form/ShiftTimming";
import { FaPlus } from "react-icons/fa6";
import AddSubmitButton from "../../components/buttons/AddSubmitButton";
import { TIME_ZONES } from "../../constants";
import DropdownMultiSelector from "../../components/form/DropdownMultiSelector";
import CloseButton from "../../components/buttons/CloseButton";

const CreateRoaster = ({ close }) => {
  const { t } = useTranslation();
  const [timeZone, setTimeZone] = useState([]);
  const [timeFormat, setTimeFormat] = useState([]);
  const [startTime, setStartTime] = useState("8:00");
  const [startOptions, setStartOptions] = useState("");
  const [endTime, setEndTime] = useState("6:00");
  const [endOptions, setEndOptions] = useState("");
  const [addNewShifts, setAddNewShifts] = useState([0]);
  // const [isAddNewShiftButtton, setIsAddNewShiftButton] = useState(false);
  const [addNewEmployee, setAddNewEmployee] = useState([]);

  const AddNewShift = () => {
    const addNewShift = {
      startTime,
      setStartTime,
      startOptions,
      setStartOptions,
      endTime,
      setEndTime,
      endOptions,
      setEndOptions,
    };
    setAddNewShifts([...addNewShifts, addNewShift]);
    // setIsAddNewShiftButton(isAddNewShiftButtton);
  };
  const TIME_FORMAT = ["12 Hourse", "24 Hourse"];

  return (
    <div className="relative w-full h-auto border border-[#E5E9EB] rounded-lg bg-white mt-6 p-6">
      <div className="text-center font-bold text-[30px] leading-[37.8px] text-[#07090D] tracking-[-1.5%]">
        {t("Create Roaster")}
      </div>
      <div className="absolute right-7 top-8">
        <CloseButton onClick={close} />
      </div>

      <form className="mt-6">
        <div className="grid lg:grid-cols-3 md:grid-cols-2 lg:gap-4 md:gap-6 lg:mr-20">
          <Inputfield
            label={"Roaster Name"}
            placeholder={"Security Guard Duty"}
            required={true}
            minLength={3}
            maxLength={50}
            utility="w-[302px]"
            icon={<img src={ManagerIcon} alt="" />}
          />
          <Inputfield
            label={"Position"}
            placeholder={"Manager"}
            required={true}
            minLength={3}
            maxLength={50}
            utility="w-[302px]"
            icon={<img src={messageIcon2} alt="" />}
          />
          <Inputfield
            label={"Role"}
            placeholder={"Manage Staff"}
            required={true}
            minLength={3}
            maxLength={50}
            utility="w-[302px]"
            icon={<img src={NewJobIcon} alt="" />}
          />
          <Inputfield
            label={"No OF Shifts"}
            type="number"
            placeholder={""}
            required={true}
            minLength={3}
            maxLength={50}
            utility="w-[302px]"
            min={0}
          />
          <Inputfield
            label={"Work Area"}
            placeholder={"working on first floor"}
            required={true}
            minLength={3}
            maxLength={50}
            utility="w-[302px]"
          />
          <div className="flex flex-col items-start w-full">
            <label htmlFor="time-zone">Time Zone</label>
            <select
              name="time-zone"
              value={timeZone}
              onChange={(e) => setTimeZone(e?.target?.value)}
            >
              {TIME_ZONES.map((time, index) => {
                return (
                  <option key={index} value={TIME_ZONES[time]}>
                    {time.text}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="flex flex-col items-start">
            <label htmlFor="time-zone">Time Zone</label>
            <select
              name="time-zone"
              value={timeFormat}
              onChange={(e) => setTimeFormat(e?.target?.value)}
            >
              {TIME_FORMAT.map((timeFormat, index) => {
                return (
                  <option key={index} value={timeFormat}>
                    {timeFormat}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="mt-4">
          <div className="text-[16px] leading-5 font-medium text-[#344054] underline underline-offset-4 mb-6">
            Shift Timming
          </div>

          {addNewShifts.map((data, id) => {
            return (
              <div className="lg:flex items-center gap-4 my-4" key={id}>
                <div className="">
                  <div className="text-sm font-medium text-[#344054] capitalize">
                    shift {id + 1}
                  </div>
                  <ShiftTimming
                    startTime={startTime}
                    setStartTime={setStartTime}
                    options={[""]}
                    startOptionsValue={startOptions}
                    setStartOptionValue={setStartOptions}
                    endTime={endTime}
                    setEndTime={setEndTime}
                    endOptionsValue={endOptions}
                    setEndOptionValue={setEndOptions}
                  />
                </div>
              </div>
            );
          })}
          <div className="">
            <button
              type="button"
              className={`flex items-center justify-center gap-3 w-[165px] h-12 bg-base-primary hover:bg-base-primary_hover rounded-md drop-shadow-[0_1px_2px_0_rgba(16, 24, 40, 0.05)] mt-[20px]`}
              onClick={AddNewShift}
            >
              <div className="border h-[17px] w-[17px] bg-white rounded-full flex items-center justify-center">
                <FaPlus size={11} className="text-base-primary" />
              </div>
              <div className="text-[16px] leading-5 font-medium text-white">
                Add new shift
              </div>
            </button>
          </div>
        </div>
        <div className="mt-4">
          <div className="text-[22px] text-[#07090D] leading-[27.72px] font-semibold tracking-[-0.8px] mb-1">
            Add workers to your Roaster
          </div>
          <div className="grid grid-cols-3">
            <DropdownMultiSelector
              text={"Add New Employee"}
              options={{ name: "jhon deo" }}
              selectedValue={addNewEmployee}
              setSelectedValue={setAddNewEmployee}
            />
          </div>
        </div>
        <div className="flex items-center justify-end mt-6">
          <AddSubmitButton text={t("Add Roaster")} />
        </div>
      </form>
    </div>
  );
};

export default CreateRoaster;
