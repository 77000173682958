import React, { useEffect, useState } from "react"; 
import { connect, useSelector } from "react-redux"; 
import CustomeTable from "../../components/widget/CustomeTable";

const EmployeeHighAttendanceList = (props) => {
  // const { t } = useTranslation();
  const [datas, setDatas] = useState([]);
  const { sales } = useSelector((state) => state.SalesReport);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    props.getAssetsFromToDateAll(
      props?.hotel?._id,
      props?.fromDate,
      props?.toDate
    );
  }, [props?.fromDate, props?.toDate]);

  useEffect(() => {
    if (sales && Array.isArray(sales) && sales?.length) {
      let expense = sales?.map((d) => {
        return {
          employeeName: d?.employeeName,
          department: d?.department,
          attendancePercentage: d?.attendancePercentage,
        };
      });
      setDatas(expense);
    }
  }, [sales]);

  const tableData = [
    {
      id: "EMP001",
      employeeName: "John Doe",
      department: "Chef",
      attendancePercentage: "90%",
    },
    {
      id: "EMP002",
      employeeName: "John Doe",
      department: "Chef",
      attendancePercentage: "90%",
    },
    {
      id: "EMP003",
      employeeName: "John Doe",
      department: "Chef",
      attendancePercentage: "90%",
    },
    {
      id: "EMP004",
      employeeName: "John Doe",
      department: "Chef",
      attendancePercentage: "90%",
    },
    {
      id: "EMP005",
      employeeName: "John Doe",
      department: "Chef",
      attendancePercentage: "90%",
    },
    {
      id: "EMP006",
      employeeName: "John Doe",
      department: "Chef",
      attendancePercentage: "70%",
    },
  ];

  const CheckAttendancePercentage = ({ value }) => {
    return (
      <>
        <div className="text-[14px] leading-6 font-normal tracking-[-0.6%] mt-2">
          {value <= "70%" ? (
            <div className="text-[#F04438]">{value}</div>
          ) : (
            <div className="text-base-primary">{value}</div>
          )}
        </div>
      </>
    );
  };

  const columnDefs = [
    {
      headerName: "Employee Id",
      field: "id",
      minWidth: 200,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Employee Name",
      field: "employeeName",
      filter: true,
    },
    {
      headerName: "Department",
      field: "department",
      filter: true,
    },
    {
      headerName: "Attendance Percentage",
      field: "attendancePercentage",
      filter: true,
      cellRenderer: CheckAttendancePercentage,
    },
  ];

  return (
    <div>
      <CustomeTable
        tableName={"High Attendance List table"}
        columnDefs={columnDefs}
        rowData={tableData}
        setSelectedRows={setSelectedRows}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({ 
});

const mapDispatchToProps = (dispatch) => ({ 
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeHighAttendanceList);
