import React from "react";
import { useDispatch } from "react-redux"; 
import  DeleteButton from "../../components/buttons/DeleteButton";
import  EditButton from "../../components/buttons/EditButton"; 
import { deleteRoomAction } from "../../lib/slices/room/roomSlice";

const RoomAction = ({ roomId, hotelId }) => {
  const dispatch = useDispatch();

  const deleteConfirm = () => {
    dispatch(deleteRoomAction(roomId, hotelId));
  };

  // const cancelDelete = () => {};

  return (
    <div className="flex items-center h-full gap-4">
     <EditButton onClick={""}/>
     <DeleteButton onClick={deleteConfirm}/>
    </div>
  );
};

export default RoomAction;
