import React  from "react";
import { useDispatch, useSelector } from "react-redux"; 
import { ROUTES } from "../../constants"; 
import Logo from "../common/Logo";
import { useTranslation } from "react-i18next";
import { logoutAction } from "../../lib/slices/auth/authSlice"; 

const Header = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();  
  const { isAuthenticated } = useSelector((state) => state.auth);

  //logout
  const handleLogout = () => { 
    dispatch(logoutAction()); 
  } 

  return (
    <>
      <header className="flex sticky top-0 left-0 w-full h-16 text-gray-700 z-[1000] bg-white border-b border-b-[#D0D5DD]">
        <div className="w-full max-w-7xl mx-auto flex items-center justify-between px-6">
          <Logo url={ROUTES.LANDING_PAGE} />
          {isAuthenticated && (
            <button
              onClick={() => handleLogout()}
              className="our-button w-32 rounded-sm"
            >
              {t("992")}
            </button>
          )}
        </div>
      </header>
    </>
  );
};

export default Header;
