import { HOTEL_ROOM_DETAILS, ROOM_TYPES } from "../constants";

export const setAvailableRooms = (rooms, bookings) => {
    //initial states
    let hotelRooms = {};
    let roomTypes = [];

    if (
        rooms &&
        Array.isArray(rooms)
    ) {
        //getting room informations 
        rooms?.map((room) => {
            const adults = room?.adults;
            const children = room?.children;
            const roomType = room?.roomType;
            roomTypes.push(roomType);

            if (room?.hotelRooms && room?.hotelRooms?.length) {
                room?.hotelRooms?.map((rx) => {
                    hotelRooms[rx?.roomNumber] = {
                        adults,
                        children,
                        roomType,
                        bookings: [],
                        roomNumber: rx?.roomNumber
                    };
                });
            }
        });

        //getting booking details @booked(some one is in room) today or future
        bookings &&
            Array.isArray(bookings) &&
            bookings?.map((data) => {
                const bookingName = data?.bookingName;
                const _id = data?._id;
                const bookingStatus = data?.bookingStatus;

                data?.roomDetails?.map((room) => {
                    const bookingDetail = {
                        checkInDate: room?.checkInDate,
                        checkOutDate: room?.checkOutDate,
                        guestNumber: room?.guestsDetails?.length,
                        roomPrice: room?.tariff,
                        bookingName,
                        _id,
                        bookingStatus,
                    };

                    if (hotelRooms[room?.roomNumber]) {
                        const previousBooked = hotelRooms[room?.roomNumber]?.bookings;
                        const previousBookedExist = hotelRooms[room?.roomNumber]?.bookings?.length > 1;
                        hotelRooms[room?.roomNumber].bookings = previousBookedExist
                            ? [...previousBooked, bookingDetail]
                            : [bookingDetail];
                    }
                });
            });
    }
    //store room types in a hotel @session storage
    sessionStorage.setItem(ROOM_TYPES, JSON.stringify(roomTypes));

    //storing hotel room numbers @session storage
    sessionStorage.setItem(HOTEL_ROOM_DETAILS, JSON.stringify(hotelRooms));
}