import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoadSpinner from "../../components/common/loader/LoadSpinner";
import { ROUTES } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import ContinueWithGoogle from "../../components/common/ContinueWithGoogle";
import AuthEmailInput from "./AuthEmailInput";
import AuthPasswordInput from "./AuthPasswordInput";
import { useTranslation } from "react-i18next";
import { ownerLoginAction } from "../../lib/slices/auth/authSlice";
// import { notifyError } from "../../components/alert";

const LoginPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  //validation error
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const { isAuthenticated, loading, error } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!emailError && !passwordError && email && password) {
      const formData = {
        email,
        password,
      };
      dispatch(ownerLoginAction(formData));
      localStorage.clear();
      sessionStorage.clear();
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate(ROUTES.DASHBOARD);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (error) {
      // notifyError(error);
    }
  }, [error]);

  return (
    <>
      <div className="w-full min-h-screen p-4 relative flex flex-col justify-center">
        <div className="w-[450px] mx-auto max-w-full rounded-lg flex flex-col border bg-[#FFFFFF]">
          <div className="w-full pt-6">
            <h1 className="text-3xl font-medium text-center text-base-primary">
              {t("220")}
            </h1>
          </div>
          <form
            className="w-full p-8 grid grid-cols-1 gap-4"
            onSubmit={handleSubmit}
          >
            <AuthEmailInput
              email={email}
              setEmail={setEmail}
              setEmailError={setEmailError}
              emailError={emailError}
            />
            <AuthPasswordInput
              password={password}
              setPassword={setPassword}
              setPasswordError={setPasswordError}
              passwordError={passwordError}
            />

            <button
              className="inline-block h-12 w-full bg-base-primary text-[#FFFFFF] rounded-3xl font-medium disabled:bg-base-rgba_primary_8"
              disabled={
                loading || emailError || passwordError || !email || !password
              }
            >
              {loading ? <LoadSpinner text={t("798")} /> : <>{t("220")}</>}
            </button>
          </form>
          <div className="w-full text-center">
            <Link
              to={ROUTES.PASSWORD_FORGOT}
              className="text-blue-400 font-sans hover:underline"
            >
              {t("799")}
            </Link>
          </div>
          <div className="w-full flex flex-col items-center relative p-8 gap-4">
            <div className="flex w-full items-center">
              <span className="border-b flex border-[#D0D5DD] w-full"></span>
              <p className="whitespace-nowrap mx-2">{t("800")}</p>
              <span className="border-b flex border-[#D0D5DD] w-full"></span>
            </div>
            <ContinueWithGoogle />
            <div className="w-full text-center">
            {t("801")}
            <Link
              to={ROUTES.REGISTER}
              className="text-blue-400 ml-2 hover:underline"
            >
              {t("802")}
            </Link>
          </div>
          </div>         
        </div>
      </div>
    </>
  );
};

export default LoginPage;
