import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ISADMIN, TOKEN } from "../../../constants";

// add kitchen action
export const addKitchenAction = createAsyncThunk(
  "kitchen/addKitchenAction",
  async (credentials, thunkAPI) => {
    try {
      const isAdmin = localStorage.getItem(ISADMIN);
      const token = localStorage.getItem(TOKEN);

      if (!isAdmin || !token) return;
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/kitchen/add`;
      const response = await axios.post(url, credentials);
      // localStorage.setItem(TOKEN, response.data.token);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

//Add kitchen slice
export const addKitchenSlice = createSlice({
  name: "kitchen/addKitchenSlice",
  initialState: {
    loading: false,
    isadded: false,
    kitchen: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addKitchenAction.pending, (state) => {
        state.loading = true;
        state.isadded = false;
        state.error = null;
      })
      .addCase(addKitchenAction.fulfilled, (state, action) => {
        state.loading = false;
        state.isadded = true;
        state.kitchen = action.payload.kitchen;
      })
      .addCase(addKitchenAction.rejected, (state, action) => {
        state.loading = false;
        state.isadded = false;
        state.error = action.payload || "Failed to add kitchen";
      });
  },
});

// get one kitchen by id Action
export const getKitchenAction = createAsyncThunk(
  "kitchen/getKitchenAction",
  async ({ credentials, hotelId }, thunkAPI) => {
    try {
      if (!hotelId) return;
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/kitchen?hotelId=${hotelId}`;
      const response = await axios.get(url, { headers: credentials });
      // localStorage.setItem(TOKEN, response.data.token);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// get one kitchen by id slice
export const getKitchenSlice = createSlice({
  name: "kitchen/getKitchenSlice",
  initialState: {
    loading: false,
    kitchen: {},
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getKitchenAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getKitchenAction.fulfilled, (state, action) => {
        state.loading = false;
        state.kitchen = action.payload.kitchen;
      })
      .addCase(getKitchenAction.rejected, (state, action) => {
        state.loading = false;
        state.kitchens = null;
        state.error = action.payload || "Failed to get Kitchen";
      });
  },
});

// get all kitchen Action
export const getAllKitchenAction = createAsyncThunk(
  "kitchen/getAllKitchenAction",
  async ({ credentials }, thunkAPI) => {
    try {
      const isadmin = localStorage.getItem(ISADMIN);
      if (!isadmin) return;
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/kitchen/all`;
      const response = await axios.get(url, { headers: credentials });
      // localStorage.setItem(TOKEN, response.data.token);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// get all kitchen slice
export const getAllKitchenSlice = createSlice({
  name: "kitchen/getAllKitchenSlice",
  initialState: {
    loading: false,
    kitchens: [],
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllKitchenAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllKitchenAction.fulfilled, (state, action) => {
        state.loading = false;
        state.kitchens = action.payload.kitchens;
      })
      .addCase(getAllKitchenAction.rejected, (state, action) => {
        state.loading = false;
        state.kitchens = [];
        state.error = action.payload || "Failed to get all Kitchens";
      });
  },
});

// update kitchen action
export const updateKitchenAction = createAsyncThunk(
  "kicthen/updateKitchenAction",
  async (credentials, thunkAPI, hotelId) => {
    try {
      if (!hotelId) return;
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/kitchen?hotelId=${hotelId}`;
      const response = await axios.put(url, credentials);
      // localStorage.setItem(TOKEN, response.data.token);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

//update kitchen slice
export const updateKitchenSlice = createSlice({
  name: "kicthen/updatekitchenSlice",
  initialState: {
    loading: false,
    isupdated: false,
    kicthen: {},
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateKitchenAction.pending, (state) => {
        state.loading = true;
        state.isupdated = false;
        state.error = null;
      })
      .addCase(updateKitchenAction.fulfilled, (state, action) => {
        state.loading = false;
        state.isupdated = true;
        state.kitchen = action.payload.kitchen;
      })
      .addCase(updateKitchenAction.rejected, (state, action) => {
        state.loading = false;
        state.isupdated = false;
        state.error = action.payload || "Failed to update kitchen";
      });
  },
});

// delete kitchen action
export const deletekitchenAction = createAsyncThunk(
  "kitchen/deletekitchenAction",
  async ({ credentials, kitchenId }, thunkAPI) => {
    try {
      if (!kitchenId) return;
      const isadmin = localStorage.getItem(ISADMIN);
      if (!isadmin) return;
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/kitchen/delete?kitchenId=${kitchenId}`;
      const response = await axios.delete(url, {
        headers: credentials,
      });
      // localStorage.setItem(TOKEN, response.data.token);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);
//  delete kitchen slice
export const deletekitchenSlice = createSlice({
  name: "kitchen/deletekitchenSlice",
  initialState: {
    loading: false,
    kitchen: {},
    isdeleted: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deletekitchenAction.pending, (state) => {
        state.loading = true;
        state.isdeleted = false;
        state.error = null;
      })
      .addCase(deletekitchenAction.fulfilled, (state, action) => {
        state.loading = false;
        state.isdeleted = true;
        state.kitchen = action.payload.kitchen;
      })
      .addCase(deletekitchenAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to delete kicthen";
      });
  },
});
