import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

// get All Bookings Occupency Report Action
export const getAllBookingsOccupencyReportAction = createAsyncThunk(
  "reports/getAllBookingsOccupencyReportAction",
  async (credentials, thunkAPI) => {
    try {
      const { hotelId } = credentials || {};
      if (!hotelId) return;
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/reports/bookings/occupancy/all`;
      const response = await axios.post(url, credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

//get get All Bookings Occupency Report Slice
export const getAllBookingsOccupencyReportSlice = createSlice({
  name: "reports/getAllBookingsOccupencyReportSlice",
  initialState: {
    loading: false,
    occupancies: [],
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllBookingsOccupencyReportAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getAllBookingsOccupencyReportAction.fulfilled,
        (state, action) => {
          state.loading = false;
          state.occupancies = action.payload.occupancies;
        }
      )
      .addCase(
        getAllBookingsOccupencyReportAction.rejected,
        (state, action) => {
          state.loading = false;
          state.occupancies = null;
          state.error =
            action.payload || "Failed to get All Bookings Occupency Report";
        }
      );
  },
});

