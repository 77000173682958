import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PROPERTY_ID } from "../../../constants";
import authAxios from "../../services/authAxios";

// get all hotel action
export const getAllHotelsAction = createAsyncThunk(
  "hotel/getAllHotelAction",
  async (_, thunkAPI) => {
    try {
      const response = await authAxios.get("/api/hotel/owner/hotels/all");
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// get All hotels slice
export const getAllHotelsSlice = createSlice({
  name: "hotel/getAllHotelSlice",
  initialState: {
    loading: true,
    hotels: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllHotelsAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllHotelsAction.fulfilled, (state, action) => {
        state.loading = false;
        state.hotels = action.payload;
      })
      .addCase(getAllHotelsAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const addHotelAction = createAsyncThunk(
  "hotel/addHotelAction",
  async (credentials, thunkAPI) => {
    try {
      const url = `/api/hotel/owner/onboarding/add`;
      const response = await authAxios.post(url, credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

//onboarding
export const updateHotelAction = createAsyncThunk(
  "hotel/updateHotelAction",
  async (credentials, thunkAPI) => {
    try {
      const url = `/api/hotel/owner/onboarding/update`;
      const response = await authAxios.put(url, credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// add property contact action
export const addProprtyContactAction = createAsyncThunk(
  "onboarding/addProprtyContactAction",
  async (credentials, thunkAPI) => {
    try {
      const url = `/api/hotel/owner/onboarding/contact`;
      const response = await authAxios.put(url, credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// update hotel
export const updateHotelSettingAction = createAsyncThunk(
  "hotel/updateHotelSettingAction",
  async (credentials, thunkAPI) => {
    try {
      const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/hotels/update`;
      const response = await authAxios.put(url, credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// get hotel by id action
export const getHotelByIdAction = createAsyncThunk(
  "hotel/getHotelByIdAction",
  async (hotelId, thunkAPI) => {
    try {
      if (!hotelId) return;
      const response = await authAxios.get(`api/hotel/owner/hotels?hotelId=${hotelId}`);
      localStorage.setItem(PROPERTY_ID, response.data?._id);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

//get hotel by id slice
export const getHotelByIdSlice = createSlice({
  name: "hotel/getHotelByIdSlice",
  initialState: {
    loading: true,
    isadded: false,
    hotel: localStorage.getItem(PROPERTY_ID) || null,
    isupdated: false,
    error: null,
  },
  reducers: {
    clearHotelAction: (state) => {
      state.hotel = null;
      state.isadded = null;
      state.isupdated = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(addHotelAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addHotelAction.fulfilled, (state, action) => {
        state.loading = false;
        state.hotel = action.payload;
      })
      .addCase(addHotelAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addProprtyContactAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addProprtyContactAction.fulfilled, (state, action) => {
        state.loading = false;
        state.hotel = action.payload;
      })
      .addCase(addProprtyContactAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getHotelByIdAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getHotelByIdAction.fulfilled, (state, action) => {
        state.loading = false;
        state.hotel = action.payload;
      })
      .addCase(getHotelByIdAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateHotelAction.pending, (state) => {
        state.loading = true;
        state.isupdated = false;
        state.error = null;
      })
      .addCase(updateHotelAction.fulfilled, (state, action) => {
        state.loading = false;
        state.isupdated = true;
        state.hotel = action.payload;
      })
      .addCase(updateHotelAction.rejected, (state, action) => {
        state.loading = false;
        state.isupdated = false;
        state.error = action.payload;
      })
      .addCase(updateHotelSettingAction.pending, (state) => {
        state.loading = true;
        state.isupdated = false;
        state.error = null;
      })
      .addCase(updateHotelSettingAction.fulfilled, (state, action) => {
        state.loading = false;
        state.isupdated = true;
        state.hotel = action.payload;
      })
      .addCase(updateHotelSettingAction.rejected, (state, action) => {
        state.loading = false;
        state.isupdated = false;
        state.error = action.payload;
      })
      .addCase(getAllHotelsAction.fulfilled, (state, action) => {
        state.loading = false;
        if (!state.hotel && action.payload && action.payload[0]) {
          state.hotel = action.payload[0];
          localStorage.setItem(PROPERTY_ID, action.payload[0]?._id);
        } else if (action.payload && !action.payload[0]) {
          state.hotel = null;
        }
      });
  },
});

export const { clearHotelAction } = getHotelByIdSlice.actions;