import React, { useState } from "react"; 
import { useTranslation } from "react-i18next";
import { ROUTES } from "../../constants"; 
import { useNavigate } from "react-router-dom";
import CustomeTable from "../../components/widget/CustomeTable"; 
import BackButton from "../../components/buttons/BackButton";

function VendorHistory() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = useState([]);

  const track = {
    heading: t("History - Amul"),
    seperator: [
      {
        text: t("134"),
      },
      {
        link: ROUTES.VENDORS,
        text: t("233"),
      },
      { text: t("History") },
    ],
  };
  const tableData = [
    {
      date: "2023-11-06",
      vendorType: "Food & Baverage",
      invoiceNumber: "INV-1234",
      items: ["Salt", "Eggs", "Chicken"],
      quantity: ["50kg", "20dz", "100lbs"],
      price: ["$600", "$800", "$600"],
      totalAmount: "$2000",
      paymentStatus: "Paid",
      receivedStatus: "Received",
    },
    {
      date: "2023-11-06",
      vendorType: "Equipments",
      invoiceNumber: "INV-1234",
      items: ["Salt", "Eggs", "Chicken"],
      quantity: ["50kg", "20dz", "100lbs"],
      price: ["$600", "$800", "$600"],
      totalAmount: "$2000",
      paymentStatus: "Pending",
      receivedStatus: "Pending",
    },
  ];

  const checkPaymentStatus = ({ value }) => {
    return (
      <>
        <div className="mt-2">
          {value === "Paid" && (
            <>
              <div className="flex justify-center  items-center w-[67px] h-[26px] bg-[#ECFDF3] rounded-[5px] text-[13px] leading-[12px] font-normal text-base-primary">
                {value}
              </div>
            </>
          )}
          {value === "Pending" && (
            <>
              <div className="flex justify-center items-center w-[67px] h-[26px] bg-[#FEF3F2] rounded-[5px] text-[13px] leading-[12px] font-normal text-[#F04438]">
                {value}
              </div>
            </>
          )}
        </div>
      </>
    );
  };

  const checkPaymentReceivedStatus = ({ value }) => {
    return (
      <>
        <div className="mt-2">
          {value === "Received" && (
            <>
              <div className="flex justify-center  items-center w-[67px] h-[26px] bg-[#ECFDF3] rounded-[5px] text-[13px] leading-[12px] font-normal text-base-primary">
                {value}
              </div>
            </>
          )}
          {value === "Pending" && (
            <>
              <div className="flex justify-center items-center w-[67px] h-[26px] bg-[#FEF3F2] rounded-[5px] text-[13px] leading-[12px] font-normal text-[#F04438]">
                {value}
              </div>
            </>
          )}
        </div>
      </>
    );
  };

  const columnDefs = [
    {
      headerName: "Date",
      field: "date",
      minWidth: 170,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Vendor Type",
      field: "vendorType",
      filter: true,
    },
    {
      headerName: "Invoice Number",
      field: "invoiceNumber",
      filter: true,
    },
    {
      headerName: "Items",
      field: "items",
      filter: true,
    },
    {
      headerName: "Quantity",
      field: "quantity",
      filter: true,
    },
    {
      headerName: "Price",
      field: "price",
      filter: true,
    },
    {
      headerName: "Total Amount",
      field: "totalAmount",
      filter: true,
    },
    {
      headerName: "Payment Status",
      field: "paymentStatus",
      filter: true,
      cellRenderer: checkPaymentStatus,
    },
    {
      headerName: "Received Status",
      field: "receivedStatus",
      filter: true,
      cellRenderer: checkPaymentReceivedStatus,
    },
  ];

  const backToVendors = () => {
    navigate(ROUTES.VENDORS);
  };
  return (
    <>
      <div className="page-container">
        <div className="flex justify-between items-end">
          {/* <Seperator data={track} /> */}
          <BackButton onClick={backToVendors} />
        </div>
        <div className="mt-4">
          <CustomeTable
            tableName={"History Table"}
            columnDefs={columnDefs}
            rowData={tableData}
            setSelectedRows={setSelectedRows}
          />
        </div>
      </div>
    </>
  );
}

export default VendorHistory;
