import React, { useState } from "react";
import CustomeTable from "../../components/widget/CustomeTable";

const OccupationalInformation = () => {
  const [selectedRows, setSelectedRows] = useState([]);

  const tableData = [
    {
      qualification: "BE Engineering",
      qesignation: "General Manager",
      department: "Admin",
      reportingTo: "CEO",
      joiningDate: "19 April 2024",
    },
  ];
  const columnDefs = [
    {
      headerName: "Qualification",
      field: "qualification",
      minWidth: 200,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Qesignation",
      field: "qesignation",
      filter: true,
    },
    {
      headerName: "Department",
      field: "department",
      filter: true,
    },
    {
      headerName: "Reporting To",
      field: "reportingTo",
      filter: true,
    },
    {
      headerName: "Joining Date",
      field: "joiningDate",
      filter: true,
    },
  ];
  return (
    <CustomeTable
      size="h-[50vh]"
      tableName={"Occupational Information List Table"}
      columnDefs={columnDefs}
      rowData={tableData}
      setSelectedRows={setSelectedRows}
    />
  );
};

export default OccupationalInformation;
