import { createSlice } from "@reduxjs/toolkit";

export const hamburgerSlice = createSlice({
  name: "toggle/hamburger",
  initialState: {
    open: true,
  },
  reducers: {
    togglehamburger: (state, action) => { 
      state.open = action.payload || !state.open;
    },
  },
});

// Export the action to be used in components
export const { togglehamburger } = hamburgerSlice.actions;
