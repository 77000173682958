import React, { useEffect, useState } from "react";
import SelectFromObject from "../../components/form/SelectFromObject";
import Inputfield from "../../components/form/InputField";
import PhoneWithCode from "../../components/form/PhoneWithCode";
import AddMoreSupAdmin from "./AddMoreSupAdmin";
import { FaRegSquarePlus } from "react-icons/fa6";
import {
  sendPhoneOtpAction,
  verifyPhoneOtpAction,
} from "../../lib/slices/auth/commonOtpSlice";
import { useDispatch, useSelector } from "react-redux";
import { addProprtyContactAction } from "../../lib/slices/hotel/hotelSlice";
import { VscVerifiedFilled } from "react-icons/vsc";

const PropertyContact = () => {
  const dispatch = useDispatch();
  const [position, setPosition] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [admin, setAdmin] = useState(1);

  const [isVerified, setIsVerified] = useState(false);
  const [isSendOtp, setIsSendOtp] = useState(false);
  const [otpId, setOtpId] = useState("");

  const { hotel } = useSelector((state) => state.hotel);

  useEffect(() => {
    console.log("check conatcts :",hotel, hotel.contacts);
  }, [hotel]);

  const sendOtp = () => {
    sendPhoneOtpAction({ phone }).then((res) => {
      if (res.otp_id) {
        setOtpId(res.otp_id);
        setIsSendOtp(true);
      }
    });

    // if (sendOtp.otpId) {

    // }
    // console.log("sent otp :", sendOtp);
  };

  const verifiedOtp = () => {
    if (otpId && firstName && lastName && phone) {
      verifyPhoneOtpAction({ otpCode: otp, otpId, phone }).then((res) => {
        if (res.token) {
          dispatch(
            addProprtyContactAction({
              hotelId: hotel._id,
              contacts: [
                {
                  position,
                  name: firstName + "_" + lastName,
                  phone,
                  token: res.token,
                },
              ],
            })
          );
        }
      });
      setIsVerified(true);
    }

    // setOtp("")
  };

  const addMoreSuperAdmin = () => {
    setAdmin(admin + 1);
  };
  return (
    <>
      <div className=" border capitalize">
        <form
          // onSubmit={addPropertyDetails}
          className="w-full grid grid-cols-1 gap-6"
        >
          <div className="">
            <h2 className="font-medium text-[#07090D] mb-2">Who am i ? *</h2>
            <SelectFromObject
              value={position}
              setValue={setPosition}
              options={{
                OWNER: "OWNER",
                MANAGER: "MANAGER",
                EMPLOYEE: "EMPLOYEE",
              }}
            />
          </div>
          <div className="">
            <h2 className="font-medium text-[#07090D] mb-2">Details *</h2>
            <div className="flex items-center gap-4 mb-4">
              <Inputfield
                placeholder={"First Name"}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                minLength={3}
                maxLength={30}
              />
              <Inputfield
                placeholder={"Last Name"}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                minLength={3}
                maxLength={30}
              />
            </div>
            <PhoneWithCode
              phone={phone}
              setPhone={setPhone}
              placeholder={"Contact No"}
              // readOnly={isExist && !edit}
            />
            <button
              type="button"
              className="border flex items-center gap-4  text-[14px] leading-6 font-medium text-base-primary hover:text-base-primary_hover duration-300 ease-[0.3s] my-4"
              onClick={() => sendOtp()}
            >
              <div className="">SendOTP</div>
              {isSendOtp ? <VscVerifiedFilled /> : null}
            </button>

            <Inputfield
              placeholder={"Enter OTP"}
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              min={3}
              max={30}
            />
            <button
              type="button"
              className="h-12 w-full rounded-md bg-[#E9FBF0] hover:bg-base-primary hover:text-white duration-300 ease-[0.3s] shadow-[0_1px_2px_0_rgba(16,24,40,0.05)] text-[16px] leading-5 font-medium text-base-primary my-4"
              onClick={() => verifiedOtp()}
            >
              {isVerified ? "Verified" : "Verify"}
            </button>
          </div>
          {/* select who is super admin */}
          {[...Array(admin)]?.map((_, index) => (
            <AddMoreSupAdmin key={index} />
          ))}

          <button
            type="button"
            className="flex items-center justify-center gap-4 rounded-md h-11 w-fit bg-[#F6F6F6] hover:bg-[#c9c9c9] duration-300 ease-[0.3s] text-[16px] leading-4 text-[#07090D] tracking-[-0.6%] font-medium px-4"
            onClick={() => addMoreSuperAdmin()}
          >
            <FaRegSquarePlus size={24} />
            {"Add More Super Admin"}
          </button>
        </form>
      </div>
    </>
  );
};
export default PropertyContact;
