import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authAxios from "../../services/authAxios";

// get hotel wifi Action
export const getWifiAction = createAsyncThunk(
  "GET_WIFI_ACTION",
  async (credentials, thunkAPI) => {
    try {
      const { hotelId } = credentials;
      if (!hotelId) return;
      const url = `/api/hotel/owner/wifi?hotelId=${hotelId}`;
      const response = await authAxios.get(url);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// add hotel wifi action
export const addWifiAction = createAsyncThunk(
  "ADD_WIFI_ACTION",
  async (credentials, thunkAPI) => {
    try {
      const url = `/api/hotel/owner/wifi`;
      const response = await authAxios.post(url, credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// update HOTEL wifi action
export const updateWifiAction = createAsyncThunk(
  "UPDATE_WIFI_ACTION",
  async (credentials, thunkAPI) => {
    try {
      const url = `/api/hotel/owner/wifi`;
      const response = await authAxios.put(url, credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// delete hotel wifi action
export const deleteWifiAction = createAsyncThunk(
  "DELETE_WIFI_ACTION",
  async (credentials, thunkAPI) => {
    try {
      const url = `/api/hotel/owner/wifi`;
      const response = await authAxios.delete(url, credentials);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

// Helper function for handling pending state
const handlePending = (state) => {
  state.loading = true;
  state.error = null;
};

// Helper function for handling fulfilled state
const handleFulfilled = (state, action) => {
  state.loading = false;
  state.wifi = action.payload;
};

// Helper function for handling rejected state
const handleRejected = (state, action) => {
  state.loading = false;
  state.error = action.payload || "An error occurred";
};

// hotel wifi slice
export const hotelWifiSlice = createSlice({
  name: "HOTEL_WIFI_SLICE",
  initialState: {
    loading: false,
    wifi: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getWifiAction.pending, handlePending)
      .addCase(getWifiAction.fulfilled, handleFulfilled)
      .addCase(getWifiAction.rejected, handleRejected)
      .addCase(addWifiAction.pending, handlePending)
      .addCase(addWifiAction.fulfilled, handleFulfilled)
      .addCase(addWifiAction.rejected, handleRejected)
      .addCase(updateWifiAction.pending, handlePending)
      .addCase(updateWifiAction.fulfilled, handleFulfilled)
      .addCase(updateWifiAction.rejected, handleRejected)
      .addCase(deleteWifiAction.pending, handlePending)
      .addCase(deleteWifiAction.fulfilled, handleFulfilled)
      .addCase(deleteWifiAction.rejected, handleRejected)
  },
});