import React from "react";
import ToolTip from "../../components/common/ToolTip";
import { useTranslation } from "react-i18next";

const RoomTypeBedTypes = ({ setBedType, options, value, setValue }) => {
  const { t } = useTranslation();
  //set number of beds in one rooms
  const setNumberOfBedsInRoom = (value) => {
    setValue(value);
    setBedType([]);
    let arr = [];
    [...Array(value)].forEach((element, index) => {
      arr.push({ bedNumber: +index + 1, details: options[0]?.type });
    });
    setBedType(arr);
  };

  return (
    <div>
      <div className="flex justify-between">
        <label className="text-sm font-semibold text-[#344054]">
          {t("101")}
        </label>
        <ToolTip Message={t("102")} />
      </div>
      <div className="relative flex items-center">
        <select onChange={(e) => setNumberOfBedsInRoom(+e?.target?.value)}>
          {[...Array(10)].map((data, index) => (
            <option value={index + 1} key={index}>
              {index + 1}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default RoomTypeBedTypes;
